<template>
  <div>
    <el-row align="middle" class="m-b-h">
      <el-col :md="24" :lg="12">
        <div>
          <slot name="header">
            <el-row type="flex" :gutter="4" align="middle" justify="center">
              <el-col :span="-1">
                <slot name="commands"></slot>
              </el-col>
              <el-col :span="10" class="m-r-h" v-if="!notFound">
                <el-button-group>
                  <t-tooltip-button
                    :content="$t('storage.commands.download')"
                    @click="() => $emit('download')"
                    icon="el-icon-icon-cloud-download icon-1_3x"
                  ></t-tooltip-button>

                  <t-tooltip-button
                    :content="$t('storage.commands.open')"
                    @click="() => $emit('open')"
                    icon="el-icon-icon-eye4 icon-1_3x"
                  ></t-tooltip-button>
                </el-button-group>
              </el-col>
              <el-col class="text">
                <slot name="info"></slot>
              </el-col>
            </el-row>
          </slot>
        </div>
      </el-col>
      <el-col :md="24" :lg="12">
        <div v-if="value != null && value.length > 1" class="text-right">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="value.length"
            :page-size="1"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>
    <div>
      <div
        class="preview-wrapper"
        v-touch:swipe.left="swipeLeftHandler"
        v-touch:swipe.right="swipeRightHandler"
        v-if="!imagePreviewError"
      >
        <div class="text-center" v-loading="!imageLoaded">
          <img
            v-if="preview != null"
            :src="preview.url"
            class="preview-image"
            @load="imageLoaded = true"
            @error="handleErrorUrl"
          />
        </div>
      </div>
      <el-alert
        v-else
        :closable="false"
        type="error"
        :title="notFound ? $t('storage.error.notFound') : $t('storage.error.preview')"
      ></el-alert>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { Getter, Action, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'FilePreviews',
})
export default class FilePreview extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  value: any[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  notFound: boolean;

  preview: any[] = null;

  currentPage = 1;

  imageLoaded = false;

  imagePreviewError = false;

  async mounted() {
    if (this.value.length === 0) {
      this.imagePreviewError = true;
    }
    this.handleCurrentChange(this.currentPage);
  }

  handleCurrentChange(page: number) {
    if (this.value != null && page > 0 && page <= this.value.length) {
      this.imageLoaded = false;
      this.imagePreviewError = false;
      this.preview = this.value[page - 1];
      this.currentPage = page;
    }
  }

  handleErrorUrl() {
    this.imagePreviewError = true;
  }

  swipeLeftHandler() {
    this.handleCurrentChange(this.currentPage + 1);
  }

  swipeRightHandler() {
    this.handleCurrentChange(this.currentPage - 1);
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
.preview-wrapper {
  background-color: $--background-color-base;
  border: $--border-base;
  & > div {
    margin: $-margin-size;
  }
  & .preview-image {
    background-color: white;
    border: $--border-base;
    max-width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .preview-wrapper {
    & > div {
      margin: $-margin-size-quarter;
    }
  }
}
</style>
