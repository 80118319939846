<template>
  <span>
    <t-form-item
      :label="schema.text"
      :prop="schema.propertyName"
      :required="schema.required"
      v-if="!simple"
      :important="schema.important"
    >
      <t-flow-trigger-lookup
        v-if="!isReadOnly"
        :value="selectedData"
        @input="change"
        :multipleSelection="schema.multiple"
        :drawer="drawer"
      ></t-flow-trigger-lookup>
      <t-lookup-tag
        v-else
        :value="list"
        :key="list.key"
        v-for="list in selectedData"
        :closable="false"
      ></t-lookup-tag>
    </t-form-item>
    <t-flow-trigger-lookup
      v-else
      :value="selectedData"
      @input="change"
      :multipleSelection="multiple"
      :drawer="drawer"
      :read-only="isReadOnly"
    ></t-flow-trigger-lookup>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 'FlowTriggerInput',
})
export default class FlowTriggerInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  async resolve(id: number): Promise<string> {
    return (await this.resolveController.FlowTrigger(id)).name;
  }

  protected get identifier(): string {
    return 'FlowTrigger';
  }
}
</script>
