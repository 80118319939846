//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SalesTargetType {
	NewOpportunityNumber = 1,
	NewOpportunityImport = 2,
	NewQuoteNumber = 3,
	NewQuoteGrossAmount = 4,
	NewQuoteNetAmount = 5,
	NewOrderNumber = 6,
	NewOrderGrossAmount = 7,
	NewOrderNetAmount = 8
}
