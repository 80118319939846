//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LayoutStudioDepth {
	Company = 0,
	Group = 1,
	Team = 2,
	User = 3
}
