//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GlobalSearchType {
	Companies = 0,
	Contacts = 1,
	Ticket = 2,
	Lead = 3,
	Campaign = 4,
	Project = 5,
	Storage = 6,
	Sales = 7,
	Activity = 8,
	Appointment = 9
}
