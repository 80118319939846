/* eslint-disable no-unused-expressions */
import Vue, { PluginObject } from 'vue';

class TinyMceInstaller implements PluginObject<any> {
  install(vue: typeof Vue, options?: any) {
    vue.component('vue-mce', () => {
      import(/* webpackChunkName: "tinymce" */ 'tinymce/tinymce');
      import(/* webpackChunkName: "tinymce" */ 'tinymce/themes/silver/theme');
      import(/* webpackChunkName: "tinymce" */ 'tinymce/themes/mobile/theme');
      const tiny = import(/* webpackChunkName: "tinymce" */ 'components/misc/TinyMce.vue');
      return tiny;
    });
  }
}

export default () => {
  Vue.use(new TinyMceInstaller());
};
