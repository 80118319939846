//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OfflinePermits {
	AllowOfflineFullSync = 116,
	ExcludeChildLocationOnSync = 118,
	AllowSignActivityReport = 138,
	AllowAttachActivityPhoto = 139,
	AllowUsingGPSPosition = 140,
	AllowViewCancelledActivity = 196
}
