//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IPasswordChangePayload } from './IPasswordChangePayload';
import { ILoginResult } from './ILoginResult';
import { IUser } from './IUser';
import { IUserLimit } from '../../Interfaces/Security/IUserLimit';
import { IUsageStats } from './IUsageStats';
import { IIspRequest } from './IIspRequest';
import { ICredentialsRequest } from './ICredentialsRequest';
import { IRefreshTokenRequest } from './IRefreshTokenRequest';
import { IMenuNode } from '../../Interfaces/Misc/IMenuNode';
import { IAuthenticationIdentity } from '../../Interfaces/IAuthenticationIdentity';
import { IConflictSessionRequest } from './IConflictSessionRequest';
import { ITokenRequest } from './OAuth2/ITokenRequest';

//element.Name=AuthController result.Name=AuthController element.FullName=Digita.Tustena.UIApiControllers.Authentication.AuthController);
/** Result of WebApiControllerGenerator activity */
export class AuthController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in AuthController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Activate(token: string, password: IPasswordChangePayload, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/Activate?'+((token === null || token === undefined) ? '' : ('&token='+encodeURIComponent(token))), password, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PreActivate(token: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/PreActivate?'+((token === null || token === undefined) ? '' : ('&token='+encodeURIComponent(token))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Get(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IUser>
	{
		return (this.axios.get('Auth', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Me(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IUser>
	{
		return (this.axios.get('Auth/Me', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Permits(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean[]>
	{
		return (this.axios.get('Auth/Permits', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Limits(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IUserLimit>
	{
		return (this.axios.get('Auth/Limits', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Stats(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IUsageStats>
	{
		return (this.axios.get('Auth/Stats', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Isp(credentials: IIspRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/Isp', credentials, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public IspAuth(credentials: ICredentialsRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Auth/IspAuth', credentials, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public IspValidate(credentials: ICredentialsRequest, userid: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/IspValidate?'+'&userid='+userid, credentials, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public IspOAuth(credentials: IIspRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/IspOAuth', credentials, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Login(credentials: ICredentialsRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/Login', credentials, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Refresh(refreshToken: IRefreshTokenRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/Refresh', refreshToken, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Logout(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Auth/Logout', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Menu(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMenuNode[]>
	{
		return (this.axios.get('Auth/Menu', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PreLogin(username: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationIdentity[]>
	{
		return (this.axios.post('Auth/PreLogin', JSON.stringify(username), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public Recover(username: string, recoverUserId?: number, recoverCustomerId?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Auth/Recover?'+((username === null || username === undefined) ? '' : ('&username='+encodeURIComponent(username)))+((recoverUserId === null || recoverUserId === undefined) ? '' : ('&recoverUserId='+recoverUserId))+((recoverCustomerId === null || recoverCustomerId === undefined) ? '' : ('&recoverCustomerId='+recoverCustomerId)), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidatePassword(token: string, password: IPasswordChangePayload, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Auth/ValidatePassword?'+((token === null || token === undefined) ? '' : ('&token='+encodeURIComponent(token))), password, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ChangePassword(token: string, password: IPasswordChangePayload, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILoginResult>
	{
		return (this.axios.post('Auth/ChangePassword?'+((token === null || token === undefined) ? '' : ('&token='+encodeURIComponent(token))), password, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Session(session: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Auth/Session', JSON.stringify(session), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public Conflict(request: IConflictSessionRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Auth/Conflict', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Token(request: ITokenRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Auth/Token', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Auth/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Auth/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
