import Vue from 'vue';
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import itLocale from 'element-ui/lib/locale/lang/it';
import VueObserveVisibility from 'vue-observe-visibility';
import Vddl from 'vddl';
import VCalendar from 'v-calendar';
import Fragment from 'vue-fragment';
import Vue2TouchEvents from 'vue2-touch-events';
import VueClipboard from 'vue-clipboard2';
import VueInputAutowidth from '@/plugins/directives/input-autowidth';

export default (i18n: VueI18n) => {
  Vue.use(Vddl);
  Vue.use(ElementUI, {
    size: 'mini',
    i18n: (key, value) => i18n.t(key, value),
  });
  Vue.use(VueObserveVisibility);
  Vue.use(VCalendar);
  Vue.use(Fragment.Plugin);
  Vue.use(Vue2TouchEvents);
  Vue.use(VueClipboard);
  Vue.use((v) => {
    v.copyText = v.prototype.$copyText;
  });
  Vue.use(VueInputAutowidth);
};
