//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IAvaliabilitySlot } from '../../../Calendar/Interfaces/IAvaliabilitySlot';
import { IBookingDTO } from '../../../DTO/IBookingDTO';

//element.Name=BookingController result.Name=BookingController element.FullName=Digita.Tustena.UIApiControllers.UI.Calendar.BookingController);
/** Result of WebApiControllerGenerator activity */
export class BookingController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in BookingController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Availabilities(minutes: number, startDate: Date, endDate: Date, userId?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<{ [key:string]: IAvaliabilitySlot[] }>
	{
		return (this.axios.get('Booking/Availabilities?'+'&minutes='+minutes+((startDate === null || startDate === undefined) ? '' : ('&startDate='+startDate.toISOString()))+((endDate === null || endDate === undefined) ? '' : ('&endDate='+endDate.toISOString()))+((userId === null || userId === undefined) ? '' : ('&userId='+userId)), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Get(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IBookingDTO>
	{
		return (this.axios.get('Booking?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Get2(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IBookingDTO>
	{
		return (this.axios.get('Booking', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Delete(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('Booking?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Put(dto: IBookingDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.put('Booking', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Print(dto: IBookingDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Booking/Print', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Confirm(appointmentId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Booking/Confirm?'+'&appointmentId='+appointmentId, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Booking/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Booking/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
