import Vue, { VNode } from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface IAppChildren extends Vue {
  children: ReadonlyArray<VNode>;
}
@Component({
  name: 'IFrameContent',
})
export default class IFrameContent extends Vue {
  @Prop({
    type: Number,
    default: 5,
  })
  scrollbarSize: number;

  render(h) {
    return h('iframe', {
      on: { load: this.renderChildren },
    });
  }

  iApp: IAppChildren;

  beforeUpdate() {
    // freezing to prevent unnessessary Reactifiation of vNodes
    if (this.iApp) {
      this.iApp.children = Object.freeze(this.$slots.default);
    }
  }

  renderChildren() {
    const children = this.$slots.default;
    const iframe = <HTMLIFrameElement> this.$el;
    const { body } = iframe.contentDocument;
    const el = document.createElement('DIV'); // we will mount or nested app to this element
    body.appendChild(el);

    const style = document.createElement('STYLE');
    style.setAttribute('type', 'text/css');
    style.innerText = `*::-webkit-scrollbar { width: ${this.scrollbarSize}px; height: ${this.scrollbarSize}px; }
    *::-webkit-scrollbar-track { background: #ddd; }
    *::-webkit-scrollbar-thumb { background: #666; }`;
    iframe.contentDocument.head.appendChild(style);

    const iApp = new Vue({
      name: 'iApp',
      // freezing to prevent unnessessary Reactifiation of vNodes
      data: { children: Object.freeze(children) },
      render(h) {
        return h('div', this.children);
      },
    });

    iApp.$mount(el); // mount into iframe

    this.iApp = iApp; // cache instance for later updates
  }
}
