<template>
  <span>
      <div v-for='sc in schema.content' v-bind:key='sc.name'>
        <t-widget :schema='sc' :data='data' @input='simpleEmit'></t-widget>
      </div>
    </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Skin',
  mixins: [simpleMixin],
})
export default class Skin extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;
}
</script>
