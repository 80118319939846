<template>
  <div>
    <el-card class="m-b-q cursor-pointer cerved-card" @click.native="handleCervedImport">
      <el-row type="flex">
        <el-col>
          <h3 class="text-primary">{{ value.dati_anagrafici.denominazione }}</h3>
        </el-col>
        <el-col class="text-right nowrap" :span="-1">
          <span>{{ value.dati_anagrafici.codice_fiscale }}</span>
          <span v-if="value.dati_anagrafici.codice_fiscale != value.dati_anagrafici.partita_iva">
            <span v-if="value.dati_anagrafici.codice_fiscale">&nbsp;/&nbsp;</span>
            {{ value.dati_anagrafici.partita_iva }}
          </span>
        </el-col>
      </el-row>
      <div v-if="value.dati_anagrafici.indirizzo" class="m-t-h">
        <div>
          <span>{{ value.dati_anagrafici.indirizzo.descrizione }}&nbsp;</span>
          <span>{{ value.dati_anagrafici.indirizzo.descrizione_comune }}&nbsp;</span>
          <span>{{ value.dati_anagrafici.indirizzo.cap }}&nbsp;</span>
          <span
            v-if="value.dati_anagrafici.indirizzo.provincia"
          >({{ value.dati_anagrafici.indirizzo.provincia }})</span>
        </div>
      </div>
      <div v-if="value.dati_attivita" class="m-t-h">
        <i>{{ value.dati_attivita.ateco }}</i>
      </div>
    </el-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';
import { CervedController } from 'rt/UIApiControllers/Utils/CervedController';
import { IAnagraphic } from 'rt/Base/Cerved/EntitySearch/IAnagraphic';

@Component({
  name: 'CervedItem',
})
export default class CervedItem extends Vue {
  cervedController = new CervedController(this.$http);

  @Prop({
    required: true,
    type: Object,
  })
  value: IAnagraphic;

  async handleCervedImport() {
    const h = this.$createElement;
    let overwrite = true;
    const id = this.value.dati_anagrafici.id_soggetto;
    const action = await this.$msgbox({
      title: this.$t('cerved.import.title').toString(),
      message: h('p', null, [
        h(
          'el-checkbox',
          {
            key: id,
            props: {
              checked: overwrite,
            },
            on: {
              input: (val) => {
                overwrite = val;
              },
            },
          },
          this.$t('cerved.import.overwrite').toString(),
        ),
      ]),
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showCancelButton: true,
      confirmButtonText: this.$t('commands.ok').toString(),
      cancelButtonText: this.$t('commands.cancel').toString(),
    });
    if (action === 'confirm') {
      this.$emit('import', { overwrite, id });
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
.cerved-card {
  &:hover {
    box-shadow: $--dialog-box-shadow;
  }
}
</style>
