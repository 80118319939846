import Vue from 'vue';
import VueI18n from 'vue-i18n';
import it from '../locales/it';
import en from '../locales/en';
import es from '../locales/es';
import de from '../locales/de';
import fr from '../locales/fr';
import ro from '../locales/ro';

import itLocale from 'element-ui/lib/locale/lang/it';
import enLocale from 'element-ui/lib/locale/lang/en';
import esLocale from 'element-ui/lib/locale/lang/es';
import deLocale from 'element-ui/lib/locale/lang/de';
import frLocale from 'element-ui/lib/locale/lang/fr';
import roLocale from 'element-ui/lib/locale/lang/ro';

export default (): VueI18n => {
  Vue.use(VueI18n);
  const europe = {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    currency_stats: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    },
  };
  const numberFormats = {
    it: europe,
    es: europe,
    fr: europe,
    de: europe,
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
      currency_stats: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      },
    },
    'en-GB': {
      currency: {
        style: 'currency',
        currency: 'GBP',
      },
      currency_stats: {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
      currency_stats: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      },
    },
  };
  const i18n = new VueI18n({
    numberFormats,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
      it: {
        ...it,
        ...itLocale,
      },
      en: {
        ...en,
        ...enLocale,
      },
      es: {
        ...es,
        ...esLocale,
      },
      fr: {
        ...fr,
        ...frLocale,
      },
      de: {
        ...de,
        ...deLocale,
      },
      ro: {
        ...ro,
        ...roLocale,
      },
    },
  });
  Vue.i18n = i18n;
  return Vue.i18n;
};
