//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InvoiceStatus {
	Paid = 0,
	ToPaid = 1,
	PartialPaid = 2,
	Expired = 3
}
