<template>
  <el-row
    :gutter="5"
    type="flex"
    align="middle"
    justify="center"
    v-if="value.displayNumber"
    class="phone m-b-h"
    @click.native="handlePhoneClick"
  >
    <el-col :span="-1">
      <el-tag :closable="false" type="success" effect="dark" v-if="icon != null">
        <i :class="icon"></i>
        <span class="m-l-q call-label">{{ $t('commands.call') }}</span>
      </el-tag>
    </el-col>
    <el-col>
      {{ value.displayNumber }}
      <span class="kind">{{ kind }}</span>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IPhoneNormalizedDTO } from 'dto/IPhoneNormalizedDTO';
import { Prop } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { PhoneNormalizeType } from 'rt/Core/PhoneNormalizeType';
import eventHubManger from 'modules/mixin/views/EventHub';

@Component({
  name: 'PhoneLabel',
})
export default class PhoneLabel extends Vue {
  @Getter('lists/resolve') resolver: (type: ListsType, key: number) => string;

  @Prop({
    type: Object,
    required: true,
  })
  value: IPhoneNormalizedDTO;

  @Prop({
    type: Number,
    required: true,
  })
  id: number;

  @Prop({
    type: Number,
    required: true,
  })
  type: BusinessObjectType;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  dial: boolean;

  get visible() {
    if (!this.value) {
      return false;
    }
    if (!isNaN(this.type)) {
      if (this.value.macroType === PhoneNormalizeType.Fax) {
        return false;
      }
    }
    return true;
  }

  get icon() {
    if (!isNaN(this.value.macroType)) {
      switch (this.value.macroType) {
        case PhoneNormalizeType.Phone:
        case PhoneNormalizeType.Phone2:
        case PhoneNormalizeType.Assistant:
          return 'el-icon-icon-phone2';
        case PhoneNormalizeType.MobilePhone:
        case PhoneNormalizeType.MobilePhone2:
          return 'el-icon-icon-mobile';
        case PhoneNormalizeType.Fax:
          return null;
      }
    }
    return 'el-icon-icon-phone2';
  }

  get kind(): string {
    let value = this.value.subType;
    if (value === 0) value = this.value.macroType;
    return this.resolver(ListsType.PhoneType, value);
  }

  handlePhoneClick() {
    return eventHubManger.getHub('phone-bar').$emit('dial', {
      number: this.value.displayNumber,
      id: this.id,
      type: this.type,
    });
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
.phone {
  color: $--color-info;
  font-size: $--font-size-small;
  cursor: pointer;
  .kind {
    font-size: 80%;
    color: $--color-info;
  }
}
</style>
