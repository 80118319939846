//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OpportunityStatus {
	Unknown = 0,
	Open = 1,
	Suspended = 2,
	CloseWin = 3,
	CloseLost = 4,
	NotPursued = 5
}
