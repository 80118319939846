<template>
  <t-form-item
    :label="schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <t-parameter-variable :value="value" @input="simpleEmit">
      <t-widget
        :schema="schema.widget"
        :data="data"
        :context="context"
        @input="v => $emit('input', v[schema.propertyName])"
        simple
      ></t-widget>
    </t-parameter-variable>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ParameterVariableInput',
  mixins: [simpleMixin],
})
export default class ParameterVariableInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: [Object, Array],
    required: true,
  })
  data: any;

  @Prop()
  value: any;

  @Prop({
    type: Object,
    required: false,
  })
  context: any;
}
</script>
