<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="breadcrumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="item.path" v-for="item in list" :key="item.path">{{ item.meta.label }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    isLast(index) {
      return index === this.list.length - 1;
    },
    showName(item) {
      let nitem = item;
      if (nitem.meta && nitem.meta.label) {
        nitem = nitem.meta && nitem.meta.label;
      }
      if (nitem.name) {
        nitem = nitem.name;
      }
      return nitem.path;
    },
  },
};
</script>
