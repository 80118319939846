//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FilterDate {
	CurrentFiscalYear = 1,
	PreviousFiscalYear = 2,
	TwoPreviousFiscalYear = 3,
	TwoPassedFiscalYear = 4,
	NextFiscalYear = 5,
	CurrentAndPreviousFiscalYear = 6,
	CurrentAndTwoPreviousFiscalYear = 7,
	CurrentAndNextFiscalYear = 8,
	CurrentFiscalQuarter = 9,
	CurrentAndNextFiscalQuarter = 10,
	CurrentAndPreviousFiscalQuarter = 11,
	NextFiscalQuarter = 12,
	PreviousFiscalQuarter = 13,
	CurrentAndNextTreeFiscalQuarter = 14,
	CurrentCalendarYear = 15,
	PreviousCalendarYear = 16,
	TwoPreviousCalendarYear = 17,
	TwoPassedCalendarYear = 18,
	NextCalendarYear = 19,
	CurrentAndPreviousCalendarYear = 20,
	CurrentAndTwoPreviousCalendarYear = 21,
	CurrentAndNextCalendarYear = 22,
	CurrentCalendarQuarter = 23,
	CurrentAndNextCalendarQuarter = 24,
	CurrentAndPreviousCalendarQuarter = 25,
	NextCalendarQuarter = 26,
	PreviousCalendarQuarter = 27,
	CurrentAndTreeNextCalendarQuarter = 28,
	PreviousMonth = 29,
	CurrentMonth = 30,
	NextMonth = 31,
	CurrentAndPreviousMonth = 32,
	CurrentAndNextMonth = 33,
	PreviousWeek = 34,
	CurrentWeek = 35,
	NextWeek = 36,
	Yesterday = 37,
	Today = 38,
	Tomorrow = 39,
	Last7Days = 40,
	Last30Days = 41,
	Last60Days = 42,
	Last90Days = 43,
	Last120Days = 44,
	Next7Days = 45,
	Next30Days = 46,
	Next60Days = 47,
	Next90Days = 48,
	Next120Days = 49,
	CurrentAndThreePreviousFiscalYear = 50,
	CurrentAndThreePreviousCalendarYear = 51,
	CurrentCalendarSixMonthly = 52,
	PreviousCalendarSixMonthly = 53
}
