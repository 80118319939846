//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum StatusImportance {
	Low = 0,
	BelowNormal = 1,
	Normal = 2,
	AboveNormal = 3,
	High = 4
}
