<template>
  <el-row type="flex" align="middle" justify="center">
    <el-col class="text-left" v-if="logoUrl !== null">
      <img :src="logoUrl" style="max-height: 75px; max-width: 140px" />
    </el-col>
    <el-col class="text-right">
      <!--eslint-disable-next-line vuejs-accessibility/anchor-has-content-->
      <a target="_blank" :href="brand.site">
        <img :src="`/${brand.id}/img/logo.svg`" width="140px" />
      </a>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';

// tslint:disable-next-line:variable-name
const AnonymousGetter = namespace('anonymous').Getter;
@Component({
  name: 'AnonymousHeader',
})
export default class AnonymousHeader extends Vue {
  @Getter brand;

  @AnonymousGetter logoUrl;
}
</script>
