//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DataSetExportType {
	XML = 0,
	ExcelXML = 1,
	TabDelimited = 2,
	CommaDelimited = 3,
	CommaDelimited2003 = 4,
	ADORecordSet = 5,
	SPSS = 6,
	CalcXML = 7,
	ExcelXMLPivot = 8,
	ExcelXLSX = 9,
	TXT = 10
}
