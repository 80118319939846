//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TustenaColorInterfaces {
	Metal = 1,
	AcidGreen = 2,
	UltraMarine = 3,
	Citrus = 4,
	Pink = 5,
	Raspberry = 6,
	Platinum = 7,
	Titanium = 8,
	Steel = 9
}
