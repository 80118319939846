import Vue from 'vue';

const l = 'T200-N545-L1@3E:1<4655-H2F2A19101:121313131-?00131J1?121022322012111313041-;342N1<1213721212010511141-92Z1;120:12521012020011111-81]1:111:1:11121122111-81]1:121601123212113111220-72^2813901210121111100410021-42d271:3202121011101301111-21D201O16<062306300215-10D13256B1-00D1612240C1.1D1422142D110D412K2.1D1503131F10100?1030002K3400002.1D1504130F121020341013012252213184205.1D1414131D122000301334102100301021100060140000003-01C1414140D12202223282000032021301334203-20C0513041B23202133372000032021302324203-40B2410131A25202134002120203110000001100000104110203-6B0R73021352040301010111050;040102';
let r = '';
let a = ' ';
for (let i = 0; i < l.length; i += 1) {
  const b = l.charCodeAt(i);
  if (b < 47) {
    r += '\r\n';
    a = b === 46 ? '@' : ' ';
  } else {
    r += a.repeat(b - 47);
    a = a === ' ' ? '@' : ' ';
  }
}
console.log(r);
if (!Vue.config.productionTip) {
  const messages = [];
  const privateLog = [];
  ['log', 'warn', 'error'].forEach((methodName) => {
    privateLog[methodName] = console[methodName];
    console[methodName] = (...args: any[]) => {
      const params = Array.prototype.slice.call(args, 1);
      const message = params.length ? Array.prototype.join.call(args) : args[0];
      messages.push({
        message,
        type: methodName, // "log", "warn", "error"
      });
    };
  });
  (window as any).printLog = () => {
    messages.forEach((m) => {
      privateLog[m.type](m.message);
    });
  };
  (window as any).restoreLog = () => {
    Object.keys(privateLog).forEach((methodName) => {
      console[methodName] = privateLog[methodName];
    });
  };
}
