<template>
  <t-field-header v-bind="$props" :value="value" :operator="operator" @operator-change="changeOperatorFilter" :valid="isValidFilter" @sort-change="v => $emit('sort-change', v)">
    <el-form autocomplete="off" @submit.native.prevent v-if="!isValidOperationFilter">
      <input autocomplete="false" name="hidden" type="text" style="display:none;" />
      <t-contact-input v-model="value" :multiple="false" simple @input="changeFilter"></t-contact-input>
    </el-form>
  </t-field-header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'ContactFieldHeader',
})
export default class ContactFieldHeader extends HeaderMixin {
  get isValidValueFilter() {
    return this.value != null && this.value > 0;
  }
}
</script>
