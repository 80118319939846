//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TustenaColor {
	SugarPaper = 0,
	Gold = 1,
	Olive = 2,
	Salmon = 3,
	Turquoise = 4,
	RosaAntico = 5,
	Violet = 6,
	DarkGreen = 7,
	Senape = 8,
	Azzurro = 9,
	Bordeaux = 10,
	Lilla = 11,
	LightGray = 12,
	Siena = 13,
	Orange = 14,
	None = 15,
	LightMaroon = 16,
	LightRed = 17,
	OrangePlus = 18,
	AcquaMarina = 19,
	Green = 20,
	GreyMaroon = 21,
	LightYellow = 22,
	LightAcquaMarina = 23,
	LightAzure = 24,
	LightViolet = 25,
	GreyRed = 26,
	LightPink = 27,
	LightFucsia = 28
}
