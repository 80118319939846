//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SocialKeyType {
	LinkedInCompany = 0,
	LinkedInCustomer = 1,
	LinkedInCustomerFromCompany = 2,
	FacebookUser = 3,
	FacebookPage = 4,
	TwitterUser = 5
}
