import { DEGREES } from '@amcharts/amcharts4/.internal/core/utils/Math';
import Vue from 'vue';

class I18nUtils {
  private repositories: ({
    loads: string[];
    loader: (lang: string) => Promise<any>;
  })[] = [];

  addRepository(repository: (lang: string) => Promise<any>) {
    this.repositories.push({ loads: [], loader: repository });
  }

  async loadLanguage(language: string) {
    console.log('load language', language, `${this.repositories.length} repositories`);
    if (language) {
      for (const repository of this.repositories) {
        if (repository.loads.indexOf(language) < 0) {
          try {
            const lang = await repository.loader(language);
            Vue.i18n.mergeLocaleMessage(language, lang.default);
            repository.loads.push(language);
          } catch (e) {
            console.warn(`error loading repository language ${language}`, e);
            repository.loads.push(language);
          }
        }
      }
    }
  }

  async setLanguage(language: string) {
    await this.loadLanguage(language);
    Vue.i18n.locale = language;
    Vue.axios.defaults.headers['Accept-Language'] = language;
    document.querySelector('html').setAttribute('lang', language);
  }
}
const i18nUtils = new I18nUtils();
export default i18nUtils;
