//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum StoragePermission {
	Read = 1,
	Write = 2,
	ChangeWorkflow = 4,
	Download = 8,
	Delete = 16,
	Public = 32,
	Link = 64
}
