<template>
  <el-card>
    <template #header v-if="header">
      <el-row>
        <el-col :md="12">
          <h1>
            <slot name="top-left"></slot>
          </h1>
        </el-col>
        <el-col class="text-right" align="right" :md="12" :class="{ 'm-t-h': $viewport.xs }">
          <slot name="top-right"></slot>
        </el-col>
      </el-row>
    </template>
    <el-row>
      <el-col>
        <slot></slot>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col>
        <slot name="bottom-left">
          <t-back-button-header :value="isMenuOpen" @input="openMenu"></t-back-button-header>
        </slot>
      </el-col>
      <el-col class="text-right">
        <slot name="bottom-right"></slot>
      </el-col>
    </el-row>
  </el-card>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'HeaderPage',
})
export default class HeaderPage extends Vue {
  @Prop({
    type: Boolean,
    default: true,
    required: false,
  })
  header: boolean;

  isMenuOpen = false;

  openMenu(v: boolean) {
    this.isMenuOpen = v;
    this.$root.$emit('openHeaderMenu', v);
  }
}
</script>
<style lang="scss" scoped>

</style>
