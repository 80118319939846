import Vue from 'vue';
import { Notification } from 'element-ui';
import { ElNotificationOptions } from 'element-ui/types/notification';
import { Gravity } from 'rt/Core/Gravity';

const getNotificationOptions = (message: string, gravity: number): ElNotificationOptions => {
  if (!message) return null;

  const msg = message
    .replace(/\&lt\;/g, '<')
    .replace(/\&gt\;/g, '>')
    .replace(/\&quot\;/g, '>')
    .replace(/\&amp\;/g, '>'); // decode HTML tags
  const linesBreakCount = (msg.match(/\<br\/\>/g) || []).length;
  const extraTime = linesBreakCount * 2000; // 2 more seconds foreach line of text

  const options: ElNotificationOptions = {
    title: '',
    message: msg,
    dangerouslyUseHTMLString: true,
    type: getNotificationType(gravity),
  };

  options.customClass = msg.length > 300 || linesBreakCount > 1 ? 'large' : null;

  switch (gravity) {
    case Gravity.Info:
      options.duration = 4500 + extraTime;
      break;
    case Gravity.Warn:
      options.duration = 8000 + extraTime;
      break;
    case Gravity.Error:
      options.duration = 10000 + extraTime;
      break;
    case Gravity.PeristentMsg:
      options.customClass = 'very-large';
      options.duration = 0; // do not auto-close
      break;
    case Gravity.Gamification:
      // options.iconClass = null;
      // options.customClass = null;
      // options.duration = null;
      break;
  }

  return options;
};

const getNotificationType = (type: number): 'success' | 'warning' | 'info' | 'error' => {
  switch (type) {
    case Gravity.Info:
      return 'success';
    case Gravity.Warn:
      return 'warning';
    case Gravity.Error:
      return 'error';
    case Gravity.PeristentMsg:
      return 'warning';
    case Gravity.Gamification:
      return 'info';
  }
  return 'info';
};

export default (message: string, gravity: number) => {
  if (message) {
    const notificationOptions = getNotificationOptions(message, gravity);
    if (notificationOptions) {
      Notification(notificationOptions);
      Vue.beep(1);
    }
  }
};
