import Vue, { PluginObject } from 'vue';

class FreeFieldComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-free-account', () => import(/* webpackChunkName: "freefield" */ './FreeAccount.vue'));
    vue.component('t-free-date', () => import(/* webpackChunkName: "freefield" */ './FreeDate.vue'));
    vue.component('t-free-input', () => import(/* webpackChunkName: "freefield" */ './FreeInput.vue'));
    vue.component('t-free-number', () => import(/* webpackChunkName: "freefield" */ './FreeNumber.vue'));
    vue.component('t-free-currency', () => import(/* webpackChunkName: "freefield" */ './FreeCurrency.vue'));
    vue.component('t-free-checkbox', () => import(/* webpackChunkName: "freefield" */ './FreeCheckBox.vue'));
    vue.component('t-free-multiple-select', () => import(/* webpackChunkName: "freefield" */ './FreeMultipleSelect.vue'));
    vue.component('t-free-select', () => import(/* webpackChunkName: "freefield" */ './FreeSelect.vue'));
    vue.component('t-free-time', () => import(/* webpackChunkName: "freefield" */ './FreeTime.vue'));
    vue.component('t-free-textarea', () => import(/* webpackChunkName: "freefield" */ './FreeTextArea.vue'));
    vue.component('t-free-account', () => import(/* webpackChunkName: "freefield" */ './FreeAccount.vue'));
    vue.component('t-free-hour', () => import(/* webpackChunkName: "freefield" */ './FreeHour.vue'));
    vue.component('t-free-iframe', () => import(/* webpackChunkName: "freefield" */ './FreeIframe.vue'));
  }
}
// () => import(/* webpackChunkName: "company" */'./TPrivacy.vue')
export default new FreeFieldComponents();
