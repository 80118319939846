<template>
  <el-dropdown class="schemaSelector" trigger="click" placeholder>
    <span class="el-dropdown-link">
      <span>{{ currentSchemaName }}</span>
      <i class="m-l-q el-icon-icon-arrow-down4"></i>
    </span>
    <template #dropdown><el-dropdown-menu>
        <el-dropdown-item v-for="item in schemas" :key="item.name">
          <el-row type="flex" :gutter="10">
            <el-col>
              <span @click="() => currentSchemaName = item.name">{{ item.name }}</span>
            </el-col>
            <el-col class="text" :span="-1" @click.native.stop="() => defaultClick(item)">
              <i class="el-icon-fa-thumbtack fas pin-icon" :class="item.isMyDefault ? 'selected' : null" />
            </el-col>
          </el-row>
        </el-dropdown-item>
      </el-dropdown-menu></template>
  </el-dropdown>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'schemaSelector',
  props: ['repeater', 'schemas', 'currentSchema'],
})
export default class SchemaSelector extends Vue {
  repeater: string;

  schemas: any[];

  currentSchema: string;

  async defaultClick(schema) {
    let endpoint;
    if (schema.isMyDefault) {
      endpoint = 'RemovePinSchemaAsMyDefault';
    } else {
      endpoint = 'PinSchemaAsMyDefault';
    }

    const response = await this.$http.post(`${this.repeater}/${endpoint}`, JSON.stringify(schema.name), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.currentSchemaName = schema.name;
  }

  get currentSchemaName() {
    return this.currentSchema;
  }

  set currentSchemaName(value) {
    this.$emit('schema-change', value);
  }
}
</script>
