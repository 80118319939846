<template>
  <el-aside width="65px" v-if="user" class="bside">
    <div class="commands">
      <el-row type="flex" justify="end" align="center" class="profile">
        <el-popover ref="popover-userprofile" placement="left" :width="300" v-model="showUserProfilePopOver">
          <user-profile @onboarding="$emit('onboarding')" @close="showUserProfilePopOver = false"></user-profile>
        </el-popover>
        <span @click="toggleCollpaseMenu" class="user-image" v-popover:popover-userprofile trigger="click">
          <t-tooltip-label :content="$t('bside.profile')" placement="left-start">
            <t-profile-image :userId="user.userId"></t-profile-image>
          </t-tooltip-label>
        </span>
      </el-row>
      <el-row type="flex" justify="center" class="mail"
        v-if="$modules.isModuleLoaded('webmail') && user.isAuthorizedToRead($enums.BusinessObjectType.WebMailAccount)">
        <el-popover ref="popover-mailbox" placement="left" :width="400" @show="showPopoverMailbox = true"
          @hide="showPopoverMailbox = false">
          <t-last-mails v-if="showPopoverMailbox" @close="$refs['popover-mailbox'].doClose()"></t-last-mails>
        </el-popover>
        <t-tooltip-label :content="$t('bside.webmail')" placement="left-start">
          <el-badge :value="unseenMails" :max="99" class="sidebar-badge">
            <t-circle-button type="default" icon="el-icon-icon-envelop" v-popover:popover-mailbox
              @dblclick.native="redirectToWebMail"></t-circle-button>
          </el-badge>
        </t-tooltip-label>
      </el-row>
      <el-row type="flex" justify="center" class="messages"
        v-if="$modules.isModuleLoaded('messages') && user.isAuthorizedToRead($enums.BusinessObjectType.Message)">
        <el-popover ref="popover-messages" placement="left" :width="400" @show="showPopoverMessages = true"
          @hide="showPopoverMessages = false">
          <t-last-messages v-if="showPopoverMessages" @close="$refs['popover-messages'].doClose()"></t-last-messages>
        </el-popover>
        <t-tooltip-label :content="$t('bside.messages')" placement="left-start">
          <el-badge :value="unseenMessages" :max="99" class="sidebar-badge">
            <t-circle-button type="default" icon="el-icon-icon-bubbles4" v-popover:popover-messages></t-circle-button>
          </el-badge>
        </t-tooltip-label>
      </el-row>

      <el-row type="flex" justify="center" class="search">
        <el-popover ref="popover-globalsearch" placement="left" :width="400" @show="speeching = false">
          <t-natural-search-input :speeching.sync="speeching" v-if="false"></t-natural-search-input>
          <t-global-search-input v-else></t-global-search-input>
        </el-popover>
        <span>
          <t-tooltip-label :content="$t('bside.search')" placement="left-start">
            <t-circle-button type="default" icon="el-icon-icon-search" v-popover:popover-globalsearch></t-circle-button>
          </t-tooltip-label>
        </span>
      </el-row>
      <transition name="el-zoom-in-top">
        <el-row type="flex" justify="center" class="spooler" v-if="$modules.isModuleLoaded('mailing') && isMailingRoute">
          <t-tooltip-label :content="$t('bside.spooler')" placement="left-start">
            <t-spooler-status></t-spooler-status>
          </t-tooltip-label>
        </el-row>
      </transition>
      <transition name="el-zoom-in-top">
        <el-row type="flex" justify="center" class="spooler"
          v-if="($modules.isModuleLoaded('company') || $modules.isModuleLoaded('contact') || $modules.isModuleLoaded('lead')) && isAnagraphicRoute">
          <t-tooltip-label :content="$t('bside.webdav.queue')" placement="left-start">
            <t-webdav-queue-status></t-webdav-queue-status>
          </t-tooltip-label>
        </el-row>
      </transition>
      <transition name="el-zoom-in-top">
        <t-tour-button></t-tour-button>
      </transition>
      <el-row type="flex" justify="center" class="exit">
        <el-popover ref="popover-exit" placement="left" :width="200" v-model="logoutPopoverVisible">
          <p>{{ $t('exit.confirm') }}</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="logoutPopoverVisible = false">{{ $t('commands.no') }}</el-button>
            <el-button type="primary" size="mini" @click="logout">{{ $t('commands.yes') }}</el-button>
          </div>
        </el-popover>
        <span>
          <t-tooltip-label :content="$t('bside.exit')" placement="left-start">
            <t-circle-button type="default" icon="el-icon-icon-esc" v-popover:popover-exit></t-circle-button>
          </t-tooltip-label>
        </span>
      </el-row>
    </div>
  </el-aside>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import {
  Action, Getter, Mutation, namespace,
} from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import HashIds from 'hashids';
import store from '@/store';
import UserProfile from './UserProfileSummary.vue';
import TLastMails from '../../webmail/views/LastMails.vue';
import TSpoolerStatus from '../../mailing/components/SpoolerStatus.vue';
import TWebdavQueueStatus from '../../profile/components/WebdavQueueStatus.vue';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import TLastMessages from '../../messages/views/LastMessages.vue';
import TLicence from '../../tustenaCompany/components/Licence.vue';
import TTourButton from '../../tour/components/TourButton.vue';
import { ISideBarSpoolerStatusResponse } from 'rt/UIApiControllers/UI/SideBar/ISideBarSpoolerStatusResponse';
import routeNames from '../../../router/routeNames';

// tslint:disable-next-line:variable-name
const WebMailAction = namespace('webmail').Action;
// tslint:disable-next-line:variable-name
const WebMailGetter = namespace('webmail').Getter;

// tslint:disable-next-line:variable-name
const MessagesAction = namespace('messages').Action;
// tslint:disable-next-line:variable-name
const MessagesGetter = namespace('messages').Getter;

@Component({
  name: 'BSidebar',
  components: {
    UserProfile,
    TLastMails,
    TLastMessages,
    TSpoolerStatus,
    TWebdavQueueStatus,
    TTourButton,
    TLicence,
  },
})
export default class BSidebar extends Vue {
  isCollapse = true;

  showPopoverMailbox = false;

  showPopoverLicence = false;

  showPopoverMessages = false;

  showUserProfilePopOver = false;

  logoutPopoverVisible = false;

  @WebMailGetter('unseen') unseenMails: number;

  @WebMailAction('loadUnseen') loadUnseenMails: () => Promise<void>;

  @MessagesGetter('unseen') unseenMessages: number;

  @MessagesAction('loadUnseen') loadUnseenMessages: () => Promise<void>;

  @Getter('user') user: IUser;

  @Getter('ticks') ticks;

  @Getter('baseUrl') baseUrl;

  @Action('logout') logout: () => Promise<void>;

  private userSize = 40;

  @Watch('user')
  handleUserAuth(to, from) {
    if (to && (from == null || to.userId !== from.userId)) {
      if (this.$modules.isModuleLoaded('webmail')) {
        this.loadUnseenMails();
      }
      if (this.$modules.isModuleLoaded('messages')) {
        this.loadUnseenMessages();
      }
    }
  }

  mounted() {
    if (this.user) {
      if (this.$modules.isModuleLoaded('webmail')) {
        this.loadUnseenMails();
      }
      if (this.$modules.isModuleLoaded('messages')) {
        this.loadUnseenMessages();
        this.$root.$on('reloadUnseenMessages', () => {
          this.loadUnseenMessages();
        });
      }
    }
  }

  get initials(): string {
    return this.user.initials;
  }

  get imageUrl() {
    const userHash = new HashIds('').encode(this.user.userId, this.user.customerId);
    const imgSize = this.$viewport.isRetina ? this.userSize * 2 : this.userSize;
    return `${this.baseUrl}/Avatar/User/${userHash}/${imgSize}?${this.ticks}`;
  }

  get isMailingRoute() {
    return this.isRoute('spooler');
  }

  get isAnagraphicRoute() {
    return this.isRoute('anagraphic');
  }

  isRoute(prop: string) {
    if (this.$route.matched) {
      for (const route of this.$route.matched) {
        if (route.meta[prop]) {
          return true;
        }
      }
    }
    return false;
  }

  toggleCollpaseMenu() {
    this.isCollapse = !this.isCollapse;
  }

  speeching = false;

  redirectToWebMail() {
    this.$router.push({
      name: this.$routeNames.webmail,
    });
  }
}
</script>

<style lang="scss" scoped>
@import '~element-variables';

.el-aside {
  overflow-x: hidden;
  background: $--color-primary;

  .el-row {
    height: 60px;
  }
}

.nl-suggest {
  margin-bottom: 8px;

  >div {
    line-height: 1em;
  }
}
</style>
<style lang="scss">
@import '~element-variables';

.WHF {
  color: $--color-warning;
}

.PHF {
  color: $--color-primary;
  padding-right: 4px;
  padding-left: 4px;
}

.bside {

  // position: relative;
  .commands {
    position: fixed;
    top: 2px;
    right: 2px;

    & .sidebar-badge {
      & .el-badge__content.is-fixed {
        top: -6px;
        right: unset;
        left: -6px;
        transform: none;
        -webkit-transform: none;
      }
    }
  }
}
</style>
