<template>
  <el-button
    type="primary"
    icon="el-icon-fa-file-certificate fas"
    :size="size"
    @click="handleMyAreaRedirect"
    :loading="loading"
    v-indexed
  >
    <slot>{{ $t('login.licenceExpired.buy') }}</slot>
  </el-button>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PurchaseController } from 'rt/UI/Manage/WebApi/PurchaseController';

@Component({
  name: 'MyAreaStoreButton',
})
export default class MyAreaStoreButton extends Vue {
  @Prop({
    required: false,
    type: String,
  })
  url: string;

  @Prop({
    required: false,
    type: String,
    default: 'big',
  })
  size: string;

  controller = new PurchaseController(Vue.axios);

  loading = false;

  async handleMyAreaRedirect() {
    if (this.url) {
      window.location.href = this.url;
      return;
    }
    try {
      this.loading = true;
      const url = await this.controller.Home();
      if (url != null) {
        window.location.href = url;
      }
    } finally {
      this.loading = false;
    }
  }
}
</script>
