<template>
  <t-form-item
    :label="schema.labelOnText ? '': schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <el-switch
      :disabled="schema.readOnly"
      :value="active"
      @input="change"
      :active-text="schema.labelOnText ? schema.text : ''"
    />
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'SwitchInput',
  mixins: [simpleMixin],
})
export default class SwitchInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: [Boolean, Number, String],
    required: true,
  })
  value: boolean | number | string;

  lastValue: boolean | number | string;

  get active() {
    if (this.schema.inactiveValue != null) {
      return this.value !== this.schema.inactiveValue;
    }
    if (this.schema.activeValue != null) {
      return this.value === this.schema.activeValue;
    }
    return this.value;
  }

  change(value) {
    if (value) {
      this.$emit('input', this.schema.activeValue ?? value);
    } else {
      this.$emit('input', this.schema.inactiveValue ?? value);
    }
  }
}
</script>
