//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';

//element.Name=TourController result.Name=TourController element.FullName=Digita.Tustena.UIApiControllers.UI.Tour.TourController);
/** Result of WebApiControllerGenerator activity */
export class TourController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in TourController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Get(routeName: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string[]>
	{
		return (this.axios.get('Tour?'+((routeName === null || routeName === undefined) ? '' : ('&routeName='+encodeURIComponent(routeName))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Put(routeName: string, target: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.put('Tour?'+((routeName === null || routeName === undefined) ? '' : ('&routeName='+encodeURIComponent(routeName)))+((target === null || target === undefined) ? '' : ('&target='+encodeURIComponent(target))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Tour/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Tour/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
