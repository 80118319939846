//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TicketMessageType {
	EmailSent = 0,
	EmailReceived = 1,
	PhoneCall = 2,
	Note = 3,
	ChangeOwner = 4,
	ChangeCategory = 5,
	ChangeStatus = 6,
	ChangeType = 7,
	Solution = 8,
	NotSolvable = 9,
	TicketInsert = 10,
	ChangeQuestion = 11,
	ChangeAnswer = 12,
	ChangeRMA = 13,
	ChangeArea = 14,
	ChangePriority = 15,
	None = 16,
	Activity = 17
}
