import Vue from 'vue';
import Component from 'vue-class-component';
import { ILeadDTO } from 'dto/ILeadDTO';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import BusinessObjectPageMixin from '@/modules/mixin/views/BusinessObjectPageMixin';
import eventHubManager from '@/modules/mixin/views/EventHub';
import { Getter, Mutation, namespace } from 'vuex-class';

// tslint:disable-next-line:variable-name
const LeadGetter = namespace('lead').Getter;
// tslint:disable-next-line:variable-name
const LeadMutation = namespace('lead').Mutation;

@Component({
  name: 'LeadViewMixin',
})
export default class LeadViewMixin extends Vue {
  @LeadGetter model: ILeadDTO;

  get eventHub(): Vue {
    return eventHubManager.getHub('lead');
  }

  get module(): string {
    return 'lead';
  }

  @LeadMutation setModel: (dto: ILeadDTO) => void;

  @LeadMutation setModelProperty: (n: string, v: any) => void;
}
