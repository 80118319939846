//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IZoneTreeViewRequest } from './IZoneTreeViewRequest';
import { ITreeViewResponse } from './ITreeViewResponse';
import { ITreeViewNodeResponse } from './ITreeViewNodeResponse';

//element.Name=ZoneTreeViewController result.Name=ZoneTreeViewController element.FullName=Digita.Tustena.UIApiControllers.TreeView.ZoneTreeViewController);
/** Result of WebApiControllerGenerator activity */
export class ZoneTreeViewController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in ZoneTreeViewController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Initialize(requestData: IZoneTreeViewRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewResponse>
	{
		return (this.axios.post('ZoneTreeView/Initialize', requestData, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LazyTreeNodes(requestData: IZoneTreeViewRequest, key: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewNodeResponse[]>
	{
		return (this.axios.post('ZoneTreeView/LazyTreeNodes?'+((key === null || key === undefined) ? '' : ('&key='+encodeURIComponent(key))), requestData, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public InitializeTree(requestData: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewResponse>
	{
		return (this.axios.post('ZoneTreeView/InitializeTree', JSON.stringify(requestData), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public LazyTree(requestData: string, key: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewNodeResponse[]>
	{
		return (this.axios.post('ZoneTreeView/LazyTree?'+((key === null || key === undefined) ? '' : ('&key='+encodeURIComponent(key))), JSON.stringify(requestData), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('ZoneTreeView/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('ZoneTreeView/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
