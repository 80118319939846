<template>
  <t-form-item
    :label="schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <t-duration :value="value" @input="changeDuration" :start="0" :step="15" :end="540"></t-duration>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'DurationInput',
  mixins: [simpleMixin],
})
export default class DurationInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  simpleEmit: (v: any) => void;

  get hours(): number {
    return Math.floor(this.value / 60);
  }

  get minutes(): number {
    return this.value % 60;
  }

  changeHour(h: number) {
    this.changeDuration(h * 60 + this.minutes);
  }

  changeMinutes(m: number) {
    let cm = m;
    if (cm < 0) cm = 0;
    this.changeDuration(this.hours * 60 + cm);
  }

  changeDuration(total: number) {
    let ctotal = total;
    if (ctotal < 0) ctotal = 0;
    this.$emit('input', ctotal);
  }

  get time() {
    return `${_.padStart(this.hours.toString(), 2, '0')}:${_.padStart(this.minutes.toString(), 2, '0')}`;
  }

  parseTime(time: string): void {
    const h = parseInt(time.split(':')[0], 10);
    const m = parseInt(time.split(':')[1], 10);
    let duration = h * 60 + m;
    if (isNaN(duration)) duration = 0;
    this.changeDuration(duration);
  }
}
</script>
