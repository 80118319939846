//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GlobalPermits {
	AllowCustomize = 50,
	AllowTodaySort = 51,
	AllowChat = 72,
	AllowViewNoZoneAnagraphic = 87,
	AllowMessageSend = 100,
	LoadSecondaryGroupsInMenu = 137,
	UseInSuggestPickerCustomerFilter = 180
}
