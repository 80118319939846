import i18nUtils from 'utils/i18nUtils';
import Vue, { PluginObject } from 'vue';

class ChartComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-web-mail-archive', () => import(/* webpackChunkName: "archive" */ './WebMailArchive.vue'));
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
  }
}
export default new ChartComponents();
