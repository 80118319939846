//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IExternalStorageSharingDTO } from '../../../DTO/IExternalStorageSharingDTO';
import { IStorageShare } from './IStorageShare';
import { IFilePreview } from './IFilePreview';
import { IStorageDTO } from '../../../DTO/IStorageDTO';

//element.Name=StorageController result.Name=StorageController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.StorageController);
/** Result of WebApiControllerGenerator activity */
export class StorageController extends DefaultDtoControllerProxy<IStorageDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Storages', BusinessObjectType.Storage);
	}
	public UploadMultipleFiles(request: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.post('Storages/UploadMultipleFiles', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public References(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Storages/References?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Reference(id: number, reference: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Storages/Reference?'+'&id='+id, reference, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteReference(id: number, crossId: number, crossType: BusinessObjectType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.delete('Storages/DeleteReference?'+'&id='+id+'&crossId='+crossId+'&crossType='+crossType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Share(content: IExternalStorageSharingDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IExternalStorageSharingDTO>
	{
		return (this.axios.put('Storages/Share', content, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Sharing(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageShare>
	{
		return (this.axios.get('Storages/Sharing?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Storages/Download?'+'&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Storages/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Storages/HtmlPreview?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Storages/Previews?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Storages/Preview?'+'&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Storages/GetDownloadUrl?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Storages/Archive?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Storages/PrintParameters?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO>
	{
		return (this.axios.get('Storages/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
