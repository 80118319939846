<template>
  <t-field-header v-bind="$props" :value="value" :operator="operator" @operator-change="changeOperatorFilter" :valid="isValidFilter" @sort-change="v => $emit('sort-change', v)">
    <el-checkbox :indeterminate="value === null" :value="value" @input="handleChangeFilter" v-if="!isValidOperationFilter"></el-checkbox>
  </t-field-header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'BooleanFieldHeader',
})
export default class BooleanFieldHeader extends HeaderMixin {
  handleChangeFilter() {
    if (this.value === null) {
      this.value = true;
    } else if (this.value) {
        this.value = false;
      } else {
        this.value = null;
      }
    this.changeFilter();
  }

  get isValidValueFilter() {
    return this.value != null;
  }
}
</script>
