<template>
  <t-form-item
    :label="schema.text || schema.id"
    :prop="schema.propertyName"
    :required="schema.required"
    key="input"
    :important="schema.important"
  >
    <el-autocomplete
      style="width: 100%"
      :value="value"
      @input="input"
      :fetch-suggestions="querySearchAsync"
      @select="handleSelect"
      value-key="description"
      :disabled="schema.readOnly"
    ></el-autocomplete>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { CitySuggestController } from 'rt/UIApiControllers/UI/Smart/CitySuggestController';

@Component({
  name: 'CityInput',
  mixins: [simpleMixin],
})
export default class CityInput extends Vue {
  citySuggest = new CitySuggestController(this.$http);

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
  })
  value: string;

  get state() {
    return this.data[this.schema.nationPropertyName] || null;
  }

  async querySearchAsync(term, cb) {
    const res = await this.citySuggest.SearchCity({
      nation: this.state,
      description: term,
    });
    cb(res);
  }

  handleSelect(item) {
    this.$emit('change', {
      ...this.data,
      [this.schema.propertyName]: item.description,
      [this.schema.provincePropertyName]: item.province,
      [this.schema.regionPropertyName]: item.region,
      [this.schema.zipCodePropertyName]: item.zipcode,
      [this.schema.nationPropertyName]: item.nation,
    });
  }

  input(value) {
    this.$emit('input', value);
  }
}
</script>
