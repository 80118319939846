<template>
  <div>
    <el-drawer :class="{ minimized, maximized: maximized }" class="phone-bar" direction="btt" :visible="phoneBarVisible"
      modal-append-to-body :modal="false" :close-on-press-escape="false" :show-close="false"
      :size="minimized ? '42px' : '120px'" :wrapperClosable="false">
      <template #title>
        <div v-if="calling">
          <el-row class="text text-1x" type="flex">
            <el-col :span="minimized ? 7 : 12">
              <span v-if="maximized" class="m-l-h">{{ $t('phoneBar.calling', { number }) }}</span>
              <span v-else>
                <span class="el-icon-icon-phone2 text-primary"></span>
                <span class="shake-little shake-constant shake-constant--hover">
                  <strong class="m-l-h nowrap" v-if="number">{{ number }}</strong>
                </span>
              </span>
            </el-col>
            <el-col class="text-primary" :span="10" v-if="minimized">
              <router-link :to="$routeHelper.findRouteFromBusinessObjectType(routes, crossType, crossId)"
                v-if="crossType != null && crossId != null">
                <t-icon-label :icon="'el-icon-' + $boMapping.toIcon(crossType)">
                  <t-bo-resolve-label :value="crossId" :type="crossType"></t-bo-resolve-label>
                </t-icon-label>
              </router-link>
            </el-col>
            <el-col class="text text-right p-r-h" :span="minimized ? 7 : 12">
              <span v-if="maximized">{{ $t('phoneBar.duration', { duration }) }}</span>
              <span v-else-if="duration">
                <span class="text-primary">{{ duration }}</span>
              </span>
            </el-col>
            <el-col class="text-right nowrap" :span="-1">
              <transition name="el-zoom-in-center">
                <i class="el-icon-fa-phone-slash fas text-primary cursor-pointer" v-if="minimized"
                  @click="closeRegisterActivity"></i>
              </transition>
              <i class="m-l-q fas cursor-pointer" @click="toggleMinimized"
                :class="{ 'el-icon-fa-window-maximize': minimized, 'el-icon-fa-window-minimize': maximized }"></i>
            </el-col>
          </el-row>
        </div>
        <el-col v-else-if="incoming != null">
          <el-row class="text text-1x" type="flex" align="middle" justify="center">
            <el-col :span="-1">
              <el-button type="success" circle icon="el-icon-fa-phone-alt fas" @click="acceptCall"></el-button>
            </el-col>
            <el-col :span="7">
              <span class="m-l-h text-1_2x">
                <span>{{ $t('phoneBar.incoming') }}</span>
                <span class="m-l-q text-primary">{{ incoming.number }}</span>
              </span>
            </el-col>
            <el-col class="text-primary" :span="17">
              <span v-if="incoming.matches">
                <el-popover placement="bottom">
                  <div v-for="(m, index) in incoming.matches" :key="index">
                    <el-tag :type="$boMapping.toTagType(m.type)" size="medium" class="cursor-pointer m-b-q"
                      @click="() => selectIncomingAnagraphicAndRedirect(m)">
                      <t-icon-label :icon="`el-icon-${$boMapping.toIcon(m.type)}`">{{ m.name }}</t-icon-label>
                    </el-tag>
                  </div>
                  <template #reference>{{ $tc('phoneBar.incomings', incoming.matches.length, { name: incoming.name })
                  }}</template>
                </el-popover>
              </span>
            </el-col>
            <el-col class="text-right nowrap" :span="-1">
              <transition name="el-zoom-in-center">
                <el-button type="danger" circle icon="el-icon-fa-phone-slash fas" @click="dropCall"></el-button>
              </transition>
              <i class="m-l-q fas cursor-pointer" @click="toggleMinimized"
                :class="{ 'el-icon-fa-window-maximize': minimized, 'el-icon-fa-window-minimize': maximized }"></i>
            </el-col>
          </el-row>
        </el-col>
      </template>
      <div v-if="maximized">
        <div v-if="calling">
          <el-row type="flex" :gutter="10">
            <el-col :span="10" class="m-l">
              <div v-if="crossType != null && crossId != null">
                <el-popover placement="bottom" v-if="matches && matches.length > 1">
                  <div v-for="(m, index) in matches" :key="index">
                    <el-tag :type="$boMapping.toTagType(m.type)" size="medium" class="cursor-pointer m-b-q"
                      @click="() => selectAnagraphicAndRedirect(m)">
                      <t-icon-label :icon="`el-icon-${$boMapping.toIcon(m.type)}`">{{ m.name }}</t-icon-label>
                    </el-tag>
                  </div>
                  <template #reference><span class="text-primary">
                      <t-icon-label :icon="'el-icon-' + $boMapping.toIcon(crossType)">
                        <t-bo-resolve-label :value="crossId" :type="crossType"></t-bo-resolve-label>
                      </t-icon-label>
                    </span></template>
                </el-popover>
                <router-link :to="$routeHelper.findRouteFromBusinessObjectType(routes, crossType, crossId)" v-else>
                  <t-icon-label :icon="'el-icon-' + $boMapping.toIcon(crossType)">
                    <t-bo-resolve-label :value="crossId" :type="crossType"></t-bo-resolve-label>
                  </t-icon-label>
                </router-link>
              </div>
            </el-col>
            <el-col :span="10">
              <div>
                <span class="text" v-if="implicitConsent">
                  <span class="text-info">{{ $t('activity.privacy.implicit.label') }}:</span>
                  <t-resolve-label v-if="implicitConsent.motivationImplicitConsentId"
                    :value="implicitConsent.motivationImplicitConsentId"
                    :type="$enums.ListsType.PrivacyMotivationsImplicitConsent"></t-resolve-label>
                  <span class="m-l pre-wrap">{{ implicitConsent.notes | hellip }}</span>
                </span>
              </div>
            </el-col>
            <el-col :span="-1" class="nowrap p-r p-l">
              <el-button type="primary" icon="el-icon-fa-phone-slash fas" @click="dropCall">{{
                $t('phoneBar.drop')
              }}</el-button>
              <el-button type="primary" icon="el-icon-fa-phone-slash fas" @click="closeRegisterActivity">{{
                $t('phoneBar.dropAndRegister')
              }}</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="implicitDialogVisible" :before-close="onBeforeCloseDialog" :close-on-click-modal="false"
      append-to-body>
      <t-activity-implicit-consent v-model="implicitModel"></t-activity-implicit-consent>
      <div class="text-right m-t-h">
        <el-button class="m-r-q" @click="handleCancelImplicit">{{ $t('commands.cancel') }}</el-button>
        <el-button type="primary" @click="handleOkImplicit"
          :disabled="!implicitModel.motivationImplicitConsentId > 0">{{ $t('commands.ok') }}</el-button>
      </div>
    </el-dialog>
    <t-voispeed-advertise></t-voispeed-advertise>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import store from '@/store';
import eventHubManager from 'modules/mixin/views/EventHub';
import { PrivacyController } from 'rt/UIApiControllers/UI/CRM/PrivacyController';
import TVoispeedAdvertise from '../components/VoispeedAdvertise.vue';
import { IActivityDTO } from 'dto/IActivityDTO';
import { RouteConfig } from 'vue-router';
import moment from 'moment';

// tslint:disable-next-line:variable-name
const PhoneBarAction = namespace('phone-bar').Action;
// tslint:disable-next-line:variable-name
const PhoneBarGetter = namespace('phone-bar').Getter;
// tslint:disable-next-line:variable-name
const PhoneBarMutation = namespace('phone-bar').Mutation;
@Component({
  name: 'PhoneBar',
  components: {
    TVoispeedAdvertise,
  },
})
export default class PhoneBar extends Vue {
  privacyController = new PrivacyController(Vue.axios);

  @Getter('routes') routes: RouteConfig[];

  @Getter user: IUser;

  @PhoneBarMutation setImplicitConsent: (payload) => void;

  @PhoneBarMutation selectIncoming: (m) => void;

  @PhoneBarMutation selectAnagraphic: (m) => void;

  @PhoneBarAction dial: (payload) => Promise<any>;

  @PhoneBarAction drop: () => Promise<any>;

  @PhoneBarAction accept: () => Promise<any>;

  @PhoneBarGetter callKey: string;

  @PhoneBarGetter number: string;

  @PhoneBarGetter crossType: number;

  @PhoneBarGetter crossId: number;

  @PhoneBarGetter startDate: Date;

  @PhoneBarGetter implicitConsent: any;

  @PhoneBarGetter minimized: any;

  @PhoneBarMutation setMinimized: (minimized: boolean) => void;

  @PhoneBarGetter calling: boolean;

  @PhoneBarGetter matches: [{ type: number; id: number; name: string; selected: boolean }];

  @PhoneBarGetter direction: 'outbound' | 'inbound';

  // @PhoneBarMutation onRegisterActivity: () => void;
  @PhoneBarGetter incoming: {
    callId: string;
    name: string;
    number: string;
    matches: [{ type: number; id: number; name: string; selected: boolean }];
  };

  created() {
    eventHubManager.getHub('phone-bar').$on('dial', this.handleDialClick);
    eventHubManager.getHub('phone-bar').$on('registerActivity', (a) => this.handleRegisterActivity(a));
  }

  beforeDestroy() {
    eventHubManager.getHub('phone-bar').$off('dial');
    eventHubManager.getHub('phone-bar').$off('registerActivity');
    if (this.durationInterval) {
      window.clearInterval(this.durationInterval);
    }
  }

  duration: string = null;

  durationInterval: number = null;

  implicitDialogVisible = false;

  implicitModel: { motivationImplicitConsentId: number; notes: string } = { motivationImplicitConsentId: null, notes: null };

  implicitAwaiterResolver: () => void = null;

  implicitAwaiterRejecter: () => void = null;

  get maximized() {
    return !this.minimized;
  }

  get phoneBarVisible(): boolean {
    return this.calling || this.incoming != null;
  }

  @Watch('phoneBarVisible') handlePhoneBarVisible(v: boolean) {
    if (v) {
      this.durationInterval = window.setInterval(this.setDuration, 1000);
    } else {
      if (this.durationInterval) {
        this.duration = null;
        window.clearInterval(this.durationInterval);
      }
    }
  }

  toggleMinimized(v) {
    this.setMinimized(!this.minimized);
  }

  onBeforeCloseDialog(done) {
    done();
    this.implicitAwaiterRejecter();
  }

  handleCancelImplicit() {
    this.implicitDialogVisible = false;
    this.implicitAwaiterRejecter();
  }

  handleOkImplicit() {
    this.implicitDialogVisible = false;
    this.implicitAwaiterResolver();
  }

  setDuration() {
    const startDate = this.startDate;
    if (startDate) {
      const duration = moment.duration(this.$moment(new Date()).diff(startDate));
      this.duration = duration.format('h [hour] :mm [min] :ss [sec]');
    } else {
      this.duration = null;
    }
  }

  async handleDialClick(payload: { type: number; id: number; number: string }) {
    if (payload.number === this.number) {
      return;
    }
    if (this.calling) {
      const res = await this.$confirm(this.$t('phoneBar.conflict.description', { number: this.number }) as string, this.$t('phoneBar.conflict.title') as string, {
        // type: 'success',
        confirmButtonText: this.$t('phoneBar.conflict.close') as string,
        cancelButtonText: this.$t('commands.cancel') as string,
      })
        .then((value) => true)
        .catch(() => false);
      if (res) {
        await this.closeRegisterActivity();
      }
      return;
    }

    const res = await this.privacyController.ExistsPermitToContact({
      crossId: payload.id,
      crossType: payload.type,
    });
    if (!res.allow) {
      this.implicitModel = { motivationImplicitConsentId: null, notes: null };
      this.implicitDialogVisible = true;
      try {
        await new Promise<void>((resolve, reject) => {
          this.implicitAwaiterResolver = resolve;
          this.implicitAwaiterRejecter = reject;
        });
      } catch {
        return false;
      }
      this.setImplicitConsent(this.implicitModel);
    } else {
      this.setImplicitConsent(null);
    }
    this.dial({
      ...payload,
      matches: [{ type: payload.type, id: payload.id, selected: true }],
    });
  }

  async dropCall() {
    await this.drop();
  }

  async acceptCall() {
    await this.accept();
  }

  async closeRegisterActivity() {
    await this.handleRegisterActivity(this.createDefaultCallActivity());
    await this.drop();
  }

  async handleRegisterActivity(activity: Partial<IActivityDTO>) {
    // this.onRegisterActivity();
    this.$root.$emit('activity', {
      activity: activity ?? this.createDefaultCallActivity(),
      filter: [this.$enums.ActivityType.PhoneCall],
      title: this.$t('phoneBar.activity.title', { number: this.number, duration: this.duration }) as string,
    });
  }

  createDefaultCallActivity() {
    const endDate = this.$moment(new Date());
    const duration = moment.duration(endDate.diff(this.startDate));
    return {
      companyId: this.crossType === this.$enums.BusinessObjectType.Company ? this.crossId : 0,
      contactId: this.crossType === this.$enums.BusinessObjectType.Contact ? this.crossId : 0,
      leadId: this.crossType === this.$enums.BusinessObjectType.Lead ? this.crossId : 0,
      activityDate: this.startDate.toISOString(),
      activityEndDate: endDate.toISOString(),
      duration: Math.round(duration.as('minutes')),
      implicitConsent: this.implicitConsent,
      subject: this.$t(`phoneBar.activity.subjects.${this.direction}`, { number: this.number }) as string,
      inOut: this.direction === 'inbound',
    };
  }

  async selectIncomingAnagraphicAndRedirect(m) {
    this.selectIncoming(m);
    this.$router.push(this.$routeHelper.findRouteFromBusinessObjectType(this.routes, m.type, m.id));
  }

  async selectAnagraphicAndRedirect(m) {
    this.selectAnagraphic(m);
    this.$router.push(this.$routeHelper.findRouteFromBusinessObjectType(this.routes, m.type, m.id));
  }
}
</script>
<style lang="scss" scoped>
.phone-bar {
  pointer-events: none;

  &.minimized {
    & ::v-deep{ .el-drawer__header {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-bottom: 0px !important;
    }
  }
  }

  & ::v-deep{ .el-drawer {
    pointer-events: initial;
    -webkit-transition: height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
    transition: height 0.5s ease-in-out;
  }
}
}
</style>
