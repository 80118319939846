<template>
  <div v-if="value != null">
    <div class="m-b-h">
      <t-search-input v-model="search"></t-search-input>
    </div>
    <el-collapse v-model="actives">
      <span v-for="(values, index) in value" :key="index">
        <el-collapse-item v-for="field in values" :key="field.name" :name="`main-${index}`"
          :disabled="results[index] === 0">
          <template #title>
            <strong :class="{ 'text-primary': results[index] !== 0 }">
              {{ field.resourceName }}
              <transition name="el-zoom-in-center">
                <span v-if="results[index] > 0"> ({{ results[index] }}) </span>
              </transition>
            </strong>
          </template>
          <t-liquid-master-field :drag="drag" :value="field" :parent="field.name" :search="search"
            @drag="v => $emit('drag', v)" @select="v => $emit('select', v)"
            @fields-result="v => handleFieldResult(index, v)"></t-liquid-master-field>
        </el-collapse-item>
      </span>
    </el-collapse>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ILiquidStruct } from 'rt/Base/Utility/ILiquidStruct';
import TLiquidMasterField from './LiquidMasterField.vue';
import { isArray } from '@amcharts/amcharts4/core';
import _ from 'lodash';

@Component({
  name: 'LiquidBrowser',
  components: {
    TLiquidMasterField,
  },
})
export default class LiquidBrowser extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  value: ILiquidStruct[][];

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  drag: boolean;

  actives: string[] = ['main-0'];

  search: string = null;

  results: {
    [key: number]: number;
  } = {};

  handleFieldResult(index: number, v: number) {
    Vue.set(this.results, index, v);
  }
}
</script>
