//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SlaTemplateOperatorType {
	EqualTo = 0,
	NotEqualTo = 1,
	NotAssigned = 2,
	Changed = 3,
	WasEqualTo = 4,
	WasntEqualTo = 5
}
