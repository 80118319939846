import Vue from 'vue';

export class Viewport {
  constructor(window: Window) {
    let zoom = 1;
    const width = window.innerWidth;
    if (this.landscape && width <= 740) {
      zoom = 0.85;
    }
    if (this.landscape && width <= 640) {
      zoom = 0.75;
    }
    this.width = width / zoom;
    this.height = window.innerHeight / zoom;
    window.addEventListener('resize', (ev: UIEvent) => {
      Vue.set(this, 'width', window.innerWidth / zoom);
      Vue.set(this, 'height', window.innerHeight / zoom);
      // this.width = window.innerWidth;
      // this.height = window.innerHeight;
    });
  }

  width: number;

  height: number;

  get xs(): boolean {
    return this.width < 768;
  }

  get sm(): boolean {
    return this.width >= 768 && this.width < 992;
  }

  get md(): boolean {
    return this.width >= 992 && this.width < 1200;
  }

  get lg(): boolean {
    return this.width >= 1200 && this.width < 1920;
  }

  get xl(): boolean {
    return this.width >= 1920;
  }

  get smAndDown(): boolean {
    return this.xs || this.sm;
  }

  get mdAndDown(): boolean {
    return this.md || this.smAndDown;
  }

  get lgAndDown(): boolean {
    return this.lg || this.mdAndDown;
  }

  get smAndUp(): boolean {
    return this.sm || this.mdAndUp;
  }

  get mdAndUp(): boolean {
    return this.md || this.lgAndUp;
  }

  get lgAndUp(): boolean {
    return this.lg || this.xl;
  }

  get formLabelWidth(): string {
    if (this.mdAndUp) return '140px';
    // if (this.mdAndUp) return '90px';
    return null;
  }

  get containerHeight(): number {
    return this.height;
  }

  get containerWidth(): number {
    return this.width;
  }

  get dialog(): string {
    if (this.xs) {
      return '95%';
    }
    if (this.sm) {
      return '95%';
    }
    if (this.md) {
      return '85%';
    }
    if (this.lg) {
      return '70%';
    }
    if (this.xl) {
      return '60%';
    }
    return '50%';
  }

  maxWidth(max: number, perc = 99): string {
    if (this.width > max) {
      return `${max}px`;
    }
    return `${perc}%`;
  }

  maxHeight(max: number, perc = 99): string {
    if (this.height > max) {
      return `${max}px`;
    }
    return `${perc}%`;
  }

  get dialogSmall(): string {
    if (this.xs) {
      return '80%';
    }
    if (this.sm) {
      return '60%';
    }
    if (this.md) {
      return '42%';
    }
    if (this.lg) {
      return '35%';
    }
    if (this.xl) {
      return '30%';
    }
    return '25%';
  }

  get isTouch(): boolean {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0) && window.matchMedia('(any-hover: none)').matches;
  }

  get isRetina(): boolean {
    return window.devicePixelRatio && window.devicePixelRatio > 1;
  }

  get square(): boolean {
    return this.height === this.width;
  }

  get portrait(): boolean {
    return this.height > this.width;
  }

  get landscape(): boolean {
    return this.width > this.height;
  }
}

export default () => {
  Vue.use((v) => {
    const viewport = new Viewport(window);
    v.prototype.$viewport = v.viewport = viewport;
    (Vue as any).util.defineReactive(v, 'viewport', viewport);
  });
};
