//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CompanyLoadPerformanceSettings {
	All = 1,
	Minimal = 2,
	LoadInvoiceConditions = 4,
	LoadFreeFields = 8,
	LoadPrivacy = 16,
	LoadMailingListRights = 32,
	LoadPayments = 64,
	LoadPhoto = 128,
	LoadRelations = 256,
	LoadCityAddressNormalizer = 512,
	LoadSharing = 1024,
	LoadRanking = 2048,
	LoadAddresses = 4096,
	LoadSalesPersons = 8192,
	LoadNormalizeEMail = 16384,
	LoadSync = 32768,
	LoadCategories = 65536,
	LoadNormalizePhone = 131072,
	LoadPortalAccount = 262144,
	LoadGeo = 524288
}
