//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SecurityPermits {
	AllowViewOtherUsersActivity = 84,
	ViewOnlyTechnicalActivity = 96,
	ViewOnlyCommercialActivity = 97,
	ViewOnlyClosedOpportunitiesOfOtherAccounts = 105,
	ViewOnlyClosedQuotesOfOtherAccount = 106,
	ViewOnlyOpportunitiesOfAccount = 119,
	ViewOnlyQuotesOfAccount = 120,
	ViewOnlyClosedOrdersOfOtherAccounts = 126,
	ViewOnlyOrdersOfAccount = 127,
	ViewOnlyDoneActivitiesOfOtherAccounts = 131,
	AllowViewOtherUsersTicket = 145,
	AllowViewNotAssignedTicket = 146,
	AllowViewOnlyTicketWithAnagraphicAccess = 147,
	EnableGroupsFilter = 153,
	AllowChangeSyncMobileForAllAccount = 184,
	EnableGroupsFilterInFreeFields = 207,
	ViewOnlyAccountsOfMyWorkingTree = 208,
	EnableZoneFilter = 212
}
