import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import eventHubManager from '@/modules/mixin/views/EventHub';

// tslint:disable-next-line:variable-name
const CompanyGetter = namespace('company').Getter;
// tslint:disable-next-line:variable-name
const CompanyMutation = namespace('company').Mutation;
@Component({
  name: 'CompanyViewMixin',
})
export default class CompanyViewMixin extends Vue {
  @CompanyGetter model: ICompanyDTO;

  get eventHub(): Vue {
    return eventHubManager.getHub('company');
  }

  get module(): string {
    return 'company';
  }

  @CompanyMutation setModel: (dto: ICompanyDTO) => void;

  @CompanyMutation setModelProperty: (n: string, v: any) => void;
}
