//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WebDavQueueStatus {
	NoAction = 0,
	ImportAsCompany = 1,
	ImportAsContact = 2,
	ImportAsLead = 3,
	DeleteInWebDav = 4
}
