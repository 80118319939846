<template>
  <div>
    <el-row type="flex">
      <el-col :span="6">
        <img :src="imageUrl" width="100%" />
      </el-col>
      <el-col :span="17" :offset="1">
        <h3 class="section-label" @click="handleDebugPerformanceClick">{{ user.name }}</h3>
        <h5 class="text-info">{{ user.userName }}</h5>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-menu router>
          <el-menu-item-group title>
            <el-menu-item index="1" @click="$emit('close')" :route="{
              name: $routeNames.profile,
            }">
              <template #title>
                <i class="el-icon-icon-profile"></i>
                <span>{{ $t('layout.viewProfile') }}</span>
              </template>
            </el-menu-item>
            <el-menu-item index="2" @click="$emit('close')" :route="{
              name: $routeNames.webmail,
            }">
              <template #title>
                <i class="el-icon-icon-envelop"></i>
                <span>{{ $t('layout.mailbox') }}</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item index="4" @click="
            $emit('close');
          $emit('onboarding');
          " :route="{}">
            <template #title>
              <i class="el-icon-icon-magic-wand"></i>
              <span>{{ $t('onboarding.action') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item index="5" :route="config.suggestions ? $route : { name: $routeNames.suggestions }"
            v-if="user.limits.suggestionsEnabled">
            <a v-if="config.suggestions" :href="config.suggestions" target="_blank">
              <i class="el-icon-fa-lightbulb-on fas"></i>
              <span>{{ $t('suggestion.suggest') }}</span>
            </a>
            <span v-else>
              <i class="el-icon-fa-lightbulb-on fas"></i>
              <span>{{ $t('suggestion.suggest') }}</span>
            </span>
          </el-menu-item>

          <el-menu-item index="6" :route="{}">
            <a :href="brand.help" target="_blank">
              <i class="el-icon-icon-lifebuoy"></i>
              <span>{{ $t('layout.guide') }}</span>
            </a>
          </el-menu-item>

          <el-menu-item v-if="$chat.exists" index="7" @click="openSupportChat" :route="{}">
            <template #title>
              <i class="el-icon-icon-question"></i>
              <span>{{ $t('layout.help') }}</span>
            </template>
          </el-menu-item>

          <el-menu-item v-if="canActivateDebug" index="8" @click="throttleLanguageDebug" :route="{}">
            <template #title>
              <i class="el-icon-fa-bug fas"></i>
              <span>{{ languageDebug ? 'Disable' : 'Enable' }} Debug Mode</span>
            </template>
          </el-menu-item>

          <el-menu-item-group title>
            <el-menu-item index="8" @click="logout">
              <template #title>
                <i class="el-icon-icon-exit"></i>
                <span>{{ $t('layout.logout') }}</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import store from '@/store';
import HashIds from 'hashids';
import _ from 'lodash';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';
import { ICloudUser } from '@/utils/trackings';
import { ICloudUserLimit } from '@/plugins/typings/UI/Injector/ICloudUserLimit';

@Component({
  name: 'UserProfileSummary',
})
export default class UserProfileSummary extends Vue {
  logoutPopoverVisible = false;

  @Getter
  user: ICloudUser;

  @Getter
  config: IConfig;

  @Getter baseUrl: string;

  @Getter build: string;

  @Getter brand: IBrand;

  @Getter languageDebug: boolean;

  @Action throttleLanguageDebug: () => void;

  @Action('logout') logout: () => Promise<any>;

  @Mutation togglePerformance: () => Promise<any>;

  @Getter ticks: number;

  @Getter canActivateDebug: boolean;

  private debugCounter = 0;

  private resetDebugCounter = () => { };

  private userSize = 75;

  mounted() {
    this.resetDebugCounter = _.debounce(() => {
      this.debugCounter = 0;
    }, 400);
  }

  get imageUrl() {
    const userHash = new HashIds('').encode(this.user.userId, this.user.customerId);
    const imgSize = this.$viewport.isRetina ? this.userSize * 2 : this.userSize;
    return `${this.baseUrl}/Avatar/User/${userHash}/${imgSize}?${this.ticks}`;
  }

  openSupportChat() {
    if (this.$chat.exists) {
      if (this.$chat.visible) {
        this.$chat.close();
        this.$chat.hide();
      } else {
        this.$root.$emit('help');
      }
    }
    this.$emit('close');
  }

  handleDebugPerformanceClick() {
    this.debugCounter += 1;
    this.resetDebugCounter();
    if (this.debugCounter === 5) {
      this.togglePerformance();
    }
  }
}
</script>
<style type="scss" scoped>
.text-info {
  color: silver;
}

.menuitem a {
  display: block;
}
</style>
