//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExchangeTustenaQueueImportAction {
	None = 0,
	ImportAsNewCompany = 1,
	ImportAsNewContact = 2,
	ImportAsNewLead = 3,
	ImportInExistentCompany = 4,
	ImportInExistentContact = 5,
	ImportInExistentLead = 6
}
