<template>
  <div class="particles-page">
    <t-vue-particles v-if="$viewport.mdAndUp" ref="particles" color="#ffffff" :cssClass="background"
      :particleOpacity="0.7" linesColor="#ffffff" :particlesNumber="80" shapeType="polygon" :particleSize="5"
      :linesWidth="2" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="3" :hoverEffect="true"
      hoverMode="repulse" :clickEffect="true" clickMode="push"></t-vue-particles>
    <transition mode="out-in" name="el-zoom-in-center">
      <router-view @particles="enableDisableParticles"></router-view>
    </transition>
    <span class="photo-info hover-show text text-0_7x">{{ title }}</span>
    <span class="privacy-info hover-show text text-0_7x">
      Made with love
      <span class="love">❤</span> in Veneto &dash; TeamSystem &dash;
      <a @click="handleCookiePreference" v-if="$viewport.mdAndUp">
        Cookie Preferences
      </a>
      &nbsp;
      <a :href="`${brand.site}/privacy`" target="_blank">{{ $t('login.privacy') }}</a>
      &nbsp;
      <a :href="`${brand.site}/security`" target="_blank">Vulnerability Disclosure</a>
    </span>
    <t-cookies-dialog v-if="$viewport.mdAndUp" ref="cookie-dialog"></t-cookies-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Getter } from 'vuex-class';
import Component from 'vue-class-component';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';

@Component({
  name: 'Particles',
})
export default class Particles extends Vue {
  @Getter brand: IBrand;

  enableParticles = true;

  backgroundsMap: { [key: string]: string } = {
    'venice-1': 'Piazza San Marco - Venezia',
    'venice-2': 'Riva degli Schiavoni - Venezia',
    'taormina-2': 'Duomo di Taormina - Taormina - ME',
    'catania-1': 'Piazza del Duomo - Catania',
    'venaria-1': 'Galleria Grande - Venaria Reale - TO',
    'siena-1': 'Piazza del Campio - Palio - Siena',
    'milano-1': 'Piazza del Duomo - Milano',
    'baeza-1': 'Palacio de Jabalquinto - Baeza - Jaén',
    'baeza-2': 'Palacio de Jabalquinto - Baeza - Jaén',
    'jaen-1': 'Catedral de la Asunción - Jaén',
    'jaen-2': 'Catedral de la Asunción - Jaén',
    'jaen-3': 'La Cruz - Jaén',
    'jaen-4': 'Olivares - Jaén',
    'jaen-5': 'Catedral de la Asunción - Jaén',
    'jaen-6': 'Catedral de la Asunción - Jaén',
    'ubeda-1': 'Palacio de las Cadenas - Úbeda - Jaén',
    'ubeda-2': 'Palacio del Deán Ortega - Úbeda - Jaén',
    'ubeda-3': 'Sacra Capilla del Salvador - Úbeda - Jaén',
    'ubeda-4': 'Sacra Capilla del Salvador - Úbeda - Jaén',
  };

  background: string = null;

  title: string = null;

  changeBackgroundInterval: number = null;

  mounted() {
    this.setBackground();
    // this.changeBackgroundInterval = window.setInterval(() => this.setBackground(), 6000);
  }

  beforeDestroy() {
    if (this.changeBackgroundInterval) {
      window.clearInterval(this.changeBackgroundInterval);
    }
  }

  setBackground() {
    const backgrounds = this.brand.backgrounds ?? Object.keys(this.backgroundsMap);
    const index = parseInt((Math.random() * 1000).toString(), 10) % backgrounds.length;
    const bg = backgrounds[index];
    this.background = bg;
    this.title = this.backgroundsMap[bg];
  }

  enableDisableParticles(v) {
    if (!v) {
      const p = this.$refs['particles'] as any;
      if (p) {
        p.stop();
      }
    }
  }

  handleCookiePreference() {
    (this.$refs['cookie-dialog'] as any).show();
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.particles-page {
  height: 100%;

  .particles-js {
    background-image: none;
    background-position: 50% 50%;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-transition: background-image 2s ease-in-out;
    transition: background-image 2s ease-in-out;

    &.venice-1 {
      background-image: url('../assets/venice_1.jpg');
    }

    &.venice-2 {
      background-image: url('../assets/venice_2.jpg');
    }

    &.venice-3 {
      background-image: url('../assets/venice_3.jpg');
    }

    &.taormina-1 {
      background-image: url('../assets/taormina_1.jpg');
    }

    &.taormina-2 {
      background-image: url('../assets/taormina_2.jpg');
    }

    &.barbaria-1 {
      background-image: url('../assets/barbaria_1.jpg');
    }

    &.catania-1 {
      background-image: url('../assets/catania_1.jpg');
    }

    &.venaria-1 {
      background-image: url('../assets/venaria_1.jpg');
    }

    &.siena-1 {
      background-image: url('../assets/siena_1.jpg');
    }

    &.orsay-1 {
      background-image: url('../assets/orsay_1.jpg');
    }

    &.milano-1 {
      background-image: url('../assets/milano_1.jpg');
    }

    &.baeza-1 {
      background-image: url('../assets/baeza_1.jpg');
    }

    &.baeza-2 {
      background-image: url('../assets/baeza_2.jpg');
    }

    &.jaen-1 {
      background-image: url('../assets/jaen_1.jpg');
    }

    &.jaen-2 {
      background-image: url('../assets/jaen_2.jpg');
    }

    &.jaen-3 {
      background-image: url('../assets/jaen_3.jpg');
    }

    &.jaen-4 {
      background-image: url('../assets/jaen_4.jpg');
    }

    &.jaen-5 {
      background-image: url('../assets/jaen_5.jpg');
    }

    &.jaen-6 {
      background-image: url('../assets/jaen_6.jpg');
    }

    &.ubeda-1 {
      background-image: url('../assets/ubeda_1.jpg');
    }

    &.ubeda-2 {
      background-image: url('../assets/ubeda_2.jpg');
    }

    &.ubeda-3 {
      background-image: url('../assets/ubeda_3.jpg');
    }

    &.ubeda-4 {
      background-image: url('../assets/ubeda_4.jpg');
    }
  }

  & .photo-info {
    position: absolute;
    bottom: 6px;
    left: 6px;
    color: $--color-text-secondary;
    padding: 0 5px;
  }

  & .privacy-info {
    position: absolute;
    bottom: 6px;
    right: 6px;
    color: $--color-text-secondary;
    padding: 0 5px;

    & a {
      text-decoration: underline;
      transition: color 0.2s;
      color: $--color-text-secondary;
    }
  }

  & .hover-show {
    background-color: transparent;
    transition: background-color 0.2s;
    transition: color 0.2s;

    &:hover {
      background-color: white;
      color: black;
      border-radius: 3px;
      cursor: pointer;

      & a {
        color: black;
      }

      & .love {
        color: red;
      }
    }
  }
}
</style>
