//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LeadCrossType {
	Status = 1,
	Rating = 2,
	ProductInterest = 3,
	Source = 4,
	Currency = 5,
	Industry = 6,
	Zone = 7,
	Priority = 8
}
