import AnonymousLanding from '../views/AnonymousLanding.vue';
import MailingUnsubscribe from '../views/MailingUnsubscribe.vue';
import MailingUnsubscribed from '../views/MailingUnsubscribed.vue';
import MailingExpired from '../views/MailingExpired.vue';
import AnonymousHeader from '../views/AnonymousHeader.vue';
import CalendarBooking from '../views/CalendarBooking.vue';
import StorageSharing from '../views/StorageSharing.vue';
import routeNames from 'router/routeNames';

const routes = [
  {
    path: '/landing',
    component: AnonymousLanding,
    meta: {
      parent: { name: routeNames.root },
    },
    children: [
      {
        path: 'mailing/unsubscribe/:id',
        name: routeNames.mailingLandingUnsubscribe,
        components: {
          header: AnonymousHeader,
          default: MailingUnsubscribe,
        },
        meta: {
          anonymous: true,
          splash: true,
          xs: true,
        },
      },
      {
        path: 'mailing/unsubscribed',
        name: routeNames.mailingLandingUnsubscribed,
        components: {
          header: AnonymousHeader,
          default: MailingUnsubscribed,
        },
        meta: {
          anonymous: true,
          splash: true,
          xs: true,
        },
      },
      {
        path: 'mailing/expired',
        name: routeNames.mailingLandingExpired,
        components: {
          header: AnonymousHeader,
          default: MailingExpired,
        },
        meta: {
          anonymous: true,
          splash: true,
          xs: true,
        },
      },
      {
        path: 'calendar/booking/:token',
        name: routeNames.calendarBooking,
        components: {
          header: AnonymousHeader,
          default: CalendarBooking,
        },
        meta: {
          anonymous: true,
          splash: true,
          xs: true,
        },
      },
      {
        path: 'calendar/booking/:token',
        name: routeNames.calendarBooking,
        components: {
          header: AnonymousHeader,
          default: CalendarBooking,
        },
        meta: {
          anonymous: true,
          splash: true,
          xs: true,
        },
      },
      {
        path: 'storage/sharing/:id/:token',
        name: routeNames.storageSharing,
        components: {
          header: AnonymousHeader,
          default: StorageSharing,
        },
        meta: {
          anonymous: true,
          splash: true,
          xs: true,
        },
      },
    ],
  },
];

export default routes;
