<template>
  <el-row>
    <el-col>
      <el-row class="m-b">
        <el-col>
          <el-tree
            ref="tree"
            node-key="key"
            :check-strictly="true"
            :props="treeProps"
            :load="loadNode"
            lazy
            :show-checkbox="multipleSelection"
            :default-expanded-keys="['Folder-0']"
            @node-click="handleNodeClick"
            @check-change="handleCheckChange"
          ></el-tree>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseTree from './BaseTree';
import { CatalogTreeViewController } from 'rt/UIApiControllers/TreeView/CatalogTreeViewController';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CatalogCategoryTree',
})
export default class CatalogCategoryTree extends BaseTree {
  catalogTreeViewController = new CatalogTreeViewController(Vue.axios);

  getTreeViewController(): any {
    return this.catalogTreeViewController;
  }

  isLeaf(item): boolean {
    return item.children.length === 0;
  }
}
</script>
