<template>
  <el-aside :width="isMenuCollapsed ? 'auto' : '220px'" :style="$route.meta.fixed ? { overflowY: 'auto' } : {}">
    <el-header class="sidebar-header">
      <transition mode="out-in" name="el-zoom-in-center">
        <img @click="toggleCollapseMenu" v-if="isMenuCollapsed" :src="`/${brand.id}/img/icon-white.svg`" width="60"
          style="max-height: 52px;" key="collapsed" />
        <img v-else @click="toggleCollapseMenu" :src="`/${brand.id}/img/logo-white.svg`" width="169px" key="full"
          style="max-height: 52px;" class="full-logo" />
      </transition>
    </el-header>
    <el-menu class="el-menu-vertical" :collapse="isMenuCollapsed" :router="true" :default-active="currentRouteName"
      ref="menu" @open="index => closeAllExcept(index)">
      <el-submenu v-for="item in menuMainItems" :key="item.id" :index="item.id.toString()">
        <template #title>
          <i v-bind:class="'el-icon-' + item.icon"></i>
          <span>{{ item.label }}</span>
        </template>
        <el-menu-item v-for="subItem in item.subItems" :key="subItem.route" :index="subItem.id.toString()"
          :route="{ [subItem.route && subItem.route.indexOf('/') === 0 ? 'path' : 'name']: subItem.route }"
          :class="{ compact: $viewport.mdAndDown }">
          <i v-bind:class="'el-icon-' + subItem.icon"></i>
          <span>{{ subItem.label }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, State } from 'vuex-class';

@Component({
  name: 'Login',
})
export default class Sidebar extends Vue {
  @Getter('menu') menu;

  @Getter brand;

  isCollapse = false;

  constructor() {
    super();
    this.isCollapse = this.$viewport.mdAndDown;
  }

  get currentRouteName(): string {
    return this.$route.name;
  }

  toggleCollapseMenu() {
    if (this.$viewport.mdAndDown) {
      this.isCollapse = true;
      return;
    }
    this.isCollapse = !this.isCollapse;
  }

  get menuMainItems() {
    return this.menu.filter((m) => (m.subItems || []).length);
  }

  get isMenuCollapsed() {
    if (this.$route.matched) {
      for (const route of this.$route.matched) {
        if (route.meta.compact) {
          return true;
        }
      }
    }
    return this.isCollapse;
  }

  closeAllExcept(index: string) {
    const sm = this.$refs['menu'] as any;
    for (const subMenu of this.menuMainItems) {
      if (subMenu.id.toString() !== index) {
        sm.close(subMenu.id.toString());
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.el-aside {
  background: $--color-primary;
  overflow: initial;

  .sidebar-header {
    border: none;
    line-height: 60px;
    padding: 0px;
    //padding-left: 6px;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .full-logo {
      padding-left: 4px;
      padding-right: 4px;
      display: inline-block;
    }
  }

  .el-menu-vertical {
    .el-menu-item {
      padding-left: 30px !important;
      height: auto;
      min-height: 50px;
    }
  }

  .el-menu--collapse {
    .el-menu {
      top: 2px;
      margin-left: 2px;
    }
  }

  .el-menu--collapse,
  .el-menu {
    background: $--color-primary;
    border: none;

    .el-menu-item,
    .el-submenu__title {
      background: $--color-primary;
      color: $--color-white;

      i,
      span {
        color: $--color-white;
      }

      &:hover,
      &:focus,
      &.is-active {
        background-color: $--color-primary-light-3;
        color: $--color-black;

        i,
        span {
          color: $--color-black;
        }
      }
    }
  }

  &::-webkit-scrollbar-thumb {
    background: mix($--color-black, $--color-primary, 40%);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
</style>
<style lang="scss">
.el-menu--vertical {
  & .el-menu {
    &.el-menu--popup {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    & .el-menu-item.compact {
      height: 32px;
    }
  }
}
</style>
