<template>
  <span>
    <span class="category" v-for="cat in categoryPaths" :key="cat.id">
      <span>{{ cat.description }}</span>
    </span>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { IStorageCategoryDTO } from 'dto/IStorageCategoryDTO';
import { Prop } from 'vue-property-decorator';
import { buildCategoriesPaths } from './storage';
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageCategoryPathLabel',
})
export default class StorageBrowserTable extends Vue {
  @Prop({ type: Number, required: false, default: 0 })
  value: number;

  @StorageGetter categories: IStorageCategoryDTO[];

  get categoryPaths(): IStorageCategoryDTO[] {
    return buildCategoriesPaths(this.categories, this.value);
  }
}
</script>
<style lang="scss" scoped>
@import 'element-variables';

.category+.category {
  margin-left: 1px;

  &::before {
    content: '/';
  }

  span {
    padding-left: 1px;
  }
}
</style>
