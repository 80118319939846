<template>
  <el-dialog :visible="visible" @update:visible="v => $emit('update:visible', v)" width="600px"
    :close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
    <div class="text-left" v-loading="importingPresets">
      <t-list-fast-layout-studio :defaults="defaults" :redirect="redirect" @saved="handleCreated" compact>
        <template #form-footer>
          <el-form-item :label="$t('list.presets.title')" v-loading="presets == null">
            <div v-if="presets">
              <el-select v-model="preset" clearable :placeholder="$t('list.presets.manual')" class="w-100">
                <el-option v-for="p in presets" :key="p.key" :value="p.key" :label="p.name"></el-option>
              </el-select>
            </div>
          </el-form-item>
        </template>
      </t-list-fast-layout-studio>
      <t-floating-panel :top="false" opaque spaced>
        <div class="text-right">
          <el-button type="default" size="big" round @click="goToCompleteSheet">{{
            $t('calendar.completeSheet')
          }}</el-button>
          <t-circle-button type="primary" icon="el-icon-icon-floppy-disk" @click="saveList"></t-circle-button>
        </div>
      </t-floating-panel>
    </div>
  </el-dialog>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';
import TListFastLayoutStudio from '../views/ListFastLayoutStudio.vue';
import eventHubManger from 'modules/mixin/views/EventHub';
import { Prop } from 'vue-property-decorator';
import { IListDTO } from 'dto/IListDTO';
import { IListPreset } from 'rt/Interfaces/TargetList/Presets/IListPreset';

// tslint:disable-next-line:variable-name
const ListGetter = namespace('list').Getter;
// tslint:disable-next-line:variable-name
const ListAction = namespace('list').Action;
@Component({
  name: 'TFastListLayoutStudioDialog',
  components: {
    TListFastLayoutStudio,
  },
})
export default class TFastListLayoutStudioDialog extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  defaults: Partial<IListDTO>;

  @Prop({
    default: false,
    type: Boolean,
  })
  visible: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  redirect: boolean;

  @ListGetter('changes') listChanges: () => any;

  @ListGetter presets: IListPreset[];

  @ListAction loadPresets: () => Promise<any>;

  @ListAction setPreset: (payload: { id: number; preset: string }) => Promise<any>;

  preset: string = null;

  importingPresets = false;

  mounted() {
    this.preset = null;
    this.loadPresets();
  }

  goToCompleteSheet() {
    this.$emit('update:visible', false);
    const c = this.listChanges();
    this.$router.push({
      name: this.$routeNames.list,
      params: {
        id: this.$hashids.encode(0),
        defaults: JSON.stringify(c),
      },
    });
  }

  saveList() {
    return eventHubManger.getHub('list.fast').$emit('save');
  }

  async handleCreated(id: number) {
    if (this.preset) {
      this.importingPresets = true;
      await this.setPreset({ id, preset: this.preset });
      this.importingPresets = false;
    }
    this.$emit('update:visible', false);
    this.$emit('created', id);
  }

  close() {
    this.$emit('update:visible', false);
  }
}
</script>
