//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GroupDataType {
	Full = 0,
	Day = 1,
	Month = 2,
	Year = 3
}
