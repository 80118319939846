<template>
  <t-form-item
    :label="schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <el-select
      :value="value"
      @input="change"
      style="width:100%"
      :clearable="schema.clearable"
      filterable
      :disabled="schema.readOnly"
    >
      <el-option v-for="item in schema.items" :key="item.key" :label="item.value" :value="item.key"></el-option>
    </el-select>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IListResult } from 'rt/UIApiControllers/Controls/IListResult';
import { ListsType } from 'rt/Core/ListsType';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'EnumListSelect',
})
export default class EnumListSelect extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: {
    listType: ListsType;
    text: string;
    items: IInt64ListResult[];
  };

  @Prop({
    type: [String, Number],
    required: true,
  })
  value: any;

  change(value) {
    this.$emit('input', value);
  }
}
</script>
