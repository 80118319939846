//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LegendPosition {
	None = 0,
	Right = 1,
	Left = 2,
	Top = 3,
	Bottom = 4
}
