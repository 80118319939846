import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import './tree.css';

@Component({
  name: 'BaseTree',
})
export default class BaseTree extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  multipleSelection: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  value: any[];

  @Prop({
    type: Boolean,
    default: true,
  })
  onlyLeaf: boolean;

  selectedData: any = null;

  created() {
    this.selectedData = this.value.map((v) => ({
      ...v,
    }));
  }

  @Watch('value') handleWatchChange(val) {
    if (val.length !== this.selectedData.length) {
      this.selectedData = val.map((v) => ({
        ...v,
      }));
    }
  }

  treeProps: any = {
    label: 'name',
    children: 'children',
    isLeaf: 'leaf',
    disabled: 'disabled',
  };

  getTreeViewController(): any {
    console.error('getTreeViewController must be overridden in subclasses');
  }

  isLeaf(item): boolean {
    console.error('isLeaf must be overridden in subclasses');
    return false;
  }

  mapItemToNode(item) {
    const isLeaf = this.isLeaf(item);
    return {
      key: item.key,
      name: item.title,
      children: item.children,
      leaf: isLeaf,
      disabled: !isLeaf,
      data: item,
    };
  }

  async reset() {
    const tree = this.$refs.tree as any;
    const { root } = tree.store;
  }

  extendRequestData(requestData: any): any {
    return requestData;
  }

  async loadNode(node, resolve) {
    const treeViewController = this.getTreeViewController();
    const requestData = this.extendRequestData({
      selectedValue: this.selectedData.map((i) => i.key),
      multipleSelection: this.multipleSelection,
    });
    if (node.level === 0) {
      const data = await treeViewController.Initialize(requestData);
      return resolve(data.children.map(this.mapItemToNode));
    }
    if (node.data.children && node.data.children.length > 0) {
      resolve(node.data.children.map(this.mapItemToNode));
    } else {
      const data = await treeViewController.LazyTreeNodes(requestData, node.data.key);
      return resolve(data.map(this.mapItemToNode));
    }
  }

  handleNodeClick(item, node, tree) {
    if (!this.multipleSelection) {
      if (item.leaf || !this.onlyLeaf) {
        this.selectedData = [
          {
            key: item.key,
            title: item.name,
          },
        ];
        this.$emit('input', this.selectedData);
      }
    } else if (item.leaf || !this.onlyLeaf) {
      node.checked = !node.checked;
    }
  }

  handleNodeExpand(item, node, tree) {
    this.$emit('expand', [item, node, tree]);
  }

  handleNodeCollapse(item, node, tree) {
    this.$emit('collapse', [item, node, tree]);
  }

  handleCheckChange(item, selected, subtreeSelection) {
    if (item.leaf) {
      if (this.multipleSelection) {
        const found = this.selectedData.find((i) => i.key === item.key);
        const index = this.selectedData.indexOf(found);
        if (selected) {
          if (index === -1) {
            this.selectedData.push({
              key: item.key,
              title: item.name,
            });
          }
        } else if (index > -1) {
          this.selectedData.splice(index, 1);
        }
      } else if (selected) {
        this.selectedData = [
          {
            key: item.key,
            title: item.name,
          },
        ];
      } else {
        this.selectedData = [];
      }
      this.$emit('input', this.selectedData);
    }
  }
}
