//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExtensionsType {
	None = 0,
	Company = 1,
	Contact = 2,
	Lead = 4,
	RollingStock = 8,
	Catalog = 16,
	Campaign = 32,
	Opportunity = 64,
	Storage = 128,
	Activity = 256,
	CampaignManager = 512,
	Ticket = 1024,
	Contract = 2048,
	JobOrders = 4096,
	JobOrderTasks = 8192,
	Appointment = 16384,
	Quote = 32768,
	Order = 65536,
	Invoice = 131072,
	DeliveryNote = 262144,
	DDT = 524288,
	CustomErpDocument = 1048576,
	Event = 2097152,
	EventItem = 4194304,
	Promotion = 8388608,
	OpportunityPromo = 16777216,
	TodayNotes = 33554432,
	SmsTemplate = 67108864,
	ContractTypeOfService = 134217728
}
