import Vue from 'vue';

class VueUtils extends Vue {
  mountScopedSlotToHtmlElement(element: HTMLElement, context: Vue, scopedSlotName: string, props: any): void {
    this.createScopedSlotComponent(context, scopedSlotName, props, {
      created() {},
      mounted() {
        element.innerHTML = this.$el.innerHTML;
      },
      beforeUpdate() {},
      updated() {
        element.innerHTML = this.$el.innerHTML;
      },
      beforeDestroy() {},
      destroyed() {
        element.innerHTML = null;
      },
    }).$mount();
  }

  renderScopedSlot(
    context: Vue,
    scopedSlotName: string,
    props: any,
    hooks?: {
      created?: () => void;
      mounted?: () => void;
      beforeUpdate?: () => void;
      updated?: () => void;
      destroyed?: () => void;
      beforeDestroy?: () => void;
    },
  ): HTMLElement {
    const slotVue = this.createScopedSlotComponent(context, scopedSlotName, props, hooks);
    if (slotVue) {
      const el = <HTMLElement>slotVue.$mount().$el;
      return el;
    }
    return null;
  }

  createScopedSlotComponent(
    context: Vue,
    scopedSlotName: string,
    props: any,
    hooks?: {
      el?: string;
      created?: () => void;
      mounted?: () => void;
      beforeUpdate?: () => void;
      updated?: () => void;
      destroyed?: () => void;
      beforeDestroy?: () => void;
    },
  ): Vue {
    if (!Object.prototype.hasOwnProperty.call(context.$scopedSlots, scopedSlotName)) {
      return null;
    }
    const slotVue = new Vue({
      ...hooks,
      render: (h) => h('span', [context.$scopedSlots[scopedSlotName](props)]),
      store: context.$store,
      router: context.$router,
      i18n: context.$i18n,
    });
    return slotVue;
    // const component = slotVue.$mount();
    // return component;
  }

  renderSlot(context: Vue, slotName: string): HTMLElement {
    if (!Object.prototype.hasOwnProperty.call(context.$slots, slotName)) {
      return null;
    }
    const slotVue = new Vue({
      render: (h) => h('span', [context.$slots[slotName]]),
      store: context.$store,
      router: context.$router,
      i18n: context.$i18n,
    });
    const component = slotVue.$mount();
    return <HTMLElement>component.$el;
  }

  renderTemplate(context: Vue, template: string, props: any) {
    const slotVue = new Vue({
      template,
      props: ['waypoints'],
      propsData: props,
      store: context.$store,
      router: context.$router,
      i18n: context.$i18n,
    });
    const component = slotVue.$mount();
    return component.$el;
  }
}

const vueUtils = new VueUtils();
export default vueUtils;
