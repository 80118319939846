import Vue, { PluginObject } from 'vue';

class ParallaxComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('parallax-container', () => import(/* webpackChunkName: "parallax" */ './ParallaxContainer.vue'));
    vue.component('parallax-element', () => import(/* webpackChunkName: "parallax" */ './ParallaxElement.vue'));
    vue.component('parallax-image', () => import(/* webpackChunkName: "parallax" */ './ParallaxImage.vue'));
  }
}
export default new ParallaxComponents();
