//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FieldTypes {
	Text = 0,
	Numeric = 1,
	Data = 2,
	DataNoLTZ = 3,
	Account = 4,
	Select = 5,
	SelectResource = 6,
	SelectExclusive = 7,
	Company = 8,
	Contact = 9,
	Lead = 10,
	Time = 11,
	Boolean = 12,
	FreeSelect = 13,
	FreeMultiSelect = 14,
	WriteOnce = 15,
	Anagraphic = 16,
	SingleData = 17,
	NumberOfDays = 18,
	Money = 19,
	RollingStock = 20,
	FreeMultiSelectTree = 21,
	Opportunity = 22,
	MultiSelectTree = 23,
	Ticket = 24,
	Geo = 25,
	Catalog = 26,
	Event = 27,
	MailTemplate = 28,
	List = 29,
	WorkPlan = 30
}
