import Vue from 'vue';
import Component from 'vue-class-component';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { IWebMailAccountDTO } from 'dto/IWebMailAccountDTO';
import { WebMailController } from 'rt/UIApiControllers/UI/WebMail/WebMailController';
import { IMailboxPreference, IExtendedIWebMailAccountDTO } from '../store/state';
import eventHubManager from '@/modules/mixin/views/EventHub';
import { MailTag } from '@/plugins/typings/Core/MailTag';
import ContinuousScrolling from '@/plugins/components/misc/ContinuousScrolling.vue';

// tslint:disable-next-line:variable-name
const WebMailAction = namespace('webmail').Action;
// tslint:disable-next-line:variable-name
const WebMailGetter = namespace('webmail').Getter;
// tslint:disable-next-line:variable-name
const WebMailMutation = namespace('webmail').Mutation;
@Component({
  name: 'MailboxMixin',
})
export default class MailboxMixin extends Vue {
  @Getter('baseUrl') baseUrl;

  @WebMailGetter('webMailAccounts')
  webMailAccounts: IExtendedIWebMailAccountDTO[];

  @WebMailGetter('mailboxPreference') mailboxPreference: IMailboxPreference;

  @WebMailGetter('webMailController') webMailController: WebMailController;

  @WebMailAction('reset') resetInStore: (pref: IMailboxPreference) => Promise<any>;

  @WebMailAction('load') load: () => Promise<void>;

  @WebMailAction('loadFolders')
  loadFolders: (mailboxId: number) => Promise<void>;

  @WebMailMutation('setMailboxPreference')
  setMailboxPreference: (preference: IMailboxPreference) => void;

  @WebMailMutation
  setForce: (force: boolean) => void;

  get currentPreference(): IMailboxPreference {
    return {
      ...this.currentMailIdRequest,
      query: this.query,
      command: this.command,
      cache: !this.onServer,
      tag: this.tag,
    };
  }

  get currentMailIdRequest(): { mailboxId: number; folder: string } {
    return {
      mailboxId: this.mailboxId,
      folder: this.folder,
    };
  }

  get eventHub(): Vue {
    return eventHubManager.getHub('webmail');
  }

  get mailbox(): IExtendedIWebMailAccountDTO {
    if (!this.webMailAccounts) return null;
    return this.webMailAccounts.find((mb) => mb.id === this.mailboxId);
  }

  get mailboxId(): number {
    return (this.$hashids.decode(this.$route.params.mailboxId)[0] as number) || 0;
  }

  get folder(): string {
    return (this.$route.query.folder as string) || 'INBOX';
  }

  get query(): string {
    return this.$route.query.q as string;
  }

  get command(): string {
    return this.$route.query.c as string;
  }

  get onServer(): boolean {
    return this.$route.query.s === '1';
  }

  get tabs(): MailTag[] {
    const tabs: MailTag[] = [];
    if (this.mailbox !== null && this.folder === 'INBOX') {
      if (this.mailbox.mailTags > 0) {
        for (const tag in MailTag) {
          const tagn = Number(tag);
          if (!isNaN(tagn) && tagn > 0) {
            // exclude Unknown
            if (tagn === 1) {
              tabs[0] = tagn as MailTag;
              // eslint-disable-next-line no-bitwise
            } else if ((this.mailbox.mailTags & tagn) === tagn) {
              tabs.push(tagn as MailTag);
            } else {
              tabs[0] += tagn as MailTag;
            }
          }
        }
      }
    }
    return tabs;
  }

  get tag(): MailTag {
    if (isNaN(+this.$route.query.t)) {
      if (this.tabs != null) {
        return this.tabs[0];
      }
      return null;
    }
    return +this.$route.query.t as MailTag;
  }
}
