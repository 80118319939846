//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PortalTicketPermissionSet {
	Visible = 1,
	OpenTicket = 2,
	CompanyTicketInsert = 4,
	CompanyTicketView = 8,
	EditSubject = 16,
	TicketCanBeClosed = 32,
	TicketCanBeReopen = 64,
	ParentLocationTicketView = 128
}
