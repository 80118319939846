//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import { IISuggestion } from '../../../Interfaces/Search/Prompters/IISuggestion';
import { ICompanyPrompterContext } from '../../../Base/Search/Prompters/ICompanyPrompterContext';
import { IContactPrompterContext } from '../../../Base/Search/Prompters/IContactPrompterContext';
import { ILeadPrompterContext } from '../../../Base/Search/Prompters/ILeadPrompterContext';
import { IAnagraphicPrompterContext } from '../../../Base/Search/Prompters/IAnagraphicPrompterContext';
import { IAccountPrompterContext } from '../../../Base/Search/Prompters/IAccountPrompterContext';
import { IAnagraphicReferencePrompterContext } from '../../../Base/Search/Prompters/IAnagraphicReferencePrompterContext';
import { IActivitySuggestion } from '../../../Base/Search/Prompters/IActivitySuggestion';
import { IOpportunitySuggestion } from '../../../Base/Search/Prompters/IOpportunitySuggestion';
import { IJobOrderTaskPrompterContext } from '../../../Base/Search/Prompters/IJobOrderTaskPrompterContext';
import { ICatalogPrompterContext } from '../../../Base/Search/Prompters/ICatalogPrompterContext';
import { ICatalogSuggestion } from '../../../Base/Search/Prompters/ICatalogSuggestion';
import { ISimplePrompterContext } from '../../../Base/Search/Prompters/ISimplePrompterContext';
import { IStoragePrompterContext } from '../../../Base/Search/Prompters/IStoragePrompterContext';
import { IStorageSuggestion } from '../../../Base/Search/Prompters/IStorageSuggestion';
import { IAssetPrompterContext } from '../../../Base/Search/Prompters/IAssetPrompterContext';
import { IListPrompterContext } from '../../../Base/Search/Prompters/IListPrompterContext';
import { IQuoteSuggestion } from '../../../Base/Search/Prompters/IQuoteSuggestion';

//element.Name=SuggestController result.Name=SuggestController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.SuggestController);
/** Result of WebApiControllerGenerator activity */
export class SuggestController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in SuggestController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Post(term: string, type: BusinessObjectType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term)))+'&type='+type, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Company(term: string, context: ICompanyPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Company?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Contact(term: string, context: IContactPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Contact?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Lead(term: string, context: ILeadPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Lead?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Anagraphic(term: string, context: IAnagraphicPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Anagraphic?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Account(term: string, context: IAccountPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Account?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Activity(term: string, context: IAnagraphicReferencePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivitySuggestion[]>
	{
		return (this.axios.post('Suggest/Activity?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Ticket(term: string, context: IAnagraphicReferencePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Ticket?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Opportunity(term: string, context: IAnagraphicReferencePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunitySuggestion[]>
	{
		return (this.axios.post('Suggest/Opportunity?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public JobOrder(term: string, context: IAnagraphicReferencePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/JobOrder?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public JobOrderTask(term: string, context: IJobOrderTaskPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/JobOrderTask?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Catalog(term: string, context: ICatalogPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICatalogSuggestion[]>
	{
		return (this.axios.post('Suggest/Catalog?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Campaign(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Campaign?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Contract(term: string, context: IAnagraphicReferencePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Contract?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PriceListDescription(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/PriceListDescription?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Bank(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Bank?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Zone(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Zone?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Storage(term: string, context: IStoragePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageSuggestion[]>
	{
		return (this.axios.post('Suggest/Storage?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StorageCategories(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/StorageCategories?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Asset(term: string, context: IAssetPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Asset?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Event(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/Event?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RollingStock(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/RollingStock?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public List(term: string, context: IListPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/List?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MailTemplate(term: string, context: IListPrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/MailTemplate?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogCategory(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/CatalogCategory?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public WorkPlan(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/WorkPlan?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowTrigger(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IISuggestion[]>
	{
		return (this.axios.post('Suggest/FlowTrigger?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Quote(term: string, context: ISimplePrompterContext, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IQuoteSuggestion[]>
	{
		return (this.axios.post('Suggest/Quote?'+((term === null || term === undefined) ? '' : ('&term='+encodeURIComponent(term))), context, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Suggest/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Suggest/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
