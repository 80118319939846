import { render, staticRenderFns } from "./PhoneBar.vue?vue&type=template&id=77d712d9&scoped=true"
import script from "./PhoneBar.vue?vue&type=script&lang=ts"
export * from "./PhoneBar.vue?vue&type=script&lang=ts"
import style0 from "./PhoneBar.vue?vue&type=style&index=0&id=77d712d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d712d9",
  null
  
)

export default component.exports