<template>
  <span>
    <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required" v-if="!simple"
      :important="schema.important">
      <t-account-lookup v-if="!isReadOnly" :value="selectedData" @input="change" :multipleSelection="schema.multiple"
        :onlyWithCalendarRights="schema.onlyWithCalendarRights" :onlySubAccounts="schema.onlySubAccounts" ref="lookup">
        <template #append>
          <slot name="append"></slot>
        </template>
      </t-account-lookup>
      <t-lookup-tag v-else :value="account" :key="account.key" v-for="account in selectedData" :closable="false">
        <template #default="{ title, id }">
          <t-profile-image class="user-image-20 m-r-q" :size="20" :userId="id"></t-profile-image>
          <span>{{ title }}</span>
        </template>
      </t-lookup-tag>
    </t-form-item>
    <t-account-lookup v-else :value="selectedData" @input="change" :multipleSelection="multiple" v-bind="$props"
      :read-only="isReadOnly" ref="lookup">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-account-lookup>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ListsType } from 'rt/Core/ListsType';
import { Prop } from 'vue-property-decorator';
// tslint:disable-next-line:variable-name
const ListsAction = namespace('lists').Action;
// tslint:disable-next-line:variable-name
const ListsGetter = namespace('lists').Getter;

@Component({
  name: 'AccountInput',
})
export default class AccountInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  @ListsAction
  load: (type: ListsType) => Promise<any>;

  @ListsGetter
  list: (type: ListsType) => Array<IInt64ListResult>;

  accounts: IInt64ListResult[] = null;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlyWithCalendarRights: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlySubAccounts: boolean;

  async resolve(id: number): Promise<string> {
    if (this.accounts == null) {
      await this.load(this.$enums.ListsType.ListOwner);
      this.accounts = this.list(this.$enums.ListsType.ListOwner);
    }
    const ac = this.accounts.find((a) => a.value === id);
    if (ac != null) {
      return ac.text;
    }
    return (await this.resolveController.Account(id)).name;
  }

  protected get identifier(): string {
    return 'Account';
  }
}
</script>
