<template>
  <el-row>
    <el-col>
      <el-tag class="el-tag-tag" :key="tag.value" v-for="tag in selectedTags" closable :disable-transitions="false"
        @close="handleRemoveTag(tag.value)" size="medium" v-indexed="tag.text">
        <span>
          <router-link :to="{ name: $routeNames.tags, query: { q3: tag.text } }" v-if="false"></router-link>
          {{ tag.text }}
        </span>
      </el-tag>
      <el-autocomplete size="mini" ref="saveTagInput" v-if="inputVisible" autofocus v-model="tagInputValue"
        v-on:keydown.enter.prevent.native="handleCreateNewTag" :fetch-suggestions="tagSearch" @select="handleTagSelected"
        value-key="text"></el-autocomplete>
      <el-button v-else class="button-new-tag" @click="showInput" v-indexed="'add-tag'">{{ $t('tags.add') }}</el-button>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import BaseTag from './BaseTag';
import { TagController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { ITagDTO } from 'dto/ITagDTO';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { Getter, Action, namespace } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'TTags',
})
export default class TTags extends BaseTag {
  tagController = new TagController(Vue.axios);

  created() {
    this.radioGlobalPersonal = null;
  }

  async createNewTag(text: string, global: boolean): Promise<number> {
    const newInstance = {} as Partial<ITagDTO>;
    newInstance.name = text;
    return await this.tagController.CreateOrUpdate(newInstance as ITagDTO);
  }

  getListType(): ListsType {
    return this.$enums.ListsType.Tags;
  }

  tagInputValue: string = null;

  tags: number[] = [];

  get inputVisible(): boolean {
    return this.popoverOpen;
  }

  set inputVisible(value: boolean) {
    this.popoverOpen = value;
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.el-tag {
  margin-bottom: $-margin-size-quarter;
  margin-right: $-margin-size-quarter;
}

.el-tag+.el-tag {}
</style>
