import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'LoadingMixin',
})
export default class LoadingMixin extends Vue {
  randomizeSize() {
    const mod = (Math.random() * 10) % 10;
    if (mod < 3) {
      return 'xl';
    }
    if (mod < 6) {
      return 'lg';
    }
    if (mod < 8) {
      return 'md';
    }
    return 'sm';
  }

  randomizeWeight() {
    const mod = (Math.random() * 10) % 10;
    if (mod < 3) {
      return 'big-';
    }
    return '';
  }

  randomizePadding() {
    const mod = (Math.random() * 10) % 10;
    if (mod < 4) {
      return 'padded';
    }
    return '';
  }

  randomize(enablePadding = false) {
    return `loading-box-${this.randomizeWeight()}line-${this.randomizeSize()} ${enablePadding ? this.randomizePadding() : null}`;
  }
}
