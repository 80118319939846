<template>
  <span>
    <el-dialog :width="$viewport.dialog" :visible.sync="dialogVisible" :append-to-body="isNestedDialog">
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
      </el-row>
      <t-grid :showCommands="false" repeater="PopOpportunityRepeater" @linkClick="linkClick"
        :customParameterData="{ search }" />
    </el-dialog>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" autostart ref="lookup-skin">
      <t-lookup-tag :route="$modules.isModuleLoaded('opportunity')" :value="opportunity" :key="opportunity.key"
        v-for="opportunity in selectedData" :closable="!readOnly" :disable-transitions="false"
        @close="handleRemoveItem(opportunity)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
      <template #autocomplete="{ item }">
        <span v-if="item.key != null">
          <div class="text-primary">
            <t-icon-label :icon="`el-icon-${$boMapping.toIcon($enums.BusinessObjectType.Opportunity)}`">
              <el-row type="flex" justify="center" align="middle" :gutter="8">
                <el-col :span="-1" class="nowrap p-t-h">
                  <el-progress :percentage="item.probability" type="circle" :width="24" :stroke-width="2"></el-progress>
                </el-col>
                <el-col>{{ item.value }}</el-col>
                <el-col :span="-1" class="nowrap">
                  <el-tag size="medium" :type="tagTypeOnStatus(item.status)">
                    <t-resolve-label :value="item.phase" :type="$enums.ListsType.ListPhase"></t-resolve-label>
                  </el-tag>
                </el-col>
              </el-row>
            </t-icon-label>
          </div>
        </span>
        <el-button v-else type="primary" size="medium" class="w-100">
          <t-icon-label :icon="`el-icon-${$boMapping.toIcon($enums.BusinessObjectType.Opportunity)}`">
            <span v-if="item.value">{{ $t('opportunity.create', { name: item.value }) }}</span>
            <span v-else>{{ $t('opportunity.new') }}</span>
          </t-icon-label>
        </el-button>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'OpportunityLookup',
  components: {
    TLookupSkin,
  },
})
export default class OpportunityLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'Opportunity';
  }

  keyPrefix() {
    return 'Opportunity';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  get enableCreate(): boolean {
    return this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Opportunity);
  }

  handleSelect(val) {
    if (val.key === null) {
      this.$root.$emit('opportunity', {
        name: val.value,
        defaults: {
          title: val.value,
          companyName: val.value,
          ...this.getAnagraphicReference(),
        },
        callback: async (id: number) => {
          if (id > 0) {
            const title = await this.resolve(id, this.$enums.BusinessObjectType.Opportunity);
            this.addItem({
              title,
              key: `${this.keyPrefix()}-${id}`,
            });
          }
        },
      });
    } else {
      this.addItem({
        key: val.key,
        title: val.value,
      });
    }
  }

  getAnagraphicReference() {
    if (this.suggestionParams) {
      if (this.suggestionParams.crossId > 0) {
        return {
          crossId: this.suggestionParams.crossId,
          crossType: this.suggestionParams.crossType,
        };
      }
      if (this.suggestionParams.companyId > 0) {
        return {
          crossId: this.suggestionParams.companyId,
          crossType: this.$enums.BusinessObjectType.Company,
        };
      }
      if (this.suggestionParams.contactId > 0) {
        return {
          crossId: this.suggestionParams.contactId,
          crossType: this.$enums.BusinessObjectType.Contact,
        };
      }
      if (this.suggestionParams.leadId > 0) {
        return {
          crossId: this.suggestionParams.leadId,
          crossType: this.$enums.BusinessObjectType.Lead,
        };
      }
    }
    return {};
  }

  linkClick(opportunityId, row) {
    const item = {
      key: `${this.keyPrefix()}-${opportunityId}`,
      title: row.TITLE.value,
    };

    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }

  tagTypeOnStatus(type) {
    switch (type) {
      case this.$enums.OpportunityStatus.CloseWin:
        return 'success';
      case this.$enums.OpportunityStatus.CloseLost:
        return 'danger';
      case this.$enums.OpportunityStatus.Suspended:
      case this.$enums.OpportunityStatus.NotPursued:
        return 'warning';
      case this.$enums.OpportunityStatus.Open:
        return 'primary';
    }
    return 'info';
  }
}
</script>
