import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import vueUtils from 'utils/vueUtils';

@Component({
  name: 'VueTitle',
})
export default class VueTitle extends Vue {
  @Prop({
    required: false,
    type: Boolean,
    default: true,
  })
  visible: boolean;

  @Prop({
    required: false,
    type: String,
  })
  prefix: string;

  @Prop({
    required: false,
    type: String,
  })
  suffix: string;

  @Prop({
    required: false,
    type: String,
    default: ' - ',
  })
  separator: string;

  @Prop({
    required: false,
    type: Number,
    default: 3,
  })
  level: number;

  @Prop({
    required: false,
    type: String,
  })
  icon: string;

  render(h) {
    if (this.$slots.default) {
      const template = vueUtils.renderSlot(this, 'default');
      const titleSlots = [];
      if (this.prefix) {
        titleSlots.push(this.prefix);
      }
      titleSlots.push(template.innerText);
      if (this.suffix) {
        titleSlots.push(this.suffix);
      }
      this.$title = titleSlots.join(this.separator);
      if (this.visible) {
        const columns = [];
        if (this.icon) {
          columns.push(
            h('el-col', {
              props: {
                span: -1,
              },
              class: {
                [this.icon]: true,
                'icon-1_3x': true,
              },
            }),
          );
        }
        columns.push(
          h('el-col', {
            domProps: {
              innerHTML: template.innerHTML,
            },
          }),
        );
        return h(`h${this.level}`, [
          h(
            'el-row',
            {
              props: {
                type: 'flex',
                gutter: 10,
              },
            },
            columns,
          ),
        ]);
      }
    }
    return null;
  }
}
