/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import VueAxios from 'vue-axios';
import jwtManager from 'utils/jwtManager';
import { IAxiosRequestConfigRetry } from 'utils/types';
import store from '@/store'; // TODO: trovare un modo migliore
import infoBox from '../utils/infoBox';
import rateLimit from 'axios-rate-limit';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line no-shadow
export enum HttpErrorCode {
  Ok = 200,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  PasswordExpired = 419,
  WebMailAuthenticationFailed = 420,
  TooManyRequests = 429,
  InternalServerError = 500,
}

function timeoutRetry(originalRequest: IAxiosRequestConfigRetry): Promise<any> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const req = originalRequest;
    if (req._tentative >= 5) {
      reject(new Error('Too Many Timeout Retry'));
      return;
    }
    try {
      req._tentative = (req._tentative ?? 1) + 1;
      const response = await axios(req);
      resolve(response);
    } catch (e) {
      reject(e);
    }
  });
}

export default (baseUrl: string, maxRequests: number) => {
  const setAxiosAuthorizationToken = (headers: any, jwt: string): void => {
    if (jwt) headers.Authorization = `Bearer ${jwt}`;
    headers['Crm-NullValueHandling'] = 'Include';
    headers['Crm-OwnerIdStyle'] = 'Id';
    headers['Crm-CommercialZoneStyle'] = 'Id';
    headers['Crm-SalesPersonsStyle'] = 'Id';
    headers['Crm-EmailStyle'] = 'Full';
    headers['Crm-PhoneStyle'] = 'Full';
    headers['Crm-NamingStrategy'] = 'LowerCamelCase';
    if (Vue.config.productionTip) {
      headers['Crm-ErrorResponseDetailLevel'] = 'Full';
    }
    if (store.getters.performance) {
      headers['server-timing'] = 'ms';
    }
    if (store.getters.languageDebug) {
      headers['rdb'] = '3';
    }
  };
  axios.defaults.baseURL = `${baseUrl}/api/latest`;
  axios.interceptors.request.use(async (r: IAxiosRequestConfigRetry) => {
    if (r.timeout) {
      r.headers['Crm-Timeout'] = r.timeout.toString();
      r.timeoutErrorMessage = `timeout-client-axios-${uuidv4()}`;
      r.timeout = null;
    }
    if (r._noAuth) return r;
    const jwt: string = await jwtManager.getValidToken();
    if (jwt) {
      setAxiosAuthorizationToken(r.headers, jwtManager.cookie ? null : jwt);
    }
    return r;
  });
  axios.interceptors.response.use(
    (r: AxiosResponse) => {
      if (r.status === HttpErrorCode.Ok || r.status === HttpErrorCode.NoContent) {
        const h = r.request.getResponseHeader('InfoBox');
        if (h != null) {
          try {
            const info = JSON.parse(h);
            for (const i in info) {
              if (Object.prototype.hasOwnProperty.call(info, i)) {
                const element = info[i];
                for (const m of element) {
                  infoBox(m, Vue.enums.Gravity[i]);
                }
              }
            }
            // eslint-disable-next-line no-empty
          } catch {}
        }
      }
      if (r.status === HttpErrorCode.NoContent) {
        r.data = null;
      }
      return r;
    },
    async (error) => {
      const originalRequest: IAxiosRequestConfigRetry = error.config;
      if (error.response) {
        if (originalRequest && error.response.status === HttpErrorCode.RequestTimeout) {
          return timeoutRetry(originalRequest);
        }
        if (originalRequest._noAuth) {
          throw error.response;
        }
        if (error.response.status === HttpErrorCode.Unauthorized && !originalRequest._retry) {
          originalRequest._retry = true;
          if (await jwtManager.refreshTokenAction()) {
            setAxiosAuthorizationToken(originalRequest.headers, jwtManager.token);
            return axios(originalRequest);
          }
          store.commit('clearAuthentication');
          throw error.response;
        }
        if (error.response.status === HttpErrorCode.TooManyRequests) {
          const retryAfter = +error.request.getResponseHeader('Retry-After');
          if (retryAfter > 0) {
            return new Promise((resolve, reject) => {
              const req = originalRequest;
              if (req._tentative > 20) {
                reject(new Error('Too Many Retry'));
                return;
              }
              window.setTimeout(async () => {
                try {
                  req._tentative = (req._tentative ?? 0) + 1;
                  const response = await axios(req);
                  resolve(response);
                } catch (e) {
                  reject(e);
                }
              }, retryAfter * 1000);
            });
          }
          return null;
        }
        if (error.response.status === HttpErrorCode.Forbidden) {
          await Vue.error.handleWebApiUnauthorized(error.response);
        }
        if (error.response.status >= HttpErrorCode.InternalServerError) {
          await Vue.error.handleWebApiException(error.response);
        }
        throw error.response;
      } else {
        if (originalRequest && error.message === originalRequest?.timeoutErrorMessage) {
          return timeoutRetry(originalRequest);
        }
        // network error
        if (error.message === 'Network Error') {
          await Vue.error.handleNetworkError();
        }
      }
      throw error;
    },
  );
  if (maxRequests) {
    Vue.use(VueAxios, rateLimit(axios, { maxRequests, perMilliseconds: 1000 }));
  } else {
    Vue.use(VueAxios, axios);
  }
};
