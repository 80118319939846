//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { ICervedConfig } from '../../Base/Cerved/ICervedConfig';
import { IAnagraphic } from '../../Base/Cerved/EntitySearch/IAnagraphic';
import { ICervedApiCall } from './ICervedApiCall';
import { IResult } from '../../Base/Cerved/EntitySearch/IResult';
import { IProfile } from '../../Base/Cerved/EntityProfile/IProfile';
import { ICompanyDTO } from '../../DTO/ICompanyDTO';
import { IContactDTO } from '../../DTO/IContactDTO';
import { ILeadDTO } from '../../DTO/ILeadDTO';

//element.Name=CervedController result.Name=CervedController element.FullName=Digita.Tustena.UIApiControllers.Utils.CervedController);
/** Result of WebApiControllerGenerator activity */
export class CervedController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in CervedController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public CheckCervedServiceKey(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.get('Cerved/CheckCervedServiceKey', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetNewEmptyCervedServiceKeyConfig(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICervedConfig>
	{
		return (this.axios.get('Cerved/GetNewEmptyCervedServiceKeyConfig', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetCervedServiceKeyConfig(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICervedConfig>
	{
		return (this.axios.get('Cerved/GetCervedServiceKeyConfig', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveCervedServiceKeyConfig(cervedConfig: ICervedConfig, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Cerved/SaveCervedServiceKeyConfig', cervedConfig, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteCervedServiceKeyConfig(cervedConfig: ICervedConfig, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Cerved/DeleteCervedServiceKeyConfig', cervedConfig, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Search(searchText: string, searchFilter: string = "COMPANIES_ONLY", excludenotactive: string = "true", cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAnagraphic[]>
	{
		return (this.axios.get('Cerved/Search?'+((searchText === null || searchText === undefined) ? '' : ('&searchText='+encodeURIComponent(searchText)))+((searchFilter === null || searchFilter === undefined) ? '' : ('&searchFilter='+encodeURIComponent(searchFilter)))+((excludenotactive === null || excludenotactive === undefined) ? '' : ('&excludenotactive='+encodeURIComponent(excludenotactive))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CervedApi(api: ICervedApiCall, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Cerved/CervedApi', api, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchById(companyId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IResult>
	{
		return (this.axios.get('Cerved/SearchById?'+'&companyId='+companyId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateCompanyData(companyId: number, company: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Cerved/UpdateCompanyData?'+'&companyId='+companyId, company, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EntitySearch(searchText: string, searchFilter?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAnagraphic[]>
	{
		return (this.axios.get('Cerved/EntitySearch?'+((searchText === null || searchText === undefined) ? '' : ('&searchText='+encodeURIComponent(searchText)))+((searchFilter === null || searchFilter === undefined) ? '' : ('&searchFilter='+searchFilter)), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EntityProfile(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IProfile>
	{
		return (this.axios.get('Cerved/EntityProfile?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AutoCompleteCompany(dto: ICompanyDTO, id: number, overwrite: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICompanyDTO>
	{
		return (this.axios.post('Cerved/AutoCompleteCompany?'+'&id='+id+'&overwrite='+overwrite, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AutoCompleteContact(dto: IContactDTO, id: number, overwrite: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IContactDTO>
	{
		return (this.axios.post('Cerved/AutoCompleteContact?'+'&id='+id+'&overwrite='+overwrite, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AutoCompleteLead(dto: ILeadDTO, id: number, overwrite: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILeadDTO>
	{
		return (this.axios.post('Cerved/AutoCompleteLead?'+'&id='+id+'&overwrite='+overwrite, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Cerved/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Cerved/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
