import Vue from 'vue';
import { MessageBox } from 'element-ui';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ICometPasswordExpireMessage } from 'rt/Base/Handlers/Comet/ICometPasswordExpireMessage';

const handlePasswordChange = async (message: ICometPasswordExpireMessage) => {
  try {
    const pwd = (
      (await MessageBox.prompt(message.message, <string>Vue.i18n.t('password.expired'), {
        inputType: 'password',
        inputPlaceholder: Vue.i18n.t('password.type') as string,
        confirmButtonText: Vue.i18n.t('changePassword') as string,
        cancelButtonText: Vue.i18n.t('commands.cancel') as string,
      }).catch(() => {})) as any
    )?.value;
    if (pwd) {
      const apiAction = message.action;
      if (apiAction) {
        let endpoint = `PasswordUpdate/${apiAction}`;
        if (message.toUpdateId > 0) {
          endpoint += `/${message.toUpdateId}`;
        }
        const response = (
          await Vue.axios.post(endpoint, JSON.stringify(pwd), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
        ).data;
        if (response) {
          MessageBox.alert(Vue.i18n.t('password.changed') as string);
        } else {
          const retry = await MessageBox.confirm(Vue.i18n.t('password.wrong') as string, {
            confirmButtonText: Vue.i18n.t('commands.yes') as string,
            cancelButtonText: Vue.i18n.t('commands.no') as string,
          });
          if (retry) {
            handlePasswordChange(message);
          }
        }
      }
    }
  } finally {
  }
};

export default handlePasswordChange;
