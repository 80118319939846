//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ReportFaxState {
	Waiting = 0,
	ReCall = 1,
	Sended = 2,
	Refused = 3
}
