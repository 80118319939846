<template>
  <span @mouseover="() => (hover = true)" @mouseout="() => (hover = false)">
    <el-tag
      :type="$boMapping.toTagType(type)"
      size="medium"
      class="cursor-pointer nowrap"
      @click="() => $router.push($routeHelper.findRouteFromBusinessObjectType(routes, type, value))"
    >
      <t-icon-label
        :icon="hover ? 'el-icon-icon-square-up-right' : `el-icon-${$boMapping.toIcon(type)}`"
      >
        <slot>
          <t-bo-resolve-label :value="value" :type="type"></t-bo-resolve-label>
          <i
            class="el-tag__close el-icon-close"
            @click.prevent.stop="v => $emit('close', v)"
            v-if="closable"
          ></i>
        </slot>
      </t-icon-label>
    </el-tag>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'BoTag',
})
export default class BoTag extends Vue {
  @Getter('routes') routes;

  @Prop({
    required: true,
    type: Number,
  })
  value: number;

  @Prop({
    required: true,
    type: Number,
  })
  type: number;

  @Prop({
    required: false,
    type: Boolean,
  })
  closable: boolean;

  hover = false;
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
</style>
