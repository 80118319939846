//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderPermits {
	AllowLogView = 103,
	AllowPrint = 144,
	ExcludeInSuggestAndPickerJoborderClosed = 181,
	StandardOrder = 183,
	AllowRepeaterExport = 185
}
