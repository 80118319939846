<template>
  <el-button-group class="t-button-group">
    <slot></slot>
  </el-button-group>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'TButtonGroup',
})
export default class TButtonGroup extends Vue {}
</script>
<style lang="scss" scoped>
.t-button-group {
  white-space: nowrap;
  & * {
    white-space: nowrap;
  }
}
</style>
