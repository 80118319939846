//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GroupByExpression {
	Sum = 0,
	Avg = 1,
	Min = 2,
	Max = 3
}
