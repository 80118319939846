<template>
  <el-popover placement="top" width="300" v-model="visible">
    <p>{{ $t(`mailing.spooler.actions.${action}`) }}</p>
    <div class="m-b-h m-t-h text text-info">{{ $t('mailing.spooler.info') }}</div>
    <div style="text-align: right; margin: 0">
      <el-button :icon="icon" @click="handleConfirmCommand" type="primary" size="mini">{{
      $t(`mailing.spooler.commands.${ action } `) }}</el-button>
    </div>
    <template #reference>
      <t-tooltip-button :content="$t(`mailing.spooler.commands.${ action }`)" :icon="icon"
        type="primary"></t-tooltip-button>
    </template>
  </el-popover>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'SpoolerSpoolerButton',
})
export default class SpoolerSpoolerButton extends Vue {
  visible = false;

  @Prop({ required: true, type: String })
  action: string;

  @Prop({ required: true, type: String })
  icon: string;

  handleConfirmCommand(e) {
    this.visible = false;
    this.$emit('click', e);
  }
}
</script>
