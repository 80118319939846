import Vue, { PluginObject } from 'vue';
import i18nUtils from 'utils/i18nUtils';

class SecurityComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
    vue.component('t-security-policies', () => import(/* webpackChunkName: "security" */ './SecurityPolicies.vue'));
  }
}
export default new SecurityComponents();
