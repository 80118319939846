<template>
  <el-dialog :width="$viewport.dialog" :visible="visible" @update:visible="v => $emit('update:visible', v)" append-to-body
    custom-class="storage-browser-dialog">
    <template #title>
      <el-tabs :value="tab" @input="handleTabChange">
        <el-tab-pane name="files" :label="$t('storage.browser.files')"></el-tab-pane>
        <el-tab-pane name="recents" :label="$t('storage.browser.recents')"></el-tab-pane>
        <el-tab-pane name="upload" :label="$t('storage.browser.upload')"></el-tab-pane>
        <el-tab-pane name="preview" :disabled="!filePreview" :label="$t('storage.browser.preview')"></el-tab-pane>
      </el-tabs>
    </template>
    <div v-if="tab === 'files'">
      <el-row class="m-b-q">
        <el-col :md="12" :sm="24">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="browser-storage-breadcrumb">
            <el-breadcrumb-item @click.native="categoryId = 0">../</el-breadcrumb-item>
            <el-breadcrumb-item v-for="category in breadcumCategories" :key="category.id"
              @click.native="categoryId = category.id">{{ category.description }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :md="12" :sm="24">
          <t-search-input v-model="tempSearchKey" @search="filter = tempSearchKey"></t-search-input>
        </el-col>
      </el-row>
      <t-storage-browser-table :data="currentData" @input="handleClick" :show-file-path="onlyFiles"
        @details="v => goToFilePreview(v)">
        <template #append>
          <div v-loading="busy && !endReached" v-observe-visibility="loadingVisibilityChanged" class="m-b m-t"></div>
        </template>
        <template #commands="{ row }">
          <i v-if="row.type === 'file'" class="el-icon-fa-search fal cursor-pointer"
            @click="() => goToFilePreview(row)"></i>
        </template>
      </t-storage-browser-table>
    </div>
    <div v-else-if="tab === 'recents'">
      <t-storage-browser-table :data="currentData" @input="handleClick" :show-file-path="true"
        @details="v => goToFilePreview(v)">
        <template #append>
          <div v-loading="busy && !endReached" v-observe-visibility="loadingVisibilityChanged" class="m-b m-t"></div>
        </template>
        <template #commands="{ row }">
          <i v-if="row.type === 'file'" class="el-icon-fa-search fal cursor-pointer"
            @click="() => goToFilePreview(row)"></i>
        </template>
      </t-storage-browser-table>
    </div>
    <div v-else-if="tab === 'upload'">
      <t-storage-browser-table :data="currentData" @input="handleClick" :show-file-path="true"
        @details="v => goToFilePreview(v)">
        <template #commands="{ row }">
          <i v-if="row.type === 'file'" class="el-icon-fa-search fal cursor-pointer"
            @click="() => goToFilePreview(row)"></i>
        </template>
      </t-storage-browser-table>
      <div class="text-center upload-panel p-t p-b">
        <div>
          <el-upload ref="footerUploader" class="upload" drag multiple :action="`${baseUrl}/api/latest/Upload/UploadFile`"
            :on-success="handleSuccess" :on-error="handleError" :before-upload="handleBeforeUpload"
            :file-list="uploadFileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">{{ $t('storage.commands.drop') }}</div>
          </el-upload>
        </div>
      </div>
    </div>
    <div v-else-if="tab === 'preview'">
      <div v-if="filePreview != null">
        <t-storage-detail :key="filePreview.id" :value="filePreview" enable-select
          @select="file => $emit('select', file.id)">
          <template #info><span></span></template>
        </t-storage-detail>
      </div>
      <div v-else v-loading="true"></div>
    </div>
  </el-dialog>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { Prop, Watch } from 'vue-property-decorator';
import StorageBrowserMixin from './StorageBrowserMixin';
import TStorageBrowserTable from './StorageBrowserTable.vue';
import TStorageDetail from './StorageDetail.vue';
import axios, { CancelTokenSource } from 'axios';
import { IStorageItem } from './storage';
import _ from 'lodash';

@Component({
  name: 'StorageBrowserLookup',
  components: {
    TStorageBrowserTable,
    TStorageDetail,
  },
})
export default class StorageBrowserLookup extends StorageBrowserMixin {
  tempSearchKey: string = null;

  filter: string = null;

  @Prop({ type: Boolean, default: false })
  visible: boolean;

  tab: 'files' | 'recents' | 'upload' | 'preview' = 'files';

  cancelTokenSource: CancelTokenSource;

  categoryId = 0;

  filePreview: IStorageDTO = null;

  @Watch('filter')
  handleFilterChange() {
    this.browseDirectory();
  }

  handleTabChange(v: 'files' | 'recents' | 'upload' | 'preview') {
    if (v === 'preview' && !this.filePreview) {
      return;
    }
    this.tab = v;
    this.browseDirectory();
  }

  async goToFilePreview(item: IStorageItem) {
    if (item.type === 'file') {
      this.filePreview = null;
      this.tab = 'preview';
      this.filePreview = await this.controller.Get(item.id);
    }
  }

  get onlyFiles() {
    if (this.filter) {
      return true;
    }
    if (this.tab === 'recents') {
      return true;
    }
    if (this.tab === 'upload') {
      return true;
    }
    return false;
  }

  get currentData(): IStorageItem[] {
    if (this.onlyFiles) {
      return [...this.files.map(this.fromStorageDtoToGridDate)];
    }
    return [
      ...this.currentCategories.map((d) => ({
        type: 'category',
        id: d.id,
        description: d.description,
        lastModifiedById: d.lastModifiedById,
        lastModifiedDate: d.lastModifiedDate,
        size: null,
        icon: 'el-icon-icon-folder',
      } as IStorageItem)),
      ...this.files.map(this.fromStorageDtoToGridDate),
    ];
  }

  protected async loadFiles(page: number, pageSize: number): Promise<IStorageDTO[]> {
    if (!this.user.isAuthorizedToRead(BusinessObjectType.Storage)) {
      return [];
    }
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
    this.cancelTokenSource = axios.CancelToken.source();
    if (this.tab === 'upload') {
      return [];
    }
    if (this.tab === 'recents') {
      return await this.controller.SearchByODataCriteria(`lastModifiedById eq ${this.user.userId} or createdById eq ${this.user.userId}`, 'lastModifiedDate desc', (this.page - 1) * this.pageSize, this.pageSize, null, this.cancelTokenSource.token);
    }
    let filter = null;
    if (this.filter) {
      filter = `contains(fileName, '${encodeURIComponent(this.filter)}')`;
      return await this.controller.SearchByODataCriteria(filter, 'fileName', (this.page - 1) * this.pageSize, this.pageSize, null, this.cancelTokenSource.token);
    }
    const files = (await this.categoriesController.Documents(this.categoryId, filter, 'fileName', this.pageSize, (this.page - 1) * this.pageSize, null, this.cancelTokenSource.token)) as IStorageDTO[];
    return files;
  }

  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
  }

  handleClick(row) {
    switch (row.type) {
      case 'category':
        this.categoryId = row.id;
        break;
      case 'file':
        this.$emit('select', row.id);
        break;
    }
  }
}
</script>
<style lang="scss">
.storage-browser-dialog {
  .el-dialog__body {
    padding-top: 0px;
  }
}

.browser-storage-breadcrumb {
  & .el-breadcrumb__item {
    & .el-breadcrumb__inner {
      cursor: pointer !important;
    }
  }
}
</style>
