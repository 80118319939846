import Vue, { PluginObject } from 'vue';

class ChartComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-analytics-input', () => import(/* webpackChunkName: "charts" */ '../form/AnalyticsInput.vue'));
    vue.component('t-analytics-chart', () => import(/* webpackChunkName: "charts" */ './AnalyticsChart.vue'));
    vue.component('t-analytics-module-chart', () => import(/* webpackChunkName: "charts" */ './AnalyticsModuleChart.vue'));
    vue.component('t-maps-am-chart', () => import(/* webpackChunkName: "charts" */ './MapsAmChart.vue'));
    vue.component('t-sales-performance-chart', () => import(/* webpackChunkName: "charts" */ './SalesPerformanceChart.vue'));
    vue.component('t-sales-performance-am-chart', () => import(/* webpackChunkName: "charts" */ './SalesPerformanceAmChart.vue'));
    vue.component('t-pie-am-chart', () => import(/* webpackChunkName: "charts" */ './PieAmChart.vue'));
    vue.component('t-bar-am-chart', () => import(/* webpackChunkName: "charts" */ './BarAmChart.vue'));
    vue.component('t-custom-am-chart', () => import(/* webpackChunkName: "charts" */ './CustomAmChart.vue'));
  }
}
// () => import(/* webpackChunkName: "company" */'./TPrivacy.vue')
export default new ChartComponents();
