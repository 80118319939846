<template>
  <t-bounced-input class="form-input" :value="value" @input="simpleEmit" @change="handleChange"
    :autosize="schema.autoSize" :type="schema.type" :disabled="schema.readOnly" :placeholder="schema.placeholder"
    v-if="simple">
    <template #append>
      <slot name="append"></slot>
    </template>

  </t-bounced-input>
  <t-form-item :label="schema.text || schema.id" :prop="schema.propertyName" :required="schema.required" key="input"
    :important="schema.important" v-else>
    <t-bounced-input class="form-input" :value="value" @input="simpleEmit" @change="handleChange"
      :autosize="schema.autoSize" :type="schema.type" :disabled="schema.readOnly">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-bounced-input>
  </t-form-item>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'Input',
  mixins: [simpleMixin],
})
export default class Input extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  handleChange(v: string) {
    this.$emit('change', {
      ...this.data,
      [this.schema.propertyName]: v,
    });
  }
}
</script>
<style lang="scss" scoped>
.form-input ::v-deep{ .el-textarea__inner {
  min-height: 60px !important;
}
}
</style>
