<template>
  <el-dialog class="schemaManager" :before-close="clearState" :visible.sync="dialogVisible"
    :close-on-click-modal="false" :width="$viewport.dialog">
    <template #title>
      <h3 class="text-center">{{ $t('grid.schemaManager.edit', { schema: currentSchemaName }) }}</h3>
      <h5 class="text-center text text-info m-t-h">{{ $t('grid.schemaManager.hint') }}</h5>
    </template>
    <div v-loading="loading">
      <div v-if="currentSchema">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-card>
              <template #header>
                <div class="text-center">
                  <h4>{{ $t('grid.schemaManager.availableColumns') }}</h4>
                </div>
              </template>
              <div>
                <t-search-input v-model="tempColumnFilter" @search="columnFilter = tempColumnFilter"></t-search-input>
              </div>
              <draggable class="fix-h-400" :value="unselectedData"
                :options="{ forceFallback: false, draggable: '.drag-item', group: 'column', animation: 100, delay: $viewport.isTouch ? 400 : 0 }"
                :min-height="600">
                <div class="drag-item" v-for="column in unselectedData" :key="column.name">
                  <span>{{ column.text }}</span>
                  <span class="fas dragging-icon"
                    :class="selectedData.length >= 10 ? 'el-icon-fa-exclamation-circle danger' : 'el-icon-fa-plus-circle'"></span>
                </div>
              </draggable>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card>
              <template #header>
                <div class="text-center">
                  <h4>{{ $t('grid.schemaManager.selectedColumns') }}</h4>
                </div>
              </template>
              <div class="input-place-holder"></div>
              <div class="drag-item" v-if="user.isAdmin">
                <el-switch :value="showKey" @input="handleShowKey"
                  :active-text="$t('grid.schemaManager.showKey')"></el-switch>
              </div>
              <draggable class="fix-h-400" v-model="selectedData"
                :options="{ forceFallback: false, draggable: '.drag-item', group: 'column', animation: 100, delay: $viewport.isTouch ? 400 : 0 }">
                <div class="drag-item" v-for="(column, index) in selectedData" :key="column.name">
                  <el-row type="flex">
                    <el-col>{{ column.text }}</el-col>
                    <el-col class="text-right" :span="-1">
                      <i class="text text-info cursor-pointer el-icon-icon-cross2"
                        @click="() => removeColumnAtIndex(index)"></i>
                    </el-col>
                  </el-row>

                  <span class="el-icon-fa-minus-circle fas dragging-icon warning"></span>
                </div>
              </draggable>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-col></el-col>
        </el-row>
      </div>
    </div>
    <template #footer v-if="currentSchema">
      <div class="dialog-footer">
        <el-button @click="closeDialog">{{ $t('commands.cancel') }}</el-button>
        <el-dropdown split-button type="primary" @click="saveSchemas" @command="handleCommands" class="m-l-h">
          <t-icon-label icon="el-icon-icon-floppy-disk">{{ $t('commands.save') }}</t-icon-label>
          <template #dropdown><el-dropdown-menu>
              <el-dropdown-item command="clone">
                <t-icon-label icon="el-icon-icon-copy4">{{ $t('grid.schemaManager.clone') }}</t-icon-label>
              </el-dropdown-item>
              <template v-for="(schema, index) in schemas.filter(s => s.name !== currentSchema.name)">
                <el-dropdown-item :key="schema.name" :divided="index === 0" :command="schema.name">
                  <t-icon-label icon="el-icon-icon-pencil">{{ $t('grid.schemaManager.edit', { schema: schema.name })
                  }}</t-icon-label>
                </el-dropdown-item>
              </template>
              <t-delete-dropdown-item divided @delete="handleDeleteCommand"
                v-if="schemas.length > 1"></t-delete-dropdown-item>
            </el-dropdown-menu></template>
        </el-dropdown>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import i18nUtils from 'utils/i18nUtils';
import { Prop, Watch } from 'vue-property-decorator';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { Getter } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'schemaManager',
})
export default class SchemaManager extends Vue {
  @Prop({ type: String, required: true })
  repeater: string;

  @Prop({ type: String, required: true })
  initSchema: any;

  @Getter user: IUser;

  open = true;

  loading = false;

  saving = false;

  columns = [];

  schemas = [];

  currentSchema = null;

  async mounted() {
    await this.handleLoadingData();
  }

  get showKey(): boolean {
    if (this.currentSchema) {
      return this.currentSchema.showKey;
    }
    return null;
  }

  handleShowKey(v: boolean) {
    if (this.currentSchema) {
      this.currentSchema.showKey = v;
    }
  }

  async handleLoadingData() {
    this.dialogVisible = true;
    if (this.dialogVisible) {
      try {
        this.loading = true;
        await this.loadData();
      } catch (e) {
        this.$message.error(e.message);
      } finally {
        this.loading = false;
      }
    }
  }

  async loadData() {
    const response = await this.$http.get(`${this.repeater}/Schemes`);
    const data = response.data;
    this.columns = data.columns;
    this.schemas = data.schemes;
    this.currentSchema = this.schemas.find((s) => s.name === this.initSchema);
    if (!this.currentSchema) {
      this.currentSchema = this.schemas[0];
    }
  }

  addNewSchema(name: string) {
    const newSchema = {
      ...this.currentSchema,
      name,
      showKey: false,
    };
    this.schemas.push(newSchema);
    this.currentSchema = newSchema;
  }

  deleteSchema() {
    if (this.schemas.length > 1) {
      this.schemas = this.schemas.filter((schema) => schema.name !== this.currentSchema.name);
      this.currentSchema = this.schemas[0];
    }
  }

  clearState(done) {
    this.columns = [];
    this.schemas = [];
    this.currentSchema = null;
    done();
  }

  async handleCommands(command: string) {
    switch (command) {
      case 'clone':
        try {
          const res = (await this.$prompt('', this.$t('grid.schemaManager.new.title') as string)) as any;
          if (res && res.value) {
            this.addNewSchema(res.value);
          }
        } catch { }
        break;
      default:
        if (command) {
          this.currentSchemaName = command;
        }
        break;
    }
  }

  async saveSchemas() {
    this.saving = true;
    try {
      const schemas = this.schemas;
      const response = await this.$http.post(`${this.repeater}/SaveSchemes`, schemas);
      this.$emit('schema-changed', this.currentSchema.name);
      this.closeDialog();
    } finally {
      this.saving = false;
    }
  }

  closeDialog() {
    this.columns = [];
    this.schemas = [];
    this.currentSchema = null;
    this.dialogVisible = false;
  }

  get dialogVisible() {
    return this.open;
  }

  set dialogVisible(val) {
    this.open = val;
    if (!val) {
      this.$emit('close');
    }
  }

  get transferData() {
    return this.columns.map((column) => ({
      key: column.name,
      label: column.text,
    }));
  }

  tempColumnFilter: string = null;

  columnFilter: string = null;

  get unselectedData() {
    const filtered = this.columns
      .filter((column) => !this.currentSchema.columns.includes(column.name))
      .filter((column) => {
        if (this.columnFilter) {
          return new RegExp(this.columnFilter, 'i').test(column.text);
        }
        return true;
      });
    return _.orderBy(
      filtered.map((column) => column),
      (c) => c.text,
    );
  }

  get selectedData() {
    const columns = [];
    for (const column of this.currentSchema.columns) {
      const c = this.columns.find((col) => col.name === column);
      if (c) {
        columns.push(c);
      }
    }
    return columns;
  }

  set selectedData(values) {
    this.currentSchema.columns = values.map((c) => c.name).slice(0, 10);
  }

  get currentSchemaName() {
    if (this.currentSchema) {
      return this.currentSchema.name;
    }
    return null;
  }

  set currentSchemaName(value) {
    const schema = this.schemas.find((schema) => schema.name === value);
    if (schema) {
      this.currentSchema = schema;
    }
  }

  async handleDeleteCommand() {
    if (this.currentSchema) {
      const index = this.schemas.findIndex((schema) => schema.name === this.currentSchema.name);
      this.schemas = [...this.schemas.slice(0, index), ...this.schemas.slice(index + 1)];
      this.currentSchema = this.schemas[0];
    }
  }

  removeColumnAtIndex(index) {
    const columns = this.currentSchema.columns;
    this.currentSchema.columns = [...columns.slice(0, index), ...columns.slice(index + 1)];
  }
}
</script>

<style lang="scss" scoped>
@import 'element-variables';

.schemaManager {
  .el-transfer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-place-holder {
    height: $--input-mini-height;
  }
}
</style>
