/* eslint-disable no-unused-expressions */
/* eslint-disable max-classes-per-file */
import jwtManager from '@/utils/jwtManager';
import { WindowScrollController } from '@fullcalendar/common';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { ICloudUser } from '@/utils/trackings';
import moment from 'moment';
import Vue from 'vue';

export interface IChat {
  exists: boolean;
  visible: boolean;
  show(): void;
  hide(): void;
  open(): void;
  close(): void;
  user(user: ICloudUser): void;
}
class CrispChat implements IChat {
  constructor(chat) {
    this.chat = chat;
  }

  chat: any;

  get exists(): boolean {
    return true;
  }

  get visible(): boolean {
    return this.chat.is('chat:visible');
  }

  show() {
    try {
      this.chat.do('chat:show');
    } catch {}
  }

  hide() {
    try {
      this.chat.do('chat:hide');
    } catch {}
  }

  open() {
    try {
      this.chat.do('chat:open');
    } catch {}
  }

  close() {
    try {
      this.chat.do('chat:close');
    } catch {}
  }

  user(user: ICloudUser) {
    if (jwtManager.refreshToken === null) {
      return;
    }
    const { chat } = this;
    if (chat && user && chat.set) {
      try {
        chat.set('user:email', user.userName);
        chat.set('user:nickname', user.name);
        chat.set('user:avatar', user.avatar);

        const session = [];
        session.push(['status', user.trial ? 'trial' : 'payed']);
        session.push(['expiration', moment(user.expirationDate).format('L')]);
        session.push(['users', user.maxUsers]);
        session.push(['company_id', user.customerId]);
        session.push(['user_id', user.userId]);
        if (user.consumerPackageId) {
          if (user.consumerPackageId.startsWith('A-S')) {
            session.push(['licence', 'ONLINE']);
          } else if (user.consumerPackageId.startsWith('SDS-')) {
            session.push(['licence', 'SoftwareDelSol']);
          } else {
            session.push(['licence', 'OFFLINE']);
          }
        }
        session.push(['consumerPackageId', user.consumerPackageId]);
        session.push(['customerCode', user.customerCode]);
        session.push(['shard', user.shard]);
        session.push(['version', user.version]);
        if (user.score) {
          session.push(['nps', user.score]);
        }
        chat.push(['set', 'session:data', [session]]);
      } catch {}
    }
  }
}
class IntercomChat implements IChat {
  constructor(intercom) {
    this.Intercom = intercom;
  }

  // tslint:disable-next-line:variable-name
  Intercom: any;

  get exists(): boolean {
    return true;
  }

  get visible(): boolean {
    return false;
  }

  show() {}

  hide() {}

  open() {
    try {
      this.Intercom('show');
    } catch {}
  }

  close() {
    try {
      this.Intercom('hide');
    } catch {}
  }

  user(user: ICloudUser) {
    if (jwtManager.refreshToken === null) {
      return;
    }
    if (!user) {
      return;
    }
    this.Intercom('boot', {
      ...(window as any).intercomSettings,
      email: user.userName,
      name: user.name,
      user_id: user.userId.toString(),
      avatar: {
        type: 'avatar',
        image_url: user.avatar,
      },
      company: {
        company_id: user.companyId.toString(),
        name: user.customerName,
        plan: user.licence,
        user_count: user.maxUsers,
        trial: user.trial,
      },
      user_hash: user.hash,
    });
  }
}
class FacadeChat implements IChat {
  get exists(): boolean {
    return this.buildChat()?.exists ?? false;
  }

  get visible(): boolean {
    return this.buildChat()?.visible ?? false;
  }

  show() {
    this.buildChat()?.show();
  }

  hide() {
    this.buildChat()?.hide();
  }

  open() {
    this.buildChat()?.open();
  }

  close() {
    this.buildChat()?.close();
  }

  user(user: ICloudUser) {
    this.buildChat()?.user(user);
  }

  buildChat(): IChat {
    const w = window as any;
    if (w.$crisp) {
      return new CrispChat(w.$crisp);
    }
    if (w.Intercom) {
      return new IntercomChat(w.Intercom);
    }
    return null;
  }
}

export default () => {
  Vue.use((v) => {
    v.prototype.$chat = v.chat = new FacadeChat();
  });
};
