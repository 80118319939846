//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SharingTemplateTeamMode {
	Team = 0,
	CurrentTeams = 1,
	CurrentManagerTeams = 2,
	CurrentSubTeams = 3,
	OwnerTeams = 4,
	OwnerManagerTeams = 5,
	OwnerSubTeams = 6,
	SalesPersonTeams = 7,
	SalesPersonManagerTeams = 8,
	SalesPersonSubTeams = 9
}
