<template>
    <span>&nbsp;</span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'Nbsp',
})
export default class Nbsp extends Vue {}
</script>
