//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FlowToType {
	CustomDestination = 0,
	FlowTemplateOwner = 1,
	FlowOwner = 2,
	FlowElement = 3,
	ElementOwner = 4,
	ElementCommercial = 5,
	ManagerFlowTemplateOwner = 6,
	ManagerElementCommercial = 7,
	ManagerElementOwner = 8,
	ActivityOwner = 9,
	AllJobOrderResources = 10,
	AllJobOrderTaskResources = 11,
	JobOrderManager = 12,
	TicketContact = 13,
	OpportunityOwner = 14,
	OpportunitySalesPerson = 15
}
