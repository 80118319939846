import axios from 'axios';
import urlParse from 'url-parse';

function getFileNameFromUrl(url: string) {
  const downloadUrl = urlParse(url);
  const name = downloadUrl.pathname.substring(downloadUrl.pathname.lastIndexOf('/') + 1);
  if (name) {
    return decodeURIComponent(name);
  }
  return name;
}
function downloadBlob(blob: Blob, filename: string) {
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = filename;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
}
function download(data: string | Blob, filename: string = null) {
  if (data instanceof Blob) {
    downloadBlob(data, filename);
    return;
  }
  axios
    .get(
      <string>data,
      <any>{
        _noAuth: true,
        responseType: 'blob', // important
      },
    )
    .then((response) => {
      downloadBlob(new Blob([response.data]), filename || getFileNameFromUrl(data as string));
    });
}
export default download;
