<template>
  <span>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :value="flowTrigger" :key="flowTrigger.key" v-for="flowTrigger in selectedData"
        :closable="!readOnly" :disable-transitions="false" @close="handleRemoveItem(flowTrigger)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'FlowTriggerLookup',
  components: {
    TLookupSkin,
  },
})
export default class FlowTriggerLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'FlowTrigger';
  }

  keyPrefix() {
    return 'FlowTrigger';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
</script>
