<template>
  <div>
    <div v-if="schema && value">
      <slot name="header"></slot>
      <el-form
        :model="value"
        :rules="rules"
        ref="simpleLayoutStudioForm"
        size="mini"
        :label-width="labelWidth"
      >
        <slot name="form-header"></slot>
        <t-ls-form
          v-if="currentLayoutStudioSchema"
          :schema="currentLayoutStudioSchema"
          :data="value"
          @input="update"
          :context="context"
        ></t-ls-form>
        <slot name="form-footer"></slot>
      </el-form>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Getter, namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ElementUI from 'element-ui';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import { ILayoutStudioSlot } from 'rt/Interfaces/UI/ILayoutStudioSlot';
import layoutStudioValidationRules from 'utils/layoutStudioValidationRules';
import _ from 'lodash';

@Component({
  name: 'SimpleLsForm',
})
export default class SimpleLsForm extends Vue {
  @Prop({ required: true, type: Object })
  schema: IWidgetPage;

  @Prop({ required: true, type: Object })
  value: any;

  @Prop({ type: Object, required: false })
  context: any;

  update(value) {
    this.$emit('input', value);
  }

  get currentLayoutStudioSchema() {
    const s = this.schema;
    if (!s) return null;
    if (!s.tab) return null;
    if (!s.tab.tabs) return null;
    if (!s.tab.tabs.length) return null;
    return s.tab.tabs[0].layoutStudioSchema;
  }

  /* ABSTRACT METHODS AND PROPERTIES TO IMPLEMENTS IN SUPERCLASS */
  get form(): ElementUI.Form {
    return this.$refs.simpleLayoutStudioForm as ElementUI.Form;
  }

  async validate(): Promise<boolean> {
    const form = this.form;
    return new Promise<boolean>((success, reject) => {
      form.validate(async (valid) => {
        success(valid);
      });
    });
  }

  get rules() {
    return layoutStudioValidationRules.buildRules(this.schema, () => this.value);
  }

  get labelWidth() {
    return this.schema.labelWidth ?? this.$viewport.formLabelWidth;
  }
}
</script>
