import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { Prop, Watch, Inject } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'LookupMixin',
  mixins: [simpleMixin],
})
export default class LookupMixin extends Vue {
  @Inject('elForm') elForm: ElementUI.Form;

  @Prop({
    type: Object,
    required: false,
    default: () => ({
        text: 'schema.text',
        propertyName: 'schema.propertyName',
        multiple: false,
        readOnly: false,
      }),
  })
  schema: {
    multiple: boolean;
    readOnly: boolean;
  };

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  multiple: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({}),
  })
  data: any;

  @Prop({
    type: [Object, Number, Array],
    required: false,
  })
  value: number | number[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  readOnly: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  drawer: boolean;

  @Prop({
    type: Number,
    required: false,
    default: null,
  })
  max: number;

  get isReadOnly() {
    return this.schema.readOnly || (<ElementUI.Form>(this.elForm || {})).disabled || this.readOnly;
  }

  selectedData: any = [];

  simpleEmit: (value: any) => void;

  /* ABSTRACT METHODS AND PROPERTIES TO IMPLEMENTS IN SUPERCLASS */
  async resolve(id: number): Promise<string> {
    console.error('You need to override async resolve(id: number): Promise<string> method in super class');
    throw 'You need to override async resolve(id: number): Promise<string> in super class';
  }

  protected get identifier(): string {
    console.error('You need to override get identifier(): string method in super class');
    throw 'You need to override get identifier(): string method in super class';
  }

  @Watch('value')
  async handleValueChange(val) {
    if (this.selectedData.length === 0 || this.getIdFromString(this.selectedData[0].key) !== val) {
      if (this.handleMultipleValues) {
        if (val && val.length > 0) {
          this.selectedData = await Promise.all((<number[]>val).map(async (v) => ({ key: `${this.identifier}-${v}`, title: await this.resolve(v) })));
        } else {
          this.selectedData = [];
        }
      } else if (val > 0) {
          this.selectedData = [{ key: `${this.identifier}-${val}`, title: await this.resolve(val) }];
        } else {
          this.selectedData = [];
        }
    }
  }

  getIdFromString(idString: string): number {
    const result = idString.split('-')[1];
    return parseInt(result, 10);
  }

  getIdentifierFromString(idString: string): string {
    return idString.split('-')[0];
  }

  async mounted() {
    if (this.handleMultipleValues) {
      if (this.value && (<number[]> this.value).length) {
        this.selectedData = await Promise.all((<number[]> this.value).map(async (v) => ({ key: `${this.identifier}-${v}`, title: await this.resolve(v) })));
      }
    } else if (this.value > 0) {
        this.selectedData = [{ key: `${this.identifier}-${this.value}`, title: await this.resolve(<number> this.value) }];
      }
  }

  private get handleMultipleValues() {
    return this.schema.multiple || (this.simple && this.multiple);
  }

  change(value) {
    if (this.handleMultipleValues) {
      if (value.length > 0) {
        this.$emit('input', value.map((v) => this.getIdFromString(v.key)));
      } else {
        this.$emit('input', []);
      }
    } else if (value.length > 0) {
        const id = this.getIdFromString(value[0].key);
        this.$emit('input', id);
      } else {
        this.$emit('input', 0);
      }
  }

  removeItem() {
    if (this.handleMultipleValues) {
      this.$emit('input', []);
    } else {
      this.$emit('input', 0);
    }
  }

  focus() {
    const input = <any> this.$refs['lookup'];
    if (input) {
      input.focus();
    }
  }
}
