//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PartStat {
	NeedsAction = 0,
	Accepted = 1,
	Declined = 2,
	Tentative = 3
}
