<template>
  <el-row type="flex" justify="start" :align="iconAlign" class="label m-b-h" :gutter="10">
    <el-col :span="-1">
      <slot name="icon"></slot>
    </el-col>
    <el-col>
      <slot></slot>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'SummaryLabel',
  props: {
    iconAlign: {
      type: String,
      required: false,
      default: 'middle',
    },
  },
})
export default class SummaryLabel extends Vue {}
</script>
<style lang="scss">
@import '~element-variables';
.label {
  color: $--color-text-regular;
  font-size: $--font-size-small;
}
</style>
