//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MailingListSelectionRule {
	AllEMailOfFirstType = 0,
	OnlyFirstEMailOfFirstType = 1,
	OnlyFirstEMailOfAllTypes = 2,
	AllEMailOfAllTypes = 3
}
