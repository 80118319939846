<template>
  <span>
    <slot :number="tweenValue">{{ locale ? $n(numericValue) : numericValue }}</slot>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, namespace } from 'vuex-class';
import TWEEN from '@tweenjs/tween.js';

@Component({
  name: 'TweeningNumber',
})
export default class TweeningNumber extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  round: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  locale: boolean;

  tweenValue: number = 0;

  created() {
    this.tweenValue = this.value;
  }

  get numericValue() {
    return this.round ? Math.round(this.tweenValue) : this.tweenValue;
  }

  @Watch('value')
  onTotalWeightSumChange(newValue: number, oldValue: number) {
    function animate() {
      if (TWEEN.update()) {
        requestAnimationFrame(animate);
      }
    }
    this.tweenValue = oldValue;
    new TWEEN.Tween(this).easing(TWEEN.Easing.Quadratic.Out).to({ tweenValue: newValue }, 500).start();

    animate();
  }
}
</script>
