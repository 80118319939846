<template>
  <span>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :value="catalogCategory" :key="catalogCategory.key" v-for="catalogCategory in selectedData"
        :closable="!readOnly" :disable-transitions="false" @close="handleRemoveItem(catalogCategory)">
        <template #default="{ title }">
          {{ title | lhellip(40) }}
        </template>
      </t-lookup-tag>
      <template #dialog-search>
        <el-popover v-model="popoverOpen" @hide="handleCatalogCategoryTreePopoverHide"
          ref="popover-catalog-category-tree" placement="right" :title="$t('lookup.catalogCategory')" trigger="click">
          <t-catalog-category-tree v-if="popoverOpen" ref="catalog-category-tree" :multipleSelection="multipleSelection"
            :value="selectedData" @input="handleTreeInputChange" :only-leaf="false" />
          <template #reference>
            <i class="el-icon-search cursor-pointer" />
          </template>
        </el-popover>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'CatalogCategoryLookup',
  components: {
    TLookupSkin,
  },
})
export default class CatalogCategoryLookup extends BaseLookup {
  suggestionControllerName() {
    return 'CatalogCategory';
  }

  keyPrefix() {
    return 'Folder';
  }

  async handleCatalogCategoryTreePopoverHide() {
    const catalogCategoryTree = this.$refs['catalog-category-tree'];
    await (catalogCategoryTree as any).reset();
  }

  handleTreeInputChange(items) {
    if (!this.multipleSelection) {
      this.popoverOpen = false;
    }
    this.changeItems(items);
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
</script>
