//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ApprovalNotificationMode {
	NotifyToApprovalOwners = 0,
	NotifyToOneLevelUserManager = 1,
	NotifyToTwoLevelUserManagers = 2,
	NotifyToAllUserManagers = 10
}
