//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TimelineItemAction {
	Created = 0,
	Modified = 1,
	Expire = 2,
	Action = 3
}
