import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'FormMixin',
})
export default class FormMixin extends Vue {
  simpleEmit(value: any): void {
    this.$emit('input', value);
  }
}
