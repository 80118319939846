import 'es6-object-assign/auto';

const inputValueResolver = (el) => el.value;
const elInputValueResolver = (el) => {
  let val;
  el.childNodes.forEach((cn) => {
    if (cn.tagName && cn.tagName.toLocaleUpperCase() === 'INPUT') {
      val = inputValueResolver(cn);
    }
  });
  return val;
};
const autoompleteValueResolver = (el) => {
  let val;
  el.childNodes.forEach((cn) => {
    if (cn.classList && cn.classList.contains('el-input')) {
      val = elInputValueResolver(cn);
    }
  });
  return val;
};

export default (el, binding) => {
  const mirror = document.querySelector(`.vue-input-autowidth-mirror-${el.dataset.uuid}`);
  const defaults = {
    maxWidth: 'none',
    minWidth: 'none',
    comfortZone: 0,
  };
  const options = { ...defaults, ...binding.value };

  el.style.maxWidth = options.maxWidth;
  el.style.minWidth = options.minWidth;

  let val;
  if (el.tagName.toLocaleUpperCase() === 'INPUT') {
    val = inputValueResolver(el);
  } else if (el.classList.contains('el-input')) {
      val = elInputValueResolver(el);
    } else if (el.classList.contains('el-autocomplete')) {
        val = autoompleteValueResolver(el);
      } else {
        throw new Error('v-input-autowidth can only be used on input elements or el-input element.');
      }

  if (!val) {
    val = el.placeholder || '';
  }
  while (mirror.childNodes.length) {
    mirror.removeChild(mirror.childNodes[0]);
  }
  mirror.appendChild(document.createTextNode(val));
  const newWidth = mirror.getBoundingClientRect().width + options.comfortZone;
  if (newWidth !== el.getBoundingClientRect().width) {
    el.style.width = `${newWidth}px`;
  }
};
