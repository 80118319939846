<template>
  <span>{{ text }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';

@Component({
  name: 'ResolveLabel',
  props: {
    value: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    fallback: {
      type: String,
      required: false,
      default: null,
    },
  },
})
export default class ResolveLabel extends Vue {
  @Getter('lists/resolve')
  resolver: (type: ListsType, key: number | string) => string;

  @Watch('value')
  handleValueChange() {
    this.updateText();
  }

  @Watch('type')
  handleTypeChange() {
    this.loadListType();
  }

  value: number | string;

  text: string = null;

  fallback: string;

  type: ListsType;

  async mounted() {
    await this.loadListType();
    this.updateText();
  }

  async loadListType() {
    await this.$store.dispatch('lists/load', this.type);
  }

  updateText() {
    this.text = this.resolver(this.type, this.value) || this.fallback;
  }
}
</script>
