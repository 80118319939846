//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MassiveUpdateStatus {
	Waiting = 0,
	Importing = 1,
	Updated = 2,
	Failed = 3,
	Aborted = 4
}
