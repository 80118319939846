<template>
  <t-form-item
    :label="schema.text || schema.id"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <t-color-picker :value="value" @input="change" :size="schema.size" :disabled="schema.readOnly"></t-color-picker>
  </t-form-item>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'ColorPickerInput',
  mixins: [simpleMixin],
})
export default class ColorPickerInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  change(value) {
    this.$emit('input', value);
  }
}
</script>
