//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MimeTopLevelMediaType {
	text = 1,
	image = 2,
	audio = 4,
	video = 8,
	application = 16,
	multipart = 32,
	message = 64
}
