const routeNames = {
  root: 'root',
  init: 'init',
  loading: 'loading',
  reenter: 'reenter',
  error4xx: '4xx',
  today: 'today',
  calendar: 'calendar',
  login: 'login',
  recoverPassword: 'recoverPassword',
  resetPassword: 'resetPassword',
  accountInvitation: 'accountInvitation',
  activating: 'activating',
  tsid: 'tsid',
  globalSearch: 'globalSearch',
  profile: 'profile',
  profileToday: 'profileToday',
  profileSync: 'profileSync',
  profileWebDavQueue: 'profileWebDavQueue',
  profileSignatures: 'profileSignatures',
  profileSignature: 'profileSignature',
  profileWebMailAccounts: 'profileWebMailAccounts',
  profileWebMailAccount: 'profileWebMailAccount',
  profileWebApiKeys: 'profileWebApiKeys',
  companies: 'companies',
  company: 'company',
  companyContacts: 'companyContacts',
  companyActivities: 'companyActivities',
  companyOpportunities: 'companyOpportunities',
  companyProducts: 'companyProducts',
  companyAddresses: 'companyAddresses',
  companyAddress: 'companyAddress',
  companyTimeline: 'companyTimeline',
  companyStorage: 'companyStorage',
  companyWebMailArchive: 'companyWebMailArchive',
  contacts: 'contacts',
  contact: 'contact',
  contactActivities: 'contactActivities',
  contactAddresses: 'contactAddresses',
  contactAddress: 'contactAddress',
  contactOpportunities: 'contactOpportunities',
  contactProducts: 'contactProducts',
  contactTimeline: 'contactTimeline',
  contactStorage: 'contactStorage',
  contactWebMailArchive: 'contactWebMailArchive',
  leads: 'leads',
  leadsGrid: 'leadsGrid',
  leadsKanban: 'leadsKanban',
  lead: 'lead',
  leadOpportunities: 'leadOpportunities',
  leadProducts: 'leadProducts',
  leadActivities: 'leadActivities',
  leadAddresses: 'leadAddresses',
  leadAddress: 'leadAddress',
  leadTimeline: 'leadTimeline',
  leadStorage: 'leadStorage',
  leadWebMailArchive: 'leadWebMailArchive',
  appointment: 'appointment',
  appointmentWayPoint: 'appointmentWayPoint',
  appointmentTimeLine: 'appointmentTimeLine',
  calendarEvent: 'calendarEvent',
  opportunities: 'opportunities',
  opportunitiesGrid: 'opportunitiesGrid',
  opportunity: 'opportunity',
  opportunityProducts: 'opportunityProducts',
  opportunityProductEdit: 'opportunityProductEdit',
  opportunityTimeline: 'opportunityTimeline',
  opportunityWorkPlan: 'opportunityWorkPlan',
  opportunityActivities: 'opportunityActivities',
  opportunityLog: 'opportunityLog',
  opportunityQuotes: 'opportunityQuotes',
  opportunityRelations: 'opportunityRelations',
  opportunityReferrers: 'opportunityReferrers',
  opportunityReferrerEdit: 'opportunityReferrerEdit',
  opportunityPartners: 'opportunityPartners',
  opportunityPartnerEdit: 'opportunityPartnerEdit',
  opportunityCompetitors: 'opportunityCompetitors',
  opportunityCompetitorEdit: 'opportunityCompetitorEdit',
  opportunityStorage: 'opportunityStorage',
  opportunitiesKanban: 'opportunitiesKanban',
  opportunitiesMap: 'opportunitiesMap',
  opportunitiesForecast: 'opportunitiesForecast',
  opportunitiesAnalysis: 'opportunitiesAnalysis',
  activities: 'activities',
  activity: 'activity',
  activityTimeline: 'activityTimeline',
  tustenaCompany: 'tustenaCompany',
  tustenaCompanyOrgChart: 'tustenaCompanyOrgChart',
  setup: 'setup',
  setupHome: 'setupHome',
  setupRedir: 'setupRedir',
  setupGridModule: 'setupGridModule',
  setupStandardGridModule: 'setupStandardGridModule',
  setupStandardEditModule: 'setupStandardEditModule',
  setupDynamicModule: 'setupDynamicModule',
  setupMailLog: 'setupMailLog',
  privacySetup: 'privacySetup',
  mailingListSignature: 'mailingListSignature',
  mailingListPlan: 'mailingListPlan',
  group: 'group',
  groupSecurityPolicies: 'groupSecurityPolicies',
  groups: 'groups',
  naturalLanguageHelp: 'naturalLanguageHelp',
  webmail: 'webmail',
  webmailView: 'webmailView',
  webmailViewOriginal: 'webmailViewOriginal',
  mailingTemplates: 'mailingTemplates',
  mailingTemplate: 'mailingTemplate',
  mailingTemplatePreview: 'mailingTemplatePreview',
  mailTemplates: 'mailTemplates',
  mailTemplate: 'mailTemplate',
  mailTemplatePreview: 'mailTemplatePreview',
  lists: 'lists',
  list: 'list',
  listAnagraphics: 'listAnagraphics',
  listAnagraphicsSearch: 'listAnagraphicsSearch',
  listAnagraphicsSearchResults: 'listAnagraphicsSearchResults',
  listBlackList: 'listBlackList',
  accounts: 'accounts',
  account: 'account',
  accountTabRouteName: 'accountTabRouteName',
  accountSecurityPolicies: 'accountSecurityPolicies',
  accountWebApiKeys: 'accountWebApiKeys',
  accountAccessLogs: 'accountAccessLogs',
  messages: 'messages',
  mailings: 'mailings',
  mailing: 'mailing',
  mailingUserAgents: 'mailingUserAgents',
  mailingStats: 'mailingStats',
  mailingSpooling: 'mailingSpooling',
  mailingSpoolingListConfirm: 'mailingSpoolingListConfirm',
  mailingSpoolingFrom: 'mailingSpoolingFrom',
  mailingSpoolingPreview: 'mailingSpoolingPreview',
  layoutStudio: 'layoutStudio',
  layoutStudioEditor: 'layoutStudioEditor',
  analytics: 'analytics',
  analyticsModule: 'analyticsModule',
  import: 'import',
  importMapping: 'importMapping',
  importPre: 'importPre',
  importProgress: 'importProgress',
  freefields: 'freefields',
  freefield: 'freefield',
  flowTemplates: 'flowTemplates',
  flowTemplate: 'flowTemplate',
  flowTemplatePlumb: 'flowTemplatePlumb',
  flowTemplateLog: 'flowTemplateLog',
  flowTemplateLogDetails: 'flowTemplateLogDetails',
  storage: 'storage',
  storages: 'storages',
  limboCompanies: 'limboCompanies',
  limboContacts: 'limboContacts',
  limboLeads: 'limboLeads',
  limboAppointments: 'limboAppointments',
  limboActivities: 'limboActivities',
  hooks: 'hooks',
  hook: 'hook',
  hookLogs: 'hookLogs',
  hookLog: 'hookLog',
  quotes: 'quotes',
  quotesGrid: 'quotesGrid',
  quotesKanban: 'quotesKanban',
  quote: 'quote',
  quoteTimeline: 'quoteTimeline',
  quotePrint: 'quotePrint',
  orders: 'orders',
  ordersGrid: 'ordersGrid',
  trialExpired: 'trialExpired',
  licenceExpired: 'licenceExpired',
  backup: 'backup',
  printTemplates: 'printTemplates',
  printTemplate: 'printTemplate',
  webdavQueue: 'webdavQueue',
  catalogs: 'catalogs',
  catalog: 'catalog',
  catalogStorage: 'catalogStorage',
  catalogPrices: 'catalogPrices',
  mailingLandingUnsubscribe: 'mailingLandingUnsubscribe',
  mailingLandingUnsubscribed: 'mailingLandingUnsubscribed',
  mailingLandingExpired: 'mailingLandingExpired',
  calendarBooking: 'calendarBooking',
  storageSharing: 'storageSharing',
  suggestions: 'suggestions',
  workPlan: 'workPlan',
  workPlanOverview: 'workPlanOverview',
  workPlanKanban: 'workPlanKanban',
  workPlanMessages: 'workPlanMessages',
  workPlanList: 'workPlanList',
  workPlanResources: 'workPlanResources',
  workPlanDashboard: 'workPlanDashboard',
  workPlanStorage: 'workPlanStorage',
};
export default routeNames;
