//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FilterUserAccount {
	CurrentUser = 1,
	CurrentGroup = 2,
	CurrentOffice = 3,
	CurrentTeams = 4,
	SelectedUser = 5,
	SelectedGroup = 6,
	SelectedOffice = 7,
	SelectedTeam = 8,
	Assigned = 9,
	NotAssigned = 10,
	AccountAndSubUser = 11
}
