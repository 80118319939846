//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Tablename {
	CRM_WorkActivity = 0,
	BASE_Contacts = 1,
	CRM_Reminder = 2,
	BASE_Referring = 3
}
