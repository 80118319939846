//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TimerType {
	Minutes = 0,
	Hours = 1,
	Days = 2,
	Months = 3
}
