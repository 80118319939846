//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SimilarityCheckType {
	CompanyName = 0,
	VatId = 1,
	TaxIdentificationNumber = 2,
	EMail = 3,
	Phone = 4,
	MobilePhone = 5,
	Fax = 6,
	SurnameName = 7
}
