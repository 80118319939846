//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IContactDTO } from '../../../DTO/IContactDTO';
import { ICloudSettings } from './ICloudSettings';
import { IAuthenticationResponse } from '../../WidgetElements/IAuthenticationResponse';
import { IGoogleCalendarSettingsDTO } from '../../../DTO/IGoogleCalendarSettingsDTO';
import { IGoogleCalendarAuthenticationRequest } from './IGoogleCalendarAuthenticationRequest';
import { IListResult } from '../../Controls/IListResult';
import { IICloudCalendarSettingsDTO } from '../../../DTO/IICloudCalendarSettingsDTO';
import { IICloudAuthenticationRequest } from './IICloudAuthenticationRequest';
import { IRadicaleCalendarSettingsDTO } from '../../../DTO/IRadicaleCalendarSettingsDTO';
import { IRadicaleAuthenticationRequest } from './IRadicaleAuthenticationRequest';
import { IDavCalendarSettingsDTO } from '../../../DTO/IDavCalendarSettingsDTO';
import { IDavAuthenticationRequest } from './IDavAuthenticationRequest';
import { IICloudContactSettingsDTO } from '../../../DTO/IICloudContactSettingsDTO';
import { IRadicaleContactSettingsDTO } from '../../../DTO/IRadicaleContactSettingsDTO';
import { IDavContactSettingsDTO } from '../../../DTO/IDavContactSettingsDTO';
import { IWebDavQueueStats } from './IWebDavQueueStats';
import { IAccountDTO } from '../../../DTO/IAccountDTO';

//element.Name=AccountController result.Name=AccountController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.AccountController);
/** Result of WebApiControllerGenerator activity */
export class AccountController extends DefaultDtoControllerProxy<IAccountDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Accounts', BusinessObjectType.Account);
	}
	public GetFreeAccounts(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Accounts/GetFreeAccounts', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ContactReference(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IContactDTO>
	{
		return (this.axios.get('Accounts/ContactReference?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetAllCloudSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICloudSettings>
	{
		return (this.axios.get('Accounts/GetAllCloudSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetGoogleContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Accounts/GetGoogleContactSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateGoogleContactSettings(id: number, dto: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateGoogleContactSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteGoogleContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteGoogleContactSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateGoogleContactSettings(id: number, auth: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateGoogleContactSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetGoogleCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleCalendarSettingsDTO>
	{
		return (this.axios.get('Accounts/GetGoogleCalendarSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateGoogleCalendarSettings(id: number, dto: IGoogleCalendarSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateGoogleCalendarSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteGoogleCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteGoogleCalendarSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateGoogleCalendarSettings(id: number, auth: IGoogleCalendarAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateGoogleCalendarSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetGoogleCalendarSettingsCollectionList(id: number, auth: IGoogleCalendarAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetGoogleCalendarSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetICloudCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IICloudCalendarSettingsDTO>
	{
		return (this.axios.get('Accounts/GetICloudCalendarSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateICloudCalendarSettings(id: number, dto: IICloudCalendarSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateICloudCalendarSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteICloudCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteICloudCalendarSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateICloudCalendarSettings(id: number, auth: IICloudAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateICloudCalendarSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetICloudCalendarSettingsCollectionList(id: number, auth: IICloudAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetICloudCalendarSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetRadicaleCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRadicaleCalendarSettingsDTO>
	{
		return (this.axios.get('Accounts/GetRadicaleCalendarSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateRadicaleCalendarSettings(id: number, dto: IRadicaleCalendarSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateRadicaleCalendarSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteRadicaleCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteRadicaleCalendarSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateRadicaleCalendarSettings(id: number, auth: IRadicaleAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateRadicaleCalendarSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetRadicaleCalendarSettingsCollectionList(id: number, auth: IRadicaleAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetRadicaleCalendarSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDavCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IDavCalendarSettingsDTO>
	{
		return (this.axios.get('Accounts/GetDavCalendarSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateDavCalendarSettings(id: number, dto: IDavCalendarSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateDavCalendarSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteDavCalendarSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteDavCalendarSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateDavCalendarSettings(id: number, auth: IDavAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateDavCalendarSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDavCalendarSettingsCollectionList(id: number, auth: IDavAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetDavCalendarSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetICloudContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IICloudContactSettingsDTO>
	{
		return (this.axios.get('Accounts/GetICloudContactSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateICloudContactSettings(id: number, dto: IICloudContactSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateICloudContactSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteICloudContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteICloudContactSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateICloudContactSettings(id: number, auth: IICloudAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateICloudContactSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetICloudContactSettingsCollectionList(id: number, auth: IICloudAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetICloudContactSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetRadicaleContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRadicaleContactSettingsDTO>
	{
		return (this.axios.get('Accounts/GetRadicaleContactSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateRadicaleContactSettings(id: number, dto: IRadicaleContactSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateRadicaleContactSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteRadicaleContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteRadicaleContactSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateRadicaleContactSettings(id: number, auth: IRadicaleAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateRadicaleContactSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetRadicaleContactSettingsCollectionList(id: number, auth: IRadicaleAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetRadicaleContactSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDavContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IDavContactSettingsDTO>
	{
		return (this.axios.get('Accounts/GetDavContactSettings?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateOrUpdateDavContactSettings(id: number, dto: IDavContactSettingsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/CreateOrUpdateDavContactSettings?'+'&id='+id, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteDavContactSettings(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post('Accounts/DeleteDavContactSettings?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateDavContactSettings(id: number, auth: IDavAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('Accounts/ValidateDavContactSettings?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDavContactSettingsCollectionList(id: number, auth: IDavAuthenticationRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.post('Accounts/GetDavContactSettingsCollectionList?'+'&id='+id, auth, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SyncQueue(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IWebDavQueueStats>
	{
		return (this.axios.get('Accounts/SyncQueue', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ImportAsCompany(id: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/ImportAsCompany', JSON.stringify(id), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public ImportAsContact(id: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/ImportAsContact', JSON.stringify(id), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public ImportAsLead(id: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Accounts/ImportAsLead', JSON.stringify(id), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public IsValidAlias(alias: string, uid: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.get('Accounts/IsValidAlias?'+((alias === null || alias === undefined) ? '' : ('&alias='+encodeURIComponent(alias)))+'&uid='+uid, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetByUsername(username: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAccountDTO>
	{
		return (this.axios.get('Accounts/GetByUsername?'+((username === null || username === undefined) ? '' : ('&username='+encodeURIComponent(username))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetMappedExternalUser(externalUsername: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAccountDTO>
	{
		return (this.axios.get('Accounts/GetMappedExternalUser?'+((externalUsername === null || externalUsername === undefined) ? '' : ('&externalUsername='+encodeURIComponent(externalUsername))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MapExternalUser(externalUsername: string, internalUsername: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Accounts/MapExternalUser?'+((externalUsername === null || externalUsername === undefined) ? '' : ('&externalUsername='+encodeURIComponent(externalUsername)))+((internalUsername === null || internalUsername === undefined) ? '' : ('&internalUsername='+encodeURIComponent(internalUsername))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ResetExternalUsersMappings(internalUsername: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Accounts/ResetExternalUsersMappings?'+((internalUsername === null || internalUsername === undefined) ? '' : ('&internalUsername='+encodeURIComponent(internalUsername))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnmapExternalUser(externalUsername: string, internalUsername: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Accounts/UnmapExternalUser?'+((externalUsername === null || externalUsername === undefined) ? '' : ('&externalUsername='+encodeURIComponent(externalUsername)))+((internalUsername === null || internalUsername === undefined) ? '' : ('&internalUsername='+encodeURIComponent(internalUsername))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SendInvitation(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Accounts/SendInvitation?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ResetPassword(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Accounts/ResetPassword?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAccountDTO>
	{
		return (this.axios.get('Accounts/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
