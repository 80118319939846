//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BusinessObjectLoadedType {
	None = 0,
	Partial = 1,
	Normal = 2,
	Full = 3
}
