//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WorkTeamsProperty {
	None = 0,
	Active = 1,
	CrmActivities = 2,
	Campaign = 4
}
