//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IWorkPlanStatusDTO } from '../../../DTO/IWorkPlanStatusDTO';

//element.Name=WorkPlanStatusController result.Name=WorkPlanStatusController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.WorkPlanStatusController);
/** Result of WebApiControllerGenerator activity */
export class WorkPlanStatusController extends DefaultDtoControllerProxy<IWorkPlanStatusDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'WorkPlanStates', BusinessObjectType.WorkPlanStatus);
	}
	public Activities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('WorkPlanStates/Activities?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('WorkPlanStates/ActivitiesIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('WorkPlanStates/ActivitiesCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Assign(id: number, activityId: number, insertAfter?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('WorkPlanStates/Assign?'+'&id='+id+'&activityId='+activityId+((insertAfter === null || insertAfter === undefined) ? '' : ('&insertAfter='+insertAfter)), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IWorkPlanStatusDTO>
	{
		return (this.axios.get('WorkPlanStates/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
