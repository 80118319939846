export default (vue) => {
  vue.component('t-email-composer', () => import(/* webpackChunkName: "emailEditor" */ './EmailComposer.vue'));
  vue.component('t-email-composer-options', () => import(/* webpackChunkName: "emailEditor" */ './EmailComposerOptions.vue'));
  vue.component('t-email-composer-config', () => import(/* webpackChunkName: "emailEditor" */ './EmailComposerConfig.vue'));
  vue.component('t-template-selector', () => import(/* webpackChunkName: "emailEditor" */ './TemplateSelector.vue'));
  vue.component('t-component-button', () => import(/* webpackChunkName: "emailEditor" */ './misc/ComponentButton.vue'));

  vue.component('SectionComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Section.vue'));
  vue.component('TextComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Text.vue'));
  vue.component('ImageComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Image.vue'));
  vue.component('ImageGroupComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/ImageGroup.vue'));
  vue.component('DividerComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Divider.vue'));
  vue.component('SpacerComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Spacer.vue'));
  vue.component('ButtonComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Button.vue'));
  vue.component('HtmlComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Html.vue'));
  vue.component('SocialComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Social.vue'));
  vue.component('ImageCaptionComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/ImageCaption.vue'));
  vue.component('SignatureComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Signature.vue'));
  vue.component('FileComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/File.vue'));
  vue.component('VideoComponent', () => import(/* webpackChunkName: "emailEditor" */ './components/Video.vue'));

  vue.component('BodyConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Body.vue'));
  vue.component('HeaderConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Body.vue'));
  vue.component('TextConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Text.vue'));
  vue.component('SectionConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Section.vue'));
  vue.component('ImageConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Image.vue'));
  vue.component('ImageGroupConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/ImageGroup.vue'));
  vue.component('DividerConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Divider.vue'));
  vue.component('SpacerConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Spacer.vue'));
  vue.component('ButtonConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Button.vue'));
  vue.component('HtmlConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Html.vue'));
  vue.component('SocialConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Social.vue'));
  vue.component('SignatureConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Signature.vue'));
  vue.component('ImageCaptionConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/ImageCaption.vue'));
  vue.component('FileConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/File.vue'));
  vue.component('VideoConfig', () => import(/* webpackChunkName: "emailEditor" */ './configs/Video.vue'));

  vue.component('BodyElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Body.vue'));
  vue.component('HeaderElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Header.vue'));
  vue.component('FooterElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Footer.vue'));
  vue.component('ColumnElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Column.vue'));
  vue.component('SectionElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Section.vue'));
  vue.component('TextElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Text.vue'));
  vue.component('ImageElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Image.vue'));
  vue.component('DividerElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Divider.vue'));
  vue.component('SpacerElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Spacer.vue'));
  vue.component('ButtonElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Button.vue'));
  vue.component('HtmlElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Html.vue'));
  vue.component('SocialElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Social.vue'));
  vue.component('SignatureElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Signature.vue'));
  vue.component('ImageCaptionElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/ImageCaption.vue'));
  vue.component('ImageGroupElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/ImageGroup.vue'));
  vue.component('FileElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/File.vue'));
  vue.component('VideoElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Video.vue'));
  vue.component('FakeElement', () => import(/* webpackChunkName: "emailEditor" */ './elements/Fake.vue'));

  vue.component('t-element-toolbar', () => import(/* webpackChunkName: "emailEditor" */ './misc/ElementToolbar.vue'));
  vue.component('t-text-align-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/TextAlignInput.vue'));
  vue.component('t-margin-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/MarginInput.vue'));
  vue.component('t-utm-url-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/UtmUrlInput.vue'));
  vue.component('t-px-size-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/PxSizeInput.vue'));
  vue.component('t-font-size-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/FontSizeInput.vue'));
  vue.component('t-line-height-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/LineHeightInput.vue'));
  vue.component('t-border-style-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/BorderStyleInput.vue'));
  vue.component('t-border-config-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/BorderConfigInput.vue'));
  vue.component('t-section-structure-input', () => import(/* webpackChunkName: "emailEditor" */ './misc/SectionStructureInput.vue'));
};
