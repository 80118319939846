//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MachineType {
	Machine = 0,
	Part = 1,
	Product = 2,
	Section = 3,
	Plant = 4
}
