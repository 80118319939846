<template>
  <div class="header m-t-q">
    <el-row type="flex" justify="center" align="middle">
      <el-col class="text-left">
        <i class="el-icon-icon-menu3 text-1_2x" v-popover:popover-menu :style="{ color: '#FFF' }"
          :class="{ 'menu-open': menuOpen }"></i>
        <el-popover class="popover-menu" ref="popover-menu" placement="top-right" :width="300" v-model="menuOpen">
          <el-menu class="el-menu-vertical" :router="true" :default-active="currentRouteName" ref="menu"
            @open="index => closeAllExcept(index)">
            <el-submenu v-for="item in menuMainItems" :key="item.id" :index="item.id.toString()">
              <template #title>
                <i v-bind:class="'el-icon-' + item.icon"></i>
                <span>{{ item.label }}</span>
              </template>
              <el-menu-item v-for="subItem in item.subItems" :key="subItem.id" :index="subItem.id.toString()"
                :route="{ [subItem.route && subItem.route.indexOf('/') === 0 ? 'path' : 'name']: subItem.route }"
                :class="{ compact: $viewport.mdAndDown }" @click.native="menuOpen = false">
                <i v-bind:class="'el-icon-' + subItem.icon"></i>
                <span>{{ subItem.label }}</span>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-popover>
      </el-col>
      <el-col class="text-center">
        <img :src="`/${brand.id}/img/logo-white.svg`" height="30px" key="collapsed" class="full-logo" trigger="click" />
      </el-col>
      <el-col class="text-right">
        <transition name="el-zoom-in-center">
          <div v-if="user != null">
            <el-popover ref="popover-userprofile" placement="left" :width="300" v-model="showUserProfilePopOver">
              <user-profile @onboarding="$emit('onboarding')" @close="showUserProfilePopOver = false"></user-profile>
            </el-popover>
            <span class="user-image" v-popover:popover-userprofile trigger="click">
              <t-tooltip-label :content="$t('bside.profile')" placement="left-start">
                <t-profile-image :userId="user.userId" :size="30" class="small"></t-profile-image>
              </t-tooltip-label>
            </span>
          </div>
        </transition>
      </el-col>
    </el-row>
  </div>
</template>
<script lang="ts">
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, State } from 'vuex-class';
import UserProfile from './UserProfileSummary.vue';

@Component({
  name: 'Login',
  components: {
    UserProfile,
  },
})
export default class Sidebar extends Vue {
  @Getter('user') user: IUser;

  @Getter('menu') menu;

  @Getter brand;

  showUserProfilePopOver = false;

  menuOpen = false;

  get currentRouteName(): string {
    return this.$route.name;
  }

  get menuMainItems() {
    return this.menu.filter((m) => (m.subItems || []).length);
  }

  closeAllExcept(index: string) {
    const sm = this.$refs['menu'] as any;
    for (const subMenu of this.menuMainItems) {
      if (subMenu.id.toString() !== index) {
        sm.close(subMenu.id.toString());
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.header {
  background: $--color-primary;
  overflow: initial;

  .full-logo {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    display: inline-block;
  }

  .menu-open {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }

  .popover-menu {
    ::v-deep { .el-popover {
      padding: 0px;
    }
  }
  }

  .el-menu-vertical {
    border-right: none;

    .el-menu-item {
      padding-left: 30px !important;
    }

    &.el-menu {
      &.el-menu--popup {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }

      & .el-menu-item.compact {
        height: 32px;
      }

      & .el-submenu {
        ::v-deep { .el-submenu__title {
          height: 40px;
          line-height: 40px;
        }
      }
      }

      & .el-submenu {
        ::v-deep { .el-menu-item {
          height: 40px;
          line-height: 40px;
        }
      }
      }
    }
  }

  .el-menu--collapse {
    .el-menu {
      top: 2px;
      margin-left: 2px;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
</style>
