//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TaxInstall {
	First = 0,
	Last = 1,
	VatOnly = 2,
	InsideInstallment = 3
}
