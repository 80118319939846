<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import eventHubManager from 'modules/mixin/views/EventHub';
import ContactLayoutStudio from './ContactLayoutStudio.vue';

// tslint:disable-next-line:variable-name
const ContactAction = namespace('contact').Action;

@Component({
  name: 'ContactFastLayoutStudio',
  props: {
    defaults: {
      type: Object,
      required: false,
    },
  },
})
export default class ContactFastLayoutStudio extends ContactLayoutStudio {
  @ContactAction('schema') loadSchema: (suffix: string) => Promise<any>;

  @ContactAction('loadNew') loadNew: (id: number) => Promise<any>;

  @ContactAction('reset') reset: () => Promise<any>;

  defaults: any;

  async mounted() {
    await this.reset();
    await this.loadSchema(null);
    await this.loadNew(this.defaults);
  }

  get eventHub(): Vue {
    return eventHubManager.getHub('contact.fast');
  }
}
</script>
