<template>
  <span>
    {{ formattedValue }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import {
  IRepeaterColumn,
  IRowDataInfoValue,
  IRowDataInfo,
} from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import moment from 'moment';

@Component({
  name: 'DateTimeCell',
})
export default class DateTimeCell extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  row: IRowDataInfoValue;

  get formattedValue(): string {
    const format = this.row.dateFormat || this.column.dateFormat;
    if (this.column.fromMinuteDurationFormat) {
      const v = moment.duration(this.row.value, 'minutes');
      return v.humanize();
    }
    const m = this.$moment(this.row.value);
    if (!m.isValid()) return '';

    if (this.column.fromCalendarFormat) {
      return m.calendar(format);
    }
    if (this.column.fromDurationFormat) {
      return moment.duration(this.$moment().diff(m)).humanize();
    }
    return m.format(format || 'L LT');
  }
}
</script>
