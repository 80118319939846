//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IListResult } from '../../Controls/IListResult';
import { IFlowTriggerDTO } from '../../../DTO/IFlowTriggerDTO';
import { IFlowDTO } from '../../../DTO/IFlowDTO';

//element.Name=FlowTriggerController result.Name=FlowTriggerController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.FlowTriggerController);
/** Result of WebApiControllerGenerator activity */
export class FlowTriggerController extends DefaultDtoControllerProxy<IFlowTriggerDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'FlowsTriggers', BusinessObjectType.FlowTrigger);
	}
	public Triggers(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.get('FlowsTriggers/Triggers', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public TriggerTypes(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('FlowsTriggers/TriggerTypes', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetPotentialConcurrentFlows(dto: IFlowTriggerDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('FlowsTriggers/GetPotentialConcurrentFlows', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Flows(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowDTO[]>
	{
		return (this.axios.get('FlowsTriggers/Flows?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('FlowsTriggers/FlowsIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowTriggerDTO>
	{
		return (this.axios.get('FlowsTriggers/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
