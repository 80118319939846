<template>
  <el-row>
    <el-col>
      <el-row class="m-b">
        <el-col>
          <el-tree ref="tree" node-key="key" :check-strictly="false" :props="treeProps" :load="loadNode"
            :default-checked-keys="selectedData.map(s => s.key)" lazy :show-checkbox="multipleSelection"
            @node-click="handleNodeClick" @node-expand="handleNodeExpand" @node-collapse="handleNodeCollapse"
            @check-change="handleCheckChange"></el-tree>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseTree from './BaseTree';
import { ZoneTreeViewController } from 'rt/UIApiControllers/TreeView/ZoneTreeViewController';

@Component({
  name: 'ZoneTree',
})
export default class ZoneTree extends BaseTree {
  zoneTreeViewController = new ZoneTreeViewController(Vue.axios);

  getTreeViewController(): any {
    return this.zoneTreeViewController;
  }

  isLeaf(item): boolean {
    return item.children.length === 0;
  }
}
</script>
