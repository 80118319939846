//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ImportQueueStatus {
	Waiting = 0,
	Importing = 1,
	Imported = 2,
	Updated = 3,
	Skipped = 4,
	Failed = 5,
	Aborted = 6
}
