//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IAccountTreeViewRequest } from './IAccountTreeViewRequest';
import { ITreeViewResponse } from './ITreeViewResponse';
import { ITreeViewNodeResponse } from './ITreeViewNodeResponse';

//element.Name=AccountTreeViewController result.Name=AccountTreeViewController element.FullName=Digita.Tustena.UIApiControllers.TreeView.AccountTreeViewController);
/** Result of WebApiControllerGenerator activity */
export class AccountTreeViewController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in AccountTreeViewController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public RetriveAccountsFromEntity(data: string, key: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('AccountTreeView/RetriveAccountsFromEntity?'+((key === null || key === undefined) ? '' : ('&key='+encodeURIComponent(key))), JSON.stringify(data), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public Initialize(requestData: IAccountTreeViewRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewResponse>
	{
		return (this.axios.post('AccountTreeView/Initialize', requestData, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LazyTreeNodes(requestData: IAccountTreeViewRequest, key: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewNodeResponse[]>
	{
		return (this.axios.post('AccountTreeView/LazyTreeNodes?'+((key === null || key === undefined) ? '' : ('&key='+encodeURIComponent(key))), requestData, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public InitializeTree(requestData: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewResponse>
	{
		return (this.axios.post('AccountTreeView/InitializeTree', JSON.stringify(requestData), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public LazyTree(requestData: string, key: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ITreeViewNodeResponse[]>
	{
		return (this.axios.post('AccountTreeView/LazyTree?'+((key === null || key === undefined) ? '' : ('&key='+encodeURIComponent(key))), JSON.stringify(requestData), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('AccountTreeView/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('AccountTreeView/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
