//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InvoiceType {
	None = 0,
	Invoice = 1,
	CreditNote = 2,
	InvoiceProfessional = 3,
	InvoiceForPrivate = 4,
	DDTInvoice = 5,
	Proforma = 6,
	ProformaProfessional = 7
}
