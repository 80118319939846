//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TypeOfPrice {
	Fixed = 0,
	MonthlyFee = 1,
	QuarterlyFee = 2,
	HalfYearlyFee = 3,
	YearlyFee = 4,
	TwoMonthly = 5,
	TwoYearlyFee = 6,
	ThreeYearly = 7
}
