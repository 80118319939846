//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CatiAnswerType {
	Radio = 0,
	CheckBox = 1,
	CheckBoxMono = 2,
	Text = 3,
	List = 4,
	Numeric = 5,
	NumericDecimal = 6
}
