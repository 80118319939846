<template>
  <div>
    <div v-for="(v, index) in filteredFields" :key="v.name">
      <div v-if="v.value != null" class="m-l-h">
        <el-collapse-item :key="v.name" :name="`${parent}.${v.name}`" :disabled="results[index] === 0">
          <template #title>
            <strong :class="{ 'text-primary': results[index] > 0 }">
              {{ v.resourceName }}
              <transition name="el-zoom-in-center">
                <span v-if="results[index] > 0"> ({{ results[index] }}) </span>
              </transition>
            </strong>
          </template>
          <liquid-master-field :drag="drag" :value="v" :parent="`${parent}.${v.name}`" :search="search"
            @fields-result="v => handleFieldResult(index, v)" @select="v => $emit('select', v)"> </liquid-master-field>
        </el-collapse-item>
      </div>
      <div v-else>
        <draggable v-if="drag" :value="[v]" :sort="false" :animation="100" :delay="$viewport.isTouch ? 400 : 0"
          :group="{ name: 'merge', pull: 'clone', put: false }" :clone="handleMergeClone">
          <t-liquid-field :value="v" :search="search"></t-liquid-field>
        </draggable>
        <t-liquid-field v-else :value="v" :search="search" @click="$emit('select', v)"
          class="cursor-pointer"></t-liquid-field>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ILiquidStruct } from 'rt/Base/Utility/ILiquidStruct';
import TLiquidField from './LiquidField.vue';
import _ from 'lodash';

@Component({
  name: 'LiquidMasterField',
  components: {
    TLiquidField,
  },
})
export default class LiquidMasterField extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: ILiquidStruct;

  @Prop({
    type: String,
    required: true,
  })
  parent: string;

  @Prop({
    type: String,
    required: false,
  })
  search: string;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  drag: boolean;

  get filteredFields() {
    if (this.search && this.search.length > 0 && this.value.value) {
      const fields = this.value.value;
      const pattern = new RegExp(this.search, 'i');
      const filteredFields = fields.filter((f) => {
        if (f.value === null) {
          return pattern.test(f.resourceName);
        }
        return true;
      });
      this.$emit('fields-result', filteredFields.filter((f) => f.value === null).length);
      return filteredFields;
    }
    this.$emit('fields-result', null);
    return this.value.value;
  }

  handleMergeClone(field) {
    if (this.parent) {
      this.$emit('drag', `{{ ${_.camelCase(this.parent)}.${field.name} }}`);
    } else {
      this.$emit('drag', `{{ ${_.camelCase(field.name)} }}`);
    }
  }

  results: {
    [key: number]: number;
  } = {};

  handleFieldResult(index: number, v: number) {
    Vue.set(this.results, index, v);
  }
}
</script>
