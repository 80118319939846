//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { ICatalogProductPriceDTO } from '../../../DTO/ICatalogProductPriceDTO';

//element.Name=CatalogProductPriceController result.Name=CatalogProductPriceController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.CatalogProductPriceController);
/** Result of WebApiControllerGenerator activity */
export class CatalogProductPriceController extends DefaultDtoControllerProxy<ICatalogProductPriceDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'CatalogProductPrices', BusinessObjectType.PriceList);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICatalogProductPriceDTO>
	{
		return (this.axios.get('CatalogProductPrices/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
