//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DebtLogType {
	ChangePhase = 0,
	Action = 1,
	CloseFromMovements = 2,
	ChangePhaseReservation = 3
}
