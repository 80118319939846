//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IAuthenticationResponse } from '../../WidgetElements/IAuthenticationResponse';
import { IExchangeAccountConfigurationDTO } from '../../../DTO/IExchangeAccountConfigurationDTO';

//element.Name=ExchangeAccountConfigurationController result.Name=ExchangeAccountConfigurationController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.ExchangeAccountConfigurationController);
/** Result of WebApiControllerGenerator activity */
export class ExchangeAccountConfigurationController extends DefaultDtoControllerProxy<IExchangeAccountConfigurationDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'ExchangeAccounts', BusinessObjectType.ExchangeAccountConfiguration);
	}
	public ValidateExchangeSettings(id: number, data: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAuthenticationResponse>
	{
		return (this.axios.post('ExchangeAccounts/ValidateExchangeSettings?'+'&id='+id, data, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IExchangeAccountConfigurationDTO>
	{
		return (this.axios.get('ExchangeAccounts/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
