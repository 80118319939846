import Vue from 'vue';
import { RouteConfig } from 'vue-router';
import { MutationTree } from 'vuex';
import { RootState } from './state';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import signalREventManager from '../signalr';
import jwtManager from 'utils/jwtManager';
import routeHelper from 'utils/routeHelper';
import i18nUtils from 'utils/i18nUtils';
import { IMapViewService } from 'rt/Interfaces/Maps/IMapViewService';
import { IMenuNode } from 'rt/Interfaces/Misc/IMenuNode';
import store from '.';
import _ from 'lodash';
import { IUserLimit } from 'rt/Interfaces/Security/IUserLimit';
import moduleManager from '@/utils/moduleManager';
import { resetRouter } from '../router/index';
import { Message } from 'element-ui';
import { INotification } from 'rt/Base/Handlers/Comet/INotification';
import { IProfile } from '@/plugins/typings/UIApiControllers/UI/MailingList/IProfile';
import trackings from 'utils/trackings';
import { IGeo } from '@/plugins/typings/Calendar/IGeo';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';

interface ISetTokenPayload {
  token: string;
  refreshToken: string;
}
const mutations: MutationTree<RootState> = {
  setToken(state, payload: ISetTokenPayload) {
    jwtManager.token = payload.token;
    jwtManager.refreshToken = payload.refreshToken;
  },
  setAuthentication(
    state: RootState,
    {
      user,
      permits,
    }: {
      user: IUser;
      permits: boolean[];
    },
  ) {
    state.currentUser = {
      ...user,
      isAuthorizedToCreate(type) {
        return this.capabilities[type]?.create ?? false;
      },
      isAuthorizedToRead(type) {
        return this.capabilities[type]?.read ?? false;
      },
      isAuthorizedToUpdate(type) {
        return this.capabilities[type]?.update ?? false;
      },
      isAuthorizedToDelete(type) {
        return this.capabilities[type]?.delete ?? false;
      },
    };
    if (permits) {
      state.permits = permits;
    }
    trackings.trackUser(state.currentUser);
    const { appInsights } = window as any;
    if (appInsights) {
      appInsights.trackPageView({ name: '', properties: { 'Screen Resolution': `${window.screen.width}x${window.screen.height}` } });
    }
    store.commit('setUserLanguage');
    localStorage.setItem(`me_${user.userId}_${user.customerId}`, JSON.stringify(user));
    // i18nUtils.loadLanguage(payload.user.cultureTwoISOSpecific);
  },
  setLanguageDebug(state: RootState, language: boolean) {
    state.languageDebug = language;
    if (state.languageDebug) {
      i18nUtils.setLanguage('no-such-locale');
      Vue.i18n.fallbackLocale = 'no-such-locale';
      Vue.prototype.$moment.locale('no-such-locale');
    } else {
      Vue.i18n.fallbackLocale = 'en';
    }
  },
  setUserLanguage(state: RootState, language: string) {
    if (!state.languageDebug) {
      const lang = language || store.getters.language;
      i18nUtils.setLanguage(lang);
      Vue.prototype.$moment.locale(lang);
    }
  },
  clearAuthentication(state) {
    jwtManager.clearTokens();
    state.currentUser = null;
    state.locked = false;
  },
  setMenu(state, payload: { menu: IMenuNode[] }) {
    state.menu = payload.menu;
  },
  setMapService(state, service: IMapViewService) {
    state.mapService = service;
  },
  setCookiePreferences(state, cookiePreferences: { [key: string]: boolean }) {
    state.cookiePreferences = cookiePreferences;
    localStorage.setItem('cookie_preferences', JSON.stringify(cookiePreferences));
  },
  setTour(state, payload: { routeName: string; tours: string[] }) {
    state.tours[payload.routeName] = payload.tours;
  },
  setNotification(state: RootState, notification: boolean) {
    state.notification = notification;
  },
  addRoute(
    state,
    payload: {
      routes: RouteConfig[];
    },
  ) {
    payload.routes.forEach((route) => {
      if (route.meta && route.meta.parent) {
        const parentRoute = routeHelper.findRoute(state.routes, route.meta.parent);
        if (parentRoute == null) {
          throw `Cannot find parent route ${JSON.stringify(route.meta.parent)} in route ${JSON.stringify(route)}`;
        }
        const oldChildren = parentRoute.children || [];
        parentRoute.children = [...oldChildren, route];
      } else {
        state.routes = [...state.routes, route];
      }
    });
  },
  setBaseUrl(state, baseUrl: string) {
    state.baseUrl = baseUrl;
  },
  initialized(state, payload: boolean) {
    state.initialized = payload;
  },
  setSplashMessageInterval(state, payload: number) {
    state.splashMessageInterval = payload;
  },
  increaseSplashMessage(state) {
    state.splashMessageIndex += 1;
  },
  refreshTicks(state) {
    state.ticks = new Date().getTime();
  },
  addPasswordExpirationsNotified(state, id) {
    state.passwordExpirationsNotified.push(id);
  },
  setNotifications(state, payload: { add: INotification[]; remove: string[] }) {
    let notifications = [...state.notifications];
    if (payload.remove) {
      notifications = _.remove(notifications, (n) => payload.remove.includes(n.key));
    }
    if (payload.add) {
      const removes = payload.add.map((n) => n.key);
      notifications = _.remove(notifications, (n) => removes.includes(n.Key));
      notifications = [...notifications, ...payload.add];
    }
    state.notifications = notifications;
  },
  setOfficeLocation(state, location: IGeo) {
    state.officeLocation = location;
  },
  setHomeLocation(state, location: IGeo) {
    state.homeLocation = location;
  },
  resetNotifications(state) {
    state.notifications = [];
  },
  setOAuthRefreshWindowReference(state, window: Window) {
    state.oauthRefreshWindowReference = () => window;
  },
  setLimits(state, limits: IUserLimit) {
    state.currentUser = {
      ...state.currentUser,
      limits,
    };
  },
  setSession(state: RootState, session: string) {
    state.session = session;
    localStorage.setItem('session_token', session);
  },
  setLocked(state: RootState, locked: boolean) {
    state.locked = locked;
  },
  setMailingProfile(state: RootState, profile: IProfile) {
    state.mailingProfile = profile;
  },
  setConfig(state: RootState, config: IConfig) {
    state.config = config;
    state.brand = {
      id: config?.id ?? state.brand.id,
      name: config?.name ?? state.brand.name,
      site: config?.site ?? state.brand.site,
      help: config?.help ?? state.brand.help,
      backgrounds: config?.backgrounds ?? state.brand.backgrounds,
    };
    if (config.maps) {
      state.mapService = config.maps;
    }
  },
  setBrand(state: RootState, brand: IBrand) {
    state.brand = {
      id: brand?.id ?? state.brand.id,
      name: brand?.name ?? state.brand.name,
      site: brand?.site ?? state.brand.site,
      help: brand?.help ?? state.brand.help,
      backgrounds: brand?.backgrounds ?? state.brand.backgrounds,
    };
  },
  reset(state) {
    moduleManager.unloadAllModules();
    resetRouter();
    signalREventManager.stop();
    const user = state.currentUser;
    if (user) {
      localStorage.removeItem(`me_${user.userId}_${user.customerId}`);
    }
    state.currentUser = null;
    state.permits = null;
    state.tours = {};
    state.mapService = null;
    state.menu = null;
    state.officeLocation = null;
    state.homeLocation = null;
    state.initialized = false;
    state.ticks = new Date().getTime();
    state.passwordExpirationsNotified = [];
    state.notifications = [];
    trackings.resetUser();
  },
  togglePerformance(state: RootState) {
    if (state.performance) {
      state.performance = false;
      Message.warning('Server Timing Benchmark Off');
    } else {
      state.performance = true;
      Message.success('Server Timing Benchmark On');
    }
  },
  setMarkdownCache(state: RootState, payload: { key: string; value: string }) {
    this.state.markdownCache[payload.key] = payload.value;
  },
  resetMarkdownCache(state: RootState) {
    this.state.markdownCache = {};
  },
};

export default mutations;
