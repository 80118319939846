//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SharingTemplateAccountMode {
	Account = 0,
	CurrentAccount = 1,
	CurrentManagerAccount = 2,
	CurrentSubAccounts = 3,
	OwnerAccount = 4,
	OwnerManagerAccount = 5,
	OwnerSubAccounts = 6,
	SalesPersonAccounts = 7,
	SalesPersonManagerAccounts = 8,
	SalesPersonSubAccounts = 9
}
