<template>
  <span v-if="schema.items != null">
    <template v-for="(sc, index) in schema.items">
      <t-widget v-bind:key="index" :schema="sc" :data="data" @input="simpleEmit"></t-widget>
    </template>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';
import { IWidget } from '../../typings/Contracts/UI/IWidget';

@Component({
  name: 'AggregateInput',
  mixins: [simpleMixin],
})
export default class AggregateInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: {
    items: IWidget[];
  };

  @Prop({
    type: [Object, Array],
    required: true,
  })
  data: any;
}
</script>
