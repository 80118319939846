//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TypeOfService {
	InstalmentPostponed = 1,
	Consumption = 2,
	InElement = 3,
	Consumptive = 4,
	FixedElement = 5,
	InstalmentInAdvanced = 6,
	InstalmentManual = 7
}
