//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivityMoveLog {
	MoveOwner = 0,
	MoveDate = 1,
	MoveCompany = 2,
	MoveContact = 3,
	MoveLead = 4,
	MoveDone = 5,
	MailSent = 6,
	ChangeStatus = 7
}
