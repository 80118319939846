//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Gravity {
	Info = 0,
	Warn = 1,
	Error = 2,
	PeristentMsg = 3,
	Gamification = 4
}
