//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Changes {
	None = 0,
	Added = 1,
	Deleted = 2,
	Updated = 3,
	Replaced = 4
}
