<template>
  <span v-loading="filteredAccounts == null">
    <div v-if="filteredAccounts">
      <div v-for="ac in filteredAccounts" :key="ac.key" class="m-b-h">
        <div v-if="multipleSelection">
          <el-checkbox :value="selectedIds.includes(ac.value)" @input="v => handleSelect(v, ac)">
            <t-profile-image class="user-image-20 m-r-q" :size="20" :userId="ac.value"></t-profile-image>
            <span>{{ ac.text }}</span>
          </el-checkbox>
        </div>
        <div
          v-else
          @click="() => handleSelect(true, ac)"
          class="cursor-pointer"
          :class="{ selected: selectedIds.includes(ac.value) }"
        >
          <t-profile-image class="user-image-20 m-r-q" :size="20" :userId="ac.value"></t-profile-image>
          <span>{{ ac.text }}</span>
        </div>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import _ from 'lodash';
// tslint:disable-next-line:variable-name
const ListsAction = namespace('lists').Action;
// tslint:disable-next-line:variable-name
const ListsGetter = namespace('lists').Getter;

@Component({
  name: 'AccountPop',
})
export default class AccountPop extends Vue {
  @ListsAction
  load: (type: ListsType) => Promise<any>;

  @ListsGetter
  list: (type: ListsType) => Array<IInt64ListResult>;

  @Getter user: IUser;

  accounts: IInt64ListResult[] = null;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlyWithCalendarRights: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlySubAccounts: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  multipleSelection: boolean;

  @Prop({
    type: Array,
    default: () => [],
  })
  value: any[];

  async mounted() {
    await this.load(this.$enums.ListsType.ListOwner);
    this.accounts = this.list(this.$enums.ListsType.ListOwner);
  }

  get filteredAccounts() {
    const ac = this.accounts?.filter((a) => {
      if (a.value === this.user.userId) {
        return true;
      }
      if (this.onlySubAccounts) {
        if (!this.user.bossOf.includes(a.value)) {
          return false;
        }
      }
      if (this.onlyWithCalendarRights) {
        if (!this.user.accountCalendarDelegates.includes(a.value)) {
          return false;
        }
      }
      return true;
    });
    if (ac == null) {
      return ac;
    }
    return _.sortBy(ac, [
      (a) => {
        const { value } = a;
        if (this.user.userId === value) {
          return 1;
        }
        if (this.user.bossOf.includes(value)) {
          return 2;
        }
        if (this.user.myBosses.includes(value)) {
          return 3;
        }
        return 4;
      },
      'text',
    ]);
  }

  get selectedIds() {
    return this.value.map((v) => +v.key.substring(8));
  }

  handleSelect(selected: boolean, ac: IInt64ListResult) {
    let selectedData = [...this.value];
    if (this.multipleSelection) {
      const index = this.selectedIds.indexOf(ac.value);
      if (selected) {
        if (index === -1) {
          selectedData.push({
            key: `Account-${ac.value}`,
            title: ac.text,
          });
        }
      } else if (index > -1) {
          selectedData.splice(index, 1);
        }
    } else if (selected) {
        selectedData = [
          {
            key: `Account-${ac.value}`,
            title: ac.text,
          },
        ];
      } else {
        selectedData = [];
      }
    this.$emit('input', selectedData);
  }
}
</script>
<style lang="scss" scoped>
@import 'element-variables';
.selected {
  color: $--color-primary;
}
</style>
