//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TeamMemberProperty {
	User = 0,
	TeamLeader = 1,
	SuperVisor = 2,
	Suspended = 3
}
