import Vue from 'vue';
import { ActionTree, ActionContext } from 'vuex';
import Router, { RouteConfig } from 'vue-router';
import router, { resetRouter } from '../router/index';
import axios, { AxiosHeaders } from 'axios';
import { RootState } from './state';
import { MessageBox } from 'element-ui';
import { MessageBoxData } from 'element-ui/types/message-box.d';
import jwtManager from 'utils/jwtManager';
import moduleManager from 'utils/moduleManager';
import signalREventManager from '../signalr';
import { MapsController } from 'rt/UIApiControllers/UI/Maps/MapsController';
import { TourController } from 'rt/UIApiControllers/UI/Tour/TourController';
import { IAxiosRequestConfigRetry } from 'utils/types';
import { ICometExpire } from 'rt/Base/Handlers/Comet/ICometExpire';
import { IMenuNode } from 'rt/Interfaces/Misc/IMenuNode';
import passwordExpiration from 'utils/passwordExpiration';
import { AuthController } from 'rt/UIApiControllers/Authentication/AuthController';
import oauthUtils from 'utils/oauthUtils';
import crosstab from 'crosstab';
import routeNames from '@/router/routeNames';
import { v4 as uuidv4 } from 'uuid';
import store from '.';
import _ from 'lodash';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { ICometAlarmMessage } from 'rt/Base/Handlers/Comet/ICometAlarmMessage';
import { ICometClientMessage } from 'rt/Base/Handlers/Comet/ICometClientMessage';
import { ICometPasswordExpireMessage } from 'rt/Base/Handlers/Comet/ICometPasswordExpireMessage';
import { ICometOAuthTokenExpiredMessage } from 'rt/Base/Handlers/Comet/ICometOAuthTokenExpiredMessage';
import { ICometOAuthAuthenticated } from 'rt/Base/Handlers/Comet/ICometOAuthAuthenticated';
import { ICometSessionMessage } from 'rt/SignalR/Messages/ICometSessionMessage';
import { ElNotificationOptions } from 'element-ui/types/notification';
import infoBox from '../utils/infoBox';
import { CompanyController, ContactController, MailingDomainController } from '@/plugins/typings/UIApiControllers/BusinessObject/DTO';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

crosstab.on('unlock', () => {
  store.dispatch('unlock');
});
crosstab.on('logout', () => {
  store.dispatch('logout');
  store.dispatch('renewSession');
});
interface ILoginJwtPayload extends JwtPayload {
  UserId: number;
  CustomerId: number;
}
interface ILoginCredentials {
  username: string;
  password: string;
}
let expireDebounced: any;
const actions: ActionTree<RootState, any> = {
  async signalRConnection(store: ActionContext<RootState, any>) {
    await signalREventManager.start(store.state.baseUrl);
    if (!jwtManager.isImpersonationToken) {
      await new AuthController(Vue.axios).Session(store.state.session);
    }
  },
  async login(store: ActionContext<RootState, any>, payload: ILoginCredentials) {
    const response = await axios.post(
      'Auth/Login',
      {
        ...payload,
        grant_type: 'password',
      },
      <IAxiosRequestConfigRetry>{
        _noAuth: true,
        _retry: false,
        headers: new AxiosHeaders({
          'Crm-ErrorResponseDetailLevel': 'Full',
        }),
      },
    );
    if (response.status === 200) {
      if (response.data.result === 0 && response.data.access_token) {
        store.commit('setToken', {
          token: response.data.access_token,
          refreshToken: response.data.refresh_token,
        });
        // await store.dispatch('loadModules');
      }
    }
  },
  async loginIsp(
    store: ActionContext<RootState, any>,
    payload: {
      code: string;
      customerId: number;
    },
  ) {
    const response = await axios.post(
      'Auth/Isp',
      {
        code: payload.code,
        grant_type: 'authorization_code',
        customer_id: payload.customerId,
      },
      <IAxiosRequestConfigRetry>{
        _noAuth: true,
        _retry: false,
      },
    );
    if (response.status === 200) {
      if (response.data.result === 0 && response.data.access_token) {
        store.commit('setToken', {
          token: response.data.access_token,
          refreshToken: response.data.refresh_token,
        });
        // await store.dispatch('loadModules');
      }
    }
  },
  async loginIspV3(
    store: ActionContext<RootState, any>,
    {
      code,
      customerId,
    }: {
      code: string;
      customerId: number;
    },
  ) {
    const response = await axios.post(
      'Auth/IspOAuth',
      {
        code,
        grant_type: 'authorization_code',
        customer_id: customerId,
      },
      <IAxiosRequestConfigRetry>{
        _noAuth: true,
        _retry: false,
      },
    );
    if (response.status === 200) {
      if (response.data.result === 0 && response.data.access_token) {
        store.commit('setToken', {
          token: response.data.access_token,
          refreshToken: response.data.refresh_token,
        });
        // await store.dispatch('loadModules');
      }
    }
  },
  async logout(store: ActionContext<RootState, any>) {
    if (store.state.currentUser != null) {
      try {
        await Vue.axios.post('Auth/Logout');
      } catch { }
      store.commit('clearAuthentication');
      router.push({
        name: routeNames.login,
      });
      store.commit('reset');
      crosstab.broadcast('logout');
    }
  },
  renewSession(store: ActionContext<RootState, any>) {
    const session = uuidv4();
    store.commit('setSession', session);
  },
  async loadUser(store: ActionContext<RootState, any>) {
    const authController = new AuthController(Vue.axios);
    const isFirstLoad = store.state.currentUser === null;
    const mePromise = new Promise<IUser>((resolve, reject) => {
      authController
        .Me(null, {
          timeout: 10000,
        })
        .then((user) => {
          resolve(user);
          store.commit('setAuthentication', {
            user,
          });
        });
      const { token } = jwtManager;
      if (token) {
        const decodedToken = jwtDecode<ILoginJwtPayload>(token);
        const userId = +decodedToken.UserId;
        const customerId = +decodedToken.CustomerId;
        if (userId > 0 && customerId > 0) {
          const json = localStorage.getItem(`me_${userId}_${customerId}`);
          if (json) {
            resolve(JSON.parse(json));
          }
        }
      }
    });
    const results = await Promise.all([
      mePromise,
      authController.Permits(null, {
        timeout: 10000,
      }),
    ]);
    const user = results[0];
    const permits = results[1];
    store.commit('setAuthentication', {
      user,
      permits,
    });
    store.dispatch('signalRConnection');
  },
  async registerNotification(store: ActionContext<RootState, any>) {
    if (jwtManager.refreshToken && 'serviceWorker' in navigator) {
      if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        // eslint-disable-next-line @typescript-eslint/quotes
        console.warn("Notifications aren't supported.");
        return;
      }
      // Check the current Notification permission.
      // If its denied, it's a permanent block until the
      // user changes the permission
      if (Notification.permission === 'denied') {
        console.warn('The user has blocked notifications.');
        return;
      }
      // Check if push messaging is supported
      if (!('PushManager' in window)) {
        // eslint-disable-next-line @typescript-eslint/quotes
        console.warn("Push messaging isn't supported.");
      }
      /*
      navigator.serviceWorker.ready.then(async serviceWorkerRegistration => {
        try {
          const subscription = await serviceWorkerRegistration.pushManager.subscribe({ userVisibleOnly: true });
          if (subscription) {
            Vue.axios.post('Auth/Register', {
              subscription,
              refreshToken: jwtManager.refreshToken,
            });
          }
        }
        catch (e) {
          if (Notification.permission === 'denied') {
            // The user denied the notification permission which
            // means we failed to subscribe and the user will need
            // to manually change the notification permission to
            // subscribe to push messages
            console.warn('Permission for Notifications was denied');
          } else {
            // A problem occurred with the subscription; common reasons
            // include network errors, and lacking gcm_sender_id and/or
            // gcm_user_visible_only in the manifest.
            console.error('Unable to subscribe to push.', e);
          }
        }
      }); */
    }
  },
  async loadMenu(store: ActionContext<RootState, any>) {
    if (store.getters.isAuthenticated) {
      const response = await axios.get('Auth/Menu');
      const menu = response.data as IMenuNode[];
      store.commit('setMenu', { menu });
    }
  },
  async throttleLanguageDebug(state: ActionContext<RootState, any>) {
    state.commit('setLanguageDebug', !state.state.languageDebug);
    store.dispatch('expire', { forceReload: true });
  },
  async init(store: ActionContext<RootState, any>, router: Router) {
    const base = await import(/* webpackChunkName: "base" */ '../modules/base');
    base.init();
    await store.dispatch('base/init');
    if (router) {
      router.addRoutes(store.state.routes);
    }
    store.dispatch('notification');
    // store.dispatch('loadModules', router);
  },
  async notification(store: ActionContext<RootState, any>) {
    if (!Object.prototype.hasOwnProperty.call(window, 'Notification')) {
      return;
    }
    const notification = (window as any).Notification;
    if (notification.permission === 'granted') {
      store.commit('setNotification', true);
      return;
    }
    // Otherwise, we need to ask the user for permission
    if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          store.commit('setNotification', true);
        }
      });
      // Finally, if the user has denied notifications and you
      // want to be respectful there is no need to bother them any more.
    }
  },
  initialized(store: ActionContext<RootState, any>, payload: boolean) {
    store.commit('initialized', payload);
    if (payload) {
      window.clearInterval(store.state.splashMessageInterval);
    } else {
      const interval = window.setInterval(() => {
        store.commit('increaseSplashMessage');
      }, 1500);
      store.commit('setSplashMessageInterval', interval);
    }
  },
  async loadModules(store: ActionContext<RootState, any>, router: Router) {
    store.dispatch('initialized', false);
    if (await jwtManager.isValidToken()) {
      await store.dispatch('loadUser');
      await store.dispatch('loadMenu');
      await moduleManager.loadRequiredModules(store);
      await moduleManager.loadModules(store, store.getters.modules);
      store.dispatch('loadMapService');
      if (router) {
        router.addRoutes(store.state.routes);
      }
      // store.commit('initialized', true);
    } else await moduleManager.unloadAllModules();
    store.dispatch('initialized', true);
  },
  async loadModule(store: ActionContext<RootState, any>, module: Promise<{ init: () => void }>) {
    const m = await module;
    m.init();
  },
  async addRoute(store: ActionContext<RootState, any>, payload: { routes: RouteConfig[] }) {
    store.commit('addRoute', payload);
  },
  async loadMapService(store: ActionContext<RootState, any>) {
    if (!store.state.mapService) store.commit('setMapService', await new MapsController(Vue.axios).GetMapViewService());
  },
  async loadTour(store: ActionContext<RootState, any>, routeName: string) {
    if (!Object.prototype.hasOwnProperty.call(store.state.tours, routeName)) {
      return store.commit('setTour', {
        routeName,
        tours: await new TourController(Vue.axios).Get(routeName),
      });
    }
  },
  async addTourStep(store: ActionContext<RootState, any>, payload: { routeName: string; target: string }) {
    new TourController(Vue.axios).Put(payload.routeName, payload.target);
    store.commit('setTour', {
      routeName: payload.routeName,
      tours: [...store.state.tours[payload.routeName], payload.target],
    });
  },
  async loadMailingProfile(store: ActionContext<RootState, any>) {
    const profile = await new MailingDomainController(Vue.axios).Profile();
    store.commit('setMailingProfile', profile);
  },
  async loadGeoCoding(store: ActionContext<RootState, any>) {
    const { companyId } = store.state.currentUser;
    const { contactId } = store.state.currentUser;
    if (companyId > 0 && !store.state.officeLocation) {
      try {
        const cmp = (await new CompanyController(Vue.axios).Get(companyId));
        store.commit('setOfficeLocation', cmp.geoLocalization?.locationObject);
      } catch (e) { }
    }
    if (contactId > 0 && !store.state.officeLocation) {
      try {
        const cnt = (await new ContactController(Vue.axios).Get(contactId));
        // await new ContactController(Vue.axios).Get(contactId);
        store.commit('setHomeLocation', cnt.geoLocalization?.locationObject);
      } catch (e) { }
    }
  },
  message(store: ActionContext<RootState, any>, message: any) {
    if (Vue.config.productionTip) {
      console.log(`SignalR Message of type ${message.type}`, message);
    }
    switch (message.type) {
      case 'ce':
        store.dispatch('expire', message);
        break;
      case 'cm':
        {
          const m = <ICometClientMessage>message;
          infoBox(m.message, m.gravity);
        }
        break;
      case 'pwe':
        {
          const m = <ICometPasswordExpireMessage>message;
          if (!store.state.passwordExpirationsNotified.includes(m.onceId)) {
            store.commit('addPasswordExpirationsNotified', m.onceId);
            passwordExpiration(message);
          }
        }
        break;
      case 'ote':
        {
          const m = <ICometOAuthTokenExpiredMessage>message;
          if (!store.state.passwordExpirationsNotified.includes(message.onceId)) {
            store.commit('addPasswordExpirationsNotified', message.onceId);
            const subject = <string>Vue.i18n.t('token.expired', message);
            const body = <string>Vue.i18n.t('token.refresh', message);
            MessageBox.confirm(body, subject, {
              confirmButtonText: <string>Vue.i18n.t('token.resign'),
              closeOnClickModal: false,
              showCancelButton: false,
            })
              .then((v: MessageBoxData) => {
                if (v === 'confirm') {
                  oauthUtils.openOAuthRefreshToken(message.crossId, message.crossType, message.davSettings, message.server).then((window) => {
                    store.commit('setOAuthRefreshWindowReference', window);
                  });
                }
              })
              .catch(() => { });
          }
        }
        break;
      case 'caa':
        {
          try {
            const m = <ICometOAuthAuthenticated>message;
            if (store.state.oauthRefreshWindowReference) {
              oauthUtils.closeWindow(store.state.oauthRefreshWindowReference());
              store.commit('webmail/setWebMailAccounts', null);
              store.dispatch('webmail/load');
            }
            store.commit('setOAuthRefreshWindowReference', null);
          } catch (e) { }
        }
        break;
      case 'cam':
        {
          const m = <ICometAlarmMessage>message;
          store.commit('setNotifications', { add: m.notifications, remove: m.deferrerNotifications });
        }
        break;
      case 'cs':
        {
          const m = <ICometSessionMessage>message;
          if (m.session !== store.state.session && !jwtManager.isImpersonationToken) {
            new AuthController(Vue.axios).Conflict({
              activeSession: m.session,
              mySession: store.state.session,
            });
            store.dispatch('lock');
          }
        }
        break;
    }
  },
  async expire(store: ActionContext<RootState, any>, message: ICometExpire) {
    if (message.forceReload) {
      if (!expireDebounced) {
        expireDebounced = _.debounce(async (s: ActionContext<RootState, any>) => {
          await s.dispatch('loadUser');
          await s.dispatch('loadMenu');
          await moduleManager.reloadModules(s, s.getters.modules);
          const router = resetRouter();
          router.addRoutes(s.state.routes);
        }, 1000);
      }
      expireDebounced(store);
    }
  },
  async reloadLimits(store: ActionContext<RootState, any>) {
    const limits = await new AuthController(Vue.axios).Limits();
    store.commit('setLimits', limits);
  },
  async unlock(store: ActionContext<RootState, any>) {
    if (store.state.locked && !jwtManager.isImpersonationToken) {
      store.commit('setLocked', false);
      store.dispatch('signalRConnection');
      crosstab.broadcast('unlock');
    }
  },
  async lock(store: ActionContext<RootState, any>) {
    if (!store.state.locked && !jwtManager.isImpersonationToken) {
      signalREventManager.stop();
      store.commit('setLocked', true);
    }
  },
};

export default actions;
