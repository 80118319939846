import Vue from 'vue';
import moduleManager from 'utils/moduleManager';
import store from '@/store';
import { LogLevel, HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import jwtManager from 'utils/jwtManager';

const eventHub: Vue = new Vue();
class SignalREventManager {
  connection: HubConnection;

  async stop() {
    if (this.connection) {
      const conn = this.connection;
      this.connection = null;
      conn.stop();
    }
  }

  async start(baseUrl: string) {
    if (this.connection) {
      console.warn('signalR Connection already UP');
      return;
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(`${baseUrl}/signalr`, {
        accessTokenFactory: async () => {
          const token = await jwtManager.getValidToken();
          return token;
        },
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.connection.on('message', (message) => {
      signalREventManager.dispatchSignalREvent(message);
    });

    const start = async () => {
      if (this.connection !== null) {
        try {
          await this.connection.start();
          console.log('SignalR Connected.');
        } catch (err) {
          console.log(err);
          setTimeout(start, 5000);
        }
      }
    };
    this.connection.onclose(start);
    await start();
  }

  dispatchSignalREvent(message: { type: string; lmid: number; uniqueguid: string }) {
    store.dispatch('message', message);
    eventHub.$emit('message', message);
    const { appInsights } = window as any;
    if (appInsights) {
      appInsights.trackEvent(`SignalR ${message.type}`);
    }
  }
}
const signalREventManager = new SignalREventManager();
export { eventHub };
export default signalREventManager;
