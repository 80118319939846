<template>
  <el-card
    :class="{ limbo: value.deleted }"
    class="m-b-h card-body-empty"
    :body-style="{ padding: '12px' }"
  >
    <t-icon-label :icon="'el-icon-' + $boMapping.toIcon(value.crossType)">
      <router-link v-if="value.isVisible" :to="route">{{ value.name }}</router-link>
      <span v-else>{{ value.name }}</span>
    </t-icon-label>
    <div v-if="value.salesPersonNames" class="text">
      <t-icon-label
        :icon="'el-icon-' + $boMapping.toIcon($enums.BusinessObjectType.Account)"
      >{{ value.salesPersonNames }}</t-icon-label>
    </div>
    <div v-if="value.city" class="text">
      <t-icon-label icon="el-icon-icon-location">{{ value.city }}</t-icon-label>
    </div>
    <div v-if="value.email" class="text">
      <t-icon-label icon="el-icon-fa-envelope fas">{{ value.email }}</t-icon-label>
    </div>
    <div v-if="value.phone" class="text">
      <t-icon-label icon="el-icon-icon-phone2">{{ value.phone }}</t-icon-label>
    </div>

    <div v-if="value.taxIdentificationNumber" class="text">
      <t-icon-label icon="el-icon-icon-vcard">{{ value.taxIdentificationNumber }}</t-icon-label>
    </div>
    <div v-if="value.vatId" class="text">
      <t-icon-label icon="el-icon-icon-vcard">{{ value.vatId }}</t-icon-label>
    </div>
  </el-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';
import { SimilarityController } from 'rt/UIApiControllers/UI/Smart/SimilarityController';
import { ISimilarityRequest } from 'rt/UIApiControllers/UI/Smart/ISimilarityRequest';
import { ISimilarityResponse } from 'rt/UIApiControllers/UI/Smart/ISimilarityResponse';
import routeHelper from 'utils/routeHelper';
import { RouteConfig } from 'vue-router';
import _ from 'lodash';

@Component({
  name: 'SimilarityItem',
})
export default class SimilarityItem extends Vue {
  @Getter('routes') routes: RouteConfig[];

  @Prop({
    required: true,
    type: Object,
  })
  value: ISimilarityResponse;

  get route() {
    return routeHelper.findRouteFromBusinessObjectType(this.routes, this.value.crossType, this.value.crossId);
  }
}
</script>
<style lang="scss" scoped>
.el-card__header {
  padding: 4px;
}
</style>
