<template>
  <span>
    <button class="el-button el-button--mini" :class="hiddenClass" style="cursor:pointer" @click="handleOpenClick"
      v-if="visible">
      <i :class="{ 'el-icon-icon-menu3': true, 'menu-open': value }"></i>
    </button>
    <t-back-button :loading="loading" :icon="icon" :router="router" @back="back" :small="small" v-indexed></t-back-button>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'TBackButtonHeader',
})
export default class TBackButtonHeader extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  router: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-undo',
  })
  icon: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  value: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'hidden-md-and-up',
  })
  hiddenClass: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  small: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default() {
      return this.$viewport.smAndUp;
    },
  })
  visible: boolean;

  back() {
    if (this.router) this.$router.back();
    else this.$emit('back');
  }

  handleOpenClick() {
    this.$emit('input', !this.value);
  }
}
</script>
<style lang="scss" scoped>
.menu-open {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
</style>
