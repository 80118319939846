import Vue from 'vue';
import Beep from 'browser-beep';

const beep = Beep();
export default () => {
  Vue.use((v) => {
    v.prototype.$beep = v.beep = (times: number) => {
      beep(times);
    };
  });
};
