//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderTaskPermits {
	AllowLogView = 104,
	AllowMassChange = 115,
	AllowChangeJobOrderTaskRequestBy = 141,
	AllowChangeJobOrderTaskRequestByOnlyIfDifferentFromJobOrderRequestBy = 142,
	AllowPrint = 143,
	AllowRollingTreeInSpareParts = 157,
	ExcludeInSuggestAndPickerJoborderTaskClosed = 182
}
