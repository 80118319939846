//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WebMailArchiveBodyStatus {
	EMailMatchFoundWaitingForArchive = 0,
	BodyNotArchivedForUnexistentMatch = 1,
	BodyArchived = 2,
	BodyArchiveError = 3,
	BodyArchiveSkippedForInternalCustomerMatch = 4,
	BodyArchiveDownloadTemporaryError = 5,
	Deleted = 6
}
