<template>
  <span>
    <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required" v-if="!simple"
      :important="schema.important">
      <t-company-lookup v-if="!isReadOnly" :value="selectedData" @input="change" :multipleSelection="schema.multiple"
        ref="lookup">
        <template #append>
          <slot name="append"></slot>
        </template>
      </t-company-lookup>
      <t-lookup-tag v-else :route="$modules.isModuleLoaded('company')" :value="company" :key="company.key"
        v-for="company in selectedData" :closable="false"></t-lookup-tag>
    </t-form-item>
    <t-company-lookup v-else :value="selectedData" :route="$modules.isModuleLoaded('company')" @input="change"
      :multipleSelection="multiple" :read-only="isReadOnly" ref="lookup">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-company-lookup>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 'CompanyInput',
})
export default class CompanyInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  async resolve(id: number): Promise<string> {
    return (await this.resolveController.Company(id)).name;
  }

  protected get identifier(): string {
    return 'Company';
  }
}
</script>
