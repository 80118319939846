<template>
  <span>
    <el-drawer :width="$viewport.dialog" :visible.sync="dialogVisible" append-to-body v-if="drawer"
      class="scrollable-drawer">
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
      </el-row>
      <t-grid :showCommands="false" repeater="PopListRepeater" @linkClick="linkClick"
        :customParameterData="{ search }" />
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="isNestedDialog" v-else>
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
      </el-row>
      <t-grid :showCommands="false" repeater="PopListRepeater" @linkClick="linkClick"
        :customParameterData="{ search }" />
    </el-dialog>

    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :route="$modules.isModuleLoaded('list')" :value="list" :key="list.key" v-for="list in selectedData"
        :closable="!readOnly" :disable-transitions="false" @close="handleRemoveItem(list)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'ListLookup',
  components: {
    TLookupSkin,
  },
})
export default class ListLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'List';
  }

  keyPrefix() {
    return 'List';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }

  linkClick(listId, row) {
    const item = {
      key: `${this.keyPrefix()}-${listId}`,
      title: row.TITLE.value,
    };

    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }
}
</script>
