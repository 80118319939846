//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CatalogPermits {
	AllowViewListPrice = 42,
	AllowEditListPrice = 43,
	AllowEditLinkedProducts = 44,
	AllowRepeaterExport = 45,
	AllowRepeaterCustomFields = 46,
	AllowEditCostField = 67,
	AllowViewCostField = 70,
	EnableGroupsFilter = 204,
	AllowMassChange = 213
}
