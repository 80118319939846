//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExchangeSynchronizationFolderType {
	Company = 0,
	Contact = 1,
	Lead = 2,
	Calendar = 3
}
