<template>
  <div>
    <el-tag size="medium" :closable="false" v-for="tag in tags" :key="tag">{{ tag }}</el-tag>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IRepeaterColumn, IRowDataInfo } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';

@Component({
  name: 'TagsCell',
})
export default class TagsCell extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  row: IRowDataInfo;

  get tags() {
    if (this.row) {
      const { value } = this.row as any;
      if (value) {
        return value.split(',');
      }
    }
    return [];
  }
}
</script>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
