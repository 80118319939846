export default {
  back: 'Back',
  alls: 'All',
  note: 'Notes',
  search: 'Search',
  choose: 'Select…',
  offline: 'A network issue has been detected. Check your internet connection and rerun the operation',
  mail: {
    to: 'TO:',
    cc: 'CC:',
    bcc: 'BCC:',
    from: 'FROM:',
    subject: 'Subject',
    validation: {
      domain: 'The domain {domain} does not exist or does not have a valid MX',
      format: 'The email {mail} is not in a valid format',
      mailbox: 'The email {mail} does not seem to exist on the destination server'
    }
  },
  printing: 'Printing',
  group: {
    add: 'Add group',
    title: 'Groups management',
    manage: 'Manage groups',
    selected: 'Selected',
    unselected: 'Not selected'
  },
  lists: {
    list: 'List',
    kanban: 'Kanban'
  },
  lock: {
    title: '{name} already in use',
    close: 'Close',
    locked: '{name} is already open in another browser. Click "Use here" to switch {name} to this browser.',
    unlock: 'Use here'
  },
  commands: {
    no: 'No',
    ok: 'Ok',
    accept: 'Accept',
    yes: 'Yes',
    save: 'Save',
    copy: 'Copy',
    view: 'View',
    edit: 'Edit',
    move: 'Move',
    plus: 'Add',
    close: 'Close',
    clone: 'Clone',
    never: 'Never',
    print: 'Print',
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    copied: 'Pasted to clipboard',
    create: 'Create',
    always: 'Always',
    reload: 'Reload',
    export: 'Export',
    actions: 'Actions',
    discard: 'Discard changes',
    confirm: 'Confirm',
    convert: 'Convert',
    rotateDevice: 'Rotate your device',
    deleteConfirm: 'Are you sure you want to delete the item?',
    copyClipboard: 'Copy to Clipboard',
    parameter: {
      value: 'Value',
      dynamic: 'Dynamic parameter'
    },
    results: {
      savesuccess: 'Saved successfully'
    },
    click: 'click here',
    rename: 'Rename',
    warning: 'Warning!',
    next: 'Next',
    call: 'Call',
    undo: 'Cancel',
    redo: 'Repeat'
  },
  layout: {
    help: 'Need help?',
    guide: 'Go to the online guide',
    logout: 'Logout',
    mailbox: 'Mailbox',
    messages: 'Messages',
    viewProfile: 'View profile'
  },
  maps: {
    coords: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    import: {
      import: 'Import this personal data',
      search: 'Search on map'
    }
  },
  lookup: {
    zone: 'Area',
    account: 'Account',
    activity: 'Activities',
    catalogCategory: 'Products categories'
  },
  licence: {
    buy: 'Activate your license',
    pro: {
      required: 'Functionality available in Professional version'
    },
    required: 'Feature not available in the current version',
    business: {
      required: 'Functionality present in the Business version'
    }
  },
  token: {
    resign: 'Login',
    expired: '{server} is not accessible',
    refresh: '{message}. Login again to continue using the service.'
  },
  loading: {
    ramp: 'preparing launch ramp',
    warmup: 'system connection in progress',
    modules: 'components download',
    profile: 'loading profile',
    testing: 'latest simulations',
    starting: 'Ignition',
    satellite: 'aligning satellites',
    connecting: 'connecting the system'
  },
  password: {
    type: 'Insert new Password',
    wrong: 'The password is not valid. Do you want to try again?',
    expired: 'Password expired',
    changed: 'The password has been successfully changed'
  },
  changePassword: 'Change Password',
  confirmPassword: 'Confirm Password',
  category: {
    global: 'Global',
    personal: 'Personal'
  },
  avatar: {
    error: {
      limit: 'The image should not be larger than 2 MB!',
      format: 'The image must be JPG or PNG'
    },
    discover: {
      link: 'Select the logo you want to associate with the card',
      title: 'The following logos have been found for the card you are viewing',
      found: 'Yes, found',
      source: 'Logo source {source}',
      nobody: 'No, none of these',
      search: 'Logo search',
      searching: 'Searching logo'
    }
  },
  modules: {
    errors: {
      load: 'There was an error loading module\'s {module}. Make sure that there are no ad-blockers that do not allow CRM to load properly, and try to reload the page.',
      reload: 'Reload page'
    }
  },
  popupBlocker: {
    detected: 'Pop-up blocker detected',
    deactivate: 'Hi, trust us, turn off the pop-up blocker to fully enjoy the features of the CRM!'
  },
  validation: {
    required: {
      once: 'At least one of the fields is mandatory',
      title: 'Mandatory value',
      field: 'The field {field} is mandatory',
      fields: 'Not all mandatory fields have been filled in'
    }
  },
  alarms: {
    notification: {
      note: 'Notes',
      title: 'Reminder expiration',
      dateAge: 'Expiration',
      postpones: 'Defer by {n} minutes'
    }
  },
  messages: {
    nodisplay: 'Ops... still nothing to see',
    nomessages: 'nobody has written to you yet'
  },
  unauthorized: {
    read: 'You are not authorized to access items in this area'
  },
  form: {
    duration: {
      custom: 'Custom duration'
    }
  },
  integrations: {
    webex: 'Open in Webex',
    linkedin: 'Open in Linkedin',
    gotomeeting: 'Open in GoToMeeting',
    googlemeet: 'Open in Google Meet',
    skymeeting: 'Open in SkyMeeting',
    teams: 'Open in Teams',
    generic: 'Open in {hostname}',
    zoom: 'Open in Zoom',
    calendly: 'Open in Calendly',
    skype: 'Open in Skype',
    office365: 'Open in Office365',
    clickmeeting: 'Open in ClickMeeting',
    maps: 'Open Map',
    lifesizecloud: 'Open in Lifesize'
  }
};