//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderBudgetType {
	CatalogProduct = 0,
	ManualProduct = 1,
	RollingStock = 2,
	ActivityFixedPrice = 5,
	ActivityKm = 6,
	ActivityJourney = 7,
	ActivityWork = 8,
	ActivityOtherPrice = 9,
	ActivityCall = 10,
	ActivityContractRate = 11,
	ActivityReplacementCode = 12,
	ActivityRental = 13,
	ActivityAccomodation = 14,
	ActivityFood = 15,
	ActivityTransport = 16,
	ActivityParking = 17,
	ActivityFuel = 18
}
