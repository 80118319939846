<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import eventHubManager from 'modules/mixin/views/EventHub';
import ListLayoutStudio from './ListLayoutStudio.vue';

// tslint:disable-next-line:variable-name
const ListAction = namespace('list').Action;

@Component({
  name: 'ListFastLayoutStudio',
})
export default class ListFastLayoutStudio extends ListLayoutStudio {
  @ListAction('schema') loadSchema: (suffix: string) => Promise<any>;

  @ListAction('loadNew') loadNew: (id: number) => Promise<any>;

  @ListAction('reset') reset: () => Promise<any>;

  @Prop({
    type: Object,
    required: false,
  })
  defaults: any;

  async mounted() {
    await this.reset();
    await this.loadSchema(null);
    const def = this.defaults;
    await this.loadNew(this.defaults);
  }

  get eventHub(): Vue {
    return eventHubManager.getHub('list.fast');
  }
}
</script>
