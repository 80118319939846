//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TypeOfInstalment {
	Monthly = 0,
	Quarterly = 1,
	SixMonthly = 2,
	Unique = 3,
	Yearly = 4,
	TwoMonthly = 5
}
