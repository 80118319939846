//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DynamoInputType {
	Input = 0,
	Numeric = 1,
	CheckBox = 2,
	AccountBox = 3,
	CompanyBox = 4,
	ContactBox = 5,
	LeadBox = 6,
	DateBox = 7,
	ActivityBox = 8,
	OpportunityBox = 9,
	JobOrderBox = 10,
	JobOrderTaskBox = 11,
	CatalogBox = 12,
	TicketBox = 13,
	ZoneBox = 14
}
