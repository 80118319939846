//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CalendarGroupMode {
	OfficeDetails = 0,
	TeamDetails = 1,
	TeamOverView = 2,
	OfficeOverView = 3,
	WorkGroupAsset = 4,
	WorkGroupDetails = 5,
	WorkGroupOverView = 6,
	WorkPlanView = 7
}
