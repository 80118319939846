//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum QuoteExtensionConversionToOrderAction {
	NoConversion = 0,
	CreateNewRowInOrder = 1,
	SumValueToOriginalRow = 2,
	ConvertToNewOrderA = 3,
	ConvertToNewOrderB = 4,
	ConvertToNewOrderC = 5,
	ConvertTaxableAmountInFreeField = 6,
	ConvertGrandTotalInFreeField = 7
}
