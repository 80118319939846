//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PortalKnowledgeBasePermissionSet {
	Visible = 1,
	ViewComments = 2,
	InsertNewComment = 4,
	ViewLink = 8,
	ViewDocuments = 16,
	ViewProducts = 32,
	ViewArguments = 64
}
