//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PermissionExceptionType {
	Create = 0,
	Read = 1,
	Write = 2,
	Delete = 3,
	LeadConvert = 4
}
