<template>
  <el-card class="m-b">
    <el-row type="flex" align="top" justify="left" :gutter="10">
      <el-col :span="-1">
        <el-switch :value="value && !abuse" :disabled="abuse" @input="v => $emit('input', v)"></el-switch>
      </el-col>
      <el-col>
        <h4>{{ list.title }}</h4>
        <div v-if="list.description" class="text m-t-q">{{ list.description }}</div>
      </el-col>
    </el-row>
    <transition name="el-zoom-in-top">
      <div class="m-t-h" v-if="!value && wasSubscribed">
        <el-input :value="reason" :rows="3" type="textarea" :placeholder="$t('mailing.reason')" @input="v => $emit('update:reason', v)"></el-input>
      </div>
    </transition>
  </el-card>
</template>

<script lang="ts">
import Vue from 'vue';
import {
 Action, Getter, Mutation, namespace,
} from 'vuex-class';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IListDTO } from 'dto/IListDTO';
import { IListMailingListRightsDTO } from 'dto/IListMailingListRightsDTO';

@Component({
  name: 'ListSubscribe',
})
export default class ListSubscribe extends Vue {
  @Prop({ type: Boolean, default: false })
  abuse: boolean;

  @Prop({ required: true, type: Object })
  list: IListDTO;

  @Prop({ required: true, type: Boolean })
  value: boolean;

  @Prop({ required: false, type: String })
  reason: string;

  wasSubscribed = false;

  async mounted() {
    this.wasSubscribed = this.value;
  }
}
</script>
