<template>
  <el-checkbox
    :indeterminate="value === 0"
    :value="value"
    @input="handleTriStateChange"
    :true-label="1"
    :false-label="2"
    ></el-checkbox>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'TriStateInput',
})
export default class TriStateInput extends Vue {
  @Prop({ required: true, type: Number })
  value: number;

  handleTriStateChange() {
    if (this.value === 0) {
      this.$emit('input', 1);
    } else if (this.value === 1) {
      this.$emit('input', 2);
    } else {
      this.$emit('input', 0);
    }
  }
}
</script>
