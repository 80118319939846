//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DossierTemplateType {
	Uno = 0,
	Due = 1,
	Tre = 2,
	Default = 3
}
