//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivityLoadPerformanceSettings {
	All = 1,
	Minimal = 2,
	LoadEMailHeaders = 4,
	LoadEMailBody = 8,
	LoadFaxHeaders = 16,
	LoadFaxBody = 32,
	LoadFreeFields = 64,
	LoadAlarms = 128,
	LoadCosts = 256,
	LoadActivityCross = 512,
	LoadProtocol = 1024,
	LoadSharing = 2048,
	LoadRelations = 4096,
	LoadAssets = 8192,
	LoadDocuments = 16384
}
