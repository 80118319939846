<template>
  <el-row v-if="value">
    <el-col>
      <transition-group name="fade-jump-bottom" mode="out-in">
        <div v-for="mup in value" :key="mup.id" class="m-b">
          <el-row type="flex" justify="center" align="middle">
            <el-col>
              <div class="text">
                <span class="m-r">{{ $t('massiveUpdate.pending.title') }}</span>
                <span class="text-info m-r">{{
                  $moment.duration($moment(mup.startDate).diff($moment())).humanize(true)
                }}</span>
                <span class="text-success m-r-q">{{ mup.updated }}</span>/
                <span class="text-primary m-l-q">{{ mup.total }}</span>
                <span class="text-info m-l">{{ eta(mup) }}</span>
              </div>
              <el-progress
                :percentage="Math.round(((mup.updated + mup.failed + mup.aborted) / mup.total) * 100)"></el-progress>
            </el-col>
            <el-col :span="-1">
              <t-delete-button :circle="false" icon="el-icon-icon-cross" @delete="$emit('abort', mup.id)">
                {{ $t('massiveUpdate.pending.abort') }}
                <template #message>
                  <span>{{ $t('massiveUpdate.pending.confirm') }}</span>
                </template>
              </t-delete-button>
            </el-col>
          </el-row>
        </div>
      </transition-group>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ICometMassiveUpdateMessage } from 'rt/SignalR/Messages/ICometMassiveUpdateMessage';

@Component({
  name: 'Markdown',
})
export default class MassiveUpdatePendingReport extends Vue {
  @Prop({ required: false, type: Array })
  value: ICometMassiveUpdateMessage[];

  eta(mup: ICometMassiveUpdateMessage) {
    const processed = mup.updated + mup.failed + mup.aborted;
    if (processed > 10) {
      const single = Math.abs(this.$moment(mup.startDate).diff(this.$moment(), 'seconds')) / processed;
      const remaining = mup.total - processed;
      const expectedEnd = this.$moment().add(remaining * single, 'seconds');
      return (this.$moment as any).duration(expectedEnd.diff(this.$moment())).humanize(true);
    }
    return null;
  }
}
</script>
