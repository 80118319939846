<template>
  <div class="loading-box-loading">
    <div v-for="i in 3" :key="i">
      <el-divider>*</el-divider>
      <el-row :gutter="10" type="flex">
        <el-col>
          <div
            v-for="j in 5"
            :key="j"
            :class="`loading-box-line-${randomizeSize()} ${randomizePadding()}`"
          ></div>
        </el-col>
        <el-col>
          <div
            v-for="i in 5"
            :key="i"
            :class="`loading-box-line-${randomizeSize()} ${randomizePadding()}`"
          ></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingMixin from './loadingMixin';

@Component({
  name: 'LoadingLayoutSchema',
})
export default class LoadingLayoutSchema extends LoadingMixin {}
</script>
<style lang="scss">
@import './loading.scss';
</style>
