<template>
  <t-field-header v-bind="$props" :value="value" :operator="operator" @operator-change="changeOperatorFilter" :valid="isValidFilter" @sort-change="v => $emit('sort-change', v)">
    <el-input v-model="value" @keyup.enter.native="changeFilter" :disabled="isValidOperationFilter"></el-input>
  </t-field-header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'textFieldHeader',
})
export default class TextFieldHeader extends HeaderMixin {
  get isValidValueFilter(): boolean {
    return this.value != null && this.value.length > 0;
  }
}
</script>
