//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IImageDiscoverRequest } from '../../../Base/Avatar/IImageDiscoverRequest';
import { ILogo } from '../../../Interfaces/Misc/ILogo';

//element.Name=ImageDiscoverController result.Name=ImageDiscoverController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.ImageDiscoverController);
/** Result of WebApiControllerGenerator activity */
export class ImageDiscoverController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in ImageDiscoverController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Post(request: IImageDiscoverRequest, size: number = 100, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILogo[]>
	{
		return (this.axios.post('ImageDiscover?'+'&size='+size, request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('ImageDiscover/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('ImageDiscover/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
