//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BusinessObjectAction {
	Created = 0,
	Updated = 1,
	Deleted = 2,
	CustomEvent = 3,
	Print = 4,
	Export = 5
}
