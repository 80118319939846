//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MigrateVersion {
	TustenaDBWithBO = 1,
	CryptedPassword = 2,
	HashedPassword = 4,
	PolyedroMenuWithPwPrefix = 8,
	FlowVersion2 = 16,
	PrintPermission = 32,
	VcmeToSecurityPolicies = 64,
	CryptedRefreshToken = 128,
	ConfigurationSchemesInDatabase = 256
}
