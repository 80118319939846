//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderBillingType {
	NoBill = 0,
	FinalBalance = 1,
	PartialOverrun = 2,
	FixedPrice = 3
}
