import { render, staticRenderFns } from "./AlarmInput.vue?vue&type=template&id=a20f6c16"
import script from "./AlarmInput.vue?vue&type=script&lang=ts"
export * from "./AlarmInput.vue?vue&type=script&lang=ts"
import style0 from "./AlarmInput.vue?vue&type=style&index=0&id=a20f6c16&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports