<template>
  <div>
    <div v-if="model === null">
      <t-loading-item></t-loading-item>
    </div>
    <div v-else-if="schema" v-loading="saving || working">
      <slot name="header"></slot>
      <el-form
        :model="model"
        :rules="rules"
        ref="layoutStudioForm"
        size="mini"
        :label-width="labelWidth"
        autocomplete="off"
        :disabled="isDisabled"
      >
        <input autocomplete="false" name="hidden" type="text" style="display:none;" />
        <slot name="form-header"></slot>
        <t-ls-form
          v-if="currentLayoutStudioSchema"
          :schema="currentLayoutStudioSchema"
          :data="model"
          @input="update"
        ></t-ls-form>
        <slot name="form-footer"></slot>
      </el-form>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Getter, namespace } from 'vuex-class';
import { Prop, Watch, Provide } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { ElNotification } from 'element-ui/lib/notification';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import { ILayoutStudioSlot } from 'rt/Interfaces/UI/ILayoutStudioSlot';
import layoutStudioValidationRules from 'utils/layoutStudioValidationRules';
import { mapSchemaRecursive } from '../store/boGetters';
import eventHubManger from './EventHub';
import _ from 'lodash';
import { MessagesController } from '@/plugins/typings/UIApiControllers/UI/Messages/MessagesController';
import DefaultDtoControllerProxy from '@/plugins/typings/UIApiControllers/BusinessObject/DTO/DefaultDtoControllerProxy';

const compactSchema = (schema: IWidgetPage, compact: boolean): IWidgetPage => {
  if (schema && compact) {
    const flatternItems = mapSchemaRecursive(schema).filter((s) => s.required || s.important);
    const fschema: IWidgetPage = _.cloneDeep(schema);
    // eslint-disable-next-line no-unused-expressions
    fschema.tab?.tabs?.splice(1);
    // eslint-disable-next-line no-unused-expressions
    fschema.tab?.tabs?.[0].layoutStudioSchema?.sections?.splice(1);
    // eslint-disable-next-line no-unused-expressions
    fschema.tab?.tabs?.[0].layoutStudioSchema?.sections?.[0].columns?.splice(1);
    if (fschema.tab?.tabs?.[0].layoutStudioSchema?.sections?.[0].columns != null) {
      fschema.tab.tabs[0].layoutStudioSchema.sections[0].columns = [flatternItems];
    }
    return fschema;
  }
  return schema;
};

@Component({
  name: 'LayoutStudioMixinView',
  beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    if (this.confirmLeaveChangesActiveForParameterChanges(to, from) && this.dirty) {
      this.confirmRouteChanges(to, from, next);
    } else {
      next();
    }
  },
  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (this.confirmLeaveChangesActiveForRoutes(to, from) && this.dirty) {
      this.confirmRouteChanges(to, from, next);
    } else {
      next();
    }
  },
})
export default class LayoutStudioMixinView<T extends { id: number }> extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  redirect: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  compact: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'DP',
  })
  myDeepProp: string;

  @Prop({
    type: Function,
    required: false,
    default: null,
  })
  preSave: (model: T) => Promise<T>;

  get currentLayoutStudioSchema() {
    if (!this.schema) return null;
    if (!this.schema.tab) return null;
    if (!this.schema.tab.tabs) return null;
    const tabName = this.$route.query.tabName;
    if (!tabName) {
      const firstTab = this.schema.tab.tabs.find((t) => t.primary);
      if (firstTab) return firstTab.layoutStudioSchema;
      return null;
    }
    const currentTab = this.schema.tab.tabs.find((t) => t.id === tabName);
    if (currentTab) return currentTab.layoutStudioSchema;
    return null;
  }

  get schemaSuffix(): string {
    return null;
  }

  @Watch('schemaSuffix')
  async handleSchemaSuffixChange(schema) {
    if (schema) {
      await this.$store.dispatch(`${this.module}/schema`, schema);
    }
  }

  get schemes(): { [suffix: string]: IWidgetPage } {
    const schemes = this.$store.state[this.module].schemes as { [suffix: string]: IWidgetPage };
    return schemes;
  }

  get schema(): IWidgetPage {
    if (Object.prototype.hasOwnProperty.call(this.schemes, this.schemaSuffix)) {
      return compactSchema(this.schemes[this.schemaSuffix], this.compact);
    }
    if (Object.prototype.hasOwnProperty.call(this.schemes, 'default')) {
      return compactSchema(this.schemes['default'], this.compact);
    }
    return null;
    // return <IWidgetPage>this.$store.state[this.module].schema;
  }

  get model(): T {
    return this.$store.state[this.module].model as T;
  }

  get originalModel(): T {
    return this.$store.state[this.module].originalModel as T;
  }

  /* ABSTRACT METHODS AND PROPERTIES TO IMPLEMENTS IN SUPERCLASS */
  get form(): ElementUI.Form {
    return this.$refs.layoutStudioForm as ElementUI.Form;
    /* console.error(
      'You need to override get form(): ElementUI.Form method in super class',
    );
    throw 'You need to override get form(): ElementUI.Form method in super class'; */
  }

  protected get routeName(): string {
    console.error('You need to override get routeName(): string method in super class');
    throw 'You need to override get routeName(): string method in super class';
  }

  protected get backRouteName(): string {
    console.error('You need to override get backRouteName(): string method in super class');
    throw 'You need to override get backRouteName(): string method in super class';
  }

  protected get module(): string {
    console.error('You need to override protected get module(): string method in super class');
    throw 'You need to override get module(): string method in super class';
  }

  @Provide('isDisabled')
  get isDisabled() {
    if (this.model && this.schema) {
      if (this.model.id > 0) {
        return !this.schema.canModify;
      }
      return !this.schema.canCreate;
    }
    return false;
  }

  get eventHub(): Vue {
    return eventHubManger.getHub(this.module);
  }

  get changes(): () => Partial<T> {
    return this.$store.getters[`${this.module}/changes`] as () => Partial<T>;
    // return <boolean>this.$store.getters(`${this.module}/dirty`);
  }

  get dirty(): boolean {
    return this.$store.getters[`${this.module}/dirty`] as boolean;
    // return <boolean>this.$store.getters(`${this.module}/dirty`);
  }

  get working(): boolean {
    return this.$store.getters[`${this.module}/working`] as boolean;
  }

  get controller(): DefaultDtoControllerProxy<T> {
    return this.$store.getters[`${this.module}/controller`] as DefaultDtoControllerProxy<T>;
  }

  get modelContext(): { [key: string]: any } {
    return this.$store.getters[`${this.module}/modelContext`] as { [key: string]: any };
  }

  created() {
    this.eventHub.$on('save', async () => {
      await this.saveModel();
    });
    this.eventHub.$on('delete', async () => {
      await this.deleteModel();
    });
    this.eventHub.$on('clone', async () => {
      await this.cloneModel();
    });
    this.eventHub.$on('reload', async () => {
      if (this.model.id) {
        this.saving = true;
        await this.$store.dispatch(`${this.module}/load`, this.model.id);
        this.saving = false;
      }
    });
    this.onCreated();
  }

  onCreated() {}

  // It's good to clean up event listeners before
  // a component is destroyed.
  beforeDestroy() {
    this.eventHub.$off('save');
    this.eventHub.$off('delete');
    this.eventHub.$off('clone');
    this.eventHub.$off('reload');
    this.onBeforeDestroy();
  }

  onBeforeDestroy() {}

  update(dto: T) {
    if (this.isDisabled) {
      return;
    }
    this.$store.commit(`${this.module}/setModel`, dto);
  }

  setModelContext(payload: { key: string; value: any }) {
    this.$store.commit(`${this.module}/setModelContext`, payload);
  }

  updateProperty(n: string, v: any) {
    const m = this.model;
    this.update({
      ...m,
      [n]: v,
    });
  }

  get id(): number {
    return this.$hashids.decode(this.$route.params.id)[0] as number;
  }

  protected async dispatchSave(): Promise<number> {
    if (this.preSave) {
      this.update(await this.preSave(this.model));
    }
    return await this.$store.dispatch(`${this.module}/save`);
  }

  saving = false;

  protected async beforeSave(): Promise<boolean> {
    return true;
  }

  async validate() {
    const form = this.form;
    return new Promise<boolean>((success, reject) => {
      form.validate(async (valid) => {
        success(valid);
      });
    });
  }

  async saveModel(optimistic = true): Promise<boolean> {
    if (this.saving) return false;
    const form = this.form;
    return new Promise<boolean>((success, reject) => {
      try {
        this.$store.commit(`${this.module}/setWorking`, true);
        form.validate(async (valid, invalidFields) => {
          if (optimistic) this.$store.commit(`${this.module}/setWorking`, false);
          if (valid) {
            try {
              if (await this.beforeSave()) {
                const saveTask = this.dispatchSave();
                const isNew = this.model.id <= 0;
                if (isNew) {
                  this.saving = true;
                  await saveTask;
                }
                const originalModel = this.originalModel;
                const model = this.model;
                const modelContext = this.modelContext;
                const route = this.$route;
                if (!isNew) {
                  this.$store.commit(`${this.module}/setOriginalModel`, this.model);
                  this.$store.commit(`${this.module}/setModelContext`, modelContext);
                }
                saveTask
                  .then(async (id) => {
                    if (id > 0) {
                      await this.saved(id);
                      await this.savePendingMessage(id, model);
                      await this.afterSave(id);
                      this.$store.commit(`${this.module}/setWorking`, false);
                      this.$emit('saved', id);
                      await this.$store.dispatch(`${this.module}/load`, id);
                      success(true);
                      this.saving = false;
                    }
                  })
                  .catch(async (e) => {
                    this.$store.commit(`${this.module}/setWorking`, false);
                    if (!isNew) {
                      if (this.model?.id !== model.id) {
                        this.$store.commit(`${this.module}/setModel`, null);
                        this.$store.commit(`${this.module}/setOriginalModel`, null);
                        if (this.$route !== route) {
                          await this.$router.push({
                            ...route,
                            query: {
                              ...route.query,
                              retry: '1',
                            },
                          });
                        }
                        this.$store.commit(`${this.module}/setOriginalModel`, originalModel);
                        this.$store.commit(`${this.module}/setModelContext`, modelContext);
                        this.$store.commit(`${this.module}/setModel`, model);
                      }
                    }
                    this.saving = false;
                    reject();
                    throw e;
                  });
                return;
              }
            } catch (e) {
              this.saving = false;
              reject();
              throw e;
            }
            this.saving = false;
          } else {
            this.notifyRequiredFields(invalidFields);
          }
          success(false);
        });
      } catch (ex) {
        this.$store.commit(`${this.module}/setWorking`, false);
        throw ex;
      }
    });
  }

  async savePendingMessage(id: number, model: T): Promise<void> {
    const pendingMessage = model['__message'];
    if (pendingMessage != null && pendingMessage.body != null && pendingMessage.body.length) {
      const request = {
        ...pendingMessage,
        crossId: id,
        crossType: await this.controller.GetBusinessObjectType(),
      };
      const result = await new MessagesController(Vue.axios).SaveMessage(request);
    }
  }

  private lastRequiredNotify: ElNotification = null;

  private notifyRequiredFields(invalidFields) {
    if (this.lastRequiredNotify) {
      this.lastRequiredNotify.close();
      this.lastRequiredNotify = null;
    }
    const nv = invalidFields;
    if (nv) {
      const msgs = [];
      for (const field in nv) {
        if (Object.prototype.hasOwnProperty.call(nv, field)) {
          const element = nv[field];
          for (const msg of element) {
            if (msg.message) {
              msgs.push(msg.message);
            }
          }
        }
      }
      if (msgs.length) {
        this.lastRequiredNotify = this.$notify({
          type: 'warning',
          title: this.$t('validation.required.fields') as string,
          dangerouslyUseHTMLString: true,
          message: msgs.map((m) => `<div class="m-b-q text">${m}</div>`).join(''),
          position: 'bottom-right',
        });
      }
    }
  }

  protected async saved(id: number): Promise<void> {}

  protected async afterSave(id: number): Promise<void> {
    if (this.redirect) {
      const query = {};
      if (this.routeName === this.$route.name) {
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'tabName')) {
          query['tabName'] = this.$route.query['tabName'];
        }
      }
      // same id (update)
      if (this.id === id) {
        return;
      }
      // complete sheet
      if (this.id === 0) {
        this.$router.replace({
          query,
          name: this.routeName,
          params: { id: this.$hashids.encode(id) },
        });
      } else {
        // fast popup sheet
        this.$router.push({
          query,
          name: this.routeName,
          params: { id: this.$hashids.encode(id) },
        });
      }
    }
  }

  protected async beforeDelete(): Promise<boolean> {
    return true;
  }

  async deleteModel() {
    if (await this.beforeDelete()) {
      const id = this.model.id;
      if (await this.$store.dispatch(`${this.module}/delete`)) {
        this.afterDelete();
        this.$emit('deleted', id);
      }
    }
  }

  protected async afterDelete(): Promise<void> {
    this.$router.replace({ name: this.backRouteName });
  }

  async cloneModel(): Promise<boolean> {
    if (this.saving) return false;
    if (this.dirty) {
      // TODO: Ask to save or not
      /*
      if (!this.saveModel()) {
        return false;
      }
      */
    }
    if (await this.beforeClone()) {
      const { id, ...clonedModel } = this.model;
      await this.$store.dispatch(`${this.module}/revert`);
      this.$router.push({
        name: this.routeName,
        params: {
          id: this.$hashids.encode(0),
          defaults: JSON.stringify({
            ...this.buildClonedModel(clonedModel),
            externalReferences: [],
          }),
        },
      });
      await this.afterClone();
      this.$store.commit('setOriginalModel', null);
    }
  }

  protected async beforeClone(): Promise<boolean> {
    return true;
  }

  protected async afterClone(): Promise<boolean> {
    return true;
  }

  protected buildClonedModel(clonedModel) {
    return clonedModel;
  }

  get rules() {
    return this.customRules(layoutStudioValidationRules.buildRules(this.schema, () => this.model));
  }

  protected customRules(rules: any): any {
    return rules;
  }

  back() {
    this.$router.back();
  }

  get labelWidth() {
    return this.schema?.labelWidth ?? this.$viewport.formLabelWidth;
  }

  private confirmLeaveChangesActiveForParameterChanges(to: Route, from: Route) {
    if (this.isDisabled) {
      return false;
    }
    const toParams = this.comparableParams(to.params);
    const fromParams = this.comparableParams(from.params);
    if (_.isEqual(toParams, fromParams)) {
      return false;
    }
    return true;
  }

  private comparableParams(params) {
    const { defaults, ...cParams } = params;
    return cParams;
  }

  private confirmLeaveChangesActiveForRoutes(to: Route, from: Route) {
    if (this.isDisabled) {
      return false;
    }
    if (to.meta && to.meta.leave && from && from.meta && from.meta.leave) {
      if (to.meta.leave.group && from.meta.leave.group) {
        if (to.meta.leave.group === from.meta.leave.group) {
          return false;
        }
      }
    }
    if (to.meta && to.meta.leave && to.meta.leave.dirty === false) {
      return false;
    }
    return true;
  }

  confirmRouteChanges(to: Route, from: Route, next: () => void) {
    console.log('changes', this.changes());
    this.$confirm(this.$t('leave.confirm') as string, this.$t('leave.title') as string, {
      distinguishCancelAndClose: true,
      confirmButtonText: this.$t('commands.save') as string,
      cancelButtonText: this.$t('commands.discard') as string,
      type: 'warning',
    })
      .then(async () => {
        if (await this.saveModel(false)) {
          next();
        }
      })
      .catch(async (action) => {
        if (action === 'cancel') {
          await this.$store.dispatch(`${this.module}/reset`);
          next();
        }
      });
  }
}
</script>
