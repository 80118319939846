//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DefaultCodeType {
	FixedPrice = 0,
	QtaKm = 1,
	JourneyPrice = 2,
	LaborPrice = 3,
	OtherPrice = 4,
	Call = 5,
	ContractRate = 6,
	ReplacementCode = 7,
	Rental = 8,
	Accomodation = 9,
	Food = 10,
	Transport = 11,
	Parking = 12,
	Fuel = 13
}
