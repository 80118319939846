import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import SortingColumn from './SortingColumn.vue';
import TFieldHeader from './FieldHeader.vue';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'HeaderMixin',
  components: {
    SortingColumn,
    TFieldHeader,
  },
})
export default class HeaderMixin extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  tableColumn: any;

  @Prop({
    type: Object,
    required: true,
  })
  tableStore: any;

  @Prop({
    type: Boolean,
    required: true,
  })
  allowFilter: boolean;

  @Prop({
    type: Array,
    required: false,
  })
  filter: any[];

  @Prop({
    type: Object,
    required: false,
    default: null,
  })
  sorting: any;

  @Watch('filter')
  handleFilterChange() {
    this.deconstructFilter();
  }

  value: any = null;

  operator: any = null;

  conditionOperatorPriority = [this.$enums.ReportConditions.Contain, this.$enums.ReportConditions.Equal, this.$enums.ReportConditions.GreaterThan];

  created() {
    this.deconstructFilter();
  }

  deconstructFilter() {
    if (this.filter && this.filter.length > 0) {
      this.operator = this.column.operators.find((o) => o.value === this.filter[0].operator);
      this.value = this.filter[0].value;
    } else {
      for (const oc of this.conditionOperatorPriority) {
        const rc = this.column.operators.find((o) => o.value === oc);
        if (rc != null) {
          this.operator = rc;
          break;
        }
      }
      if (this.operator == null) {
        this.operator = this.column.operators[1];
      }
      this.value = null;
    }
  }

  mounted() {}

  changeFilter() {
    if (this.isValidOperationFilter) {
      this.tableStore.commit('filterChange', { column: this.tableColumn, values: [{ operator: this.operator.value }] });
    } else if (this.isValidValueFilter) {
        this.tableStore.commit('filterChange', { column: this.tableColumn, values: [{ value: this.value, operator: this.operator.value }] });
      } else {
        this.tableStore.commit('filterChange', { column: this.tableColumn, values: [] });
      }
  }

  changeOperatorFilter(newOperator) {
    this.operator = newOperator;
    if (this.isValidOperationFilter) {
      this.changeFilter();
    } else if (this.value && this.value !== '') {
      this.changeFilter();
    }
  }

  get isValidValueFilter(): boolean {
    console.error('You need to override get isValidValueFilter(): boolean method in super class');
    throw 'You need to override get isValidValueFilter(): boolean method in super class';
  }

  get isValidOperationFilter(): boolean {
    return this.operator.value === this.$enums.ReportConditions.IsEmpty || this.operator.value === this.$enums.ReportConditions.IsNotEmpty;
  }

  get isValidFilter(): boolean {
    return this.isValidValueFilter || this.isValidOperationFilter;
  }
}
