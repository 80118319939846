<template>
  <span>
    <el-dialog :width="$viewport.dialog" :visible.sync="dialogVisible" :append-to-body="isNestedDialog">
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
      </el-row>
      <t-grid :showCommands="false" repeater="PopContactRepeater" @linkClick="linkClick"
        :customParameterData="{ search }" />
    </el-dialog>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :route="$modules.isModuleLoaded('contact')" :value="contact" :key="contact.key"
        v-for="contact in selectedData" :closable="!readOnly" :disable-transitions="false"
        @close="handleRemoveItem(contact)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'ContactLookup',
  components: {
    TLookupSkin,
  },
})
export default class ContactLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'Contact';
  }

  keyPrefix() {
    return 'Contact';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }

  linkClick(contactId, row) {
    const item = {
      key: `${this.keyPrefix()}-${contactId}`,
      title: `${row.NAME.value} ${row.SURNAME.value}`,
    };
    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }
}
</script>
