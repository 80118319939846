<template>
  <el-dropdown @command="c => $emit('input', c)" trigger="click" v-if="dropdown">
    <span>
      <t-resolve-label :type="type" :value="value" :fallback="captionText"></t-resolve-label>
      <i class="el-icon-icon-arrow-down4 text text-info el-icon--right"></i>
    </span>
    <template #dropdown><el-dropdown-menu>
        <el-dropdown-item v-for="item in filteredItems" :key="item.value" :command="item.value">{{
          item.text
        }}</el-dropdown-item>
      </el-dropdown-menu></template>
  </el-dropdown>
  <el-select :value="value" @input="change" style="width:100%" :filterable="!editing" :disabled="disabled"
    :multiple="multiple" :placeholder="captionText" :clearable="clearable" v-else>
    <draggable :value="filteredItems"
      :options="{ handle: '.sort-handle', draggable: '.option-item', group: 'item', animation: 100, delay: $viewport.isTouch ? 400 : 0 }"
      @change="handleComplete">
      <el-option v-for="item in filteredItems" :key="item.value" :label="item.text" :value="item.value"
        :class="{ editing: editing, 'form-reset': editing, 'option-item': (editable || {}).orderPropertyName }">
        <el-row v-if="editable != null && item.value == 0" type="flex">
          <el-col>
            <span v-if="!editing">{{ item.text }}</span>
          </el-col>
          <el-col :span="-1" @click.native.prevent.stop="startEditing" class="p-l-h p-r-h">
            <i class="el-icon-fa-pencil fas"></i>
          </el-col>
        </el-row>
        <t-remote-editable-option v-else-if="editable != null" :value="item" @input="() => handleEditOption(false)"
          :editable="editable" :type="type" :editing="editing"></t-remote-editable-option>
        <span v-else>{{ item.text }}</span>
      </el-option>
    </draggable>
    <el-option v-if="editable != null && editing" :class="{ editing, 'form-reset': editing }" :value="'+++'">
      <t-remote-editable-option :value="{ text: '', value: null }" :editable="editable" :type="type"
        @input="() => handleEditOption(true)" editing :placeholder="$t('commands.plus')"
        :lastItemId="filteredItems.slice(-1)[0].value"></t-remote-editable-option>
    </el-option>
  </el-select>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IListResult } from 'rt/UIApiControllers/Controls/IListResult';
import { ListsType } from 'rt/Core/ListsType';
import TRemoteEditableOption from './RemoteEditableOption.vue';
import move from 'lodash-move';

// tslint:disable-next-line:variable-name
const ListsAction = namespace('lists').Action;
// tslint:disable-next-line:variable-name
const ListsGetter = namespace('lists').Getter;
@Component({
  name: 't-remote-select',
  components: {
    TRemoteEditableOption,
  },
  props: {
    value: {
      type: [Number, String, Array],
      required: false,
      default: null,
    },
    type: {
      type: Number,
      required: true,
    },
    captionValue: {
      type: [Number, String],
      required: false,
      default: null,
    },
    captionText: {
      type: String,
      required: false,
      default: null,
    },
    filter: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Object,
      required: false,
      default: null,
    },
  },
})
export default class RemoteSelect extends Vue {
  @ListsGetter
  list: (type: ListsType) => Array<IInt64ListResult | IListResult>;

  @ListsAction
  load: (type: ListsType) => Promise<any>;

  @ListsAction
  refresh: (type: ListsType) => Promise<any>;

  type: ListsType;

  captionValue: any;

  captionText: string;

  value: any;

  multiple: boolean;

  newItems: Array<any> = [];

  loading = false;

  disabled: boolean;

  dropdown: boolean;

  clearable: boolean;

  editable: { type: number; propertyName: string; orderPropertyName: string; delete: boolean };

  editing = false;

  filter: (items: IInt64ListResult | IListResult) => boolean;

  get filteredItems(): Array<IInt64ListResult | IListResult> {
    if (this.filter != null) {
      return this.newItems.filter(this.filter);
    }
    return this.newItems;
  }

  async mounted() {
    await this.load(this.type);
    this.loadItems();
    this.loading = false;
  }

  loadItems() {
    const items = [...this.list(this.type)];
    if (this.captionValue != null && !this.multiple) {
      if (!items.some((item) => item.value === this.captionValue)) {
        items.unshift({
          value: this.captionValue,
          text: this.captionText,
          infoData: null,
        });
      }
    }
    this.newItems = items;
  }

  change(value) {
    if (this.editing) {
      return;
    }
    this.$emit('input', value);
  }

  async handleEditOption(isNew = false) {
    if (isNew) {
      this.sortedItems = null;
    }
    await this.refresh(this.type);
    await this.load(this.type);
    this.loadItems();
  }

  startEditing() {
    this.editing = !this.editing;
  }

  sortedItems: [{ id: number }] = null;

  async handleComplete({ moved }: { moved: { newIndex: number; oldIndex: number } }) {
    const type = this.$enums.BusinessObjectType[this.editable.type];
    if (this.sortedItems === null) {
      const items = this.filteredItems;
      const ids = items.map((v) => v.value);
      this.sortedItems = await this.$http.post(`${type}/MultipleGet`, { iDs: ids, select: ['id', this.editable.orderPropertyName] }).then((res) => res.data.map((v) => v.item));
    }
    this.sortedItems = move(this.sortedItems, moved.oldIndex, moved.newIndex);
    this.newItems = move(this.newItems, moved.oldIndex, moved.newIndex);
    const promises = [];
    for (let i = 0; i < this.sortedItems.length; i += 1) {
      const si = this.sortedItems[i];
      if (si && si[this.editable.orderPropertyName] !== i) {
        promises.push(
          this.$http.put(type, {
            id: si.id,
            [this.editable.orderPropertyName]: i,
          }),
        );
        si[this.editable.orderPropertyName] = i;
      }
    }
    await Promise.all(promises);
    await this.refresh(this.type);
    await this.load(this.type);
    this.loadItems();
  }
}
</script>
<style lang="scss" scoped>
.editing {
  padding-left: 4px;
  padding-right: 4px;
}
</style>
