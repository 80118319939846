//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ResolvingCrossType {
	DDTCause = 0,
	DDTPorto = 1,
	DDTAppearance = 2,
	PaymentList = 3,
	InvoiceBank = 4,
	CatalogPriceListDescription = 5,
	MovementsCode = 6
}
