import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { VNode } from 'vue/types/umd';

export default () => {
  Vue.directive('min-height', {
    update: (el) => {
      if (el.style && el.parentElement) {
        el.style.minHeight = null;
        el.style.minHeight = `${el.parentElement.offsetHeight}px`;
      }
    },
  });
  Vue.directive('fixed-width', {
    bind: (el) => {
      if (el.style && el.parentElement) {
        el.style.width = `${el.parentElement.offsetWidth}px`;
      }
    },
    unbind: (el) => {
      if (el.style) {
        el.style.width = null;
      }
    },
  });
  Vue.directive('indexed', {
    bind: (el: HTMLElement, binding: { value?: string }, vnode: VNode) => {
      if (vnode.context != null) {
        const key = Vue.indexer.generate(vnode.context);
        if (key) {
          let suffixValue = binding.value ?? '';
          if (binding.value) {
            suffixValue = `-${binding.value.replace(/([^a-zA-Z0-9\-\.\_])/gi, '-')}`;
          }
          el.setAttribute('data-idx', key + suffixValue);
        }
      }
    },
    unbind: (el: HTMLElement, binding: { value?: string }, vnode: VNode) => {},
  });
};
