//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum EMailNormalizeType {
	Custom = 0,
	MailingList = 1,
	Invoice = 2,
	Certified = 3,
	Ticket = 4,
	Group = 5
}
