<template>
  <transition name="el-zoom-in-center">
    <t-circle-button
      v-if="dirty"
      :icon="icon"
      :type="$viewport.smAndUp ? '' : 'primary'"
      :size="$viewport.smAndUp ? 'small' : 'medium'"
      :loading="loading"
      @click="save"
      class="float-save-button"
      :class="`in-${position} ${(calling ? 'calling': '')}`"
      v-indexed
      pulse
    ></t-circle-button>
  </transition>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';

// tslint:disable-next-line:variable-name
const PhoneBarGetter = namespace('phone-bar').Getter;
@Component({
  name: 'TFloatSaveButton',
})
export default class TFloatSaveButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  dirty: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-floppy-disk',
  })
  icon: string;

  @Prop({
    type: String,
    required: false,
    default: 'bar',
  })
  position: string;

  @PhoneBarGetter calling: boolean;

  save() {
    this.$emit('save');
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.float-save-button {
  position: fixed !important;
  z-index: 10 !important;
  &.in-page {
    bottom: 14px;
    right: 90px;
    &.calling {
      bottom: 36px + 14px;
    }
  }
  &.in-bar {
    bottom: 8px;
    right: 8px;
    &.calling {
      bottom: 36px + 8px;
    }
  }
}
</style>
