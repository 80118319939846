//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IMailTemplateDTO } from '../../../DTO/IMailTemplateDTO';
import { ILiquidStruct } from '../../../Base/Utility/ILiquidStruct';

//element.Name=MailTemplateController result.Name=MailTemplateController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.MailTemplateController);
/** Result of WebApiControllerGenerator activity */
export class MailTemplateController extends DefaultDtoControllerProxy<IMailTemplateDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'MailTemplates', BusinessObjectType.MailTemplate);
	}
	public SendTestingEMail(dto: IMailTemplateDTO, address: string, crossType: BusinessObjectType, crossId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('MailTemplates/SendTestingEMail?'+((address === null || address === undefined) ? '' : ('&address='+encodeURIComponent(address)))+'&crossType='+crossType+'&crossId='+crossId, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(dto: IMailTemplateDTO, sanitize: boolean = true, mergeItemType?: BusinessObjectType, mergeItemId?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('MailTemplates/Preview?'+'&sanitize='+sanitize+((mergeItemType === null || mergeItemType === undefined) ? '' : ('&mergeItemType='+mergeItemType))+((mergeItemId === null || mergeItemId === undefined) ? '' : ('&mergeItemId='+mergeItemId)), dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GeneratePlainBody(dto: IMailTemplateDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('MailTemplates/GeneratePlainBody', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Properties(name: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('MailTemplates/Properties?'+((name === null || name === undefined) ? '' : ('&name='+encodeURIComponent(name))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EmptyStructure(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('MailTemplates/EmptyStructure', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Fonts(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('MailTemplates/Fonts', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Fields(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ILiquidStruct[]>
	{
		return (this.axios.post('MailTemplates/Fields', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SignaturePreview(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('MailTemplates/SignaturePreview', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Clone(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('MailTemplates/Clone?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMailTemplateDTO>
	{
		return (this.axios.get('MailTemplates/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
