//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TustenaColorTemplates {
	Classic = 0,
	Acqua = 1,
	Metal = 2,
	Fashion = 3,
	Blue = 4,
	Green = 5,
	Cherry = 6,
	Khaki = 7,
	Polyedro = 8,
	AcidGreen = 9,
	UltraMarine = 10,
	Citrus = 11,
	Pink = 12,
	Raspberry = 13,
	AshGray = 14,
	Polyedro2 = 15
}
