//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TypeOfMassive {
	SalesPerson = 0,
	Refer = 1,
	Signaler = 2,
	Manager = 3,
	Reports = 4,
	QuoteStage = 5,
	Sync = 6,
	DND = 7,
	Group = 8,
	Merge = 9,
	MLFlag = 10,
	Category = 11,
	Owner = 12,
	OpenTicket = 13,
	CommercialZone = 14,
	LeadStatus = 15,
	LeadRating = 16,
	LeadProductInterest = 17,
	ConvertLeadToCompany = 18,
	ActivityStatus = 19,
	ActivityInOut = 20,
	ActivityClassification = 21,
	ActivityToBill = 22,
	ActivityCommercial = 23,
	ActivityTechnical = 24,
	RecalcCost = 25,
	TicketWorkGroup = 26,
	TicketArea = 27,
	TicketType = 28,
	TicketAssignedTo = 29,
	TicketPriority = 30,
	TicketStatus = 31,
	FreeFields = 32,
	Country = 33,
	Workgroup = 34,
	Source = 35,
	Rating = 36,
	Payment = 37,
	PaymentMode = 38,
	PriceList = 39,
	CompanyType = 40,
	CompanySect = 41,
	IsPaid = 42,
	Appearance = 43,
	Porto = 44,
	Carrier = 45,
	OrderStage = 46,
	ShippedBy = 47,
	Currency = 48,
	LeadArea = 49,
	LeadPriority = 50,
	JobOrderTaskStatus = 51,
	JobOrderTaskType = 52,
	JobOrderTaskWorkGroup = 53,
	JobOrderTaskOwnerId = 54,
	OpportunityStatus = 55,
	OpportunityCategory = 56,
	OpportunityPhase = 57,
	OpportunityAmount = 58,
	OpportunityBudget = 59,
	OpportunityProbability = 60,
	OpportunityOwner = 61,
	OpportunitySalesPerson = 62,
	OpportunityCloseDate = 63,
	ContractTypeOfServiceCatalogId = 64,
	ContractTypeOfServiceDescription = 65,
	ContractTypeOfServiceQty = 66,
	ContractTypeOfServiceFirstactivation = 67,
	ContractTypeOfServiceTermination = 68,
	ContractTypeOfServiceStartDate = 69,
	ContractTypeOfServiceEndDate = 70,
	ContractTypeOfServiceCancellation = 71,
	ContractTypeOfServiceCrossCompanyId = 72,
	ContractTypeOfServiceRenew = 73,
	ContractTypeOfServiceRenewMonth = 74,
	ContractTypeOfServiceContractDaysCancellation = 75,
	ContractTypeOfServiceTypeValue = 76,
	ContractTypeOfServiceTypeOfInstalmentValue = 77,
	ContractTypeOfServiceSubscriptionPriceValue = 78,
	ContractTypeOfServiceTaxCodeValue = 79,
	ContractTypeOfServiceStatusValue = 80,
	Privacy = 81,
	Tags = 82,
	BusinessRole = 83,
	CatalogTaxCode = 84,
	CatalogPrintDescription = 85,
	CatalogPriceExpire = 86,
	CatalogLifeMonth = 87,
	CatalogSeller = 88,
	CatalogRenewable = 89,
	CatalogPublish = 90,
	CatalogActive = 91,
	CatalogCategory = 92,
	CatalogMaxDiscount = 93
}
