<template>
  <el-row type="flex" class="h-100" justify="center" align="middle">
    <el-col :xs="20" :sm="12" :md="8" :lg="4" :xl="3">
      <el-card class="box-card">
        <template #header>
          <div class="clearfix">
            <span>
              <transition mode="out-in" name="el-zoom-in-center">
                <img alt="logo" :src="`/${brand.id}/img/logo.svg`" v-if="companyPhoto == null" key="defaultPhoto"
                  width="100%" />
                <img alt="photo" :src="companyPhoto" class="companyPhoto" v-else key="companyPhoto" />
              </transition>
            </span>
          </div>
        </template>
        <div v-loading="true" class="m-b" style="min-height: 40px" :element-loading-text="$t(`loading.${splashMessage}`)">
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { IMenuNode } from 'rt/Interfaces/Misc/IMenuNode';
import { RawLocation } from 'vue-router';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'Init',
})
export default class Init extends Vue {
  @Getter user: IUser;

  @Getter menu: IMenuNode[];

  @Getter brand: string;

  @Getter initialized: boolean;

  @Getter splashMessage: string;

  @Watch('initialized')
  handleInitializedChange(newVal: boolean) {
    if (newVal) {
      this.goToFirstRoutePage();
    }
  }

  mounted() {
    if (this.initialized) this.goToFirstRoutePage();
  }

  get companyPhoto(): string {
    if (this.user) {
      return this.user.companyPhoto;
    }
    return null;
  }

  goToFirstRoutePage() {
    if (this.$route.query.reenton) {
      const query = { ...this.$route.query };
      delete query.reenton;
      this.$router.replace({
        query,
        path: this.$route.query.reenton as string,
      });
    } else {
      this.$router.replace(this.findFirstRoute(this.menu));
    }
  }

  findFirstRoute(items: IMenuNode[]): RawLocation {
    if (items) {
      for (const item of items) {
        if (item.subItems) {
          for (const subItem of item.subItems) {
            if (subItem.route) {
              return { name: subItem.route };
            }
          }
        }
      }
    }
    return { name: 'notFound' };
  }
}
</script>
<style lang="scss" scoped>
.companyPhoto {
  max-width: 100%;
}
</style>
