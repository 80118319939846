//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DebtRecoveryPhaseCommunication {
	Call = 0,
	Email = 1,
	Letter = 2,
	Pec = 3,
	LetterOrPec = 4
}
