<template>
  <a :href="schema.location" :target="schema.target">
    <el-dropdown-item v-if="schema.dropdown">
      <t-icon-label :icon="`el-icon-${schema.icon}`">{{ schema.text }}</t-icon-label>
    </el-dropdown-item>
    <t-button type="primary" :icon="`el-icon-${schema.icon}`" v-else>{{ schema.text }}</t-button>
  </a>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IButton } from 'rt/Interfaces/UI/IButton';

@Component({
  name: 'RedirectButton',
})
export default class RedirectButton extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: IButton;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;
}
</script>
