import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { RootState } from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

Vue.use(Vuex); // TODO: vedere come spostarlo in initializers;

const store = new Store({
  getters,
  actions,
  mutations,
  state: new RootState(),
  strict: process.env.NODE_ENV !== 'production',
});
export default store;
