//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../Core/BusinessObjectType';
import { HelperClassType } from '../../Core/HelperClassType';

//element.Name=OAuth2Controller result.Name=OAuth2Controller element.FullName=Digita.Tustena.UIApiControllers.Authentication.OAuth2Controller);
/** Result of WebApiControllerGenerator activity */
export class OAuth2Controller
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in OAuth2Controller constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public GetLoginLinkUri(identifier: string, serviceType: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('OAuth2/GetLoginLinkUri?'+((identifier === null || identifier === undefined) ? '' : ('&identifier='+encodeURIComponent(identifier)))+((serviceType === null || serviceType === undefined) ? '' : ('&serviceType='+encodeURIComponent(serviceType))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetLoginLinkUriToRefreshTokenAccess(identifier: string, businessObjectId: number, serviceType: string, businessObjectType?: BusinessObjectType, davSettings?: HelperClassType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('OAuth2/GetLoginLinkUriToRefreshTokenAccess?'+((identifier === null || identifier === undefined) ? '' : ('&identifier='+encodeURIComponent(identifier)))+((businessObjectId === null || businessObjectId === undefined) ? '' : ('&businessObjectId='+businessObjectId))+((serviceType === null || serviceType === undefined) ? '' : ('&serviceType='+encodeURIComponent(serviceType)))+((businessObjectType === null || businessObjectType === undefined) ? '' : ('&businessObjectType='+businessObjectType))+((davSettings === null || davSettings === undefined) ? '' : ('&davSettings='+davSettings)), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Callback(admin_consent?: boolean, tenant?: string, code?: string, state?: string, error?: string, error_description?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.get('OAuth2/Callback?'+((admin_consent === null || admin_consent === undefined) ? '' : ('&admin_consent='+admin_consent))+((tenant === null || tenant === undefined) ? '' : ('&tenant='+encodeURIComponent(tenant)))+((code === null || code === undefined) ? '' : ('&code='+encodeURIComponent(code)))+((state === null || state === undefined) ? '' : ('&state='+encodeURIComponent(state)))+((error === null || error === undefined) ? '' : ('&error='+encodeURIComponent(error)))+((error_description === null || error_description === undefined) ? '' : ('&error_description='+encodeURIComponent(error_description))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('OAuth2/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('OAuth2/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
