const sideBarParameters = {
  placement: 'left-start',
  modifiers: {
    arrow: {
      enabled: false,
    },
    preventOverflow: {
      enabled: false,
      boundariesElement: 'viewport',
    },
    hide: {
      enabled: false,
    },
    offset: {
      offset: '0,8',
    },
  },
  onCreate: (popper) => {
    popper.instance.state.updateBound();
    const arrow = document.querySelector('.v-popper__arrow');
    if (arrow) {
      (arrow as any).style.top = 0;
    }
  },
};

const sideBarBottomParameters = {
  placement: 'left-end',
  onCreate: (popper) => {
    popper.instance.state.updateBound();
    const arrow = document.querySelector('.v-popper__arrow');
    if (arrow) {
      (arrow as any).style.top = 'calc(100% - 2em)';
    }
  },
};

const saveButtonTour = {
  target: '.float-save-button', // We're using document.querySelector() under the hood
  content: 'tour.commands.save',
  params: { ...sideBarBottomParameters },
};
const gridTours = [
  {
    target: '.tustena-grid thead th .el-icon-icon-filter3',
    content: 'tour.grid.filters',
    params: {
      placement: 'bottom',
    },
  },
  {
    target: '.tustena-grid .info-bar .what-search',
    content: 'tour.grid.advancedSearch',
    params: {
      placement: 'bottom-start',
    },
  },
  {
    target: '.tustena-grid .info-bar .schemaSelector',
    content: 'tour.grid.schema',
    params: {
      placement: 'top',
    },
  },
  {
    target: '.tustena-grid .commands-bar button.more',
    content: 'tour.grid.commands',
    params: {
      placement: 'top',
    },
  },
];

export default {
  today: [
    {
      target: '.el-aside .el-menu.el-menu-vertical li',
      content: 'tour.today.menu',
      tip: 'tour.today.menutip',
      params: {
        placement: 'left-start',
        onCreate: (popper) => {
          const arrow = document.querySelector('.v-popper__arrow');
          if (arrow) {
            (arrow as any).style.top = 0;
          }
        },
      },
    },
    {
      target: '.bside .commands .profile span.user-image',
      content: 'tour.today.profile',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .mail button',
      content: 'tour.today.mail',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .messages button',
      content: 'tour.today.messages',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .search button',
      content: 'tour.today.search',
      tip: 'tour.today.searchtip',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .spooler .spooler-button button',
      content: 'tour.today.spooler',
      tip: 'tour.today.spoolertip',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .tour button',
      content: 'tour.today.tour',
      tip: 'tour.today.tourtip',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .profile.el-row span.user-image',
      content: 'tour.today.suggestion',
      tip: 'tour.today.suggestiontip',
      params: { ...sideBarParameters },
    },
    {
      target: '.bside .commands .exit button.el-popover__reference',
      content: 'tour.today.exit',
      tip: 'tour.today.exittip',
      params: { ...sideBarParameters },
    },
  ],
  companies: [...gridTours],
  opportunities: [
    ...gridTours.slice(0, 3),
    {
      target: '.tustena-grid .commands-bar .stats',
      content: 'tour.opportunities.stats',
      params: {
        placement: 'bottom',
      },
    },
    {
      target: '.tustena-grid .commands-bar .kanban',
      content: 'tour.opportunities.kanban',
      params: {
        placement: 'bottom',
      },
    },
    ...gridTours.slice(3),
  ],
  company: [
    {
      target: 'label[for="CompanyName"] ~ div input', // We're using document.querySelector() under the hood
      content: 'tour.company.companyName',
      params: {
        placement: 'auto',
      },
    },
    {
      target: '.avatar-uploader-icon',
      content: 'tour.company.image',
    },
    { ...saveButtonTour },
  ],
  lead: [
    {
      target: '.breadcrumb.states',
      content: 'tour.lead.states',
    },
    { ...saveButtonTour },
  ],
  opportunity: [
    {
      target: '#tab-opportunity',
      content: 'tour.opportunity.opportunity',
    },
    {
      target: '.breadcrumb.phases',
      content: 'tour.opportunity.phases',
    },
    {
      target: '#tab-opportunityActivities',
      content: 'tour.opportunity.activities',
    },
    {
      target: '#tab-opportunityReferrers',
      content: 'tour.opportunity.referrers',
    },
    {
      target: '#tab-opportunityStorage',
      content: 'tour.opportunity.files',
    },
    {
      target: '#tab-opportunityMessages',
      content: 'tour.opportunity.messages',
    },
    { ...saveButtonTour },
  ],
  calendar: [
    {
      target: '.calendar-props .el-col',
      content: 'tour.calendar.properties',
      tip: 'tour.calendar.propertiestip',
      params: {
        placement: 'left',
      },
    },
    {
      target: '.fc-toolbar .fc-button-group .fc-month-button',
      content: 'tour.calendar.views',
      params: {
        placement: 'left',
      },
    },
    {
      target: '.fc-event',
      content: 'tour.calendar.event',
    },
    {
      target: '.fc-today .fc-day-number',
      content: 'tour.calendar.create',
    },
  ],
  appointment: [
    {
      target: '#tab-appointment',
      content: 'tour.appointment.appointment',
    },
    {
      target: '.fc-event:not(.ghost)',
      content: 'tour.appointment.timeline',
      tip: 'tour.appointment.timelinetip',
      params: {
        placement: 'top-end',
      },
    },
    {
      target: 'label[for="Location"] ~ div input',
      content: 'tour.appointment.location',
      params: {
        placement: 'left',
      },
    },
    {
      target: '#tab-appointmentWayPoint',
      content: 'tour.appointment.waypoints',
    },
  ],
  default: [],
};
