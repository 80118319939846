//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContractServicesStatus {
	PendingApproval = 0,
	FirstCheck = 1,
	SecondCheck = 2,
	Active = 3,
	BreakOff = 4,
	Suspend = 5,
	Ended = 6,
	Rejected = 7
}
