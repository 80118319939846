//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TicketGrabberLogEvent {
	TicketCreated = 200,
	EMailMoved = 202,
	AnonumousTicketCreated = 203,
	CannotCreateMultipleTicket = 205,
	NotificationSended = 206,
	TicketReplyCreated = 207,
	MultipleMatchTicket = 208,
	CannotCreateTicket = 209,
	CannotReplyTicket = 210,
	EMailStorageFolderNotSetted = 301,
	TicketReplyFailed = 302,
	NoAccountSelected = 400,
	NoConfigurationLoaded = 401,
	CannotCreateAnonumousTicket = 402,
	GenericError = 404,
	ConnectionError = 405,
	PasswordError = 406,
	LoginError = 407
}
