<template>
  <t-field-header
    v-bind="$props"
    :value="value"
    :operator="operator"
    @operator-change="changeOperatorFilter"
    :valid="isValidFilter"
    @sort-change="v => $emit('sort-change', v)"
  >
    <el-select
      v-model="value"
      style="width: 100%"
      @change="changeFilter"
      clearable
      filterable
      :disabled="isValidOperationFilter"
    >
      <el-option
        v-for="option in column.filterSelectOptions"
        :key="option.key"
        :label="option.value"
        :value="option.key"
      ></el-option>
    </el-select>
  </t-field-header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'SelectFieldHeader',
})
export default class SelectFieldHeader extends HeaderMixin {
  get isValidValueFilter() {
    if (this.value != null) {
      if (this.isNumericOptions) {
        if (this.column.filterSelectOptions.some((s) => s.key === this.value)) {
          return true;
        }
        return this.value > 0;
      }
      return this.value.length > 0;
    }
    return false;
  }

  get isNumericOptions() {
    return !this.column.filterSelectOptions.some((s) => isNaN(s.key));
  }
}
</script>
