import Vue from 'vue';
import Component from 'vue-class-component';
import { IOpportunityDTO } from 'dto/IOpportunityDTO';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import BusinessObjectPageMixin from '@/modules/mixin/views/BusinessObjectPageMixin';
import eventHubManager from '@/modules/mixin/views/EventHub';
import { Getter, Mutation, namespace } from 'vuex-class';

// tslint:disable-next-line:variable-name
const OpportunityGetter = namespace('opportunity').Getter;
// tslint:disable-next-line:variable-name
const OpportunityMutation = namespace('opportunity').Mutation;

@Component({
  name: 'OpportunityViewMixin',
})
export default class OpportunityViewMixin extends Vue {
  @OpportunityGetter model: IOpportunityDTO;

  get eventHub(): Vue {
    return eventHubManager.getHub('opportunity');
  }

  get module(): string {
    return 'opportunity';
  }

  @OpportunityMutation setModel: (dto: IOpportunityDTO) => void;

  @OpportunityMutation setModelProperty: (n: string, v: any) => void;

  get activityDefaults() {
    if (!this.model) {
      return null;
    }
    return {
      companyId: this.model.crossType === this.$enums.BusinessObjectType.Company ? this.model.crossId : 0,
      contactId: this.model.crossType === this.$enums.BusinessObjectType.Contact ? this.model.crossId : 0,
      leadId: this.model.crossType === this.$enums.BusinessObjectType.Lead ? this.model.crossId : 0,
      opportunityId: this.model.id,
    };
  }
}
