//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContactLoadPerformanceSettings {
	All = 1,
	Minimal = 2,
	LoadSync = 4,
	LoadFreeFields = 8,
	LoadPrivacy = 16,
	LoadMailingListRights = 32,
	LoadPhoto = 64,
	LoadRelations = 128,
	LoadCityAddressNormalizer = 256,
	LoadSharing = 512,
	LoadSalesPersons = 1024,
	LoadNormalizeEMail = 2048,
	LoadCategories = 4096,
	LoadOtherCompanies = 8192,
	LoadNormalizePhone = 16384,
	LoadPortalAccount = 32768,
	LoadGeo = 65536
}
