import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';
import { IStorageDTO } from 'dto/IStorageDTO';
import { StorageController } from 'rt/UIApiControllers/BusinessObject/DTO/StorageController';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { IStorageCategoryDTO } from 'dto/IStorageCategoryDTO';
import { StorageCategoryController } from 'rt/UIApiControllers/BusinessObject/DTO/StorageCategoryController';
import { Prop, Watch } from 'vue-property-decorator';
import approx from 'approximate-number';
import _ from 'lodash';
import urlParse from 'url-parse';
import { IStorageItem, buildCategoriesPaths } from './storage';

// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;
// tslint:disable-next-line:variable-name
const StorageAction = namespace('storage').Action;

@Component({
  name: 'StorageBrowserMixin',
})
export default class StorageBrowserMixin extends Vue {
  @Getter baseUrl: string;

  @Getter user: IUser;

  @StorageGetter controller: StorageController;

  @StorageGetter sizeLimit;

  @StorageGetter categories: IStorageCategoryDTO[];

  @StorageGetter categoriesController: StorageCategoryController;

  @StorageAction loadCategories: (force?: boolean) => Promise<any>;

  @Prop({ type: String, default: 'download' })
  defaultFileAction: string;

  @Prop({ type: Boolean, default: true })
  enableUpload: boolean;

  files: IStorageDTO[] = [];

  categoryId: number;

  @Watch('categoryId')
  handleCategoryIdChange() {
    this.browseDirectory();
  }

  async beforeMount() {
    await this.loadCategories();
  }

  async mounted() {
    await this.reset();
  }

  async browseDirectory() {
    this.reset();
  }

  get currentData(): IStorageItem[] {
    return [...this.files.map(this.fromStorageDtoToGridDate)];
  }

  protected fromStorageDtoToGridDate(f: IStorageDTO): IStorageItem {
    return {
      type: 'file',
      id: f.id,
      category: f.type,
      description: f.fileName,
      lastModifiedById: f.lastModifiedById,
      lastModifiedDate: f.lastModifiedDate,
      size: approx(f.size, { capital: true, suffix: 'b' }),
      icon: `el-icon-${f.icon}`,
    };
  }

  // upload region
  uploadFileList: any[] = [];

  handleBeforeUpload(file) {
    const isLt10M = file.size < this.sizeLimit;
    if (!isLt10M) {
      this.$alert(
        this.$t('storage.error.limit', {
          file: file.name,
          limit: approx(this.sizeLimit, {
            suffix: 'b',
          }),
        }) as string,
        {
          type: 'error',
        },
      );
    } else {
      this.uploadFileList = [...this.uploadFileList, file];
    }
    return isLt10M;
  }

  protected async buildUploadStorageDTO(fr: { fileGuid: string; fileName: string }): Promise<any> {
    return {
      attachments: [fr],
      // Type: this.categoryId,
      description: '',
    };
  }

  async getStorageDtoAfterUpload(id: number): Promise<IStorageDTO> {
    return await this.controller.Get(id);
  }

  uploadChain: Promise<void> = new Promise((resolve, reject) => {
    try {
      resolve();
    } catch (e) {
      reject(e);
    }
  });

  async handleSuccess(fr: { fileGuid: string; fileName: string }, file, fileList): Promise<any> {
    if (fr) {
      this.uploadChain = this.uploadChain.then(async () => {
        try {
          const res = await this.controller.UploadMultipleFiles(await this.buildUploadStorageDTO(fr));
          if (res && res.length) {
            const id = res[0];
            const file = await this.getStorageDtoAfterUpload(id);
            this.files.push(file);
          }
        } catch {
        } finally {
          this.uploadFileList = this.uploadFileList.filter((f) => f.uid !== file.uid);
        }
      });
    }
  }

  async handleError(err: { message: string }, fr: { fileGuid: string; fileName: string }, file, fileList): Promise<any> {
    this.$error.handleWebApiException({ status: 400, data: JSON.parse(err.message) });
  }

  showDetail = false;

  detailFile: IStorageDTO = null;

  async handleFileCommand(command: string, file: IStorageDTO) {
    switch (command) {
      case 'download':
        await this.download(file);
        break;
      case 'delete':
        this.deleteFile(file);
        break;
      case 'rename':
        this.renameFile(file);
        break;
      case 'details':
        this.detailFile = this.files.find((f) => f.id === file.id);
        if (this.detailFile != null) {
          this.showDetail = true;
        }
        break;
    }
  }

  private getFileNameFromUrl(url: string) {
    const downloadUrl = urlParse(url);
    const name = downloadUrl.pathname.substring(downloadUrl.pathname.lastIndexOf('/') + 1);
    return name;
  }

  async download(file: IStorageDTO) {
    const res = await this.controller.GetDownloadUrl(file.id);
    this.$http
      .get(res, <any>{
        _noAuth: true,
        responseType: 'blob', // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.download = this.getFileNameFromUrl(res);
        document.body.appendChild(link);
        link.click();
      });
  }

  deleteFile(file) {
    this.$confirm(this.$t('commands.deleteConfirm') as string, {
      type: 'warning',
    })
      .then(async (v) => {
        if (await this.controller.Delete(file.id)) {
          const files = [...this.files];
          _.remove(files, (f) => f.id === file.id);
          this.files = files;
        } else {
          this.$alert(this.$t('storage.error.delete') as string, '', {
            type: 'error',
          });
        }
      })
      .catch(() => {});
  }

  renameFile(file) {
    this.$prompt(this.$t('storage.commands.rename') as string, '', {
      inputValue: file.description,
    })
      .then(async (v: any) => {
        if (v.value) {
          const dto = await this.controller.Get(file.id);
          dto.fileName = v.value;
          if (await this.controller.CreateOrUpdate(dto)) {
            const idx = this.files.findIndex((f) => f.id === file.id);
            Vue.set(this.files, idx, await this.controller.Get(file.id));
          }
        }
      })
      .catch(() => {});
  }

  busy = false;

  endReached = false;

  page = 1;

  pageSize = 20;

  protected async reset(): Promise<void> {
    this.page = 1;
    this.files = [];
    this.loadingVisible = true;
    this.busy = false;
    this.endReached = false;
    this.uploadFileList = [];
    return await this.loadDocuments();
  }

  loadingVisible = true;

  async loadingVisibilityChanged(visible: boolean): Promise<void> {
    this.loadingVisible = visible;
    if (visible && !this.busy && !this.endReached) {
      await this.loadDocuments();
    }
  }

  private async loadDocuments(): Promise<void> {
    if (!this.loadingVisible) {
      return;
    }
    this.busy = true;
    const files = await this.loadFiles(this.page, this.pageSize);
    if (files && files.length) {
      this.files = [...this.files, ...files];
      this.page += 1;
      this.busy = false;
      await this.loadDocuments();
    } else this.endReached = true;
    this.$emit('updated');
  }

  protected async loadFiles(page: number, pageSize: number): Promise<IStorageDTO[]> {
    throw new Error('Load files not abstracted');
  }

  get breadcumCategories(): IStorageCategoryDTO[] {
    return buildCategoriesPaths(this.categories, this.categoryId);
  }

  get currentCategories(): IStorageCategoryDTO[] {
    if (!this.categories) {
      return [];
    }
    return this.categories.filter((c) => c.parentId === this.categoryId);
  }
}
