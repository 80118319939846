//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LeadConversionType {
	ToCompany = 0,
	ToCompanyWithContact = 1,
	ToContact = 2,
	ToContactLinkedToCompany = 3,
	ToCompanyNaturalPerson = 4
}
