<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import eventHubManager from 'modules/mixin/views/EventHub';
import LeadLayoutStudio from './LeadLayoutStudio.vue';

// tslint:disable-next-line:variable-name
const LeadAction = namespace('lead').Action;

@Component({
  name: 'LeadFastLayoutStudio',
})
export default class LeadFastLayoutStudio extends LeadLayoutStudio {
  @LeadAction('schema') loadSchema: (suffix: string) => Promise<any>;

  @LeadAction('loadNew') loadNew: (id: number) => Promise<any>;

  @LeadAction('reset') reset: () => Promise<any>;

  @Prop({
    type: Object,
    required: false,
  })
  defaults: any;

  async mounted() {
    await this.reset();
    await this.loadSchema(null);
    const def = this.defaults;
    await this.loadNew(this.defaults);
  }

  get eventHub(): Vue {
    return eventHubManager.getHub('lead.fast');
  }
}
</script>
