<template>
  <t-form-item :label="schema.text || schema.id" :prop="schema.propertyName" :required="schema.required" key="input"
    :important="schema.important">
    <el-tag size="medium" :key="tag" v-for="tag in value" closable :disable-transitions="false"
      @close="handleClose(tag)">{{ tag }}</el-tag>
    <el-input class="input-new-tag m-l-h" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput">+</el-button>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'TagsInput',
  mixins: [simpleMixin],
})
export default class TagsInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: false,
    default() {
      return [];
    },
  })
  value: string[];

  inputVisible = false;

  inputValue = '';

  handleClose(tag) {
    const v = [...this.value];
    v.splice(this.value.indexOf(tag), 1);
    this.$emit('input', v);
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).focus();
    });
  }

  handleInputConfirm() {
    const { inputValue } = this;
    if (inputValue) {
      this.$emit('input', [...this.value, inputValue]);
    }
    this.inputVisible = false;
    this.inputValue = '';
  }
}
</script>
<style lang="scss" scoped>
.el-tag {
  &+.el-tag {
    margin-left: 10px;
  }

  &+.input-new-alias {
    margin-left: 10px;
  }

  &+.button-new-tag {
    margin-left: 10px;
  }
}

.input-new-tag {
  width: 90px;
  vertical-align: bottom;

  & input {
    min-width: 90px;
  }
}
</style>
