import { GetterTree } from 'vuex';
import { BusinessObjectState } from './boState';
import { RootState } from '@/store/state';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import DefaultDtoControllerProxy from 'rt/UIApiControllers/BusinessObject/DTO/DefaultDtoControllerProxy';
import difference from 'utils/difference';
import _ from 'lodash';
import { ILayoutStudioSlot } from 'rt/Interfaces/UI/ILayoutStudioSlot';
import { ICometMassiveUpdateMessage } from 'rt/SignalR/Messages/ICometMassiveUpdateMessage';
import { ILiquidStruct } from 'rt/Base/Utility/ILiquidStruct';

export function mapSchemaRecursive(schema: IWidgetPage): ILayoutStudioSlot[] {
  const slots = [];
  if (schema && schema.tab && schema.tab.tabs) {
    for (const tab of schema.tab.tabs) {
      if (tab.layoutStudioSchema && tab.layoutStudioSchema.sections) {
        for (const section of tab.layoutStudioSchema.sections) {
          if (section.columns) {
            slots.push(_.flatMapDeep(section.columns));
          }
        }
      }
    }
  }
  return _.flatMapDeep(slots);
}

export class BusinessObjectGetters<BO extends { id: number }, S extends IWidgetPage, C extends DefaultDtoControllerProxy<BO>> implements GetterTree<BusinessObjectState<BO, S, C>, RootState> {
  [key: string]: (state: BusinessObjectState<BO, S, C>, getters: any, rootState: RootState, rootGetters: any) => any;

  model = (state: BusinessObjectState<BO, S, C>): BO => state.model;

  originalModel = (state: BusinessObjectState<BO, S, C>): BO => state.originalModel;

  modelContext = (state: BusinessObjectState<BO, S, C>): { [key: string]: any } => state.modelContext;

  schemes = (state: BusinessObjectState<BO, S, C>): { [key: string]: S } => state.schemes;

  schema = (state: BusinessObjectState<BO, S, C>): S => state.schemes['default'];

  flattenSchema = (state: BusinessObjectState<BO, S, C>): ILayoutStudioSlot[] => {
    const schema = state.schemes['default'];
    if (schema) {
      return mapSchemaRecursive(schema);
    }
    return null;
  };

  requiredSlots = (state: BusinessObjectState<BO, S, C>): any[] => {
    for (const schemaKey in state.schemes) {
      const schema = <any>state.schemes[schemaKey];
      if (Object.prototype.hasOwnProperty.call(schema, 'requiredSlots') && schema.requiredSlots) {
        return schema.requiredSlots;
      }
    }
    return null;
  };

  controller = (state: BusinessObjectState<BO, S, C>): C => state.controller;

  dirty = (state: BusinessObjectState<BO, S, C>): boolean => !_.isEqual(state.model, state.originalModel);

  changes = (state: BusinessObjectState<BO, S, C>): (() => Partial<BO>) => () => {
    console.log('build difference bewtween', state.model, state.originalModel);
    return difference(state.model, state.originalModel);
  };

  timelineOptions = (state: BusinessObjectState<BO, S, C>): any => state.timelineOptions;

  working = (state: BusinessObjectState<BO, S, C>): boolean => state.working;

  pendingMassiveUpdates = (state: BusinessObjectState<BO, S, C>): ICometMassiveUpdateMessage[] => state.massiveUpdates.filter((mu) => mu.finishDate == null);

  collapseItemsPreferences = (state: BusinessObjectState<BO, S, C>): string[] => state.collapseItemsPreferences;

  printableFields = (state: BusinessObjectState<BO, S, C>): ILiquidStruct[] => state.printableFields;
}
