<template>
  <div v-if="model != null">
    <t-form-item v-for="(item, index) in renderingModel" :key="item.position" class="t-email-normalized-input"
      :prop="schema.propertyName" :required="schema.required" :important="schema.important">
      <template #label>
        <span v-if="schema.readOnly">{{ resolveTypeLabel(item) }}</span>
        <el-dropdown @command="(c) => handleTypeChange(item, c)" trigger="click" v-else>
          <span class="el-form-item__label nowrap cursor-pointer">
            {{ resolveTypeLabel(item) }}
            <i class="el-icon-icon-arrow-down4 text text-info el-icon--right"></i>
          </span>
          <template #dropdown><el-dropdown-menu>
              <el-dropdown-item v-for="listType in listTypes" :key="listType.value" :command="listType.value">{{
                listType.text
              }}</el-dropdown-item>
            </el-dropdown-menu></template>
        </el-dropdown>
      </template>
      <t-similarity-pop-over :value="item.email" :id="data.id" :type="formSchema.businessObjectType"
        :mode="$enums.SimilarityCheckType.EMail">
        <el-input :value="item.email" @input="(newValue) => handleEmailInput(item, newValue)" class="input-with-select"
          :disabled="schema.readOnly">
          <template #append>
            <el-popover :title="$t('note')" v-if="!schema.readOnly">
              <template #reference>
                <el-button icon="el-icon-icon-notebook" />
              </template>
              <el-input type="textarea" :rows="3" width="350" :value="item.note"
                @input="(newValue) => handleNoteInput(item, newValue)" />
            </el-popover>
            <el-button icon="el-icon-icon-envelop" @click="() => handleMailSendClick(item)" />
            <span v-if="!schema.readOnly">
              <el-button v-if="index === model.length - 1" @click="handlePlusClick" icon="el-icon-plus"></el-button>
              <el-button v-else @click="() => handleRemoveEmail(item)" icon="el-icon-icon-bin" />
            </span>
          </template>
        </el-input>
      </t-similarity-pop-over>
    </t-form-item>
    <div v-if="model.length > limit" class="text text-right text-info cursor-pointer m-b" @click="() => limit += limit">
      {{ $tc('others', model.length - limit) }}
    </div>
  </div>
</template>

<script lang="ts">
import { IEMailNormalizedDTO } from 'dto/IEMailNormalizedDTO';
import { Watch, Prop, Inject } from 'vue-property-decorator';
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';

@Component({
  name: 'EmailNormalizedInput',
})
export default class EmailNormalizedInput extends Vue {
  @Inject() formSchema;

  @Getter('lists/list')
  list: (type: ListsType) => Array<IInt64ListResult>;

  @Watch('value')
  handleValueChange(val) {
    this.model = this.value.map((v) => ({
      ...v,
    }));
    if (this.model.length === 0) {
      this.handlePlusClick();
    }
  }

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: true,
  })
  value: IEMailNormalizedDTO[];

  limit: number = 10;

  listTypes: Array<IInt64ListResult> = [];

  model: IEMailNormalizedDTO[] = null;

  created() {
    this.model = this.value.map((v) => ({
      ...v,
    }));
  }

  async mounted() {
    await this.$store.dispatch('lists/load', this.$enums.ListsType.EmailType);
    this.listTypes = [...this.list(this.$enums.ListsType.EmailType)];
    if (this.model.length === 0) {
      this.handlePlusClick();
    }
  }

  get renderingModel() {
    if (this.model && this.model.length > this.limit) {
      return this.model.slice(0, this.limit);
    }
    return this.model;
  }

  handleEmailInput(item, newValue) {
    item.email = newValue;
    this.$emit('input', [...this.model]);
  }

  handleNoteInput(item, newValue) {
    item.note = newValue;
    this.$emit('input', [...this.model]);
  }

  handleRemoveEmail(item) {
    const index = this.model.indexOf(item);
    this.model.splice(index, 1);
    this.$emit('input', [...this.model]);
  }

  handlePlusClick() {
    this.model = [...this.model, {
      position: this.model.length + 1,
      macroType: this.listTypes[0].value,
      subType: 0,
      email: '',
      displayName: '',
      note: '',
    }];
  }

  handleTypeChange(item: IEMailNormalizedDTO, type: number) {
    if (type < 0) {
      item.macroType = -type;
      item.subType = 0;
    } else {
      item.macroType = 0;
      item.subType = type;
    }
    this.$emit('input', [...this.model]);
  }

  resolveTypeLabel(item: IEMailNormalizedDTO) {
    let value = item.subType;
    if (value === 0) value = -item.macroType;
    if (!this.listTypes || !this.listTypes.length) return '';
    const t = this.listTypes.find((l) => l.value === value);
    if (t) return t.text;
    return this.listTypes[0].text;
  }

  handleMailSendClick(item: IEMailNormalizedDTO) {
    this.$root.$emit('activity', {
      filter: [this.$enums.ActivityType.Email],
      schema: 'webmail',
      activity: {
        companyId: this.formSchema.businessObjectType === this.$enums.BusinessObjectType.Company ? this.data.id : 0,
        contactId: this.formSchema.businessObjectType === this.$enums.BusinessObjectType.Contact ? this.data.id : 0,
        leadId: this.formSchema.businessObjectType === this.$enums.BusinessObjectType.Lead ? this.data.id : 0,
        email: {
          to: [{ address: item.email }],
        },
      },
    });
  }
}
</script>

<style lang="scss">
@import '../../../../scss/modifiers.scss';

.t-email-normalized-input {
  @extend .inline-input;
}
</style>
