import Vue from 'vue';
import _ from 'lodash';

const blacklistNames = ['App', 'Container', 'DetailPage', 'HeaderPage', 'Widget', 'TemplateWidget', 'DynamicComponent'];
export class Indexer {
  generate = (vue: Vue): string => {
    let p = vue;
    const names: string[] = [];
    do {
      if (p.$options?.name) {
        names.push(p.$options?.name);
      }
      p = p.$parent;
    } while (p != null);
    const filteredNames = names.filter((n) => n.indexOf('El') && !blacklistNames.includes(n));
    return _.reverse(filteredNames).join('-');
  };
}
const indexer = new Indexer();
export default indexer;
