<template>
  <el-tooltip :placement="placement" :manual="true" v-model="tooltipVisible">
    <template #content>
      <slot name="content">{{ content }}</slot>
    </template>
    <span v-touch:longtap="toggleTapEvent" @mouseenter="() => mouseEventTooltipVisible = true"
      @mouseleave="() => mouseEventTooltipVisible = false">
      <slot></slot>
    </span>
  </el-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TooltipLabel',
})
export default class TooltipLabel extends Vue {
  @Prop({
    type: String,
    required: false,
    default: null,
  })
  content: string;

  @Prop({
    type: String,
    required: false,
    default: 'top',
  })
  placement: string;

  mouseEventTooltipVisible = false;

  tapTooltipVisible = false;

  toggleTapEvent() {
    this.tapTooltipVisible = !this.tapTooltipVisible;
    window.setTimeout(() => {
      this.tapTooltipVisible = false;
    }, 5000);
  }

  get tooltipVisible(): boolean {
    if (this.$viewport.isTouch) {
      return this.tapTooltipVisible;
    }
    return this.mouseEventTooltipVisible;
  }

  set tooltipVisible(v: boolean) {
    this.mouseEventTooltipVisible = v;
  }
}
</script>
