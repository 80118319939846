//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PhoneNormalizeType {
	Phone = 0,
	Phone2 = 1,
	MobilePhone = 2,
	MobilePhone2 = 3,
	Assistant = 4,
	Fax = 5
}
