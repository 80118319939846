import Vue, { PluginObject } from 'vue';

class ChartComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-simple-mail-view', () => import(/* webpackChunkName: "mail" */ './SimpleMailView.vue'));
    vue.component('t-email-address-label', () => import(/* webpackChunkName: "mail" */ './EMailAddressLabel.vue'));
    vue.component('t-email-addresses-label', () => import(/* webpackChunkName: "mail" */ './EMailAddressesLabel.vue'));
    vue.component('t-email-sender-input', () => import(/* webpackChunkName: "mail" */ './EMailSenderInput.vue'));
    vue.component('t-email-tag', () => import(/* webpackChunkName: "mail" */ './EMailTag.vue'));
  }
}
export default new ChartComponents();
