import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { IListDTO } from 'dto/IListDTO';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import { ListController } from 'rt/UIApiControllers/BusinessObject/DTO/ListController';
import BusinessObjectPageMixin from '@/modules/mixin/views/BusinessObjectPageMixin';
import eventHubManager from '@/modules/mixin/views/EventHub';

// tslint:disable-next-line:variable-name
const ListGetter = namespace('list').Getter;
@Component({
  name: 'ListViewMixin',
})
export default class ListViewMixin extends Vue {
  @ListGetter model: IListDTO;

  @ListGetter('controller') controller: ListController;

  get eventHub(): Vue {
    return eventHubManager.getHub('list');
  }

  get module(): string {
    return 'list';
  }
}
