<template>
  <span>
    <el-dialog :width="$viewport.dialog" :visible.sync="dialogVisible" :append-to-body="isNestedDialog">
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
      </el-row>
      <t-grid :showCommands="false" repeater="PopCatalogRepeater" @linkClick="linkClick"
        :customParameterData="{ search }" />
    </el-dialog>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :route="$modules.isModuleLoaded('catalog')" :value="catalog" :key="catalog.key"
        v-for="catalog in selectedData" :closable="!readOnly" :disable-transitions="false"
        @close="handleRemoveItem(catalog)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'CatalogLookup',
  components: {
    TLookupSkin,
  },
})
export default class CatalogLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'Catalog';
  }

  keyPrefix() {
    return 'Catalog';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }

  linkClick(productId, row) {
    const item = {
      key: `${this.keyPrefix()}-${productId}`,
      title: row.CODE.value,
    };
    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }
}
</script>
