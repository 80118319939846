//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Events {
	All = 0,
	OnPreLoad = 1,
	OnLoadComplete = 2,
	OnPreSave = 4,
	OnSaveComplete = 8,
	OnPreRuleEngine = 16,
	OnPostRuleEngine = 32,
	OnDelete = 64,
	OnError = 128,
	OnCustomEvent = 256
}
