//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CallLogResult {
	Success = 0,
	ErrorUnknown = 1,
	NoResourceAvaliable = 2,
	DestinationUnreachable = 3,
	Busy = 4,
	RejectedCall = 5,
	NotAnswered = 6,
	Timeout = 7,
	ServiceDisabled = 8
}
