//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AccountAccessLevel {
	User = 0,
	AreaManager = 1,
	Commercial = 2,
	External = 3,
	ExternalAdvanced = 4,
	Light = 5,
	Application = 6
}
