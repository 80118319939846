//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import { ILiquidStruct } from '../../../Base/Utility/ILiquidStruct';

export default class DefaultDtoControllerProxy<T extends { id: number }> {
  axios: AxiosInstance;
  controller: string;
  type: BusinessObjectType;
  constructor(axios: AxiosInstance, controller: string, type?: BusinessObjectType) {
    if (axios === null || axios === undefined) throw `axios argument is required in ${BusinessObjectType[type]}Controller (${controller}) constructor class, and cannot be empty or undefined`;
    this.axios = axios;
    this.controller = controller;
    this.type = type;
  }
  public CountIdsBySingleCriteria(criteria: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number> {
    return this.axios.post(this.controller + '/CountIdsBySingleCriteria', criteria, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public CountIdsByConnectedCriteria(criteria: any[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number> {
    return this.axios.post(this.controller + '/CountIdsByConnectedCriteria', criteria, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public SearchIdsBySingleCriteria(criteria: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios.post(this.controller + '/SearchIdsBySingleCriteria', criteria, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public SearchIdsByConnectedCriteria(criteria: any[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios.post(this.controller + '/SearchIdsByConnectedCriteria', criteria, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public View(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<any> {
    return this.axios.get(this.controller + '/View/' + id, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public Get(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T> {
    return this.axios.get(this.controller + '/' + id, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetFull(id: number, cancelToken?: CancelToken): Promise<T> {
    return this.axios
      .get(this.controller + '/GetFull/' + id, { cancelToken })
      .then(r => r.data);
  }
  public Recent(id: number, cancelToken?: CancelToken): Promise<boolean> {
    return this.axios
      .put(this.controller + '/Recent/' + id, null, { cancelToken })
      .then(r => r.data);
  }
   public GetOrNew(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T> {
    if (id > 0) return this.Get(id, cancelToken, axiosConfig);
     return this.GetNewInstance(cancelToken, axiosConfig);
  }
  public GetHash(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<string> {
    return this.axios.get(this.controller + '/GetHash?' + 'id=' + id, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetNewInstance(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T> {
    return this.axios.get(this.controller + '/GetNewInstance', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public MultipleGet(model: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<{ id: number; item: T }[]> {
    return this.axios.post(this.controller + '/MultipleGet', model, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public Exists(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
    return this.axios.get(this.controller + '/Exists?' + 'id=' + id, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public MultipleExists(model: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T[]> {
    return this.axios.post(this.controller + '/MultipleExists', model, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public CreateOrUpdate(dto: Partial<T>, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number> {
    return this.axios.put(this.controller, dto, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public Delete(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
    return this.axios.delete(this.controller + '/' + id, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public MultipleDelete(model: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T[]> {
    return this.axios.post(this.controller + '/MultipleDelete', model, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public Retrieve(id: number, fields: string[], full: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<Partial<T>> {
    return this.axios.get(this.controller + '/Retrieve?' + 'id=' + id + '&' + (fields ? 'fields=' + encodeURIComponent(fields.join(',')) : '') + '&' + 'full=' + full, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public MultipleRetrieve(idsstring: string, fields: string[], full: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<Partial<T>[]> {
    return this.axios.get(this.controller + '/MultipleRetrieve?' + (idsstring ? 'idsstring=' + encodeURIComponent(idsstring) : '') + '&' + (fields ? 'fields=' + encodeURIComponent(fields.join(',')) : '') + '&' + 'full=' + full, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public SearchIdsByODataCriteria(filter: string, orderBy?: string, skip: number = 0, top: number = 2147483647, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios
        .get(
            this.controller +
            '/SearchIdsByODataCriteria?' +
            (filter ? 'filter=' + encodeURIComponent(filter) : '') +
            '&' +
            (orderBy ? 'orderBy=' + encodeURIComponent(orderBy) : '') +
            '&' +
            'skip=' +
            skip +
            '&' +
            'top=' +
            top +
            '&' +
            (select === null || select === undefined ? '' : 'select=' + select.join(',')),
            { ...axiosConfig, cancelToken },
        )
        .then(r => r.data);
  }
  public SearchByODataCriteria(filter: string, orderBy?: string, skip: number = 0, top: number = 2147483647, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T[]> {
    return this.axios
        .get(
            this.controller +
            '/SearchByODataCriteria?' +
            (filter ? 'filter=' + encodeURIComponent(filter) : '') +
            '&' +
            (orderBy ? 'orderBy=' + encodeURIComponent(orderBy) : '') +
            '&' +
            'skip=' +
            skip +
            '&' +
            'top=' +
            top +
            '&' +
            (select === null || select === undefined ? '' : 'select=' + select.join(',')),
            { ...axiosConfig, cancelToken },
        )
        .then(r => r.data);
  }
  public GetFilterablePropertiesNames(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<string[]> {
    return this.axios.get(this.controller + '/GetFilterablePropertiesNames', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public Search(field: string, value: string, comparator: string = 'Equals', cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios.get(this.controller + '/Search?' + (field ? 'field=' + encodeURIComponent(field) : '') + '&' + (value ? 'value=' + encodeURIComponent(value) : '') + '&' + (comparator ? 'comparator=' + encodeURIComponent(comparator) : ''), { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public SearchIds(filter: string, orderBy?: string, skip: number = 0, top: number = 2147483647, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios
        .get(this.controller + '/SearchIds?' + (filter ? 'filter=' + encodeURIComponent(filter) : '') + '&' + (orderBy ? 'orderBy=' + encodeURIComponent(orderBy) : '') + '&' + 'skip=' + skip + '&' + 'top=' + top + '&' + (select === null || select === undefined ? '' : 'select=' + select.join(',')), {
          ...axiosConfig,
          cancelToken,
        })
        .then(r => r.data);
  }

  public Count(filter: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number> {
    return this.axios.get(this.controller + '/Count?' + (filter ? 'filter=' + encodeURIComponent(filter) : ''), { ...axiosConfig, cancelToken }).then(r => r.data);
  }

  public GetMethodsRaise404ErrorOnNotFound(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
    return this.axios.get(this.controller + '/GetMethodsRaise404ErrorOnNotFound', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public SearchMethodsRaise404ErrorOnNotFound(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
    return this.axios.get(this.controller + '/SearchMethodsRaise404ErrorOnNotFound', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public DeleteMethodsRaise404ErrorOnNotFound(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
    return this.axios.get(this.controller + '/DeleteMethodsRaise404ErrorOnNotFound', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public async GetBusinessObjectType(cancelToken?: CancelToken): Promise<number> {
    return this.type;
  }
  public GetMostRecentItemsIds(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios.get(this.controller + '/GetMostRecentItemsIds', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetMostRecentItems(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T[]> {
    return this.axios.get(this.controller + '/GetMostRecentItems', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetPolymorphicSchema(serializePropertiesAsDictionary: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<any> {
    return this.axios.get(this.controller + '/GetPolymorphicSchema?' + 'serializePropertiesAsDictionary=' + serializePropertiesAsDictionary, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetStandardSchema(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<any> {
    return this.axios.get(this.controller + '/GetStandardSchema', { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetSwagger(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<any> {
    return this.axios.get(this.controller + '/GetSwagger', { ...axiosConfig, cancelToken }).then(r => r.data);
  }

  public MapByExternalReference(externalReference: string, internalItemId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<void> {
    return this.axios.get(this.controller + '/MapByExternalReference?' + (externalReference === null || externalReference === undefined ? '' : 'externalReference=' + encodeURIComponent(externalReference)) + '&' + 'internalItemId=' + internalItemId, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public ResetExternalReferences(internalItemId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<void> {
    return this.axios.get(this.controller + '/ResetExternalReferences?' + 'internalItemId=' + internalItemId, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public UnmapByExternalReference(externalReference: string, internalItemId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<void> {
    return this.axios
        .get(this.controller + '/UnmapByExternalReference?' + (externalReference === null || externalReference === undefined ? '' : 'externalReference=' + encodeURIComponent(externalReference)) + '&' + 'internalItemId=' + internalItemId, { ...axiosConfig, cancelToken })
        .then(r => r.data);
  }
  public GetIdsMappedByExternalReference(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios.post(this.controller + '/GetIdsMappedByExternalReference', filter, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetItemsMappedByExternalReference(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T[]> {
    return this.axios.post(this.controller + '/GetItemsMappedByExternalReference', filter, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetIdsNotMappedByExternalReference(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<number[]> {
    return this.axios.post(this.controller + '/GetIdsNotMappedByExternalReference', filter, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public GetItemsNotMappedByExternalReference(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<T[]> {
    return this.axios.post(this.controller + '/GetItemsNotMappedByExternalReference', filter, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
  public PrintableFields(includeFull: boolean = true, includeNesting: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig): Promise<ILiquidStruct[]> {
    return this.axios.get(this.controller + '/PrintableFields?includeFull=' + includeFull + '&includeNesting=' + includeNesting, { ...axiosConfig, cancelToken }).then(r => r.data);
  }
}

