//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ImportConflictRule {
	ImportNew = 0,
	ImportOldest = 1,
	ImportNewer = 2,
	ImportAll = 3,
	ImportNever = 4,
	ImportOverWrite = 5,
	ImportNeverAnd = 6,
	ImportNeverOr = 7
}
