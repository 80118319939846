//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IEmailLookupResult } from './IEmailLookupResult';
import { IEmailValidationResult } from '../../../WebMail/Utils/IEmailValidationResult';

//element.Name=AddressBookController result.Name=AddressBookController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.AddressBookController);
/** Result of WebApiControllerGenerator activity */
export class AddressBookController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in AddressBookController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Email(email: string, take: number = 10, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IEmailLookupResult[]>
	{
		return (this.axios.get('AddressBook/Email?'+((email === null || email === undefined) ? '' : ('&email='+encodeURIComponent(email)))+'&take='+take, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Validate(email: string, verifyDomain: boolean = true, verifyMailbox: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IEmailValidationResult>
	{
		return (this.axios.get('AddressBook/Validate?'+((email === null || email === undefined) ? '' : ('&email='+encodeURIComponent(email)))+'&verifyDomain='+verifyDomain+'&verifyMailbox='+verifyMailbox, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('AddressBook/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('AddressBook/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
