import Vue from 'vue';
import { ILayoutStudioSlot } from 'rt/Interfaces/UI/ILayoutStudioSlot';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';

class LayoutStudioValidationRules {
  buildRules(schema: IWidgetPage, model: () => any) {
    let rules = {};
    if (schema && schema.tab && schema.tab.tabs) {
      for (const tab of schema.tab.tabs) {
        if (tab.layoutStudioSchema && tab.layoutStudioSchema.sections) {
          for (const section of tab.layoutStudioSchema.sections) {
            if (section.columns) {
              for (const column of section.columns) {
                rules = this.extractRules(column, rules, schema.validationEndPoint, model);
              }
            }
          }
        }
      }
    }
    if (schema && Object.prototype.hasOwnProperty.call(schema, 'requiredSlots') && schema['requiredSlots']) {
      rules = this.extractRules(schema['requiredSlots'], rules, schema.validationEndPoint, model);
    }
    return rules;
  }

  extractRules(column: ILayoutStudioSlot[], originalRules: any, validationUrl: string, model: () => any): any {
    let rules = originalRules;
    for (const slot of column) {
      {
        if (Object.prototype.hasOwnProperty.call(slot, 'propertyName')) {
          let slotRules = [];
          const pname = <string>slot['propertyName'];
          if (Object.prototype.hasOwnProperty.call(slot, 'rules') && slot['rules']) {
            slotRules = this.mutateRules(pname, slot['rules'], validationUrl, model);
          }
          if (slot.required) {
            const message = <string>Vue.i18n.t('validation.required.field', {
              field: slot.text,
            });
            slotRules = [
              {
                message,
                required: true,
              },
              ...slotRules,
            ];
            /* slotRules.push({
              message,
              required: true,
            }); */
          }
          if (slotRules.length) {
            rules = {
              ...rules,
              [pname]: slotRules,
            };
          }
        } else if (Object.prototype.hasOwnProperty.call(slot, 'rules') && slot['rules']) {
          if (Object.prototype.hasOwnProperty.call(slot, 'validationPropertyName') && slot['validationPropertyName']) {
            const propertyName = slot['validationPropertyName'];
            const slotRules = this.mutateRules(propertyName, slot['rules'], validationUrl, model);
            rules = {
              ...rules,
              [propertyName]: slotRules,
            };
          }
        } else if (Object.prototype.hasOwnProperty.call(slot, 'items') && slot['items']) {
          const subItems = <ILayoutStudioSlot[]>slot['items'];
          const subRules = this.extractRules(subItems, originalRules, validationUrl, model);
          rules = { ...rules, ...subRules };
        }
      }
    }
    return rules;
  }

  private mutateRules(
    propertyName: string,
    rules: {
      trigger?: string;
      type: string;
      pattern?: string;
      message?: string;
      actionName?: string;
      pushCompleteModel?: boolean;
      modelFields: string[];
      companyPropertyName?: string;
      contactPropertyName?: string;
      leadPropertyName?: string;
      min?: number;
      max?: number;
    }[],
    validationUrl: string,
    model: () => any,
  ): any[] {
    const currentRules = [];
    for (const rule of rules) {
      switch (rule.type) {
        case 'method':
          if (validationUrl) {
            const xr = {
              validator: (r, value, callback, source, options) => {
                const m = model();
                this.validate(validationUrl, rule.actionName, callback, rule.pushCompleteModel ? m : this.partialModel(m, propertyName, rule.modelFields));
              },
            };
            currentRules.push(xr);
          }
          break;
        case 'date':
          currentRules.push({
            validator: (r, value, callback, source, options) => {
              const minDate = rule.min != null ? new Date(rule.min) : new Date(-6847721396000);
              callback(typeof value === 'string' && new Date(value).getTime() > minDate.getTime() ? [] : [rule.message]);
            },
          });
          break;
        case 'string':
          if (rule.trigger === 'regex' && rule.pattern) {
            currentRules.push({
              validator: (r, value, callback, source, options) => {
                callback(typeof value === 'string' && !!value.match(rule.pattern) ? [] : [rule.message]);
              },
            });
          } else {
            currentRules.push(rule);
          }
          break;
        case 'ternary':
          {
            const xr = {
              validator: (r, value, callback, source, options) => {
                const m = model();
                const companyId = m[rule.companyPropertyName];
                const leadId = m[rule.contactPropertyName];
                const contactId = m[rule.leadPropertyName];
                if (companyId > 0 || leadId > 0 || contactId > 0) {
                  callback([]);
                  return;
                }
                callback([rule.message || 'error']);
              },
            };
            currentRules.push(xr);
          }
          break;
        default:
          currentRules.push(rule);
          break;
      }
    }
    return currentRules;
  }

  private partialModel(model: any, propertyName: string, modelFields: string[]): any {
    if (!model) {
      return model;
    }
    const nm = {
      id: model.id,
      [propertyName]: model[propertyName],
    };
    if (modelFields) {
      for (const mf of modelFields) {
        nm[mf] = model[mf];
      }
    }
    return nm;
  }

  private validate(validationUrl: string, actionName: string, callback: (errors: string | string[]) => void, source: any) {
    Vue.axios.post(`${validationUrl}/${actionName}`, source).then((r) => {
      const errors = r.data || [];
      callback(errors);
    });
  }
}
const layoutStudioValidationRules = new LayoutStudioValidationRules();
export default layoutStudioValidationRules;
