//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WebMailArchiveStatus {
	Waiting = 0,
	Archived = 1,
	Failed = 2,
	Deleted = 3
}
