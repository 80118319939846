<template>
  <div class="clearfix" v-loading="loading">
    <div v-if="!loading">
      <div v-if="hasWebMail">
        <div>
          <el-row type="flex">
            <el-col>
              <h3>{{ $t('mailbox.last') }}</h3>
            </el-col>
            <el-col class="text-right">
              <router-link :to="{ name: $routeNames.webmail }" @click.native="$emit('close')">
                <el-button type="primary">{{ $t('webmail.open') }}</el-button>
              </router-link>
            </el-col>
          </el-row>
        </div>
        <div class="m-b m-t">
          <transition-group name="fade-jump-bottom" appear>
            <div v-if="lastMails && lastMails.length" key="list" class="mail-wrapper">
              <div v-for="mail in lastMails" :key="mail.messageId">
                <t-web-mail-list-side-item :mail="mail" :id="mail.messageId"></t-web-mail-list-side-item>
              </div>
            </div>
            <div v-else key="empty" class="text-center m-t">
              <i class="el-icon-icon-weather-windy icon-1_5x" style="vertical-align: bottom"></i>
              <span class="text text-1_2x m-l-h">{{ $t('messages.nomessages') }}</span>
            </div>
          </transition-group>
        </div>
      </div>
      <div class="m-t m-b text-center" v-else>
        <router-link
          :to="{
            name: $routeNames.profileWebMailAccounts,
            id: $routeNames.profileWebMailAccounts,
          }"
        >{{ $t('mailbox.notfound') }}</router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import VueScrollTo from 'vue-scrollto';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { IWebMailAccountDTO } from 'dto/IWebMailAccountDTO';
import { IWebMailSmallMessage } from 'rt/Base/WebMail/IWebMailSmallMessage';
import TWebMailListSideItem from '../components/WebMailListSideItem.vue';
import MailboxMixin from './MailboxMixin';

// tslint:disable-next-line:variable-name
const WebMailAction = namespace('webmail').Action;
// tslint:disable-next-line:variable-name
const WebMailGetter = namespace('webmail').Getter;
// tslint:disable-next-line:variable-name
const WebMailMutation = namespace('webmail').Mutation;

@Component({
  name: 'LastMails',
  components: {
    TWebMailListSideItem,
  },
})
export default class LastMails extends MailboxMixin {
  loading = false;

  lastMails: IWebMailSmallMessage[] = null;

  async mounted() {
    this.loading = true;
    await this.load();
    this.lastMails = await this.webMailController.LastMessages();
    this.loading = false;
  }

  get hasWebMail(): boolean {
    return this.webMailAccounts && this.webMailAccounts.length > 0;
  }
}
</script>

<style lang="scss" scoped>
@import '~element-variables';
.mail-wrapper {
  background-color: white;
  border-top: $--border-base;
  border-left: $--border-base;
  border-right: $--border-base;
  border-radius: 4px;
  overflow: hidden;
}
</style>
