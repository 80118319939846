import { IStorageCategoryDTO } from '@/plugins/typings/DTO/IStorageCategoryDTO';
import _ from 'lodash';

interface IStorageItem {
  type: 'file' | 'category';
  id: number;
  category: number;
  description: string;
  lastModifiedById: number;
  lastModifiedDate: string;
  size: string;
  icon: string;
  linked?: string;
}

const buildCategoriesPaths = (allCategories: IStorageCategoryDTO[], categoryId: number): IStorageCategoryDTO[] => {
  if (!allCategories) {
    return [];
  }
  const categories = [];
  let currentCategoryId = categoryId;
  do {
    const currentCategory = allCategories.find((c) => c.id === currentCategoryId);
    if (!currentCategory) {
      break;
    }
    categories.push(currentCategory);
    currentCategoryId = currentCategory.parentId;
  } while (currentCategoryId > 0);
  return _.reverse(categories);
};
export { IStorageItem, buildCategoriesPaths };
