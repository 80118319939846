<template>
  <span>
    <span v-if="cerved" class="v-middle-all cerved" :class="{ 'active': term && term.length > 3 }"
      @click="handleCervedRequest">
      <span class="text-condensed">Cerved&nbsp;</span>
      <i class="el-icon-fa-external-link fas text-0_8x"></i>
    </span>
    <el-dialog :visible.sync="cervedSearch" :width="$viewport.dialog" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body>
      <template #title>
        <span>{{ $t('cerved.search.searching', { term }) }}</span>
        <span v-if="user.limits.cervedDailyLimit != null" class="text-warning m-l-h text">{{
          $tc('cerved.search.remaining', user.limits.cervedDailyLimit - user.limits.cervedDailyUsage > 0 ?
            user.limits.cervedDailyLimit - user.limits.cervedDailyUsage : 0)
        }}</span>
      </template>

      <div v-loading="result == null || importing">
        <div v-if="result != null">
          <div v-if="result.length">
            <div v-for="res in result" :key="res.dati_anagrafici.id_soggetto">
              <t-cerved-item :value="res" @import="handleImportCervedData"></t-cerved-item>
            </div>
          </div>
          <div v-else>
            <div class="text-danger">
              <el-alert type="warning" :closable="false" center show-icon effect="dark">{{
                $tc('cerved.search.results',
              0)
              }}</el-alert>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';
import { CervedController } from 'rt/UIApiControllers/Utils/CervedController';
import { IAnagraphic } from 'rt/Base/Cerved/EntitySearch/IAnagraphic';
import TCervedItem from './CervedItem.vue';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { ICloudUserLimit } from '@/plugins/typings/UI/Injector/ICloudUserLimit';

@Component({
  name: 'AnagraphicCerved',
  components: {
    TCervedItem,
  },
})
export default class AnagraphicCerved extends Vue {
  @Getter user: IUser;

  @Action loadUser;

  cervedController = new CervedController(this.$http);

  @Prop({
    required: true,
    type: Object,
  })
  value: ICompanyDTO | IContactDTO | ILeadDTO;

  @Prop({
    required: false,
    type: String,
  })
  term: string;

  get cervedDailyLimit() {
    return (this.user.limits as ICloudUserLimit).cervedDailyLimit;
  }

  get cerved() {
    return (this.user.limits as ICloudUserLimit).cerved ?? true;
  }

  get cervedDailyUsage() {
    return (this.user.limits as ICloudUserLimit).cervedDailyUsage;
  }

  result: IAnagraphic[] = null;

  cervedSearch = false;

  importing = false;

  async handleCervedRequest() {
    if (this.term?.length > 3) {
      this.result = null;
      this.cervedSearch = true;
      this.importing = false;
      this.result = await this.cervedController.EntitySearch(this.term, this.searchFilter);
    }
  }

  get searchFilter() {
    switch (this.type) {
      case BusinessObjectType.Company:
        return 1;
      case BusinessObjectType.Contact:
        return 2;
      case BusinessObjectType.Lead:
        return 0;
    }
    return 1;
  }

  async handleImportCervedData(payload: { id: number; overwrite: boolean }) {
    if (this.cervedDailyLimit != null) {
      if (this.cervedDailyLimit <= this.cervedDailyUsage) {
        this.$alert(this.$tc('cerved.search.remaining', 0), {
          type: 'warning',
        });
        return;
      }
    }
    this.importing = true;
    const importedDto = await this.autoComplete(payload);
    if (importedDto != null) {
      this.$emit('input', importedDto);
    }
    this.cervedSearch = false;
    this.importing = false;
    if (this.cervedDailyLimit != null) {
      this.loadUser();
    }
  }

  get type() {
    if (Object.prototype.hasOwnProperty.call(this.value, 'companyName') && Object.prototype.hasOwnProperty.call(this.value, 'surname')) {
      return BusinessObjectType.Lead;
    }
    if (Object.prototype.hasOwnProperty.call(this.value, 'companyName')) {
      return BusinessObjectType.Company;
    }
    if (Object.prototype.hasOwnProperty.call(this.value, 'surname')) {
      return BusinessObjectType.Contact;
    }
    return false;
  }

  async autoComplete(payload: { id: number; overwrite: boolean }) {
    if (this.type === BusinessObjectType.Lead) {
      return await this.cervedController.AutoCompleteLead(this.value as ILeadDTO, payload.id, payload.overwrite);
    }
    if (this.type === BusinessObjectType.Company) {
      return await this.cervedController.AutoCompleteCompany(this.value as ICompanyDTO, payload.id, payload.overwrite);
    }
    if (this.type === BusinessObjectType.Contact) {
      return await this.cervedController.AutoCompleteContact(this.value as IContactDTO, payload.id, payload.overwrite);
    }
    return null;
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.cerved {
  text-transform: uppercase;
  opacity: 0.6;

  &.active {
    color: $--color-primary;
    cursor: pointer;
    opacity: 1;
  }
}
</style>
