//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IOpportunityDTO } from '../../../DTO/IOpportunityDTO';
import { IStorageDTO } from '../../../DTO/IStorageDTO';
import { IFlowDTO } from '../../../DTO/IFlowDTO';
import { IListDTO } from '../../../DTO/IListDTO';
import { IMailingDeliveryDTO } from '../../../DTO/IMailingDeliveryDTO';
import { IFilePreview } from './IFilePreview';
import { IContactDTO } from '../../../DTO/IContactDTO';

//element.Name=ContactController result.Name=ContactController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.ContactController);
/** Result of WebApiControllerGenerator activity */
export class ContactController extends DefaultDtoControllerProxy<IContactDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Contacts', BusinessObjectType.Contact);
	}
	public ResolveCustomerToken(token: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/ResolveCustomerToken?'+((token === null || token === undefined) ? '' : ('&token='+encodeURIComponent(token))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Activities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('Contacts/Activities?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Contacts/ActivitiesIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/ActivitiesCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Opportunities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityDTO[]>
	{
		return (this.axios.get('Contacts/Opportunities?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OpportunitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Contacts/OpportunitiesIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OpportunitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/OpportunitiesCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Documents(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO[]>
	{
		return (this.axios.get('Contacts/Documents?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Contacts/DocumentsIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/DocumentsCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Location(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Contacts/Location?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Flows(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowDTO[]>
	{
		return (this.axios.get('Contacts/Flows?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Contacts/FlowsIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/FlowsCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Lists(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListDTO[]>
	{
		return (this.axios.get('Contacts/Lists?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ListsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Contacts/ListsIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ListsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/ListsCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Mailings(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMailingDeliveryDTO[]>
	{
		return (this.axios.get('Contacts/Mailings?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MailingsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Contacts/MailingsIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MailingsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/MailingsCount?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Contacts/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Contacts/Download?'+'&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Contacts/HtmlPreview?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Contacts/Previews?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Contacts/Preview?'+'&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Contacts/GetDownloadUrl?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Contacts/Archive?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Contacts/PrintParameters?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IContactDTO>
	{
		return (this.axios.get('Contacts/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
