//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FlowStepResult {
	Success = 0,
	Fail = 1,
	Timeout = 2,
	Abort = 3
}
