<template>
  <span>
    <el-row type="flex" :gutter="10" v-if="active">
      <el-col :span="-1">
        <t-tooltip-button
          :content="$t(isParameter ? 'commands.parameter.dynamic': 'commands.parameter.value')"
          icon="el-icon-icon-code"
          :type="isParameter ? 'primary' : ''"
          @click="changeParameterType"
        ></t-tooltip-button>
      </el-col>
      <el-col>
        <span v-if="isParameter">
          <el-input
            :value="value.parameter"
            @input="handleParameterValueChange"
            ref="parameterInput"
          ></el-input>
        </span>
        <span v-else>
          <slot></slot>
        </span>
      </el-col>
    </el-row>
    <slot v-else></slot>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'ParameterVariable',
})
export default class ParameterVariable extends Vue {
  @Prop({ type: [Object, String, Number, Boolean], required: false })
  value: any;

  @Prop({ type: Boolean, required: false, default: true })
  active: boolean;

  lastValue: any = null;

  lastParameterValue: any = null;

  changeParameterType() {
    if (this.isParameter) {
      this.lastParameterValue = _.cloneDeep(this.value);
      this.$emit('input', this.lastValue);
    } else {
      this.lastValue = _.cloneDeep(this.value);
      this.$emit('input', this.lastParameterValue || { parameter: '{{ }}' });
      this.$nextTick(() => {
        (this.$refs['parameterInput'] as any).focus();
      });
    }
  }

  get isParameter(): boolean {
    if (this.value && typeof this.value === typeof {} && Object.prototype.hasOwnProperty.call(this.value, 'parameter')) {
      return true;
    }
    return false;
  }

  handleParameterValueChange(v) {
    this.$emit('input', {
      ...this.value,
      parameter: v,
    });
  }
}
</script>
