<template>
  <el-form-item :label="label" :prop="prop" :label-width="labelWidth" class="t-form-item">
    <template #label>
      <span :class="{ 'important': important || required, 'required': required }">
        <slot name="label">{{ label }}</slot>
      </span>
    </template>
    <slot></slot>
  </el-form-item>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FormItem',
})
export default class FormItem extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  label: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  prop: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  required: boolean;

  @Prop({
    type: String,
    required: false,
  })
  labelWidth: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  important: boolean;
}
</script>
<style lang="scss">
@import '~element-variables';

.t-form-item {
  & .important {
    // color: $--color-warning;
    text-decoration: underline;
    font-weight: bold;
  }

  & .required {
    color: mix($--color-danger, black, 30%);
  }
}
</style>
