//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ListsType {
	None = 0,
	ListGroup = 1,
	ListIndustry = 2,
	ListSource = 3,
	ListRating = 4,
	ListContactCategory = 5,
	ListReferrerCategory = 6,
	ListWorkGroup = 7,
	ListCompanyType = 8,
	ListPhase = 9,
	ListZone = 10,
	ListOpportunityCategory = 11,
	ListLostReason = 12,
	ListOwner = 13,
	ListCountry = 14,
	TicketArea = 15,
	TicketType = 16,
	JobOrderStatus = 17,
	JobOrderTaskStatus = 18,
	JobOrderType = 19,
	JobOrderPriority = 20,
	TicketStatus = 21,
	BusinessRole = 22,
	QuoteStage = 23,
	PriceListDescription = 24,
	Currency = 25,
	Appearance = 26,
	DeliveryCarriage = 27,
	ListActivityType = 28,
	EmailType = 29,
	ListLeadStatus = 30,
	ListLeadPriority = 31,
	ListLeadInterest = 32,
	TicketPriority = 33,
	MailCategory = 34,
	CustomErpDocumentType = 35,
	CatalogCategory = 36,
	EventItemStatus = 37,
	UnitOfMeasure = 38,
	ListCategoryContract = 39,
	Payment = 40,
	TaxValue = 41,
	DeliveryCause = 42,
	ListPaymentsMode = 43,
	PhoneType = 44,
	Office = 45,
	WorkTeam = 46,
	ReportCategories = 47,
	PrivacyTypeManagement = 48,
	PrivacyMotivationsImplicitConsent = 49,
	PrivacyRuleForList = 50,
	StorageStatus = 51,
	DefaultDocumentCategories = 52,
	ActivityClassification = 53,
	ActivityState = 54,
	ActivityPriority = 55,
	Language = 56,
	TimeZone = 57,
	Culture = 58,
	OpportunityStatus = 59,
	ImapCommand = 60,
	Calendars = 61,
	Relations = 62,
	FlowToType = 63,
	RecurrenceType = 64,
	RecurrenceFrequency = 65,
	RecurrenceDayKind = 66,
	Tags = 67,
	MessageChannel = 68,
	AccountAlias = 69,
	SalesTargetType = 70,
	SalesTargetReference = 71,
	MailingListDeliverStatus = 72,
	MailTemplates = 73,
	WorkRange = 74,
	Banks = 75,
	ShippedBy = 76,
	BusinessObjectTypes = 77,
	BusinessObjectActions = 78,
	CatalogPriceListKind = 79,
	CatalogPriceListRound = 80,
	MailingDomainStatus = 81,
	CurrencyISOCodes = 82,
	WorkPlanStatus = 83,
	OrderStage = 84
}
