export default {
  back: 'Atrás',
  search: 'Buscar',
  choose: 'Seleccione...',
  commands: {
    actions: 'Acciones',
    save: 'Guardar',
    delete: 'Eliminar',
    edit: 'Editar',
    remove: 'Quitar',
    copy: 'Copia',
    copied: 'Copiado en el portapapeles',
    close: 'Cerrar',
    clone: 'Duplicar/Clonar',
    view: 'Read',
    create: 'Create',
    yes: 'Sí',
    no: 'No',
    ok: 'Aceptar',
    accept: 'Acepta',
    never: 'Alguna vez',
    always: 'Siempre',
    convert: 'Convierte',
    reload: 'Volver a cargar',
    move: 'Mover',
    deleteConfirm: '¿Estás seguro que deseas eliminar el registro?',
    results: {
      savesuccess: 'Guardado con éxito'
    },
    print: 'Imprimir',
    plus: 'Añadir',
    export: 'Exportación',
    copyClipboard: 'Copiar en el portapapeles',
    rotateDevice: 'Gire el dispositivo',
    parameter: {
      value: 'Valor',
      dynamic: 'Parámetro dinámico'
    },
    discard: 'Descartar los cambios',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    click: 'Haz clic aquí',
    rename: 'Cambiar el nombre',
    warning: '¡Atención!',
    undo: 'Cancelar',
    redo: 'Repetir',
    call: 'Llamar',
    next: 'Siguiente'
  },
  lists: {
    list: 'Lista',
    kanban: 'Kanban'
  },
  token: {
    resign: 'Accede',
    expired: 'Imposible acceder a {server}',
    refresh: '{message}. Vuelva a iniciar sesión para continuar utilizando el servicio.'
  },
  lookup: {
    account: 'Cuenta',
    activity: 'Acciones Comerciales',
    zone: 'Zona',
    catalogCategory: 'Categorías de productos'
  },
  mail: {
    subject: 'Asunto',
    from: 'De:',
    to: 'A:',
    cc: 'CC:',
    bcc: 'CCO:',
    validation: {
      domain: 'El dominio {dominio} no existe o no tiene un MX válido',
      format: 'El correo electrónico {mail} no tiene un formato válido',
      mailbox: 'El correo {mail} parece no existir en el servidor de destino'
    }
  },
  confirmPassword: 'Confirmación password',
  changePassword: 'Cambiar contraseña',
  password: {
    type: 'Introduzca la nueva contraseña',
    expired: 'Contraseña caducada',
    changed: 'Su contraseña se ha cambiado correctamente',
    wrong: 'La contraseña no es válida. ¿Quieres intentarlo de nuevo?'
  },
  avatar: {
    error: {
      limit: '¡La imagen no debe ser mayor de 2 MB!',
      format: 'La imagen debe ser JPG o PNG en formato'
    },
    discover: {
      title: 'Se han encontrado los siguientes logotipos para la pestaña que está viendo',
      link: 'Seleccione el logotipo que desea asociar con la pestaña',
      source: 'Origen logotipo: {source}',
      found: 'Sí, encontrado, es esto',
      nobody: 'No, ninguno de estos',
      searching: 'Estoy mirando en la web',
      search: 'Buscar online'
    }
  },
  layout: {
    viewProfile: 'Mi perfil',
    mailbox: 'Bandejas de entrada',
    messages: 'Mensajes',
    help: '¿Necesitas ayuda?',
    guide: 'Ir a la ayuda online',
    logout: 'Salir'
  },
  maps: {
    coords: 'Coordenadas',
    latitude: 'Latitud',
    longitude: 'Longitud',
    import: {
      search: 'Buscar en el mapa',
      import: 'Importar estos datos personales'
    }
  },
  offline: 'Atención, se ha detectado un problema en la red. Comprueba tu conexión a Internet y vuelve a ejecutar la operación',
  group: {
    title: 'Gestión del grupo',
    selected: 'Seleccionado',
    unselected: 'No seleccionado',
    add: 'Agregar grupo',
    manage: 'Administrar grupos'
  },
  alls: 'todos',
  note: 'Notas',
  printing: 'Impresión',
  category: {
    global: 'global',
    personal: 'Personal'
  },
  validation: {
    required: {
      fields: 'No todos los campos obligatorios se han introducido',
      title: 'Valor obligatorio',
      once: 'Se requiere al menos uno de los campos',
      field: 'El campo {field} es obligatorio'
    }
  },
  popupBlocker: {
    detected: 'Se ha detectado un bloqueador de las ventanas emergentes',
    deactivate: '¡Hola, confía en nosotros, desactiva el bloqueador de ventanas emergentes para disfrutar plenamente de las características del CRM!'
  },
  modules: {
    errors: {
      load: 'Se ha producido un error en la carga del módulo\'{module}\'. Asegúrate de que no haya bloqueadores de anuncios que no permitan que el CRM se cargue correctamente e intenta volver a cargar la página.',
      reload: 'Vuelva a cargar la página'
    }
  },
  alarms: {
    notification: {
      title: 'Recordatorios de vencimiento',
      dateAge: 'Vencimiento',
      note: 'Notas',
      postpones: 'Posponer {n} minutos'
    }
  },
  licence: {
    buy: 'Activa la licencia',
    pro: {
      required: 'Función disponible en la versión Profesional'
    },
    business: {
      required: 'Función activa en la versión Business'
    },
    required: 'Función no disponible en la versión actual'
  },
  loading: {
    modules: 'Descargar módulos',
    profile: 'Cargando el perfil',
    connecting: 'Conectando al sistema',
    satellite: 'Alineando los satélites',
    ramp: 'Preparando el lanzamiento',
    warmup: 'Calentando los motores',
    testing: 'Ultimando los detalles',
    starting: 'Ignición'
  },
  lock: {
    title: '{name} ya está en uso',
    locked: '{name} está abierto en otro navegador. Haz clic en "Usar aquí" para usar {name} en este navegador.',
    unlock: 'Usar aquí',
    close: 'Cerrar'
  },
  messages: {
    nodisplay: 'Ups... Todavía no hay nada que ver',
    nomessages: 'Nadie te ha escrito todavía'
  },
  unauthorized: {
    read: 'No se te permite acceder a los elementos de esta área'
  },
  form: {
    duration: {
      custom: 'Duración personalizada'
    }
  },
  integrations: {
    office365: 'Abrir en Office365',
    zoom: 'Abrir en zoom',
    calendly: 'Abrir en Calendly',
    skype: 'Abrir en Skype',
    linkedin: 'Abrir en Linkedin',
    webex: 'Abrir en Webex',
    clickmeeting: 'Abrir en ClickMeeting',
    maps: 'Abrir Mappa',
    lifesizecloud: 'Abrir en Lifesize',
    gotomeeting: 'Abrir en GoToMeeting',
    googlemeet: 'Abrir en Google Meet',
    skymeeting: 'Abrir en SkyMeeting',
    teams: 'Abrir en Teams',
    generic: 'Abrir en {hostname}'
  }
};