//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LocalizeMode {
	Standard = 0,
	Simple = 1,
	TextArea = 2,
	TitleAndHtml = 3,
	Html = 4
}
