//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CalendarPermits {
	AllowCalendarOverlap = 80,
	ViewOnlyOtherAccountEngage = 98,
	CanOpenPrivateAppointment = 99,
	CheckCalendarOverlapOnlyForSameCalendar = 102,
	EnableGroupsFilter = 206
}
