//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum QuoteOptions {
	Appereance = 1,
	Porto = 2,
	Carrier = 4,
	ShippedBy = 8,
	Bank = 16,
	Cost = 32,
	PrintLongDescrition = 64,
	ViewPaymentCondition = 128
}
