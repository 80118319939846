<template>
  <div class="avatar-uploader p-b p-t" v-loading="autoDiscovering"
    :element-loading-text="$t('avatar.discover.searching')">
    <span class="content" :style="(isValidImage || base64value) ? {} : { minWidth: maxWidth }">
      <el-upload action :before-upload="beforeAvatarUpload" :http-request="handleAuthenticatedRequest"
        :show-file-list="false" accept="image/*" :class="{ unexistent: !isValidImage }" :on-error="handleError">
        <span v-if="base64value">
          <img :src="`data:image/png;base64, ${base64value}`" class="image" />
        </span>
        <span v-else-if="isValidImage">
          <img :src="photoUrl" class="image" />
        </span>
        <span v-else class="avatar-uploader-icon" :class="icon"></span>
      </el-upload>
      <transition name="el-zoom-in-center">
        <div v-if="autoDiscoverAvailable" class="auto-discover-button m-t p-t">
          <el-button class="m-t" type="primary" icon="el-icon-fa-searchengin el-icon-fab" @click="autoDiscoverImages"
            v-indexed>{{ $t('avatar.discover.search') }}</el-button>
        </div>
      </transition>
    </span>
    <span v-if="(isValidImage || base64value)" class="delete-command" @click="removeAvatar()">
      <i class="el-icon-icon-cross"></i>
    </span>
    <el-dialog :visible.sync="autoDiscoverVisible" :close-on-click-modal="false">
      <template #title>
        <h3>{{ $t('avatar.discover.title') }}</h3>
        <h5 class="m-t-h">
          <cite>{{ $t('avatar.discover.link') }}</cite>
        </h5>
      </template>
      <el-carousel type="card" v-if="autoDiscoveredLogos" :interval="carouselIndex" :autoplay="false"
        @change="handleCarouselChange">
        <el-carousel-item v-for="(logo, index) in autoDiscoveredLogos" :key="index" class="auto-discover-item p-b">
          <el-row align="middle" justify="center" class="p-t p-b h-100" type="flex">
            <el-col :span="24">
              <i class="m-b logo-source text text-info">{{ $t('avatar.discover.source', { source: logo.source }) }}</i>
              <img :src="`data:image/png;base64, ${logo.logo}`" class="image" />
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
      <template #footer>
        <div class="text-right">
          <el-button @click="() => autoDiscoverVisible = false" icon="el-icon-fa-thumbs-down fas">{{
            $t('avatar.discover.nobody')
          }}</el-button>
          <el-button @click="() => handleDiscoverLogoSelect()" type="primary" icon="el-icon-fa-thumbs-up fas">{{
            $t('avatar.discover.found')
          }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IImageDiscoverRequest } from 'rt/Base/Avatar/IImageDiscoverRequest';
import { ILogo } from 'rt/Interfaces/Misc/ILogo';
import { ImageDiscoverController } from 'rt/UIApiControllers/UI/Smart/ImageDiscoverController';
import difference from 'utils/difference';
import _ from 'lodash';
import jwtManager from 'utils/jwtManager';
import b64toBlob from 'b64-to-blob';

@Component({
  name: 'Base64AvatarUpload',
})
export default class Base64AvatarUpload extends Vue {
  imageDiscoverController = new ImageDiscoverController(Vue.axios);

  @Getter('baseUrl')
  baseUrl;

  @Prop({
    type: String,
    required: false,
  })
  value: string;

  @Prop({
    type: String,
    required: false,
  })
  uploadEndpoint: string;

  @Prop({
    type: Object,
    required: false,
  })
  discover: IImageDiscoverRequest;

  get photoUrl() {
    return this.value;
  }

  @Watch('uploadEndpoint')
  handleEndpointChange(to, from) {
    if (from == null && to != null && this.file != null) {
      this.submitFiles(this.file);
    }
  }

  @Prop({
    type: String,
    required: false,
    default: '140px',
  })
  maxWidth: string;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-image2',
  })
  icon: string;

  file: any;

  base64value: any = null;

  deleted = false;

  get isValidImage(): boolean {
    return this.value != null && !this.deleted;
  }

  submitFiles(file: Blob, fileName?: string) {
    if (this.uploadEndpoint) {
      const formData = new FormData();
      formData.append('file', file, fileName);

      this.$http
        .post(this.uploadEndpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.file = null;
          this.$emit('uploaded');
        })
        .catch(() => { });
    }
  }

  async handleAuthenticatedRequest(request: { file: File }) {
    const b64 = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(request.file);
      reader.onload = () => {
        this.file = request.file;
        this.submitFiles(this.file);
        resolve(reader.result as any);
      };
      reader.onerror = (error) => reject(error);
    });
    const idx = b64.indexOf(',');
    if (idx >= 0) {
      this.base64value = b64.substring(idx + 1);
    }
  }

  async removeAvatar() {
    this.base64value = null;
    if (this.uploadEndpoint) {
      await this.$http.delete(this.uploadEndpoint);
      this.deleted = true;
    }
  }

  avatarSizeLimit: number = 2 * 1024 * 1024;

  beforeAvatarUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size < this.avatarSizeLimit;

    if (!isJPG && !isPNG) {
      this.$message.error(this.$t('avatar.error.format') as string);
      return false;
    }
    if (!isLt2M) {
      this.$message.error(this.$t('avatar.error.limit') as string);
      return false;
    }
    return true;
  }

  async handleError(err: { message: string }, fr: { fileGuid: string; fileName: string }, file, fileList): Promise<any> {
    this.$error.handleWebApiException({ status: 400, data: JSON.parse(err.message) });
  }

  beforeDestroy() {
    if (this.discoverCancelTokenSource) {
      this.discoverCancelTokenSource.cancel('aborting pending request');
    }
  }

  autoDiscovering = false;

  carouselIndex = 0;

  autoDiscoverVisible = false;

  autoDiscoveredLogos: ILogo[] = null;

  discoverCancelTokenSource: CancelTokenSource;

  handleCarouselChange(index) {
    this.carouselIndex = index;
  }

  get autoDiscoverAvailable(): boolean {
    if (this.autoDiscovering) {
      return false;
    }
    if (this.base64value) {
      return false;
    }
    if (this.value) {
      return false;
    }
    if (!this.discover) {
      return false;
    }
    if (this.autoDiscoverVisible) {
      return false;
    }
    if ((this.discover.domain && this.discover.domain.length > 0) || (this.discover.emails && this.discover.emails.length > 0)) {
      return true;
    }
    return false;
  }

  async autoDiscoverImages() {
    if (this.discoverCancelTokenSource) {
      this.discoverCancelTokenSource.cancel('aborting pending request');
    }
    if (this.autoDiscoverAvailable) {
      this.discoverCancelTokenSource = axios.CancelToken.source();
      this.autoDiscovering = true;
      try {
        const logos = await this.imageDiscoverController.Post(this.discover, 300, this.discoverCancelTokenSource.token);
        this.autoDiscovering = false;
        this.autoDiscoveredLogos = logos;
        this.carouselIndex = 0;
        if (logos && logos.length) {
          this.autoDiscoverVisible = true;
        }
      } catch (e) {
        this.autoDiscovering = false;
        throw e;
      }
    }
  }

  handleDiscoverLogoSelect() {
    this.autoDiscoverVisible = false;
    const logo = this.autoDiscoveredLogos[this.carouselIndex];
    if (logo && logo.logo) {
      this.base64value = logo.logo as any as string;
      const blob = b64toBlob(this.base64value, 'image/png') as any;
      this.submitFiles(blob, 'autodiscover.png');
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'element-variables';

.image {
  max-width: 100%;
}

$--uploader-sys-color: #8c939d;
$--uploader-border-color: #d9d9d9;

.avatar-uploader {
  .content {
    display: inline-block;
    position: relative;

    .auto-discover-button {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;

      & button {
        pointer-events: all;
      }
    }
  }

  .delete-command {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3px;
    border-bottom: 1px dashed $--uploader-border-color;
    border-left: 1px dashed $--uploader-border-color;
    color: $--uploader-sys-color;
  }

  &:hover {
    .delete-command {
      display: block;
    }
  }

  & ::v-deep { .unexistent {
    & .el-upload {
      border: 1px dashed $--uploader-border-color;
    }
  }
}

  ::v-deep { .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: block;
  }
}

  ::v-deep { .el-upload:hover {
    border-color: #409eff;
  }
}

  .avatar-uploader-icon {
    font-size: 6em;
    color: mix($--color-white, $--uploader-sys-color, 70%);
    display: block;
    min-height: 100px;
    width: 100%;
    line-height: 100px;
    text-align: center;
  }

  .auto-discover-item {
    border: 1px dashed $--uploader-border-color;

    ::v-deep { .el-row {
      overflow: hidden;
    }
  }

    .logo-source {
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .auto-discover-item:nth-child(2n) {
    background-color: mix($--color-white, $--color-text-regular, 70%);
  }

  .auto-discover-item:nth-child(2n + 1) {
    background-color: mix($--color-white, $--color-text-regular, 90%);
  }
}
</style>
