export default {
  commands: {
    actions: 'Azioni',
    next: 'Avanti',
    click: 'clicca qui',
    save: 'Salva',
    discard: 'Abbandona le modifiche',
    confirm: 'Conferma',
    cancel: 'Annulla',
    delete: 'Cancella',
    rename: 'Rinomina',
    remove: 'Rimuovi',
    warning: 'Attenzione!',
    copy: 'Copia',
    copied: 'Copiato negli appunti',
    close: 'Chiudi',
    clone: 'Duplica/Clona',
    view: 'Visualizza',
    create: 'Crea',
    edit: 'Modifica',
    yes: 'Si',
    no: 'No',
    ok: 'Ok',
    accept: 'Accetta',
    never: 'Mai',
    always: 'Sempre',
    convert: 'Converti',
    reload: 'Ricarica',
    move: 'Muovi',
    undo: 'Annulla',
    redo: 'Ripeti',
    call: 'Chiama',
    deleteConfirm: "Sicuro di voler cancellare l'elemento?",
    results: {
      savesuccess: 'Salvataggio eseguito con successo',
    },
    print: 'Stampa',
    plus: 'Aggiungi',
    export: 'Esporta',
    copyClipboard: 'Copia negli appunti',
    rotateDevice: 'Ruota il tuo dispositivo',
    parameter: {
      value: 'Valore',
      dynamic: 'Parametro dinamico',
    },
  },
  lists: {
    kanban: 'Kanban',
    list: 'Lista',
  },
  confirmPassword: 'Conferma Password',
  changePassword: 'Cambia Password',
  password: {
    type: 'Inserisci la nuova Password',
    expired: 'Password scaduta',
    changed: 'La password è stata modificata con successo',
    wrong: 'La password non è valida. Vuoi riprovare?',
  },
  token: {
    expired: 'Impossibile accedere a {server}',
    refresh: "{message}. Riesegui l'accesso per continuare ad utilizzare il servizio.",
    resign: 'Accedi',
  },
  avatar: {
    error: {
      limit: "L'immagine non deve avere una dimensione superiore ai 2MB!",
      format: "L'immagine dev'essere di formato JPG o PNG",
    },
    discover: {
      title: 'Sono stati trovati i seguenti loghi per la scheda che stai visualizzando',
      link: 'Seleziona il logo che vuoi associare alla scheda',
      source: 'Origine logo: {source}',
      found: 'Si, trovato, è questo',
      nobody: 'No, nessuno di questi',
      searching: 'Sto cercando nel web',
      search: 'Cerca online',
    },
  },
  layout: {
    viewProfile: 'Il mio profilo',
    mailbox: 'Casella di posta',
    messages: 'Messaggi',
    help: 'Hai bisogno di aiuto?',
    guide: 'Vai alla guida on-line',
    logout: 'Esci',
  },
  maps: {
    coords: 'Coordinate',
    latitude: 'Latitudine',
    longitude: 'Longitudine',
    import: {
      search: 'Ricerca su mappa',
      import: "Importa quest'anagrafica",
    },
  },
  search: 'Cerca',
  choose: 'Seleziona...',
  back: 'Indietro',
  offline: "Attenzione, è stato rilevato un problema di rete. Controllare la connessione internet e rieseguire l'operazione",
  group: {
    title: 'Gestione dei gruppi',
    selected: 'Selezionati',
    unselected: 'Non selezionati',
    add: 'Aggiungi gruppo',
    manage: 'Gestisci gruppi',
  },
  alls: 'Tutti',
  note: 'Note',
  others: ` | e un'altro | e altri {count}`,
  printing: 'Stampa in corso',
  category: {
    global: 'Globale',
    personal: 'Personale',
  },
  lookup: {
    account: 'Account',
    activity: 'Attività',
    zone: 'Zona',
    catalogCategory: 'Categorie prodotti',
  },
  validation: {
    required: {
      fields: 'Non tutti i campi obbligatori sono stati compilati',
      title: 'Valore obbligatorio',
      once: 'Almeno uno dei campi è obbligatorio',
      field: 'Il campo {field} è obbligatorio',
    },
  },
  popupBlocker: {
    detected: 'Rilevato pop-up blocker',
    deactivate: 'Ciao, fidati di noi, disattiva il pop-up blocker per godere a pieno delle funzionalità del CRM!',
  },
  modules: {
    errors: {
      load: "C'è stato un errore nel caricamento del modulo '{module}'. Assicurarsi che non ci siano ad-blocker che non consentano al CRM di caricarsi correttamente, e provare a ricaricare la pagina.",
      reload: 'Ricarica la pagina',
    },
  },
  alarms: {
    notification: {
      title: 'Scadenza promemoria',
      dateAge: 'Scadenza',
      note: 'Note',
      postpones: 'Rimanda di {n} minuti',
    },
  },
  licence: {
    buy: 'Attiva la tua licenza',
    required: 'Funzionalità non disponibile nella versione corrente',
    pro: {
      required: 'Funzionalità presente dalla versione Professional',
    },
    business: {
      required: 'Funzionalità presente nella versione Business',
    },
  },
  mail: {
    from: 'DA:',
    to: 'A:',
    cc: 'CC:',
    bcc: 'BCC:',
    subject: 'Oggetto',
    validation: {
      format: 'La mail {mail} non è in formato valido',
      domain: 'Il dominio {domain} non esiste oppure non ha un MX valido',
      mailbox: 'La mail {mail} sembra non esistere nel server di destinazione',
    },
  },
  loading: {
    modules: 'download moduli',
    profile: 'caricando il profilo',
    connecting: 'connettendo il sistema',
    satellite: 'allineando i satelliti',
    ramp: 'alzando la rampa di lancio',
    warmup: 'scaldando i motori',
    testing: 'ultime simulazioni',
    starting: 'accensione',
  },
  lock: {
    title: '{name} già in uso',
    locked: '{name} è aperto in un\'altro browser. Clicca "Usa qui" per usare {name} in questo browser.',
    unlock: 'Usa qui',
    close: 'Chiudi',
  },
  messages: {
    nodisplay: 'ops.. ancora niente da vedere',
    nomessages: 'nessuno ti ha ancora scritto',
  },
  unauthorized: {
    read: "Non sei autorizzato ad accedere agli elementi di quest'area",
  },
  form: {
    duration: {
      custom: 'Durata personalizzata',
    },
  },
  integrations: {
    webex: 'Apri in Webex',
    zoom: 'Apri in Zoom',
    calendly: 'Apri in Calendly',
    skype: 'Apri in Skype',
    linkedin: 'Apri in Linkedin',
    office365: 'Apri in Office365',
    clickmeeting: 'Apri in ClickMeeting',
    maps: 'Apri Mappa',
    lifesizecloud: 'Apri in Lifesize',
    gotomeeting: 'Apri in GoToMeeting',
    googlemeet: 'Apri in Google Meet',
    skymeeting: 'Apri in SkyMeeting',
    teams: 'Apri in Teams',
    generic: 'Apri in {hostname}',
  },
};
