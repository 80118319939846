<template>
  <div>
    <t-storage-browser-table class="storage-lookup-table" :data="currentData" @input="handleClick"
      @details="(row) => handleFileCommand('details', row)" :last-modified-date="false" :last-modified-by-id="false"
      :border="false" :stripe="false" :highlight-current-row="false">
      <template #commands="{ row }">
        <t-storage-file-commands @command="command => handleFileCommand(command, row)" key="fileCommands"
          v-if="row.type === 'file'" :delete="false" :rename="false">
          <el-dropdown-item command="remove">{{ $t('storage.commands.remove') }}</el-dropdown-item>
        </t-storage-file-commands>
      </template>
      <template #append>
        <div v-if="enableUpload">
          <el-upload ref="uploader" class="upload" multiple :action="`${baseUrl}/api/latest/Upload/UploadFile`"
            :on-success="handleSuccess" :before-upload="handleBeforeUpload" :file-list="uploadFileList"
            :on-error="handleError">
            <div class="text-right text text-1x">
              <t-tooltip-label :content="$t('storage.commands.drop')">
                <i class="el-icon-icon-cloud-upload cursor-pointer m-r-q"></i>
              </t-tooltip-label>
              <t-tooltip-label :content="$t('storage.commands.existent')">
                <i class="el-icon-search cursor-pointer" @click.prevent.stop="handleArchive"></i>
              </t-tooltip-label>
            </div>
          </el-upload>
        </div>
      </template>
    </t-storage-browser-table>
    <div v-if="dialogVisible">
      <t-storage-browser-lookup @select="handleSelect" :visible.sync="dialogVisible"></t-storage-browser-lookup>
    </div>

    <el-dialog :title="`${$t('storage.commands.details')} ${detailFile.fileName}`" :visible.sync="showDetail"
      :width="$viewport.dialog" v-if="detailFile" append-to-body>
      <t-storage-detail :key="detailFile.id" :value="detailFile"></t-storage-detail>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { IWidget } from 'rt/Contracts/UI/IWidget';
import { Getter, Action, namespace } from 'vuex-class';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { IStorageCategoryDTO } from 'dto/IStorageCategoryDTO';
import { StorageCategoryController } from 'rt/UIApiControllers/BusinessObject/DTO/StorageCategoryController';
import { StorageController } from 'rt/UIApiControllers/BusinessObject/DTO/StorageController';
import { Prop, Watch, Inject } from 'vue-property-decorator';
import approx from 'approximate-number';
import _ from 'lodash';
import StorageBrowserMixin from '../storage/StorageBrowserMixin';
import TStorageBrowserTable from '../storage/StorageBrowserTable.vue';
import TStorageFileCommands from '../storage/StorageFileCommands.vue';
import TStorageBrowserLookup from '../storage/StorageBrowserLookup.vue';
import TStorageDetail from '../storage/StorageDetail.vue';
import ElementUI from 'element-ui';
// tslint:disable-next-line:variable-name
const StorageAction = namespace('storage').Action;
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageLookup',
  components: {
    TStorageBrowserTable,
    TStorageFileCommands,
    TStorageBrowserLookup,
    TStorageDetail,
  },
})
export default class StorageLookup extends Vue {
  @Inject('elForm') elForm: ElementUI.Form;

  @Getter baseUrl: string;

  @StorageGetter controller: StorageController;

  @StorageGetter sizeLimit;

  @Prop({ type: Array, required: false })
  value: number[];

  @Prop({ type: String, default: 'download' })
  defaultFileAction: string;

  @Prop({ type: Boolean, default: true })
  enableUpload: boolean;

  @Watch('value')
  async handleValueChange() {
    await this.reset();
  }

  files: IStorageDTO[] = [];

  dialogVisible = false;

  protected async buildUploadStorageDTO(fr: { fileGuid: string; fileName: string }): Promise<any> {
    // TODO: choose category
    return {
      attachments: [fr],
      type: 0,
      description: '',
      distinct: true,
    };
  }

  get currentData() {
    return [...this.files.map(this.fromStorageDtoToGridDate)];
  }

  async mounted() {
    if (this.elForm && this.elForm.$el) {
      this.setDragFileTarget(this.elForm.$el);
    }
    await this.reset();
  }

  beforeDestroy() {
    if (this.elForm && this.elForm.$el) {
      this.removeDragFileTarget(this.elForm.$el);
    }
  }

  overlay: HTMLElement = null;

  setDragFileTarget(form) {
    form.classList.add('storage-target-zone');
    this.overlay = document.createElement('DIV');
    this.overlay.classList.add('overlay');
    form.appendChild(this.overlay);
    form.addEventListener('dragenter', this.handleDragEnter, false);
    this.overlay.addEventListener('dragover', this.handleDragOver, false);
    this.overlay.addEventListener('dragleave', this.handleDragLeave, false);
    this.overlay.addEventListener('dragend', this.handleDragEnd, false);
    this.overlay.addEventListener('drop', this.handleDrop, false);
  }

  removeDragFileTarget(form) {
    this.overlay.removeEventListener('dragover', this.handleDragOver, false);
    this.overlay.removeEventListener('dragleave', this.handleDragLeave, false);
    this.overlay.removeEventListener('dragend', this.handleDragEnd, false);
    this.overlay.removeEventListener('drop', this.handleDrop, false);

    if (this.overlay) {
      form.removeChild(this.overlay);
    }
    form.removeEventListener('dragenter', this.handleDragEnter, false);
    document.body.removeAttribute('draggable');
    form.classList.remove('storage-target-zone');
  }

  containsFiles(event) {
    if (event.dataTransfer.types) {
      return event.dataTransfer.types.includes('Files');
    }
    return false;
  }

  handleDragEnter(e) {
    if (this.containsFiles(e)) {
      document.body.setAttribute('draggable', 'draggable');
      e.preventDefault();
      this.elForm.$el.classList.add('storage-lookup-drag');
    }
  }

  handleDragOver(e) {
    e.preventDefault();
    if (e.currentTarget) {
      this.elForm.$el.classList.add('storage-lookup-drag');
    }
  }

  handleDrop(e) {
    e.stopPropagation();
    e.preventDefault();
    const uploader = this.$refs['uploader'] as any;
    for (const file of e.dataTransfer.files) {
      uploader.handleStart(file);
    }
    this.$nextTick(() => {
      uploader.submit();
    });
    this.stopDraggable();
  }

  handleDragLeave(e) {
    this.stopDraggable();
  }

  handleDragEnd(e) {
    this.stopDraggable();
  }

  stopDraggable() {
    document.body.removeAttribute('draggable');
    this.elForm.$el.classList.remove('storage-lookup-drag');
  }

  async browseDirectory() {
    this.reset();
  }

  handleClick(row) {
    this.$emit('file', row.id);
    if (this.defaultFileAction) {
      this.handleFileCommand(this.defaultFileAction, row);
    }
  }

  private async loadDocumentsFromODataApi(ids: number[]): Promise<IStorageDTO[]> {
    if (ids == null || ids.length === 0) {
      return [];
    }
    return (await this.controller.MultipleGet({ ids })).map((s) => s.item);
  }

  protected async loadFiles(): Promise<IStorageDTO[]> {
    const files = (await this.loadDocumentsFromODataApi(this.value)) as IStorageDTO[];
    return files;
  }

  handleArchive() {
    this.dialogVisible = true;
  }

  async handleSelect(id: number) {
    this.dialogVisible = false;
    const dto = await this.getStorageDtoAfterUpload(id);
    this.$emit('input', _.uniq([...(this.value || []), id]));
  }

  protected fromStorageDtoToGridDate(f: IStorageDTO) {
    return {
      type: 'file',
      id: f.id,
      description: f.fileName,
      size: approx(f.size, { capital: true, suffix: 'b' }),
      icon: `el-icon-${f.icon}`,
    };
  }

  uploadFileList: any[] = [];

  handleBeforeUpload(file) {
    const isLt10M = file.size < this.sizeLimit;
    if (!isLt10M) {
      this.$alert(
        this.$t('storage.error.limit', {
          file: file.name,
          limit: approx(this.sizeLimit, {
            suffix: 'b',
          }),
        }) as string,
        {
          type: 'error',
        },
      );
    } else {
      this.uploadFileList = [...this.uploadFileList, file];
    }
    return isLt10M;
  }

  async getStorageDtoAfterUpload(id: number): Promise<IStorageDTO> {
    return await this.controller.Get(id);
  }

  uploadChain: Promise<void> = new Promise((resolve, reject) => {
    try {
      resolve();
    } catch (e) {
      reject(e);
    }
  });

  async handleSuccess(fr: { fileGuid: string; fileName: string }, file, fileList): Promise<any> {
    if (fr) {
      this.uploadChain = this.uploadChain.then(async () => {
        try {
          const res = await this.controller.UploadMultipleFiles(await this.buildUploadStorageDTO(fr));
          if (res && res.length) {
            const id = res[0];
            // const file = await this.getStorageDtoAfterUpload(id);
            this.$emit('input', _.uniq([...(this.value || []), id]));
          }
        } catch { } finally {
          this.uploadFileList = this.uploadFileList.filter((f) => f.uid !== file.uid);
        }
      });
    }
  }

  async handleError(err: { message: string }, fr: { fileGuid: string; fileName: string }, file, fileList): Promise<any> {
    this.$error.handleWebApiException({ status: 400, data: JSON.parse(err.message) });
  }

  showDetail = false;

  detailFile: IStorageDTO = null;

  async handleFileCommand(command: string, file: IStorageDTO) {
    switch (command) {
      case 'download':
        await this.download(file);
        break;
      case 'remove':
        this.removeFile(file);
        break;
      case 'details':
        this.detailFile = this.files.find((f) => f.id === file.id);
        if (this.detailFile != null) {
          this.showDetail = true;
        }
        break;
      case 'select':
        this.$emit('select', file);
        break;
    }
  }

  removeFile(file) {
    this.$confirm(this.$t('storage.commands.detach') as string, {
      type: 'warning',
    })
      .then((v) => {
        const index = this.value.findIndex((v) => v === file.id);
        this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
      })
      .catch(() => { });
  }

  async open(file: any) {
    const res = await this.controller.GetDownloadUrl(file.id);
    const link = document.createElement('a');
    link.href = `${res}&inline=true`;
    link.target = '_blank';
    link.download = file.description;
    document.body.appendChild(link);
    link.click();
  }

  async download(file: any) {
    const res = await this.controller.GetDownloadUrl(file.id);
    this.$download(res, file.description);
  }

  protected async reset(): Promise<void> {
    return await this.loadDocuments();
  }

  private async loadDocuments(): Promise<void> {
    this.files = await this.loadFiles();
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
@import '~@/reset.scss';

.file {
  cursor: pointer;
}

.commands {
  cursor: pointer;
}

.upload {
  margin-top: $-margin-size-quarter;
  border-bottom: 1px solid $--border-color-base;
  clear: right;
  overflow: hidden;

  &.focus {
    border-color: $--input-focus-border;
  }

  & ::v-deep{ .el-upload--text {
    display: block;
  }
}
}

.storage-lookup-table ::v-deep{ .el-table__header-wrapper {
  display: none;
}
}
</style>
<style lang="scss">
.storage-target-zone {
  position: relative;

  .overlay {
    // border: 2px dashed silver;
    display: none;
  }

  &.storage-lookup-drag {
    .overlay {
      display: block;
      opacity: 0.8;
      background-color: white;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='400px'><text x='20' y='50' fill='silver' fill-opacity='0.5' font-size='50' font-family='Arial'>Drop File Here</text></svg>");
      border: 2px dashed silver;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
    }
  }
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
</style>
