<template>
  <el-row>
    <el-col>
      <el-tag class="el-tag-tag" :key="tag.value" v-for="tag in selectedTags" :closable="!readOnly"
        :disable-transitions="false" @close="handleRemoveTag(tag.value)" size="medium">{{ tag.text }}</el-tag>
      <el-popover v-model="popoverOpen" placement="right" trigger="click" v-if="!readOnly">
        <el-row>
          <el-col>
            <el-row class="m-b">
              <el-col>
                <el-autocomplete style="width: 100%;" v-model="tagInputValue" autofocus
                  v-on:keydown.enter.prevent.native="handleCreateNewTag" :fetch-suggestions="tagSearch"
                  @select="handleTagSelected" value-key="text"></el-autocomplete>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-radio v-model="radioGlobalPersonal" label="global">{{ $t('category.global') }}</el-radio>
                <el-radio v-model="radioGlobalPersonal" label="personal">{{ $t('category.personal') }}</el-radio>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <template #reference>
          <el-button class="button-new-tag m-l-q">+</el-button>
        </template>
      </el-popover>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ListsType } from 'rt/Core/ListsType';
import BaseTag from './BaseTag';
import { ReferrerCategoryController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';

@Component({
  name: 'ReferrerCategoryTag',
})
export default class ReferrerCategoryTag extends BaseTag {
  referrerCategoryController = new ReferrerCategoryController(Vue.axios);

  async createNewTag(text: string, global: boolean): Promise<number> {
    const newInstance = await this.referrerCategoryController.GetNewInstance();
    newInstance.description = text;
    newInstance.personal = !global;
    return await this.referrerCategoryController.CreateOrUpdate(newInstance);
  }

  getListType(): ListsType {
    return this.$enums.ListsType.ListReferrerCategory;
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.el-tag {
  margin-bottom: $-margin-size-quarter;
  margin-right: $-margin-size-quarter;
}

.el-tag+.el-tag {}
</style>
