import Vue from 'vue';

const eventHub: Vue = new Vue();

class EventHubManger {
  hubs: { [key: string]: Vue } = {};

  getHub(module: string): Vue {
    if (!Object.prototype.hasOwnProperty.call(this.hubs, module)) this.hubs[module] = new Vue();
    return this.hubs[module];
  }

  removeHub(module: string) {
    if (!Object.prototype.hasOwnProperty.call(this.hubs, module)) delete this.hubs[module];
  }
}
export default new EventHubManger();
