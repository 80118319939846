import { ActionContext } from 'vuex';
import { RootState } from '@/store/state';
import Vue from 'vue';
import rootStore from '@/store';
import _ from 'lodash';
import { MessageBox } from 'element-ui';
// await store.dispatch('loadModule', );
// lists.init();
// await store.dispatch('profile/init');

export class ModuleManager {
  private modules: {
    [module: string]: {
      required: boolean;
      loader: () => Promise<{ init: () => void }>;
      dependencies?: string[];
    };
  } = {
    // syntax () => import is needed for loading modules on demand, after login, in module initialization

    grids: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/grids') },
    advancedSearch: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/advancedSearch') },
    lists: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/lists') },
    help: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/help') },
    profile: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/profile') },
    signature: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/signature') },
    messages: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/messages') },
    'phone-bar': { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/phone-bar') },
    flow: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/flow') },
    // optional modules
    today: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/today') },
    calendar: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/calendar'), dependencies: ['appointment', 'calendarEvent'] },
    company: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/company'), dependencies: ['address'] },
    contact: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/contact'), dependencies: ['address'] },
    lead: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/lead'), dependencies: ['address'] },
    opportunity: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/opportunity') },
    address: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/address') },
    activity: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/activity') },
    setup: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/setup'), dependencies: ['hook', 'groups'] },
    freefield: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/freefield') },
    tustenaCompany: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/tustenaCompany') },
    groups: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/groups') },
    hook: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/hook') },
    list: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/list') },
    webmail: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/webmail') },
    mailTemplate: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/mailTemplate') },
    account: { required: true, loader: () => import(/* webpackChunkName: "crm" */ '../modules/account') },
    appointment: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/appointment') },
    calendarEvent: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/calendarEvent') },
    mailing: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/mailing') },
    workPlan: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/workPlan') },
    layoutStudio: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/layoutStudio'), dependencies: ['freefield'] },
    analytics: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/analytics') },
    import: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/import') },
    flowTemplate: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/flowTemplate') },
    storage: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/storage') },
    quote: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/quote') },
    order: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/order') },
    printTemplate: { required: false, loader: () => import(/* webpackChunkName: "setup" */ '../modules/printTemplate') },
    catalog: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/catalog'), dependencies: ['catalogProductPrice'] },
    catalogProductPrice: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/catalogProductPrice') },
    suggestion: { required: false, loader: () => import(/* webpackChunkName: "crm" */ '../modules/suggestion') },
  };

  private loadedModules: {
    [module: string]: boolean;
  } = {};

  async loadAllModules(store: ActionContext<RootState, any>): Promise<void> {
    for (const module in this.modules) {
      if (Object.prototype.hasOwnProperty.call(this.modules, module)) {
        // eslint-disable-next-line no-await-in-loop
        await this.loadModule(store, module);
      }
    }
    await this.loadTourModules(store);
    await this.loadSuggestionModules(store);
  }

  async loadTourModules(store: ActionContext<RootState, any>) {
    const tourInitializer = await import(/* webpackChunkName: "components" */ '../modules/tour');
    tourInitializer.init();
  }

  async loadSuggestionModules(store: ActionContext<RootState, any>) {
    this.loadModule(store, 'suggestion');
  }

  async loadRequiredModules(store: ActionContext<RootState, any>) {
    // fill required modules
    const modules = [];
    for (const module in this.modules) {
      if (Object.prototype.hasOwnProperty.call(this.modules, module)) {
        if (this.modules[module].required) {
          modules.push(module);
        }
      }
    }
    await this.loadModules(store, modules);
  }

  getUniqueModulesToLoad(store: ActionContext<RootState, any>, modules: string[]) {
    let loadingModules = [...modules];
    for (const module of modules) {
      const m = this.modules[module];
      if (m && m.dependencies) {
        loadingModules = [...loadingModules, ...m.dependencies];
      }
    }
    return _.uniq(loadingModules);
  }

  async reloadModules(store: ActionContext<RootState, any>, modules: string[]) {
    for (const module of this.getUniqueModulesToLoad(store, modules)) {
      if (Object.prototype.hasOwnProperty.call(this.modules, module)) {
        if (!this.loadedModules[module]) {
          // eslint-disable-next-line no-await-in-loop
          await this.loadModule(store, module);
        }
      }
    }
  }

  async loadModules(store: ActionContext<RootState, any>, modules: string[]) {
    for (const module of this.getUniqueModulesToLoad(store, modules)) {
      if (Object.prototype.hasOwnProperty.call(this.modules, module)) {
        // eslint-disable-next-line no-await-in-loop
        await this.loadModule(store, module);
      } else {
        console.warn(`module ${module} not found`);
      }
    }
    await this.loadTourModules(store);
    await this.loadSuggestionModules(store);
  }

  async loadModule(store: ActionContext<RootState, any>, module: string, retry = 0) {
    try {
      if (this.loadedModules[module]) {
        rootStore.unregisterModule(module);
      }
      const m = await this.modules[module].loader();
      m.init();
      await store.dispatch(`${module}/init`);
      this.loadedModules[module] = true;
    } catch (err) {
      console.error(err);
      if (retry > 10) {
        await MessageBox.alert(<string>Vue.i18n.t('modules.errors.load', { module }), {
          type: 'error',
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          confirmButtonText: <string>Vue.i18n.t('modules.errors.reload'),
        });
        location.reload();
        throw err;
      }
      return new Promise<void>((resolve, reject) => {
        window.setTimeout(async () => {
          try {
            await this.loadModule(store, module, retry + 1);
            resolve();
          } catch {
            reject();
          }
        }, 1000);
      });
    }
  }

  async unloadAllModules() {
    for (const module in Object.keys(this.loadedModules)) {
      if (Object.prototype.hasOwnProperty.call(this.loadedModules, module) && this.loadedModules[module]) {
        rootStore.unregisterModule(module);
      }
    }
    this.loadedModules = {};
  }

  isModuleLoaded(module: string): boolean {
    return this.loadedModules[module] || false;
  }
}
const moduleManager = new ModuleManager();
export default moduleManager;
