<template>
  <el-tag
    class="el-tag-lookup"
    :type="type"
    v-bind="$props"
    :disable-transitions="false"
    @close="$emit('close')"
    @click.native.stop
  >
    <slot :title="value.title" :id="id" :prefix="prefix">
      <span
        @mouseover="() => hover = true"
        @mouseout="() => hover = false"
        v-if="route || hoverIcon"
      >
        <router-link
          v-if="route"
          :to="$routeHelper.findRouteFromBusinessObjectType(routes, getIdentifier, id)"
          :style="{ 'color': 'inherit' }"
        >
          <template v-if="icon">
            <i
              :class="`${hoverIcon || 'el-icon-icon-square-up-right'} m-r-q`"
              v-if="hover"
              key="hover"
            ></i>
            <i :class="`el-icon-${icon} m-r-q`" v-else key="icon"></i>
          </template>
          <span>{{ value.title }}</span>
        </router-link>
        <template v-else-if="icon">
          <i
            :class="`${hoverIcon || 'el-icon-icon-square-up-right'} m-r-q cursor-pointer`"
            v-if="hover"
            key="hover"
            @click="$emit('click')"
          ></i>
          <i :class="`el-icon-${icon} m-r-q`" v-else key="icon"></i>
          <span>{{ value.title }}</span>
        </template>
      </span>
      <span v-else>
        <i :class="`el-icon-${icon} m-r-q`" v-if="icon"></i>
        <span>{{ value.title }}</span>
      </span>
    </slot>
  </el-tag>
</template>

<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import businessObjectTypeMapping from 'utils/businessObjectTypeMapping';
import { Getter, Action, namespace } from 'vuex-class';

@Component({
  name: 'LookupSkin',
})
export default class LookupTag extends Vue {
  @Getter('routes') routes;

  @Prop({
    type: Object,
    required: true,
  })
  value: any;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  closable: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'medium',
  })
  size: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  route: boolean;

  @Prop({
    type: String,
    required: false,
  })
  hoverIcon: string;

  hover = false;

  get id(): number {
    return +this.value.key.split('-')[1];
  }

  get prefix(): string {
    return this.value.key.split('-')[0];
  }

  get type() {
    const t = this.getIdentifier;
    if (!isNaN(t)) {
      return businessObjectTypeMapping.toTagType(t);
    }
    return null;
  }

  get icon() {
    const t = this.getIdentifier;
    if (!isNaN(t)) {
      return businessObjectTypeMapping.toIcon(t);
    }
    return null;
  }

  get getIdentifier(): number {
    if (this.value && this.value.key) {
      return +this.$enums.BusinessObjectType[this.prefix];
    }
    return null;
  }
}
</script>
