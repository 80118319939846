<template>
  <div class="detail-page">
    <transition name="fade-jump-bottom" mode="in-out">
      <div v-if="model === null" key="loading" class="loading">
        <el-row :gutter="10" class="m-b-h" v-if="header">
          <el-col>
            <el-card>
              <t-loading-header></t-loading-header>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="m-b-h" v-if="aside">
          <el-col
            :lg="5"
            :xl="4"
            :md="6"
            :sm="14"
            :xs="20"
            :class="{ 'float-side-bar': isFloating }"
            v-if="isSideVisible"
          >
            <t-loading-side></t-loading-side>&nbsp;
          </el-col>
          <el-col
            :lg="isSideVisible ? 19 : 24"
            :xl="isSideVisible ? 20 : 24"
            :md="isSideVisible ? 18 : 24"
          >
            <el-card>
              <t-loading-layout-schema></t-loading-layout-schema>
            </el-card>
          </el-col>
        </el-row>
        <el-card v-else>
          <t-loading-layout-schema></t-loading-layout-schema>
        </el-card>
      </div>
      <div v-else key="view">
        <el-row :gutter="10" class="m-b-h" v-if="header">
          <el-col>
            <transition mode="out-in" name="el-zoom-in-bottom">
              <router-view v-if="model" name="header" key="header" />
            </transition>
          </el-col>
        </el-row>

        <el-row :gutter="10" v-if="aside" :class="{ 'm-b-h': !$route.meta.fixed }">
          <el-col
            :lg="5"
            :xl="4"
            :md="6"
            :sm="14"
            :xs="20"
            :class="{ 'float-side-bar': isFloating }"
            v-if="isSideVisible"
            class="no-print"
          >
            <span v-if="model">
              <transition mode="out-in" name="el-zoom-in-center">
                <router-view name="aside" />
              </transition>
            </span>
            &nbsp;
          </el-col>
          <el-col
            :lg="isSideVisible ? 19 : 24"
            :xl="isSideVisible ? 20 : 24"
            :md="isSideVisible ? 18 : 24"
          >
            <el-row :gutter="10">
              <el-col>
                <router-view name="breadcrumb" />
              </el-col>
            </el-row>
            <transition mode="out-in" name="fade-jump-bottom">
              <div v-if="model">
                <el-card :body-style="{ 'padding-bottom': $route.meta.fixed ? '0px': null }">
                  <span>
                    <router-view key="tab" name="tab" v-if="tab" class="no-print"></router-view>
                    <router-view key="content" v-if="content"></router-view>
                  </span>
                </el-card>
                <router-view name="outer"></router-view>
              </div>
            </transition>
          </el-col>
        </el-row>
        <el-card v-else>
          <span v-if="model">
            <router-view name="tab" v-if="tab" class="no-print" />
            <router-view />
          </span>
        </el-card>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'DetailPage',
})
export default class DetailPage extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  aside: boolean;

  @Prop({
    type: Object,
    required: false,
  })
  model: any;

  @Prop({
    type: Boolean,
    default: true,
  })
  header: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  tab: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  content: boolean;

  sideVisible = false;

  @Prop({
    type: Number,
    default: 992,
  })
  limit: number;

  @Prop({
    type: Boolean,
    default: true,
  })
  loading: boolean;

  created() {
    this.$root.$on('openHeaderMenu', (v) => {
      this.sideVisible = v;
    });
  }

  beforeDestroy() {
    this.$root.$off('openHeaderMenu');
  }

  get isSideVisible() {
    if (this.$viewport.width >= this.limit) return true;
    return this.sideVisible;
  }

  get isFloating() {
    return this.$viewport.width < this.limit;
  }
}
</script>
<style lang="scss" scoped>
@import 'element-variables';
.detail-page {
  position: relative;
  & .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}
.float-side-bar {
  position: absolute;
  ::v-deep{ .el-card {
    -webkit-box-shadow: 6px 10px 6px -6px rgba(125, 125, 125, 1);
    -moz-box-shadow: 6px 10px 6px -6px rgba(125, 125, 125, 1);
    box-shadow: 6px 10px 6px -6px rgba(125, 125, 125, 1);
  }
  // background-color: $--color-white;
  z-index: 200;
  left: 0;
  top: 0;
}
}
</style>
