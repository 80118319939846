<template>
  <t-form-item
    :label="schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <el-input-number
      controls-position="right"
      type="numeric"
      :value="bouncedValue"
      @input="change"
      :disabled="schema.readOnly"
      :min="schema.min == null ? -Infinity : schema.min"
      :max="schema.max == null ? Infinity : schema.max"
    ></el-input-number>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'NumericInput',
  mixins: [simpleMixin],
})
export default class NumericInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  bounce = 300;

  @Watch('value')
  handleValueChange(value: number) {
    this.bouncedValue = value;
  }

  bouncedValue: number = null;

  mounted() {
    this.bouncedValue = this.value;
  }

  bouncedValueChange(value) {}

  change(value: number) {
    this.bouncedValue = value;
    this.bouncedChange(value);
  }

  created() {
    this.bouncedChange = _.debounce((value) => {
      if (this.value !== value) {
        this.$emit('input', value);
      }
    }, this.bounce);
  }

  beforeDestroy() {
    this.bouncedChange.cancel();
  }

  bouncedChange: any = null;
}
</script>
