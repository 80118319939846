<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import OpportunityLayoutStudio from './OpportunityLayoutStudio.vue';
import eventHubManager from 'modules/mixin/views/EventHub';

// tslint:disable-next-line:variable-name
const OpportunityAction = namespace('opportunity').Action;
// tslint:disable-next-line:variable-name
const OpportunityGetter = namespace('opportunity').Getter;

@Component({
  name: 'OpportunityFastLayoutStudio',
  props: {
    defaults: {
      type: Object,
      required: false,
    },
  },
})
export default class OpportunityFastLayoutStudio extends OpportunityLayoutStudio {
  @OpportunityAction('schema') loadSchema: (suffix: string) => Promise<any>;

  @OpportunityAction('loadNew') loadNew: (id: number) => Promise<any>;

  @OpportunityAction('reset') reset: () => Promise<any>;

  defaults: any;

  async mounted() {
    await this.reset();
    await this.loadSchema(null);
    await this.loadNew(this.defaults);
  }

  get labelWidth() {
    const lw = this.$viewport.formLabelWidth;
    if (!lw) {
      return lw;
    }
    return '100px';
  }

  get eventHub(): Vue {
    return eventHubManager.getHub('opportunity.fast');
  }
}
</script>
