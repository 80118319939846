//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ConsumptionType {
	Hours = 0,
	Minutes = 1,
	Elements = 2,
	Manual = 3
}
