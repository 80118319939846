//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CatalogSearchOption {
	Code = 0,
	CodeAndProductName = 1,
	CodeAndDescription = 2,
	CodeNameAndDescription = 3
}
