//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OrderStage {
	Tobeconfirmed = 1,
	NotDispached = 2,
	PartialyDispached = 3,
	Suspended = 4,
	OnHold = 5,
	Dispached = 6
}
