//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TustenaDBColumnType {
	Text = 0,
	Number = 1,
	Date = 2,
	Hour = 3,
	List = 4,
	Multiline = 5,
	Currency = 6,
	WebSite = 7,
	Email = 8,
	LinkToCompany = 9,
	LinkToContact = 10,
	LinkToLead = 11,
	Flag = 12,
	CheckList = 13,
	RadioList = 14,
	Matrix = 15,
	LinkToCatalog = 16,
	LinkToAnagraphic = 17,
	Cati = 18,
	LinkToOpportunity = 19,
	LinkToTicket = 20,
	AutomaticDate = 21,
	LinkToEvent = 22,
	LinkToJobOrder = 23,
	LinkToJobOrderTask = 24,
	LinkToRollingStock = 25,
	LinkToContract = 26
}
