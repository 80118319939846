//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IFilePreview } from './IFilePreview';

//element.Name=ActivityController result.Name=ActivityController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.ActivityController);
/** Result of WebApiControllerGenerator activity */
export class ActivityController extends DefaultDtoControllerProxy<IActivityDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Activities', BusinessObjectType.Activity);
	}
	public CreateOrUpdateAndSendEmail(dto: IActivityDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Activities/CreateOrUpdateAndSendEmail', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SendEmail(dto: IActivityDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Activities/SendEmail', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EmailLog(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Activities/EmailLog?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Reply(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO>
	{
		return (this.axios.post('Activities/Reply?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ReplyAll(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO>
	{
		return (this.axios.post('Activities/ReplyAll?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Forward(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO>
	{
		return (this.axios.post('Activities/Forward?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Activities/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Activities/Download?'+'&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Activities/HtmlPreview?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Activities/Previews?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Activities/Preview?'+'&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Activities/GetDownloadUrl?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Activities/Archive?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Activities/PrintParameters?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO>
	{
		return (this.axios.get('Activities/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
