import { BusinessObjectType } from 'rt/Core/BusinessObjectType';

export class BusinessObjectTypeMapping {
  private iconMapping: {
    [key: number]: string;
  } = {
    [BusinessObjectType.Account]: 'icon-user',
    [BusinessObjectType.Company]: 'icon-factory',
    [BusinessObjectType.Contact]: 'icon-user-tie',
    [BusinessObjectType.Lead]: 'icon-users3',
    [BusinessObjectType.CalendarEvent]: 'icon-bookmark4',
    [BusinessObjectType.WebMailAccount]: 'icon-envelop',
    [BusinessObjectType.Appointment]: 'icon-calendar2',
    [BusinessObjectType.Opportunity]: 'icon-golf2',
    [BusinessObjectType.Activity]: 'icon-clipboard2',
    [BusinessObjectType.FlowTemplate]: 'fa-robot fad',
    [BusinessObjectType.Flow]: 'icon-strategy',
    [BusinessObjectType.TustenaCompany]: 'icon-office',
    [BusinessObjectType.Storage]: 'icon-archive',
    [BusinessObjectType.MailTemplate]: 'icon-insert-template',
    [BusinessObjectType.Mailing]: 'icon-bullhorn',
    [BusinessObjectType.List]: 'icon-list',
    [BusinessObjectType.Signature]: 'fa-signature',
    [BusinessObjectType.Message]: 'icon-bubbles4',
    [BusinessObjectType.Hook]: 'icon-atom2',
    [BusinessObjectType.Catalog]: 'fa-cookie-bite fas',
    [BusinessObjectType.Addresses]: 'icon-location5',
    [BusinessObjectType.Quote]: 'fa-file-invoice-dollar fas',
    [BusinessObjectType.PriceList]: 'fa-list-ol fad',
    [BusinessObjectType.Group]: 'fa-object-group fad',
    [BusinessObjectType.WorkPlan]: 'fa-line-columns fad',
  };

  toIcon(type: BusinessObjectType): string {
    return this.iconMapping[type];
  }

  private tagTypeMapping: {
    [key: number]: string;
  } = {
    [BusinessObjectType.Company]: null,
    [BusinessObjectType.Contact]: 'success',
    [BusinessObjectType.Lead]: 'warning',
    [BusinessObjectType.Opportunity]: null,
    [BusinessObjectType.Activity]: 'info',
  };

  toTagType(type: BusinessObjectType): string {
    return this.tagTypeMapping[type];
  }
}

export default new BusinessObjectTypeMapping();
