//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChartType {
	Pie = 0,
	BarGraph = 1,
	LineGraph = 2,
	MultiBarGraph = 3,
	MultiLineGraph = 4,
	Bubble = 5,
	Funnel = 6,
	Gauge = 7,
	StackedArea = 8,
	None = -1
}
