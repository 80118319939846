<template>
  <span v-if="value > 0">
    <el-dropdown-item v-loading="loading" :divided="divided" @click.native="print" v-indexed>
      <t-icon-label :icon="icon">
        <slot>{{ $t('commands.print') }}</slot>
      </t-icon-label>
    </el-dropdown-item>
    <el-dialog
      :visible.sync="printDialogOpen"
      v-if="printDialogOpen"
      :width="$viewport.dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <t-printer v-bind="$props"></t-printer>
    </el-dialog>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { IPrintDtoControllerProxy } from 'modules/mixin/store/print/IPrintDtoControllerProxy';
import axios, { CancelToken, CancelTokenSource } from 'axios';

@Component({
  name: 'TPrintDropdownItem',
})
export default class TPrintDropdownItem extends Vue {
  @Prop({ type: Number, required: true })
  value: number;

  @Prop({ type: Object, default: null, required: false })
  parameters;

  @Prop({ type: Boolean, default: false, required: false })
  loading;

  @Prop({ type: String, default: 'el-icon-icon-printer', required: false })
  icon;

  @Prop({ type: Boolean, default: false, required: false })
  divided: boolean;

  @Prop({ type: String, required: true })
  module: string;

  printDialogOpen = false;

  async print() {
    this.printDialogOpen = true;
  }
}
</script>
