//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SupplierDocumentType {
	Invoice = 0,
	DDT = 1,
	ImmediateInvoice = 2,
	CreditNote = 3,
	StockMovement = 4
}
