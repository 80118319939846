//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LogType {
	Phase = 0,
	Status = 1,
	LostReason = 2,
	Budget = 3,
	Amount = 4,
	CloseDate = 5,
	Category = 6,
	Description = 7,
	Probability = 8,
	WorkPlan = 9
}
