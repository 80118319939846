<script lang="ts">
import Component from 'vue-class-component';
import { ILeadDTO } from 'dto/ILeadDTO';
import LayoutStudioMixinView from 'modules/mixin/views/LayoutStudioMixin.vue';
import { LeadConversionType } from 'rt/Core/LeadConversionType';
import LeadMixin from './LeadMixin';
import { LeadController } from 'rt/UIApiControllers/BusinessObject/DTO/LeadController';

@Component({
  name: 'LeadLayoutStudioView',
  mixins: [LeadMixin],
})
export default class LeadLayoutStudioView extends LayoutStudioMixinView<ILeadDTO> {
  protected get module(): string {
    return 'lead';
  }

  protected get routeName(): string {
    return this.$routeNames.lead;
  }

  protected get backRouteName(): string {
    return this.$routeNames.leads;
  }

  onCreated() {
    this.eventHub.$on('convert', async (a: LeadConversionType) => {
      if (!isNaN(a)) {
        await this.convert(a);
      }
    });
  }

  // It's good to clean up event listeners before
  // a component is destroyed.
  onBeforeDestroy() {
    this.eventHub.$off('convert');
  }

  async convert(a: LeadConversionType) {
    if (this.dirty) {
      if (!(await this.saveModel())) {
        return;
      }
    }
    this.saving = true;
    const results = await (this.controller as LeadController).Convert(this.model.id, a);
    if (results.length) {
      const result = results[0];
      if (result.key === this.$enums.BusinessObjectType.Company) {
        this.$router.replace({
          name: this.$routeNames.company,
          params: {
            id: this.$hashids.encode(result.value),
          },
        });
      } else {
        if (result.key === this.$enums.BusinessObjectType.Contact) {
          this.$router.replace({
            name: this.$routeNames.contact,
            params: {
              id: this.$hashids.encode(result.value),
            },
          });
        }
      }
    }
    this.saving = false;
  }

  protected customRules(rules: any): any {
    const checkAnagraphicName = (rule, value: ILeadDTO, callback) => {
      if (this.model.surname || this.model.name || this.model.companyName) {
        callback();
      } else callback(new Error(this.$t('validation.required.once') as string));
    };

    rules.surname = [{ required: true, validator: checkAnagraphicName }];
    rules.name = [{ required: true, validator: checkAnagraphicName }];
    rules.companyName = [{ required: true, validator: checkAnagraphicName }];
    return rules;
  }
}
</script>
