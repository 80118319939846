<template>
  <el-col v-if="column.sortable" :span="-1" class="text-right overflow-clip">
    <span class="sorting-wrapper nowrap" @click="changeSort" :class="currentSortOrder">
      <i :class="{ 'el-icon-icon-sort': !hasSorting, 'el-icon-icon-arrow-down7': hasSorting, 'rotate-180': currentSortOrder === 'descending'  }"></i>
    </span>
  </el-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'SortingColumn',
})
export default class SortingColumn extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: {
    order: 'ascending' | 'descending' | '';
  };

  @Prop({
    type: Object,
    required: true,
  })
  tableColumn: any;

  @Prop({
    type: Object,
    required: true,
  })
  tableStore: any;

  @Prop({
    type: Object,
    required: false,
  })
  sorting: { columnName: string; direction: 'ascending' | 'descending' };

  get hasSorting(): boolean {
    return this.currentSortOrder === 'ascending' || this.currentSortOrder === 'descending';
  }

  get currentSortOrder(): string {
    if (this.sorting) {
      if (this.tableColumn.property === this.sorting.columnName) {
        return this.sorting.direction;
      }
    }
    return null;
  }

  getNewSortOrder(): string {
    if (this.currentSortOrder === 'ascending') {
      return 'descending';
    }
    if (this.currentSortOrder === 'descending') {
      return null;
    }
    return 'ascending';
  }

  changeSort() {
    const order = this.getNewSortOrder();
    if (order != null) {
      this.$emit('sort-change', { order, prop: this.tableColumn.property });
    } else {
      this.$emit('sort-change', null);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~element-variables';
.sorting-wrapper {
  cursor: pointer;
  &.descending, &.ascending {
    color: $--color-primary;
  }
}
</style>
