/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
import _ from 'lodash';

const difference = <T>(object: T, base: T, maxDepth = Infinity): Partial<T> => {
  const changes = (object, base, depth) => {
    if (depth >= maxDepth) {
      return object;
    }
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key], depth + 1) : value;
      }
    });
  };
  return changes(object, base, 0);
};

const arrayDifference = <T>(object: T[], base: T[]): T[] => [...(<any>difference(object, base)), ...(<any>difference(base, object))];
export { arrayDifference };
export default difference;
