<template>
  <el-container class="outer-container" :style="$route.meta.fixed ? { height: $viewport.containerHeight + 'px' } : {}">
    <app-sidebar v-if="initialized && $viewport.smAndUp" class="no-print" />
    <el-container class="main-container">
      <el-header v-if="$viewport.xs" class="xs-header" height="40px">
        <app-xsheader></app-xsheader>
      </el-header>
      <el-main class="main-page" :style="{ 'margin-top': $viewport.xs ? '40px' : null }"
        :class="{ fullscreen: $route.meta.fullscreen, fixed: $route.meta.fixed }">
        <transition name="el-fade-in-linear" mode="out-in">
          <el-header v-if="isInBlackFriday && false" height="100px" class="bf-header"
            :style="{ paddingBottom: $route.meta.fullscreen ? '0px' : '10px' }" @click.native="goToPurchaseLanding">
            <div class="bg">
              <el-row type="flex" class="h-100">
                <el-col :span="16">
                  <div class="img"></div>
                </el-col>
                <el-col :span="4">
                  <div class="ann"></div>
                </el-col>
                <el-col :span="4">
                  <div class="logo" :style="{ backgroundImage: `url('/${brand.id}/img/logo-white.svg')` }"></div>
                </el-col>
              </el-row>
            </div>
          </el-header>
          <div v-else-if="isInSDSBlackFriday && false" class="bf-header">
            <img src="https://cdn.crmincloud.it/public/black-friday/sds-bar.png"
              style="width: 100%; border-radius: 4px;" />
          </div>
          <div v-else-if="isInSDSCampaign" class="sds-campaign">
            <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
            <a :href="config.campaign.link" target="blank" class="cursor-pointer">
              <div class="img" :style="{ backgroundImage: `url('${config.campaign.url}')` }"></div>
            </a>
          </div>
        </transition>
        <transition name="el-fade-in-linear" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
    <app-bsidebar v-if="initialized && $viewport.smAndUp" @onboarding="onboardingVisible = true" class="no-print" />
    <t-tour v-if="Object.prototype.hasOwnProperty.call($route.query, 'tour')" :delay="tourDelay"
      :only-new="onlyNewTourSteps" @stop="stopTour"></t-tour>
    <el-dialog :visible.sync="onboardingVisible" v-if="onboardingVisible">
      <template #title>
        <h3>{{ $t('onboarding.title', { name: user.name }) }}</h3>
      </template>
      <t-on-boarding-story></t-on-boarding-story>
    </el-dialog>

    <el-dialog :title="activityTitle" :visible.sync="activityCreateDialogOpen" :width="$viewport.dialog"
      v-if="activityCreateDialogOpen" :close-on-click-modal="false" :close-on-press-escape="false">
      <t-activity-layout-studio-tabs-wrapper v-model="activitySubTypeId" :filteredTypes="activityFilterTypes">
        <div class="text-left" v-if="activitySubTypeId">
          <t-activity-fast-layout-studio :defaultType="$enums.ActivityType.Email" :subTypeId="activitySubTypeId"
            :defaults="defaultsActivityProperties" :redirect="false" :schemaName="activitySchemaName"
            :pre-save="activityPreSave" @saved="handleActivityCreated" @sent="handleActivityEMailSent"
            compact></t-activity-fast-layout-studio>
          <t-floating-panel :top="false" opaque spaced>
            <div class="text-right">
              <el-button type="default" size="big" round @click="goToCompleteActivitySheet"
                v-if="activityCompleteButton">{{ $t('calendar.completeSheet') }}</el-button>
              <t-circle-button type="primary" :icon="icon" @click="saveActivity"></t-circle-button>
            </div>
          </t-floating-panel>
        </div>
      </t-activity-layout-studio-tabs-wrapper>
    </el-dialog>
    <t-anagraphic-fast-layout-studio v-if="anagraphicCreateDialogOpen" :visible.sync="anagraphicCreateDialogOpen"
      :defaults="anagraphicDefaults" :company="anagraphicDialogCanCreateCompany"
      :contact="anagraphicDialogCanCreateContact" :lead="anagraphicDialogCanCreateLead" @created="handleAnagraphicCreated"
      compact></t-anagraphic-fast-layout-studio>
    <t-fast-opportunity-layout-studio-dialog v-if="opportunityCreateDialogOpen"
      :visible.sync="opportunityCreateDialogOpen" :defaults="opportunityDefaults" @created="handleOpportunityCreated"
      compact></t-fast-opportunity-layout-studio-dialog>
    <t-fast-list-layout-studio-dialog v-if="listCreateDialogOpen" :visible.sync="listCreateDialogOpen"
      :defaults="listDefaults" @created="handleListCreated" compact></t-fast-list-layout-studio-dialog>
    <t-alarm-notification-dialog></t-alarm-notification-dialog>
    <t-phone-bar v-if="$modules.isModuleLoaded('phone-bar')" ref="phone-bar" class="no-print"></t-phone-bar>
    <t-performance v-if="performance" class="no-print"></t-performance>
    <div v-if="licence != null" class="no-print">
      <el-dialog :visible.sync="showPurchaseDialog" :close-on-click-modal="false" :width="$viewport.maxWidth(700)">
        <template #title>
          <div class="text-center">
            <h3 class="text-primary">{{ $t('account.overlimit.title', { count: Math.abs(user.freeAccounts) }) }}</h3>
          </div>
        </template>
        <div class="text-center">
          <p>{{ $t('account.overlimit.description', { count: Math.abs(user.freeAccounts) }) }}</p>
          <p class="m-t-h">{{ $t('tustenaCompany.update.info2') }}</p>
        </div>
        <t-licence-update-purchase :licence="licence" v-model="toPurchaseUsers"
          @close="showPurchaseDialog = false"></t-licence-update-purchase>
      </el-dialog>
    </div>
    <t-cookies-dialog v-if="$viewport.mdAndUp && initialized" ref="cookie-dialog"></t-cookies-dialog>
  </el-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { Watch, Provide } from 'vue-property-decorator';
import { IActivityDTO } from 'dto/IActivityDTO';
import {
  Sidebar, BSidebar, Breadcrumb, AlarmNotificationDialog, XsHeader,
} from '../components';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import TPhoneBar from 'modules/phone-bar/views/PhoneBar.vue';
import TPerformance from '../components/Performance.vue';
import TAnagraphicFastLayoutStudio from '../components/AnagraphicFastLayoutStudio.vue';
import TFastOpportunityLayoutStudioDialog from '../../opportunity/components/TFastOpportunityLayoutStudioDialog.vue';
import TFastListLayoutStudioDialog from '../../list/components/TFastListLayoutStudioDialog.vue';
import eventHubManger from 'modules/mixin/views/EventHub';
import { ILeadDTO } from '../../../plugins/typings/DTO/ILeadDTO';
import { ICompanyDTO } from '../../../plugins/typings/DTO/ICompanyDTO';
import { IContactDTO } from '../../../plugins/typings/DTO/IContactDTO';
import { IOpportunityDTO } from '../../../plugins/typings/DTO/IOpportunityDTO';
import { IListDTO } from '../../../plugins/typings/DTO/IListDTO';
import trackings, { ICloudUser } from 'utils/trackings';
import { MessageBox } from 'element-ui';
import { PurchaseController } from '@/plugins/typings/UI/Manage/WebApi/PurchaseController';
import { IPurchasedLicence } from '@/plugins/typings/UI/Manage/WebApi/IPurchasedLicence';
import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';
// tslint:disable-next-line:variable-name
const ActivityGetter = namespace('activity').Getter;
// tslint:disable-next-line:variable-name
const ActivityAction = namespace('activity').Action;
@Component({
  name: 'Container',
  components: {
    TPerformance,
    TAnagraphicFastLayoutStudio,
    TFastOpportunityLayoutStudioDialog,
    TFastListLayoutStudioDialog,
    'app-sidebar': Sidebar,
    'app-bsidebar': BSidebar,
    'app-xsheader': XsHeader,
    't-alarm-notification-dialog': AlarmNotificationDialog,
    't-phone-bar': TPhoneBar,
    't-tour': () => import(/* webpackChunkName: "tour" */ '../../tour/components/Tour.vue'),
    't-on-boarding-story': () => import(/* webPackChunkName:"onboarding" */ '../../onboarding/components/TOnBoardingStory.vue'),
    't-activity-fast-layout-studio': () => import(/* webPackChunkName:"activity" */ 'modules/activity/views/ActivityFastLayoutStudio.vue'),
    't-activity-layout-studio-tabs-wrapper': () => import(/* webPackChunkName:"activity */ 'modules/activity/components/ActivityLayoutStudioTabsWrapper.vue'),
  },
})
export default class Container extends Vue {
  @Getter initialized: boolean;

  @Getter user: IUser;

  @Getter performance: boolean;

  @Getter brand;

  @Getter isInBlackFriday: boolean;

  @Getter isInSDSBlackFriday: boolean;

  @Getter isInSDSCampaign: boolean;

  @Getter config: IConfig;

  @ActivityGetter('changes') activityChanges: () => any;

  @ActivityGetter('model') activity: IActivityDTO;

  onboardingVisible = false;

  stopTour() {
    const q = { ...this.$route.query };
    delete q.tour;
    this.$router.replace({
      query: q,
    });
  }

  immediateStart = false;

  onlyNewTourSteps = false;

  created() {
    this.$root.$on('tour', (payload: { immediate: boolean; onlyNew: boolean }) => {
      this.immediateStart = payload.immediate;
      this.onlyNewTourSteps = payload.onlyNew;
      this.$router.replace({
        query: {
          ...this.$route.query,
          tour: null,
        },
      });
    });
    if (this.$chat.exists) {
      this.$chat.user(this.user as ICloudUser);
      this.$root.$on('help', () => {
        this.$chat.user(this.user as ICloudUser);
        this.$chat.show();
        this.$chat.open();
      });
    }
    this.$root.$on('activity', this.createActivity);
    this.$root.$on('anagraphic', this.createAnagraphic);
    this.$root.$on('opportunity', this.createOpportunity);
    this.$root.$on('list', this.createList);
    this.$root.$on('purchase-dialog', this.initPurchaseDialog);
  }

  beforeDestroy() {
    this.$root.$off('tour');
    this.$root.$off('help');
    this.$root.$off('activity');
    this.$root.$off('anagraphic');
    this.$root.$off('opportunity');
    this.$root.$off('list');
    this.$root.$off('purchase-dialog');
  }

  get tourDelay() {
    if (this.immediateStart) {
      return 0;
    }
    return 1000;
  }

  activityCreateDialogOpen = false;

  activitySubTypeId: number = null;

  defaultsActivityProperties: Partial<IActivityDTO> = {};

  activityFilterTypes: number[] = null;

  activityTitle: string = null;

  activitySchemaName: string = null;

  activityPreSave: () => Promise<void> = null;

  activityCompleteButton = true;

  goToCompleteActivitySheet() {
    this.activityCreateDialogOpen = false;
    this.$router.push({
      name: this.$routeNames.activity,
      params: {
        id: this.$hashids.encode(0),
        subTypeId: this.activity.subTypeId.toString(),
        defaults: JSON.stringify(this.activityChanges()),
      },
    });
  }

  saveActivity() {
    return eventHubManger.getHub('activity.fast').$emit('save');
  }

  createActivity(payload: { activity: Partial<IActivityDTO>; filter: number[]; title: string; schema?: string; preSave: () => Promise<void>; complete?: boolean }) {
    this.activitySubTypeId = null;
    this.defaultsActivityProperties = payload.activity;
    this.activityFilterTypes = payload.filter;
    this.activityTitle = payload.title;
    this.activitySchemaName = payload.schema;
    this.activityCreateDialogOpen = true;
    this.activityPreSave = payload.preSave;
    this.activityCompleteButton = payload.complete ?? true;
  }

  handleActivityCreated() {
    this.activityCreateDialogOpen = false;
  }

  handleActivityEMailSent() {
    this.activityCreateDialogOpen = false;
  }

  anagraphicCreateDialogOpen = false;

  anagraphicDefaults: Partial<IActivityDTO | ICompanyDTO | IContactDTO> = {};

  anagraphicTitle: string = null;

  anagraphicDialogCanCreateCompany = false;

  anagraphicDialogCanCreateContact = false;

  anagraphicDialogCanCreateLead = false;

  anagraphicCallback: (payload: { id: number; type: number }) => Promise<void> = null;

  createAnagraphic(payload: { anagraphic: Partial<IActivityDTO | ICompanyDTO | IContactDTO>; company?: boolean; contact?: boolean; lead?: boolean; title: string; callback: (payload: { id: number; type: number }) => Promise<void> }) {
    this.anagraphicDefaults = payload.anagraphic;
    this.anagraphicTitle = payload.title;
    this.anagraphicCreateDialogOpen = true;
    this.anagraphicCallback = payload.callback;
    this.anagraphicDialogCanCreateCompany = payload.company ?? true;
    this.anagraphicDialogCanCreateContact = payload.contact ?? true;
    this.anagraphicDialogCanCreateLead = payload.lead ?? true;
  }

  handleAnagraphicCreated(payload: { id: number; type: number }) {
    this.anagraphicCreateDialogOpen = false;
    if (this.anagraphicCallback) {
      this.anagraphicCallback(payload);
    }
  }

  opportunityCreateDialogOpen = false;

  opportunityDefaults: Partial<IOpportunityDTO> = {};

  opportunityCallback: (id: number) => Promise<void> = null;

  createOpportunity(payload: { defaults: Partial<IOpportunityDTO>; callback: (id: number) => Promise<void> }) {
    this.opportunityDefaults = payload.defaults;
    this.opportunityCreateDialogOpen = true;
    this.opportunityCallback = payload.callback;
  }

  handleOpportunityCreated(id: number) {
    this.opportunityCreateDialogOpen = false;
    if (this.opportunityCallback) {
      this.opportunityCallback(id);
    }
  }

  listCreateDialogOpen = false;

  listDefaults: Partial<IListDTO> = {};

  listCallback: (id: number) => Promise<void> = null;

  createList(payload: { defaults: Partial<IListDTO>; callback: (id: number) => Promise<void> }) {
    this.listDefaults = payload.defaults;
    this.listCreateDialogOpen = true;
    this.listCallback = payload.callback;
  }

  handleListCreated(id: number) {
    this.listCreateDialogOpen = false;
    if (this.listCallback) {
      this.listCallback(id);
    }
  }

  get icon(): string {
    if (this.activity && this.activity.type === this.$enums.ActivityType.Email) {
      if (this.activity.email && !this.activity.email.sent) {
        return 'el-icon-icon-paperplane';
      }
    }
    return 'el-icon-icon-floppy-disk';
  }

  userChangePurchaseTimeout = 0;

  showPurchaseDialog = false;

  toPurchaseUsers: number = null;

  licence: IPurchasedLicence = null;

  purchaseDialogShown = false;

  initPurchaseDialog(user) {
    if (user.isAdmin && !user.trial && user.freeAccounts !== null && user.freeAccounts < 0 && !this.purchaseDialogShown) {
      window.clearTimeout(this.userChangePurchaseTimeout);
      this.userChangePurchaseTimeout = window.setTimeout(async () => {
        if (!this.initialized) {
          this.initPurchaseDialog(user);
          return;
        }
        this.purchaseDialogShown = true;
        if (user.licence.indexOf('A-S') === 0) {
          const controller = new PurchaseController(this.$http);
          this.licence = await controller.Get();
          if (this.licence != null) {
            this.toPurchaseUsers = this.licence.purchasedQuantity + Math.abs(user.freeAccounts);
            this.showPurchaseDialog = true;
          }
        } else {
          await MessageBox.alert(Vue.i18n.t('account.overlimit.description', { count: Math.abs(user.freeAccounts) }) as string, Vue.i18n.t('account.overlimit.title', { count: Math.abs(user.freeAccounts) }) as string, {
            type: 'error',
            confirmButtonText: 'OK',
          });
          this.$router.push({
            name: this.$routeNames.accounts,
          });
        }
        this.showPurchaseDialog = true;
      }, 1000);
    }
  }

  async goToPurchaseLanding() {
    const controller = new PurchaseController(this.$http);
    const res = await controller.Home();
    if (res) {
      window.location.href = res;
    }
  }
}
</script>
<style lang="scss">
@import '~csshake';
@import '~element-variables';

.outer-container {
  min-height: 100%;
}

.xs-header {
  background: $--color-primary;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 500;
}

.xs-header {
  height: 40px;
  width: 100%;
}

.main-container {
  min-height: 100%;
  width: 100vh; // TODO: vedere come mettere meglio
  background-color: $--background-color-base;
}

.el-main {
  padding: $-padding-size-half;

  &.fixed {
    padding-bottom: 0px;
  }
}

.el-footer {
  width: 100%;
}

.el-dialog__wrapper {
  -webkit-overflow-scrolling: touch;
}

.el-input__inner {
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-radius: 0px;
}

.el-input-number {

  & .el-input-number__increase,
  & .el-input-number__decrease {
    border-left-width: 0px !important;
    border-bottom-width: 0px !important;
    -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;
  }

  & .el-input-number__decrease {
    border-right-width: 0px !important;
  }

  & .el-input-number__increase:not(:hover) {
    background-color: transparent;
  }

  & .el-input-number__decrease:not(:hover) {
    background-color: transparent;
  }

  .el-dropdown-link {
    cursor: pointer;
  }
}

.el-input-group__append,
.el-input-group__prepend {
  border-right-width: 0px;
  border-left-width: 0px;
  border-top-width: 0px;
  background-color: transparent;
  border-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.el-switch__core {
  width: 35px !important;
  border-radius: 2px;

  &:after {
    border-radius: 2px;
  }
}

.form-reset {
  .el-input__inner {
    border-left-width: $--border-width-base;
    border-right-width: $--border-width-base;
    border-top-width: $--border-width-base;
    border-radius: $--input-border-radius;
  }

  & .el-input-number__increase,
  & .el-input-number__decrease {
    border-left-width: $--border-width-base !important;
    border-bottom-width: $--border-width-base !important;
    border-right-width: $--border-width-base !important;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    border-right-width: $--border-width-base;
    border-left-width: $--border-width-base;
    border-top-width: $--border-width-base;
    background-color: $--background-color-base;
    padding-left: 10px;
    padding-right: 10px;
  }

  .el-input-group__append {
    border-top-right-radius: $--input-border-radius;
    border-bottom-right-radius: $--input-border-radius;
  }

  .el-input-group__prepend {
    border-top-left-radius: $--input-border-radius;
    border-bottom-left-radius: $--input-border-radius;
  }

  .el-input-group--append {
    & .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .el-input-group--prepend .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

a {
  text-decoration: none;
  color: $--color-primary;
}

.text {
  font-size: 12px;

  & .small,
  &.small {
    font-size: 0.8em;
  }

  & .smaller,
  &.smaller {
    font-size: 0.6em;
  }

  & .normal,
  &.normal {
    font-size: 1em;
  }

  & .big,
  &.big {
    font-size: 1.2em;
  }

  & .bigger,
  &.bigger {
    font-size: 1.4em;
  }
}

.text-success {
  color: $--color-success;
}

.text-warning {
  color: $--color-warning;
}

.text-danger {
  color: $--color-danger;
}

.text-info {
  color: $--color-info;
}

.text-success-light {
  color: $--color-success-light;
}

.text-warning-light {
  color: $--color-warning-light;
}

.text-danger-light {
  color: $--color-danger-light;
}

.text-info-light {
  color: $--color-info-light;
}

.text-success-lighter {
  color: $--color-success-lighter;
}

.text-warning-lighter {
  color: $--color-warning-lighter;
}

.text-danger-lighter {
  color: $--color-danger-lighter;
}

.text-info-lighter {
  color: $--color-info-lighter;
}

.text-primary {
  color: $--color-primary;
}

.text-regular {
  color: $--color-text-regular;
}

.text-secondary {
  color: $--color-text-secondary;
}

.text-placeholder {
  color: $--color-text-placeholder;
}

.text-winner {
  // color: lighten(goldenrod, 10%);
  color: #d4af37;
}

.text-limit {
  max-width: 15em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.main-page {
  &.fullscreen {
    padding: 0px;
    -webkit-transition: padding 0.5s ease-in-out;
    -moz-transition: padding 0.5s ease-in-out;
    -o-transition: padding 0.5s ease-in-out;
    transition: padding 0.5s ease-in-out;
  }
}

.el-menu {
  .el-menu-item {
    line-height: 1.5em;
    display: flex;
    align-items: center;
    white-space: initial;
  }
}

.dash-column {
  width: 100%;
  height: 100%;
  // min-height: 70vh;
  position: relative;

  .el-card__body {
    padding: 4px;
    padding-right: 0px;
    padding-left: 0px;
    height: 100%;

    & .dash-column>div {
      min-height: 100%;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .el-main {
    padding: 2px;
  }
}

.sds-campaign {
  padding: 0px 0px 10px 0px;
  height: 50px;

  & .img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    height: 100%;
    width: 100%;
  }
}

.bf-header {
  padding: 0px 0px 10px 0px;
  height: 100px;

  & .bg {
    background-image: url('https://cdn.crmincloud.it/public/black-friday/bf-bg.png');
    background-repeat: repeat-x;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    position: relative;

    & .img {
      background-image: url('https://cdn.crmincloud.it/public/black-friday/bf-img.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: center;
      height: 100%;
      width: 100%;
    }

    & .ann {
      background-image: url('https://cdn.crmincloud.it/public/black-friday/bf-ann-ex.png');
      background-repeat: no-repeat;
      background-size: 70%;
      background-position-y: center;
      background-position-x: center;
      height: 100%;
      width: 100%;
      max-width: 300px;
    }

    & .logo {
      background-repeat: no-repeat;
      background-size: 60%;
      background-position-y: center;
      background-position-x: center;
      height: 100%;
      width: 100%;
      max-width: 300px;
    }
  }
}
</style>
