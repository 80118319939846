<template>
  <span>
    <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required" v-if="!simple"
      :important="schema.important">
      <t-catalog-category-lookup v-if="!isReadOnly" :value="selectedData" @input="change" :multipleSelection="multiple"
        ref="lookup">
        <template #append>
          <slot name="append"></slot>
        </template>
      </t-catalog-category-lookup>
      <t-lookup-tag v-else :value="catalogCategory" :key="catalogCategory.key" v-for="catalogCategory in selectedData"
        :closable="false"></t-lookup-tag>
    </t-form-item>
    <t-catalog-category-lookup v-else :value="selectedData" @input="change" :multipleSelection="multiple"
      v-bind="$props" :read-only="isReadOnly" ref="lookup">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-catalog-category-lookup>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CatalogCategoryInput',
})
export default class CatalogCategoryInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  async resolve(id: number): Promise<string> {
    return (await this.resolveController.CatalogCategory(id)).name;
  }

  protected get identifier(): string {
    return 'CatalogCategory';
  }
}
</script>
