<template>
  <el-row type="flex" v-if="editing">
    <el-col>
      <el-input
        @click.native.prevent.stop
        ref="input"
        v-model="editingValue"
        @blur="handleChange"
        @keyup.native.enter="handleChange"
        :placeholder="placeholder"
      ></el-input>
    </el-col>
    <el-col :span="-1" class="sort-handle p-r-h p-l-q" v-if="editable.orderPropertyName">
      <i class="fas el-icon-fa-ellipsis-v"></i>
    </el-col>
  </el-row>
  <span v-else>{{ value.text }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';

@Component({
  name: 'RemoteEditableOption',
})
export default class RemoteSelect extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: { value: any; text: any };

  @Watch('value', { deep: true }) handleValueChange(v: { text: any }) {
    this.editingValue = v.text;
  }

  @Prop({
    type: Object,
    required: true,
  })
  editable: { type: number; propertyName: string; orderPropertyName: string; delete: boolean };

  @Prop({
    type: Number,
    required: true,
  })
  type: ListsType;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  editing: boolean;

  @Prop({
    type: String,
  })
  placeholder: string;

  @Prop({
    type: Number,
  })
  lastItemId: number;

  editingValue: string = null;

  mounted() {
    this.editingValue = this.value.text;
  }

  async handleChange() {
    if (!this.editable) {
      this.editingValue = this.value.text;
      return;
    }
    if (this.editingValue.trim() === this.value.text.trim()) {
      return;
    }
    const type = this.$enums.BusinessObjectType[this.editable.type].toString();
    if (this.editingValue && this.editingValue.length) {
      if (this.editable.propertyName) {
        const payload = {
          id: this.value.value ?? -1,
          [this.editable.propertyName]: this.editingValue,
        };
        if (this.value.value === null && this.lastItemId && this.editable.orderPropertyName) {
          payload[this.editable.orderPropertyName] = (await this.$http.get(`${type}/${this.lastItemId}`).then((r) => parseInt(r.data[this.editable.orderPropertyName], 10))) + 1;
        }
        const res = await this.$http.put(type, payload).then((r) => parseInt(r.data, 10));
        if (res > 0) {
          this.$emit('input', {
            id: res,
            text: this.value.text,
          });
        }
      }
    } else {
      // delete mode
      if (this.editable.delete && this.value.value > 0) {
        await this.$http.delete(`${type}/${this.value.value}`);
        this.$emit('input', null);
      } else {
        this.editingValue = this.value.text;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
.remote-editable {
  &:hover {
    color: $--color-primary;
  }
}
</style>
