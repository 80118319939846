//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SharingPriorityMode {
	UserTeamGroup = 0,
	UserGroupTeam = 1,
	TeamUserGroup = 2,
	TeamGroupUser = 3,
	GroupUserTeam = 4,
	GroupTeamUser = 5
}
