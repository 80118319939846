//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SlaTemplateActionType {
	Email = 0,
	Message = 1,
	ChangeOwner = 2,
	ChangePriority = 3,
	ChangeStatus = 4,
	IncrementSLAExpiration = 5,
	Sms = 6
}
