<template>
  <t-input v-bind="$props" @input="v => $emit('input', v)">
    <template #append>
      <i v-if="link == null" class="el-icon-fa-external-link fas text-0_8x"></i>
      <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
      <a v-else class="text-primary cursor-pointer" :href="link" target="_blank" aria-hidden="true">
        <i class="el-icon-fa-external-link fas text-0_8x"></i>
      </a>
    </template>
  </t-input>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'HyperlinkInput',
  mixins: [simpleMixin],
})
export default class Input extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  get link() {
    const v = this.value;
    if (v && v.length) {
      if (v.match(/^(http|ftp|cdn)[s]*:\/\/[\w]+/i)) {
        return v;
      }
      return `//${v}`;
    }
    return null;
  }
}
</script>
