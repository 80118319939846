//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CatalogVariantGridMode {
	None = 0,
	FirstLevel = 1,
	SecondLevelManual = 2,
	SecondLevelAutomatic = 3,
	SecondLevelOnGrid = 4
}
