//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CampaignStatus {
	Open = 0,
	Close = 1,
	Suspend = 2,
	CloseLost = 3,
	QualityCheck = 4
}
