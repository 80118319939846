//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PdfPageSize {
	Letter = 0,
	Ledger = 1,
	Letter11x17 = 2,
	HalfLetter = 3,
	Flsa = 4,
	ArchA = 5,
	ArchB = 6,
	ArchC = 7,
	ArchD = 8,
	ArchE = 9,
	B5 = 10,
	B4 = 11,
	B3 = 12,
	B1 = 13,
	B2 = 14,
	A10 = 15,
	Note = 16,
	Legal = 17,
	A0 = 18,
	A1 = 19,
	A2 = 20,
	B0 = 21,
	A3 = 22,
	A5 = 23,
	A6 = 24,
	A7 = 25,
	A8 = 26,
	A9 = 27,
	A4 = 28
}
