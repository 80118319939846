//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MessageCode {
	Ok = 0,
	CredentialError = 1,
	NotActiveAccount = 2,
	NotActiveCompany = 3,
	TrialExpire = 4,
	NotForBetaVersion = 5,
	PasswordExpired = 6,
	ApplicationUserNotAllowed = 7,
	FailRetryExceeded = 8,
	TSIDMacthButNotYetAttached = 9,
	TSIDActive = 10,
	TSIDGenericError = 11,
	AntiBruteForceAttack = 12,
	ExpireOnLogin = 13,
	LastPasswordChangeTooOld = 14,
	PolyedroGenericError = 15,
	OnlyLoginWithTSID = 16,
	ErrorDuringUserAssociationWithTSID = 17,
	UserAlreadyHasTSID = 18,
	TsIDAlreadyUsedInSameTier = 19
}
