<template>
  <el-dialog :visible.sync="visible" @close="handleCloseNotificationDialog">
    <template #title>
      <t-icon-label shake="shake shake-constant shake-rotate" icon="el-icon-icon-bell3 text-primary icon-1_3x">{{
        $t('alarms.notification.title')
      }}</t-icon-label>
    </template>
    <el-table :data="notifications" style="width: 100%">
      <el-table-column type="expand">
        <template #default="props">
          <div class="pre-wrap">{{ props.row.body }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="note" :label="$t('alarms.notification.note')">
        <template #default="props">
          <a :href="props.row.link" v-if="props.row.link">{{ props.row.note }}</a>
          <span v-else>{{ props.row.note }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dateAge" :label="$t('alarms.notification.dateAge')" width="90"></el-table-column>
    </el-table>
    <div class="m-t text-right">
      <el-button @click="handleCloseNotificationDialog">{{ $t('commands.close') }}</el-button>

      <el-dropdown split-button class="m-l-h" type="primary" @click="() => handlePostPonesNotifications(5)"
        @command="c => handlePostPonesNotifications(+c)">
        <t-icon-label icon="el-icon-icon-bell-plus">{{ $tc('alarms.notification.postpones', 5) }}</t-icon-label>
        <template #dropdown><el-dropdown-menu>
            <el-dropdown-item command="10">
              <t-icon-label icon="el-icon-icon-bell-plus">{{ $tc('alarms.notification.postpones', 10) }}</t-icon-label>
            </el-dropdown-item>
            <el-dropdown-item command="20">
              <t-icon-label icon="el-icon-icon-bell-plus">{{ $tc('alarms.notification.postpones', 20) }}</t-icon-label>
            </el-dropdown-item>
            <el-dropdown-item command="30">
              <t-icon-label icon="el-icon-icon-bell-plus">{{ $tc('alarms.notification.postpones', 30) }}</t-icon-label>
            </el-dropdown-item>
            <el-dropdown-item command="60">
              <t-icon-label icon="el-icon-icon-bell-plus">{{ $tc('alarms.notification.postpones', 60) }}</t-icon-label>
            </el-dropdown-item>
          </el-dropdown-menu></template>
      </el-dropdown>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import ElementUI from 'element-ui';
import Component from 'vue-class-component';
import { Getter, Action, Mutation } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { AlarmController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { INotification } from '@/plugins/typings/Base/Handlers/Comet/INotification';

@Component({
  name: 'AlarmNotificationDialog',
})
export default class AlarmNotificationDialog extends Vue {
  alarmController = new AlarmController(Vue.axios);

  @Getter('notifications') notifications: INotification[];

  @Mutation('resetNotifications') resetNotifications;

  visible = false;

  @Watch('notifications') handleNotificationChange() {
    this.visible = this.notifications.length > 0;
    if (this.visible) {
      this.$beep(3);
    }
  }

  mounted() {
    this.visible = this.notifications.length > 0;
  }

  handleCloseNotificationDialog() {
    this.visible = false;
    this.resetNotifications();
  }

  async handlePostPonesNotifications(minutes) {
    await this.alarmController.PostPones(this.notifications.map((n) => ({ alarmId: n.alarmId, position: n.position })), minutes);
  }
}
</script>
