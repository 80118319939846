//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RowContentType {
	Note = 0,
	Reference = 1,
	Service = 2,
	Catalog = 3,
	Description = 4,
	Value = 5,
	Gift = 6
}
