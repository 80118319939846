import { GetterTree } from 'vuex';
import { RootState } from './state';
import { ActivityPermits } from 'rt/Core/ActivityPermits';
import { CalendarPermits } from 'rt/Core/CalendarPermits';
import { CatalogPermits } from 'rt/Core/CatalogPermits';
import { CompanyPermits } from 'rt/Core/CompanyPermits';
import { ContactPermits } from 'rt/Core/ContactPermits';
import { FaxPermits } from 'rt/Core/FaxPermits';
import { GlobalPermits } from 'rt/Core/GlobalPermits';
import { JobOrderPermits } from 'rt/Core/JobOrderPermits';
import { JobOrderTaskPermits } from 'rt/Core/JobOrderTaskPermits';
import { LeadPermits } from 'rt/Core/LeadPermits';
import { OpportunityPermits } from 'rt/Core/OpportunityPermits';
import { ReportPermits } from 'rt/Core/ReportPermits';
import { SaleDocumentsPermits } from 'rt/Core/SaleDocumentsPermits';
import { SecurityPermits } from 'rt/Core/SecurityPermits';
import { SmsPermits } from 'rt/Core/SmsPermits';
import { StoragePermits } from 'rt/Core/StoragePermits';
import { TicketPermits } from 'rt/Core/TicketPermits';
import { WebMailPermits } from 'rt/Core/WebMailPermits';
import { IMapViewService } from 'rt/Interfaces/Maps/IMapViewService';
import _ from 'lodash';
import { IGeo } from '@/plugins/typings/Calendar/IGeo';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';
import moment from 'moment';

const recursiveMenu = (menu) => {
  let modules = [];
  if (menu) {
    for (const m of menu) {
      if (m.module) {
        modules.push(m.module);
      }
      if (m.subItems) {
        modules = [...modules, ...recursiveMenu(m.subItems)];
      }
    }
  }
  return modules;
};

const defaultLanguage = 'en';
const stateGetters: GetterTree<RootState, any> = {
  isAuthenticated(state) {
    return state.currentUser !== null;
  },
  menu(state) {
    return state.menu;
  },
  modules(state) {
    return _.uniq(recursiveMenu(state.menu));
  },
  user(state) {
    return state.currentUser;
  },
  routes(state) {
    return state.routes;
  },
  baseUrl(state): string {
    return state.baseUrl;
  },
  initialized(state): boolean {
    return state.initialized;
  },
  mapService(state): IMapViewService {
    return state.mapService;
  },
  build(state): string {
    return state.build;
  },
  canActivateDebug(state): boolean {
    return state.build.toLowerCase().indexOf('dev') > -1;
  },
  serverBuild(state): string {
    return state.serverBuild;
  },
  homeLocation(state): IGeo {
    return state.homeLocation;
  },
  officeLocation(state): IGeo {
    return state.officeLocation;
  },
  defaultLanguage(): string {
    return defaultLanguage;
  },
  cookiePreferences(state): { [key: string]: boolean } {
    return state.cookiePreferences;
  },
  config(state): IConfig {
    return state.config;
  },
  brand(state): IBrand {
    return {
      id: state.currentUser?.brand?.id ?? state.brand.id,
      name: state.currentUser?.brand?.name ?? state.brand.name,
      site: state.currentUser?.brand?.site ?? state.brand.site,
      help: state.currentUser?.brand?.help ?? state.brand.help,
      backgrounds: state.currentUser?.brand?.backgrounds ?? state.brand.backgrounds,
    };
  },
  language(state): string {
    try {
      if (state && state.currentUser && state.currentUser.cultureTwoISOSpecific) {
        return state.currentUser.cultureTwoISOSpecific;
      }
      return (navigator.language || defaultLanguage).substring(0, 2);
    } catch (e) {
      console.warn(e);
      return defaultLanguage;
    }
  },
  tour(state): (routeName: string) => string[] {
    return (routeName: string) => state.tours[routeName] || [];
  },
  permits(state) {
    return (
      p:
        | ActivityPermits
        | CalendarPermits
        | CatalogPermits
        | CompanyPermits
        | ContactPermits
        | FaxPermits
        | GlobalPermits
        | JobOrderPermits
        | JobOrderTaskPermits
        | LeadPermits
        | OpportunityPermits
        | ReportPermits
        | SaleDocumentsPermits
        | SecurityPermits
        | SmsPermits
        | StoragePermits
        | TicketPermits
        | WebMailPermits,
    ): boolean => state.permits[p];
  },
  isAdmin(state) {
    if (!state.currentUser) {
      return false;
    }
    return state.currentUser.isAdmin;
  },
  ticks(state) {
    return state.ticks;
  },
  notifications(state) {
    return state.notifications;
  },
  splashMessage(state) {
    if (state.initialized) {
      return 'starting';
    }
    return state.splashMessages[state.splashMessageIndex % state.splashMessages.length];
  },
  locked(state) {
    return state.locked;
  },
  performance(state) {
    return state.performance;
  },
  languageDebug(state) {
    return state.languageDebug;
  },
  mailingProfile(state) {
    return state.mailingProfile;
  },
  markdownCache(state) {
    return state.markdownCache;
  },
  isInSDSBlackFriday(state) {
    if (!state.initialized) {
      return false;
    }
    if (state.currentUser && state.currentUser.trial && state.currentUser.isAdmin) {
      if (state.currentUser.licence.startsWith('SDS-')) {
        const dt = moment();
        return dt.isBetween(
          moment().set({
            year: 2023,
            month: 10,
            date: 22,
          }),
          moment().set({
            year: 2023,
            month: 10,
            date: 30,
          }),
          'day',
          '[]',
        );
      }
    }
    return false;
  },
  isInSDSCampaign(state) {
    if (!state.initialized) {
      return false;
    }
    if (!state.config.campaign) {
      return false;
    }
    if (state.currentUser && state.currentUser.trial && state.currentUser.isAdmin) {
      if (state.currentUser.licence.startsWith('SDS-')) {
        const untilDate = moment(state.config.campaign.until);
        const dt = moment();
        return dt.isBefore(untilDate);
      }
    }
    return false;
  },
  isInBlackFriday(state) {
    if (!state.initialized) {
      return false;
    }
    if (state.currentUser && state.currentUser.trial && state.currentUser.isAdmin) {
      if (state.currentUser.licence.startsWith('A-S')) {
        const dt = moment();
        return dt.isBetween(
          moment().set({
            year: 2024,
            month: 10,
            date: 14,
          }),
          moment().set({
            year: 2024,
            month: 11,
            date: 2,
          }),
          'day',
          '[]',
        );
      }
    }
    return false;
  },
};

export default stateGetters;
