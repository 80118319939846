var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{attrs:{"to":_vm.route}},[_c('div',{class:{ 'mail-item': true, 'mail-seen': _vm.mail.seen, 'mail-selected': _vm.selected }},[_c('div',{staticClass:"nowrap clearfix"},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":-1}},[_c('t-email-gravatar',{attrs:{"email":_vm.mail.fromAddress},scopedSlots:_vm._u([(_vm.mail.isLinkedActivity)?{key:"overlay",fn:function(){return [_c('t-tooltip-label',{attrs:{"content":_vm.$t('mailbox.activityCreated')}},[_c('i',{staticClass:"el-icon-icon-checkmark text text-success"})])]},proxy:true}:null],null,true)})],1),_c('el-col',[_c('div',{staticClass:"text m-l-q",style:(_vm.unSeenStyle)},[_c('div',{staticClass:"m-b-q"},[_c('t-email-address-label',{attrs:{"value":{
                displayName: _vm.mail.fromName,
                address: _vm.mail.fromAddress,
              }}})],1),_c('div',[_c('span',[(_vm.mail.answered)?_c('i',{staticClass:"el-icon-icon-reply"}):_vm._e()]),_c('span',{staticClass:"icon-1x m-r-q",class:{
                'el-icon-fa-smile fad fa-swap-opacity': _vm.positiveSentiment,
                'text-success': _vm.positiveSentiment,
                'el-icon-fa-angry fad fa-swap-opacity': _vm.negativeSentiment,
                'text-danger': _vm.negativeSentiment
              }}),_c('span',[_vm._v(_vm._s(_vm.mail.subject))])])]),_c('div',{staticClass:"text text-right smaller date"},[_vm._v(_vm._s(_vm.$moment(_vm.mail.date).fromNow()))])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }