<template>
  <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required"
    :important="schema.important">
    <t-activity-lookup v-if="!isReadOnly" :value="selectedData" @input="change" :multipleSelection="schema.multiple"
      ref="lookup">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-activity-lookup>
    <t-lookup-tag v-else :route="$modules.isModuleLoaded('activity')" :value="activity" :key="activity.key"
      v-for="activity in selectedData" :closable="false"></t-lookup-tag>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 'ActivityInput',
})
export default class ActivityInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  async resolve(id: number): Promise<string> {
    return (await this.resolveController.Activity(id)).name;
  }

  protected get identifier(): string {
    return 'Activity';
  }
}
</script>
