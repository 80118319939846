//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InvoiceShip {
	NotShipped = 0,
	Email = 1,
	Mail = 2,
	RegisteredMail = 3
}
