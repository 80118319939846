//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ApprovalPendingStatus {
	Pending = 0,
	Approved = 1,
	Rejected = 2,
	Removed = 3
}
