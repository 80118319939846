//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DateTimePrecision {
	DateAndTime = 0,
	DateAndTimeUntilSeconds = 1,
	DateAndTimeUntilMinutes = 2,
	OnlyDate = 3,
	OnlyTime = 4,
	OnlyTimeUntilSeconds = 5,
	OnlyTimeUntilMinutes = 6
}
