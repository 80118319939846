//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContractPermits {
	AllowEditPrice = 4,
	AllowEditPrintSection = 5,
	AllowEditPrintableSheet = 6,
	AllowEditInvoiceConfiguration = 47,
	AllowEditPriceOverDiscount = 49,
	AllowEditNote = 58
}
