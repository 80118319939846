//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ActiveModules } from './ActiveModules';
import { HelperClassType } from './HelperClassType';
import { BusinessObjectType } from './BusinessObjectType';
import { BusinessObjectLoadedType } from './BusinessObjectLoadedType';
import { BusinessObjectInvoker } from './BusinessObjectInvoker';
import { Events } from './Events';
import { Gravity } from './Gravity';
import { LeadCrossType } from './LeadCrossType';
import { ServiceCrossType } from './ServiceCrossType';
import { CrossType } from './CrossType';
import { ErpDocumentStatus } from './ErpDocumentStatus';
import { PhoneNormalizeType } from './PhoneNormalizeType';
import { EMailNormalizeType } from './EMailNormalizeType';
import { NormalizeParsingMode } from './NormalizeParsingMode';
import { ActivityMode } from './ActivityMode';
import { ActivitySource } from './ActivitySource';
import { ActivityPriorities } from './ActivityPriorities';
import { ActivityStates } from './ActivityStates';
import { ActivityClassification } from './ActivityClassification';
import { ToDoStates } from './ToDoStates';
import { CostType } from './CostType';
import { CrossActivityWith } from './CrossActivityWith';
import { FreeFieldsType } from './FreeFieldsType';
import { Direction } from './Direction';
import { MovementAction } from './MovementAction';
import { Gender } from './Gender';
import { DebtRecoveryPhaseCommunication } from './DebtRecoveryPhaseCommunication';
import { DebtRecoveryStatus } from './DebtRecoveryStatus';
import { DebtLogType } from './DebtLogType';
import { ERPRowType } from './ERPRowType';
import { RowContentType } from './RowContentType';
import { InvoiceShip } from './InvoiceShip';
import { InvoiceStatus } from './InvoiceStatus';
import { InvoiceType } from './InvoiceType';
import { InvoiceConditions } from './InvoiceConditions';
import { ShippedBy } from './ShippedBy';
import { ResolvingCrossType } from './ResolvingCrossType';
import { SupplierDocumentType } from './SupplierDocumentType';
import { DueDate } from './DueDate';
import { FixedDRounding } from './FixedDRounding';
import { TaxInstall } from './TaxInstall';
import { LeadConversionType } from './LeadConversionType';
import { StoragePermission } from './StoragePermission';
import { TicketMessageType } from './TicketMessageType';
import { TicketStatusType } from './TicketStatusType';
import { TypeOfTemplateTicket } from './TypeOfTemplateTicket';
import { Tablename } from './Tablename';
import { StatusType } from './StatusType';
import { ListChanges } from './ListChanges';
import { CatalogFlags } from './CatalogFlags';
import { CatalogType } from './CatalogType';
import { RollingInvoiceType } from './RollingInvoiceType';
import { WarrantyTypology } from './WarrantyTypology';
import { MovementType } from './MovementType';
import { FileCrossTypes } from './FileCrossTypes';
import { FileCrossAttributes } from './FileCrossAttributes';
import { MachineType } from './MachineType';
import { ValueType } from './ValueType';
import { CRMTables } from './CRMTables';
import { BOTypeFlags } from './BOTypeFlags';
import { GetNameFor } from './GetNameFor';
import { ActivityMoveLog } from './ActivityMoveLog';
import { MailEvents } from './MailEvents';
import { ActivityType } from './ActivityType';
import { PermissionExceptionType } from './PermissionExceptionType';
import { GlobalSearchType } from './GlobalSearchType';
import { EventType } from './EventType';
import { MailListStatus } from './MailListStatus';
import { GroupProfile } from './GroupProfile';
import { DefaultDocumentCategories } from './DefaultDocumentCategories';
import { FillPageFromControlMode } from './FillPageFromControlMode';
import { TustenaColor } from './TustenaColor';
import { GeograficType } from './GeograficType';
import { CampaignStatus } from './CampaignStatus';
import { PrintModuleType } from './PrintModuleType';
import { QuoteOptions } from './QuoteOptions';
import { CampaignElementsTypeOfView } from './CampaignElementsTypeOfView';
import { TargetListCrossType } from './TargetListCrossType';
import { TargetListMode } from './TargetListMode';
import { TargetDeleteMode } from './TargetDeleteMode';
import { CatalogSearchOption } from './CatalogSearchOption';
import { DefaultCodeType } from './DefaultCodeType';
import { ERCostType } from './ERCostType';
import { ERPaymentType } from './ERPaymentType';
import { OrderStage } from './OrderStage';
import { QuoteStage } from './QuoteStage';
import { TustenaColorTemplates } from './TustenaColorTemplates';
import { TustenaColorInterfaces } from './TustenaColorInterfaces';
import { TemplateColorGroupType } from './TemplateColorGroupType';
import { ExtensionsType } from './ExtensionsType';
import { Changes } from './Changes';
import { InvoiceFixedMonth } from './InvoiceFixedMonth';
import { ActivityRenderType } from './ActivityRenderType';
import { OperationType } from './OperationType';
import { OperationResult } from './OperationResult';
import { LayoutStudioDepth } from './LayoutStudioDepth';
import { Encodings } from './Encodings';
import { AttachmentLocation } from './AttachmentLocation';
import { MethodType } from './MethodType';
import { ConsumptionType } from './ConsumptionType';
import { PasswordRequirements } from './PasswordRequirements';
import { CryptType } from './CryptType';
import { WorkTeamsProperty } from './WorkTeamsProperty';
import { ImportStatus } from './ImportStatus';
import { StorageLogType } from './StorageLogType';
import { StorageShareType } from './StorageShareType';
import { UnifiedMessageService } from './UnifiedMessageService';
import { ReportFaxState } from './ReportFaxState';
import { SmsType } from './SmsType';
import { ReportSmsState } from './ReportSmsState';
import { CatiAnswerType } from './CatiAnswerType';
import { TimerType } from './TimerType';
import { ImportConflictRule } from './ImportConflictRule';
import { OppositionRegistryNumberType } from './OppositionRegistryNumberType';
import { CatiExportCompilationType } from './CatiExportCompilationType';
import { CatiExportDataType } from './CatiExportDataType';
import { CatiQuotaComputeMode } from './CatiQuotaComputeMode';
import { CampaignTypeOfExport } from './CampaignTypeOfExport';
import { FlowObjectType } from './FlowObjectType';
import { FlowToType } from './FlowToType';
import { FlowStepResult } from './FlowStepResult';
import { SocialNetwork } from './SocialNetwork';
import { SocialKeyType } from './SocialKeyType';
import { PartStat } from './PartStat';
import { NotifyAppointmentMode } from './NotifyAppointmentMode';
import { MimeTopLevelMediaType } from './MimeTopLevelMediaType';
import { MessagePrintMode } from './MessagePrintMode';
import { TitleListType } from './TitleListType';
import { ApprovedStatus } from './ApprovedStatus';
import { SalesTargetType } from './SalesTargetType';
import { SalesTargetReference } from './SalesTargetReference';
import { TypeOfMassive } from './TypeOfMassive';
import { SchemaType } from './SchemaType';
import { SimilarityCheckType } from './SimilarityCheckType';
import { JobOrderBillingType } from './JobOrderBillingType';
import { JobOrderStatusType } from './JobOrderStatusType';
import { JobOrderSparePart } from './JobOrderSparePart';
import { JobOrderBudgetType } from './JobOrderBudgetType';
import { JobOrderBudgetLogType } from './JobOrderBudgetLogType';
import { JobOrderTaskMode } from './JobOrderTaskMode';
import { CalendarGroupMode } from './CalendarGroupMode';
import { SharingPriorityMode } from './SharingPriorityMode';
import { SharingConflictPriority } from './SharingConflictPriority';
import { ParamType } from './ParamType';
import { OpportunityStatus } from './OpportunityStatus';
import { WebDavService } from './WebDavService';
import { WebDavQueueStatus } from './WebDavQueueStatus';
import { AlarmActionType } from './AlarmActionType';
import { AlarmTimeValueType } from './AlarmTimeValueType';
import { TeamMemberProperty } from './TeamMemberProperty';
import { OpportunityCrossType } from './OpportunityCrossType';
import { QuoteType } from './QuoteType';
import { PredefinedTitleMode } from './PredefinedTitleMode';
import { PredefinedTitleForceMode } from './PredefinedTitleForceMode';
import { KnowledgeBaseAccessibility } from './KnowledgeBaseAccessibility';
import { StatusImportance } from './StatusImportance';
import { SlaTemplateActionType } from './SlaTemplateActionType';
import { SlaTemplateOperatorType } from './SlaTemplateOperatorType';
import { DossierTemplateType } from './DossierTemplateType';
import { PortalDocumentPermissionSet } from './PortalDocumentPermissionSet';
import { PortalKnowledgeBasePermissionSet } from './PortalKnowledgeBasePermissionSet';
import { PortalCatalogPermissionSet } from './PortalCatalogPermissionSet';
import { PortalMailingListPermissionSet } from './PortalMailingListPermissionSet';
import { PortalPersonalDataPermissionSet } from './PortalPersonalDataPermissionSet';
import { PortalTicketPermissionSet } from './PortalTicketPermissionSet';
import { PortalReportPermissionSet } from './PortalReportPermissionSet';
import { PortalQuoteRequestPermissionSet } from './PortalQuoteRequestPermissionSet';
import { SharingTemplateAccountMode } from './SharingTemplateAccountMode';
import { SharingTemplateGroupMode } from './SharingTemplateGroupMode';
import { SharingTemplateTeamMode } from './SharingTemplateTeamMode';
import { QuoteExtensionConversionToOrderAction } from './QuoteExtensionConversionToOrderAction';
import { QuoteExtensionType } from './QuoteExtensionType';
import { ErpGroupingType } from './ErpGroupingType';
import { ApprovalRuleCalcType } from './ApprovalRuleCalcType';
import { ApprovalRuleApplyMode } from './ApprovalRuleApplyMode';
import { ApprovalPendingStatus } from './ApprovalPendingStatus';
import { ApproveMailingToAllSender } from './ApproveMailingToAllSender';
import { ExpenseReportStatus } from './ExpenseReportStatus';
import { ExpenseVehicleType } from './ExpenseVehicleType';
import { SearchOptimizedFor } from './SearchOptimizedFor';
import { CallLogMultipleMatchMode } from './CallLogMultipleMatchMode';
import { CallLogResult } from './CallLogResult';
import { ExpireNotificationType } from './ExpireNotificationType';
import { ActivityFromListGeneratorMode } from './ActivityFromListGeneratorMode';
import { CatalogPriceListKind } from './CatalogPriceListKind';
import { CatalogPriceListRound } from './CatalogPriceListRound';
import { CatalogPriceListUsage } from './CatalogPriceListUsage';
import { ApprovalNotificationMode } from './ApprovalNotificationMode';
import { BillingSpareParts } from './BillingSpareParts';
import { BillingRollStockSparePartMode } from './BillingRollStockSparePartMode';
import { TicketGrabberLogEvent } from './TicketGrabberLogEvent';
import { JobOrderTaskPlanningMode } from './JobOrderTaskPlanningMode';
import { JobOrderTaskPlannedCrossMode } from './JobOrderTaskPlannedCrossMode';
import { JobOrderTaskPlannedRollingStockMode } from './JobOrderTaskPlannedRollingStockMode';
import { JobOrderPlannedMode } from './JobOrderPlannedMode';
import { MergePropertyType } from './MergePropertyType';
import { MergePropertyStringJoinChar } from './MergePropertyStringJoinChar';
import { MessageType } from './MessageType';
import { MessageBodyFormat } from './MessageBodyFormat';
import { InfluencerRoleType } from './InfluencerRoleType';
import { OpportunityRefStatusType } from './OpportunityRefStatusType';
import { SortDirection } from './SortDirection';
import { MailingListSelectionRule } from './MailingListSelectionRule';
import { DefaultCodeValueIsNotBillableBehavior } from './DefaultCodeValueIsNotBillableBehavior';
import { Alignment } from './Alignment';
import { MessageCode } from './MessageCode';
import { InvalidateOptions } from './InvalidateOptions';
import { MigrateVersion } from './MigrateVersion';
import { TypeScheduler } from './TypeScheduler';
import { SchedulerRequestCalledFroms } from './SchedulerRequestCalledFroms';
import { PersonalDataChangesApprovalStatus } from './PersonalDataChangesApprovalStatus';
import { TypePrivacy } from './TypePrivacy';
import { DateTimePrecision } from './DateTimePrecision';
import { EditorType } from './EditorType';
import { TriStatus } from './TriStatus';
import { SearchableMode } from './SearchableMode';
import { TreeMode } from './TreeMode';
import { CheckSelectMode } from './CheckSelectMode';
import { AccountAccessLevel } from './AccountAccessLevel';
import { SubAccountType } from './SubAccountType';
import { AccountStatus } from './AccountStatus';
import { AnagraphicStatus } from './AnagraphicStatus';
import { AnagraphicStatusKind } from './AnagraphicStatusKind';
import { SyncPropertyFlag } from './SyncPropertyFlag';
import { BookingKind } from './BookingKind';
import { BusinessObjectAction } from './BusinessObjectAction';
import { CatalogVariantComposition } from './CatalogVariantComposition';
import { CatalogVariantInputType } from './CatalogVariantInputType';
import { CatalogVariantGridMode } from './CatalogVariantGridMode';
import { CatalogVariantProductOverrideFlag } from './CatalogVariantProductOverrideFlag';
import { ContractServicesStatus } from './ContractServicesStatus';
import { TypeOfInstalment } from './TypeOfInstalment';
import { TypeOfSection } from './TypeOfSection';
import { TypeOfContractSection } from './TypeOfContractSection';
import { ContractServiceStatusPermissionType } from './ContractServiceStatusPermissionType';
import { TypeOfPrice } from './TypeOfPrice';
import { TypeOfService } from './TypeOfService';
import { KindOfPrice } from './KindOfPrice';
import { ServiceInstalmentKind } from './ServiceInstalmentKind';
import { YearMode } from './YearMode';
import { TustenaDBColumnType } from './TustenaDBColumnType';
import { DynamoInputType } from './DynamoInputType';
import { DynamoAxisType } from './DynamoAxisType';
import { DynamoPageLocation } from './DynamoPageLocation';
import { DynamoContentType } from './DynamoContentType';
import { ErpRowColumnType } from './ErpRowColumnType';
import { RowTypeGiftVatMode } from './RowTypeGiftVatMode';
import { CurrencyFormat } from './CurrencyFormat';
import { EventItemStatusType } from './EventItemStatusType';
import { ExchangeSynchronizationConflictResolutionStrategy } from './ExchangeSynchronizationConflictResolutionStrategy';
import { ExchangeSynchronizationItemDeleteMode } from './ExchangeSynchronizationItemDeleteMode';
import { ExchangeSynchronizationConflictResolutionMode } from './ExchangeSynchronizationConflictResolutionMode';
import { TustenaType } from './TustenaType';
import { ExchangeTustenaRuleForNewExchangeContactItem } from './ExchangeTustenaRuleForNewExchangeContactItem';
import { ExchangeTustenaQueueImportAction } from './ExchangeTustenaQueueImportAction';
import { ExchangeSynchronizationEventType } from './ExchangeSynchronizationEventType';
import { ExchangeSynchronizationFolderType } from './ExchangeSynchronizationFolderType';
import { FlowAction } from './FlowAction';
import { FreeFieldsParentType } from './FreeFieldsParentType';
import { MType } from './MType';
import { SType } from './SType';
import { ErrorSetSentStatus } from './ErrorSetSentStatus';
import { ImportErrorType } from './ImportErrorType';
import { ImportQueueStatus } from './ImportQueueStatus';
import { ListsType } from './ListsType';
import { LocalizeMode } from './LocalizeMode';
import { LocalizeType } from './LocalizeType';
import { MailingDomainStatus } from './MailingDomainStatus';
import { MailTemplateMode } from './MailTemplateMode';
import { MassiveUpdateStatus } from './MassiveUpdateStatus';
import { LogType } from './LogType';
import { CompanyLoadPerformanceSettings } from './CompanyLoadPerformanceSettings';
import { ContactLoadPerformanceSettings } from './ContactLoadPerformanceSettings';
import { LeadLoadPerformanceSettings } from './LeadLoadPerformanceSettings';
import { EventItemLoadPerformanceSettings } from './EventItemLoadPerformanceSettings';
import { ActivityLoadPerformanceSettings } from './ActivityLoadPerformanceSettings';
import { PluginCodeType } from './PluginCodeType';
import { PdfPageOrientation } from './PdfPageOrientation';
import { PdfPageSize } from './PdfPageSize';
import { RecentMode } from './RecentMode';
import { RecentType } from './RecentType';
import { RecurrenceType } from './RecurrenceType';
import { RecurrenceFrequency } from './RecurrenceFrequency';
import { RecurrenceDayKind } from './RecurrenceDayKind';
import { AndOrErrors } from './AndOrErrors';
import { ComputeType } from './ComputeType';
import { FieldTypes } from './FieldTypes';
import { ReportConditions } from './ReportConditions';
import { LegendPosition } from './LegendPosition';
import { GroupDataType } from './GroupDataType';
import { ChartType } from './ChartType';
import { FilterUserAccount } from './FilterUserAccount';
import { FilterDate } from './FilterDate';
import { ReportType } from './ReportType';
import { GroupByExpression } from './GroupByExpression';
import { LogicalOperator } from './LogicalOperator';
import { TimelineItemAction } from './TimelineItemAction';
import { WebMailArchiveMailType } from './WebMailArchiveMailType';
import { WebMailArchiveStatus } from './WebMailArchiveStatus';
import { WebMailArchiveBodyStatus } from './WebMailArchiveBodyStatus';
import { WebMailProtocol } from './WebMailProtocol';
import { WebMailConnectionResult } from './WebMailConnectionResult';
import { MailTag } from './MailTag';
import { SecurityPolicyRule } from './SecurityPolicyRule';
import { SecurityPageMenu } from './SecurityPageMenu';
import { OfflinePermits } from './OfflinePermits';
import { GlobalPermits } from './GlobalPermits';
import { CompanyPermits } from './CompanyPermits';
import { ContactPermits } from './ContactPermits';
import { LeadPermits } from './LeadPermits';
import { ActivityPermits } from './ActivityPermits';
import { ContractPermits } from './ContractPermits';
import { CalendarPermits } from './CalendarPermits';
import { CatalogPermits } from './CatalogPermits';
import { TicketPermits } from './TicketPermits';
import { WebMailPermits } from './WebMailPermits';
import { StoragePermits } from './StoragePermits';
import { SmsPermits } from './SmsPermits';
import { SaleDocumentsPermits } from './SaleDocumentsPermits';
import { FaxPermits } from './FaxPermits';
import { IdeaPermits } from './IdeaPermits';
import { JobOrderPermits } from './JobOrderPermits';
import { JobOrderTaskPermits } from './JobOrderTaskPermits';
import { SecurityPermits } from './SecurityPermits';
import { ReportPermits } from './ReportPermits';
import { OpportunityPermits } from './OpportunityPermits';

export default { ActiveModules,
HelperClassType,
BusinessObjectType,
BusinessObjectLoadedType,
BusinessObjectInvoker,
Events,
Gravity,
LeadCrossType,
ServiceCrossType,
CrossType,
ErpDocumentStatus,
PhoneNormalizeType,
EMailNormalizeType,
NormalizeParsingMode,
ActivityMode,
ActivitySource,
ActivityPriorities,
ActivityStates,
ActivityClassification,
ToDoStates,
CostType,
CrossActivityWith,
FreeFieldsType,
Direction,
MovementAction,
Gender,
DebtRecoveryPhaseCommunication,
DebtRecoveryStatus,
DebtLogType,
ERPRowType,
RowContentType,
InvoiceShip,
InvoiceStatus,
InvoiceType,
InvoiceConditions,
ShippedBy,
ResolvingCrossType,
SupplierDocumentType,
DueDate,
FixedDRounding,
TaxInstall,
LeadConversionType,
StoragePermission,
TicketMessageType,
TicketStatusType,
TypeOfTemplateTicket,
Tablename,
StatusType,
ListChanges,
CatalogFlags,
CatalogType,
RollingInvoiceType,
WarrantyTypology,
MovementType,
FileCrossTypes,
FileCrossAttributes,
MachineType,
ValueType,
CRMTables,
BOTypeFlags,
GetNameFor,
ActivityMoveLog,
MailEvents,
ActivityType,
PermissionExceptionType,
GlobalSearchType,
EventType,
MailListStatus,
GroupProfile,
DefaultDocumentCategories,
FillPageFromControlMode,
TustenaColor,
GeograficType,
CampaignStatus,
PrintModuleType,
QuoteOptions,
CampaignElementsTypeOfView,
TargetListCrossType,
TargetListMode,
TargetDeleteMode,
CatalogSearchOption,
DefaultCodeType,
ERCostType,
ERPaymentType,
OrderStage,
QuoteStage,
TustenaColorTemplates,
TustenaColorInterfaces,
TemplateColorGroupType,
ExtensionsType,
Changes,
InvoiceFixedMonth,
ActivityRenderType,
OperationType,
OperationResult,
LayoutStudioDepth,
Encodings,
AttachmentLocation,
MethodType,
ConsumptionType,
PasswordRequirements,
CryptType,
WorkTeamsProperty,
ImportStatus,
StorageLogType,
StorageShareType,
UnifiedMessageService,
ReportFaxState,
SmsType,
ReportSmsState,
CatiAnswerType,
TimerType,
ImportConflictRule,
OppositionRegistryNumberType,
CatiExportCompilationType,
CatiExportDataType,
CatiQuotaComputeMode,
CampaignTypeOfExport,
FlowObjectType,
FlowToType,
FlowStepResult,
SocialNetwork,
SocialKeyType,
PartStat,
NotifyAppointmentMode,
MimeTopLevelMediaType,
MessagePrintMode,
TitleListType,
ApprovedStatus,
SalesTargetType,
SalesTargetReference,
TypeOfMassive,
SchemaType,
SimilarityCheckType,
JobOrderBillingType,
JobOrderStatusType,
JobOrderSparePart,
JobOrderBudgetType,
JobOrderBudgetLogType,
JobOrderTaskMode,
CalendarGroupMode,
SharingPriorityMode,
SharingConflictPriority,
ParamType,
OpportunityStatus,
WebDavService,
WebDavQueueStatus,
AlarmActionType,
AlarmTimeValueType,
TeamMemberProperty,
OpportunityCrossType,
QuoteType,
PredefinedTitleMode,
PredefinedTitleForceMode,
KnowledgeBaseAccessibility,
StatusImportance,
SlaTemplateActionType,
SlaTemplateOperatorType,
DossierTemplateType,
PortalDocumentPermissionSet,
PortalKnowledgeBasePermissionSet,
PortalCatalogPermissionSet,
PortalMailingListPermissionSet,
PortalPersonalDataPermissionSet,
PortalTicketPermissionSet,
PortalReportPermissionSet,
PortalQuoteRequestPermissionSet,
SharingTemplateAccountMode,
SharingTemplateGroupMode,
SharingTemplateTeamMode,
QuoteExtensionConversionToOrderAction,
QuoteExtensionType,
ErpGroupingType,
ApprovalRuleCalcType,
ApprovalRuleApplyMode,
ApprovalPendingStatus,
ApproveMailingToAllSender,
ExpenseReportStatus,
ExpenseVehicleType,
SearchOptimizedFor,
CallLogMultipleMatchMode,
CallLogResult,
ExpireNotificationType,
ActivityFromListGeneratorMode,
CatalogPriceListKind,
CatalogPriceListRound,
CatalogPriceListUsage,
ApprovalNotificationMode,
BillingSpareParts,
BillingRollStockSparePartMode,
TicketGrabberLogEvent,
JobOrderTaskPlanningMode,
JobOrderTaskPlannedCrossMode,
JobOrderTaskPlannedRollingStockMode,
JobOrderPlannedMode,
MergePropertyType,
MergePropertyStringJoinChar,
MessageType,
MessageBodyFormat,
InfluencerRoleType,
OpportunityRefStatusType,
SortDirection,
MailingListSelectionRule,
DefaultCodeValueIsNotBillableBehavior,
Alignment,
MessageCode,
InvalidateOptions,
MigrateVersion,
TypeScheduler,
SchedulerRequestCalledFroms,
PersonalDataChangesApprovalStatus,
TypePrivacy,
DateTimePrecision,
EditorType,
TriStatus,
SearchableMode,
TreeMode,
CheckSelectMode,
AccountAccessLevel,
SubAccountType,
AccountStatus,
AnagraphicStatus,
AnagraphicStatusKind,
SyncPropertyFlag,
BookingKind,
BusinessObjectAction,
CatalogVariantComposition,
CatalogVariantInputType,
CatalogVariantGridMode,
CatalogVariantProductOverrideFlag,
ContractServicesStatus,
TypeOfInstalment,
TypeOfSection,
TypeOfContractSection,
ContractServiceStatusPermissionType,
TypeOfPrice,
TypeOfService,
KindOfPrice,
ServiceInstalmentKind,
YearMode,
TustenaDBColumnType,
DynamoInputType,
DynamoAxisType,
DynamoPageLocation,
DynamoContentType,
ErpRowColumnType,
RowTypeGiftVatMode,
CurrencyFormat,
EventItemStatusType,
ExchangeSynchronizationConflictResolutionStrategy,
ExchangeSynchronizationItemDeleteMode,
ExchangeSynchronizationConflictResolutionMode,
TustenaType,
ExchangeTustenaRuleForNewExchangeContactItem,
ExchangeTustenaQueueImportAction,
ExchangeSynchronizationEventType,
ExchangeSynchronizationFolderType,
FlowAction,
FreeFieldsParentType,
MType,
SType,
ErrorSetSentStatus,
ImportErrorType,
ImportQueueStatus,
ListsType,
LocalizeMode,
LocalizeType,
MailingDomainStatus,
MailTemplateMode,
MassiveUpdateStatus,
LogType,
CompanyLoadPerformanceSettings,
ContactLoadPerformanceSettings,
LeadLoadPerformanceSettings,
EventItemLoadPerformanceSettings,
ActivityLoadPerformanceSettings,
PluginCodeType,
PdfPageOrientation,
PdfPageSize,
RecentMode,
RecentType,
RecurrenceType,
RecurrenceFrequency,
RecurrenceDayKind,
AndOrErrors,
ComputeType,
FieldTypes,
ReportConditions,
LegendPosition,
GroupDataType,
ChartType,
FilterUserAccount,
FilterDate,
ReportType,
GroupByExpression,
LogicalOperator,
TimelineItemAction,
WebMailArchiveMailType,
WebMailArchiveStatus,
WebMailArchiveBodyStatus,
WebMailProtocol,
WebMailConnectionResult,
MailTag,
SecurityPolicyRule,
SecurityPageMenu,
OfflinePermits,
GlobalPermits,
CompanyPermits,
ContactPermits,
LeadPermits,
ActivityPermits,
ContractPermits,
CalendarPermits,
CatalogPermits,
TicketPermits,
WebMailPermits,
StoragePermits,
SmsPermits,
SaleDocumentsPermits,
FaxPermits,
IdeaPermits,
JobOrderPermits,
JobOrderTaskPermits,
SecurityPermits,
ReportPermits,
OpportunityPermits };

