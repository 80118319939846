//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InvoiceFixedMonth {
	January = 1,
	February = 2,
	March = 4,
	April = 8,
	May = 16,
	June = 32,
	July = 64,
	August = 128,
	September = 256,
	October = 512,
	November = 1024,
	December = 2048
}
