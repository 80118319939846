export default {
  back: 'Înapoi',
  alls: 'Toți',
  note: 'Notă',
  search: 'Cauta',
  choose: 'Selectați...',
  commands: {
    no: 'Nu',
    ok: 'Ok',
    yes: 'Da',
    save: 'Salvează',
    edit: 'Modifică',
    close: 'Închide',
    never: 'Niciodată',
    print: 'Imprimă',
    cancel: 'Anulează',
    delete: 'Șterge',
    always: 'Întotdeauna',
    export: 'Exportă',
    actions: 'Acțiuni',
    confirm: 'Confirmă',
    convert: 'Transformă',
    deleteConfirm: 'Confirmi că vrei să ștergi elementul?',
    results: {
      savesuccess: 'Salvare efectuată cu succes'
    },
    remove: 'Elimină',
    copy: 'Copiază',
    view: 'Vizualizează',
    create: 'Crează',
    reload: 'Încărcare',
    plus: 'Adaugă',
    parameter: {
      value: 'Valoare',
      dynamic: 'Parametru dinamic'
    },
    click: 'fă clic aici',
    rename: 'Redenumește',
    warning: 'Atenție!',
    next: 'Înainte',
    discard: 'Abandonează schimbările',
    copied: 'Copiat în clipboard',
    clone: 'Duplicați/Clonați',
    call: 'Cine iubeste',
    copyClipboard: 'Copiați în clipboard',
    rotateDevice: 'Rotiți dispozitivul',
    move: 'Mutare',
    undo: 'Cancel',
    redo: 'Repeta'
  },
  layout: {
    help: 'Ai nevoie de ajutor?',
    guide: 'Mergi la ghidul on-line',
    logout: 'Ieși',
    mailbox: 'Căsuță de e-mail',
    messages: 'Mesaje',
    viewProfile: 'Afișează profil'
  },
  maps: {
    coords: 'Coordonate',
    latitude: 'Latitudine',
    longitude: 'Longitudine',
    import: {
      search: 'Căutare pe hartă',
      import: 'Importați această înregistrare'
    }
  },
  lookup: {
    zone: 'Zonă',
    account: 'Cont',
    activity: 'Activitate',
    catalogCategory: 'Categorii de produse'
  },
  changePassword: 'Schimbă Parola',
  group: {
    selected: 'Selectați',
    unselected: 'Neselectați',
    add: 'Adaugă grup',
    title: 'Managementul grupului',
    manage: 'Gestionați grupuri'
  },
  confirmPassword: 'Confirmare Parolă',
  category: {
    global: 'Global',
    personal: 'Personal'
  },
  avatar: {
    error: {
      limit: 'Dimensiunea imaginii nu trebuie să depășească 2MB!',
      format: 'Imaginea trebuie să fie în format JPG sau PNG'
    },
    discover: {
      title: 'Au fost găsite următoarele sigle pentru fila pe care o vizualizați',
      link: 'Selectați sigla pe care doriți să o asociați cardului',
      source: 'Sursa siglei: {source}',
      found: 'Da, am găsit-o, asta este',
      nobody: 'Nu, niciuna dintre acestea',
      searching: 'Caut pe web',
      search: 'Caută online'
    }
  },
  popupBlocker: {
    detected: 'S-a detectat blocarea ferestrelor pop-up',
    deactivate: 'Bună, ai încredere în noi, dezactivează blocarea ferestrelor pop-up pentru a te bucura pe deplin de caracteristicile produsului!'
  },
  validation: {
    required: {
      once: 'Cel puțin unul dintre câmpuri este obligatoriu',
      title: 'Valoare obligatorie',
      field: 'Câmpul {field} este obligatoriu',
      fields: 'Nu toate câmpurile obligatorii au fost completate'
    }
  },
  alarms: {
    notification: {
      title: 'Termen limită memento',
      dateAge: 'Data expirării',
      note: 'Notă',
      postpones: 'Întârziere de {n} minute'
    }
  },
  lists: {
    list: 'Listă',
    kanban: 'Kanban'
  },
  password: {
    changed: 'S-a reușit modificarea parolei',
    wrong: 'Parola nu este valabilă. Vrei să reîncerci?',
    type: 'Introdu noua parolă',
    expired: 'Parola a expirat'
  },
  token: {
    resign: 'Acces',
    expired: 'Nu se poate accesa {server}',
    refresh: '{mesaj}. Vă rugăm să vă conectați din nou pentru a continua să utilizați serviciul.'
  },
  mail: {
    to: 'La:',
    subject: 'Subiect',
    from: 'DIN:',
    cc: 'CC:',
    bcc: 'BCC:',
    validation: {
      format: 'E-mailul {mail} nu este într-un format valid',
      domain: 'Domeniul {domain} nu există sau nu are un MX valid',
      mailbox: 'E-mailul {mail} nu pare să existe pe serverul țintă'
    }
  },
  lock: {
    close: 'Închide',
    title: '{name} este deja utilizat',
    locked: '{name} este deschis în alt browser. Faceți clic pe „Utilizați aici” pentru a utiliza {name} în acest browser.',
    unlock: 'Utilizați aici'
  },
  loading: {
    starting: 'aprinde',
    connecting: 'conectarea sistemului',
    satellite: 'alinierea sateliților',
    ramp: 'ridicând rampa de lansare',
    warmup: 'încălzirea motoarelor',
    testing: 'ultimele simulări',
    profile: 'profil de încărcare',
    modules: 'descărcări de module'
  },
  offline: 'Atenție, a fost detectată o problemă de rețea. Verificați conexiunea la internet și încercați din nou operațiunea',
  printing: 'Imprimare',
  licence: {
    buy: 'Activează-ți licența',
    required: 'Funcția nu este disponibilă în versiunea curentă',
    pro: {
      required: 'Funcționalitate prezentă din versiunea Professional'
    },
    business: {
      required: 'Caracteristică prezentă în versiunea Business'
    }
  },
  messages: {
    nodisplay: 'hopa.. inca nimic de vazut',
    nomessages: 'nimeni nu ti-a scris inca'
  },
  unauthorized: {
    read: 'Nu sunteți autorizat să accesați articolele din această zonă'
  },
  form: {
    duration: {
      custom: 'Durată personalizată'
    }
  },
  integrations: {
    webex: 'Deschideți în Webex',
    zoom: 'Deschide în Zoom',
    calendly: 'Deschide în Calendly',
    skype: 'Deschideți în Skype',
    linkedin: 'Deschideți în Linkedin',
    office365: 'Deschideți în Office365',
    clickmeeting: 'Deschideți în ClickMeeting',
    maps: 'Deschide Harta',
    gotomeeting: 'Deschideți în GoToMeeting',
    googlemeet: 'Deschideți în Google Meet',
    skymeeting: 'Deschideți în SkyMeeting',
    teams: 'Deschideți în echipe',
    generic: 'Deschideți în {hostname}',
    lifesizecloud: 'Deschide în Lifesize'
  },
  modules: {
    errors: {
      load: 'A apărut o eroare la încărcarea modulului „{module}”. Asigurați-vă că nu există blocuri de anunțuri care să împiedice CRM să se încarce corect și încercați să reîncărcați pagina.',
      reload: 'Reîncărcați pagina'
    }
  }
};