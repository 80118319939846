<template>
  <el-row>
    <el-col>
      <el-tag class="el-tag-tag" :key="tag.value" v-for="tag in filteredGroups" :closable="isClosable(tag)"
        :disable-transitions="false" @close="handleRemoveTag(tag.value)" size="medium">
        <span>
          <router-link :to="{ name: $routeNames.tags, query: { q3: tag.text } }" v-if="false"></router-link>
          {{ tag.text }}
        </span>
      </el-tag>
      <span v-if="inputVisible">
        <el-autocomplete size="mini" ref="saveTagInput" autofocus v-model="tagInputValue"
          v-on:keydown.enter.prevent.native="handleCreateNewTag" :fetch-suggestions="handleGroupSearch"
          @select="handleTagSelected" value-key="text">
          <template #append>
            <el-tooltip :content="$t('group.manage')">
              <span class="el-icon-fa-object-group fad text-primary cursor-pointer"
                @click="groupEditorVisible = true"></span>
            </el-tooltip>
          </template>
        </el-autocomplete>
      </span>
      <el-button v-else class="button-new-tag" @click="showInput">{{ $t('group.add') }}</el-button>
      <el-dialog :title="$t('group.add')" :visible.sync="groupEditorVisible" width="50%" class="groupEditor">
        <el-row>
          <el-col>
            <el-transfer :value="value" @change="v => $emit('input', v)" :props="{
              key: 'value',
              label: 'text',
            }" :titles="[$t('group.unselected'), $t('group.selected')]" :data="newItems"></el-transfer>
          </el-col>
        </el-row>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import BaseTag from './BaseTag';
import { ITagDTO } from 'dto/ITagDTO';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { Getter, Action, namespace } from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import _ from 'lodash';

@Component({
  name: 'GroupTag',
})
export default class GroupTag extends BaseTag {
  @Getter('user') user: IUser;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  global: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  systemAdministrator: boolean;

  created() {
    this.radioGlobalPersonal = null;
  }

  async createNewTag(text: string, global: boolean): Promise<number> {
    return null;
  }

  getListType(): ListsType {
    return this.$enums.ListsType.ListGroup;
  }

  tagInputValue: string = null;

  tags: number[] = [];

  get inputVisible(): boolean {
    return this.popoverOpen;
  }

  set inputVisible(value: boolean) {
    this.popoverOpen = value;
  }

  get filteredGroups() {
    return this.selectedTags.filter((f) => {
      if (f.value === 0) {
        return this.global;
      }
      if (f.value === this.user.adminGroupId) {
        return this.systemAdministrator;
      }
      return true;
    });
  }

  handleGroupSearch(queryString, callback) {
    this.tagSearch(queryString, (c) => {
      callback(
        c.filter((f) => {
          if (f.value === 0) {
            return this.global;
          }
          if (f.value === this.user.adminGroupId) {
            return this.systemAdministrator;
          }
          return true;
        }),
      );
    });
  }

  isClosable(t) {
    return !this.readOnly && t.value !== this.user.adminGroupId;
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }

  get newItems(): Array<any> {
    const list = this.list(this.$enums.ListsType.ListGroup);
    if (list) {
      return list.map((g) => ({
        ...g,
        disabled: g.value === this.user.adminGroupId,
      }));
    }
    return [];
  }

  loading = false;

  groupEditorVisible = false;
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.el-tag {
  margin-bottom: $-margin-size-quarter;
  margin-right: $-margin-size-quarter;
}

.el-tag+.el-tag {}

.groupEditor {
  .el-transfer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
