<template>
  <span>
    <slot name="button" v-if="$viewport.smAndUp"></slot>
    <el-dropdown type="primary" class="dropdown-action-button" @command="command => $emit('command', command)"
      v-indexed>
      <el-button type="primary" icon="el-icon-fa-chevron-double-down fad" class="m-l-q"></el-button>
      <template #dropdown><el-dropdown-menu>
          <slot name="button" v-if="$viewport.xs"></slot>
          <slot></slot>
        </el-dropdown-menu></template>
    </el-dropdown>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop, Provide } from 'vue-property-decorator';
import Component from 'vue-class-component';
import vueUtils from 'utils/vueUtils';
import ElementUI from 'element-ui';

@Component({
  name: 'TDropdownActions',
})
export default class TDropdownActions extends Vue {
  @Provide() get asDropItem() {
    return this.$viewport.xs;
  }

  mounted() {
    console.log('mounted TDropdownActions', this.$slots.button);
  }

  updated() {
    console.log('update TDropdownActions', this.$slots.button);
  }

  boforeUpdate() {
    console.log('beforeUpdate TDropdownActions', this.$slots.button);
  }
}
</script>
<style lang="scss">
@import '~element-variables';

.dropdown-action-button .el-dropdown-link * {
  color: $--color-white !important;
}
</style>
