/* eslint-disable max-classes-per-file */
import jwtDecode, { JwtPayload } from 'jwt-decode';
import axios, { AxiosHeaders } from 'axios';
import { IAxiosRequestConfigRetry } from 'utils/types';
import Visibility from 'visibilityjs';
import { v4 as uuidv4 } from 'uuid';
import { EncryptStorage } from 'encrypt-storage';

export interface IJwtManager {
  readonly cookie: boolean;
  token: string;
  refreshToken: string;
  readonly isImpersonationToken: boolean;

  isValidToken(): Promise<boolean>;
  clearTokens();
  disable();
  getValidToken(): Promise<string>;
  refreshTokenAction(): Promise<boolean>;
}

interface ILocalStorageManager {
  get token(): string;
  set token(token: string);
  get refreshToken(): string;
  set refreshToken(refreshToken: string);
  clearTokens();
}

class EncryptedLocalStorageManager implements ILocalStorageManager {
  private at = 'at';

  private rt = 'rt';

  private storage: Storage;

  private prefix: string = null;

  constructor(secretKey: string, prefix?: string) {
    this.storage = new EncryptStorage(secretKey, { storageType: 'localStorage' });
    this.prefix = prefix;
  }

  private get accessTokenKey(): string {
    return this.prefix ? `${this.prefix}_${this.at}` : this.at;
  }

  private get refreshTokenKey(): string {
    return this.prefix ? `${this.prefix}_${this.rt}` : this.rt;
  }

  get token(): string {
    try {
      return this.storage.getItem(this.accessTokenKey);
    } catch {
      return null;
    }
  }

  set token(token: string) {
    this.storage.setItem(this.accessTokenKey, token);
  }

  get refreshToken(): string {
    try {
      return this.storage.getItem(this.refreshTokenKey);
    } catch {
      return null;
    }
  }

  set refreshToken(refreshToken: string) {
    this.storage.setItem(this.refreshTokenKey, refreshToken);
  }

  clearTokens() {
    this.storage.removeItem(this.accessTokenKey);
    this.storage.removeItem(this.refreshTokenKey);
  }
}


class LocalStorageManager implements ILocalStorageManager {
  private at = 'access_token';

  private rt = 'refresh_token';

  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  get token(): string {
    return this.storage.getItem(this.at);
  }

  set token(token: string) {
    this.storage.setItem(this.at, token);
  }

  get refreshToken(): string {
    return this.storage.getItem(this.rt);
  }

  set refreshToken(refreshToken: string) {
    this.storage.setItem(this.rt, refreshToken);
  }

  clearTokens() {
    this.storage.removeItem(this.at);
    this.storage.removeItem(this.rt);
  }
}

export class LocalStorageJwtManager implements IJwtManager {
  constructor(storage: ILocalStorageManager) {
    this.storage = storage;
  }

  private storage: ILocalStorageManager;

  private enabled = true;

  get cookie(): boolean {
    return false;
  }

  get token(): string {
    return this.storage.token;
  }

  set token(token: string) {
    this.storage.token = token;
  }

  get refreshToken(): string {
    return this.storage.refreshToken;
  }

  set refreshToken(refreshToken: string) {
    this.storage.refreshToken = refreshToken;
  }

  get isImpersonationToken() {
    return this.token != null && this.refreshToken == null;
  }

  async isValidToken(): Promise<boolean> {
    return (await this.getValidToken()) != null;
  }

  clearTokens() {
    this.storage.clearTokens();
  }

  disable() {
    this.enabled = false;
  }

  async getValidToken(): Promise<string> {
    if (!this.enabled) {
      return null;
    }
    const jwt = this.token;
    if (jwt) {
      try {
        const decoded = jwtDecode<JwtPayload>(jwt);
        if (decoded) {
          const curretDate = new Date().getTime();
          if (curretDate < decoded.exp * 1000) {
            return jwt;
          }
          if (await this.refreshTokenAction()) {
            return this.token;
          }
        }
      } catch (e) { }
    }
    return null;
  }

  async refreshTokenAction(): Promise<boolean> {
    if (!this.enabled) {
      return null;
    }
    const { refreshToken } = this;
    if (!refreshToken) return null;
    try {
      const tokenUpdateResponse = await axios.post(
        'auth/refresh',
        {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          background: Visibility.hidden(),
        },
        <IAxiosRequestConfigRetry>{
          _noAuth: true,
          headers: new AxiosHeaders({
            _retry: true,
          }),
        },
      );
      if (tokenUpdateResponse.status === 200) {
        this.token = tokenUpdateResponse.data.access_token;
        this.refreshToken = tokenUpdateResponse.data.refresh_token;
        return true;
      }
    } catch {
      this.clearTokens();
      return false;
    }
    return false;
  }
}

export class CookieSessionJwtManager implements IJwtManager {
  private enabled = true;

  private aToken: string = null;

  private rToken: string = null;

  get cookie(): boolean {
    return true;
  }

  get token(): string {
    return this.aToken;
  }

  set token(token: string) {
    this.aToken = token;
  }

  get refreshToken(): string {
    return this.rToken;
  }

  set refreshToken(refreshToken: string) {
    this.rToken = refreshToken;
  }

  get isImpersonationToken() {
    return this.token != null && this.refreshToken == null;
  }

  async isValidToken(): Promise<boolean> {
    return (await this.getValidToken()) != null;
  }

  clearTokens() {
    this.aToken = null;
    this.rToken = null;
  }

  disable() {
    this.enabled = false;
  }

  async getValidToken(): Promise<string> {
    if (!this.enabled) {
      return null;
    }
    const jwt = this.token;
    if (!jwt) {
      try {
        // const tokenResponse = await new AuthController(axios).Session(uuidv4());
        const tokenResponse = await axios
          .post('auth/session', JSON.stringify(uuidv4()), <IAxiosRequestConfigRetry>{
            _noAuth: true,
            headers: new AxiosHeaders({
              _retry: true,
              'content-type': 'application/json',
            }),
          })
          .then((r) => r.data);
        if (tokenResponse) {
          this.token = tokenResponse;
        }
      } catch (e) {
        return null;
      }
    }
    return this.token;
  }

  async refreshTokenAction(): Promise<boolean> {
    if (!this.enabled) {
      return null;
    }
    return false;
  }
}

class JwtManagerProxy implements IJwtManager {
  constructor(m: IJwtManager) {
    this.manager = m;
  }

  private manager: IJwtManager;

  get cookie(): boolean {
    return this.manager.cookie;
  }

  get token(): string {
    return this.manager.token;
  }

  set token(token: string) {
    this.manager.token = token;
  }

  get refreshToken(): string {
    return this.manager.refreshToken;
  }

  set refreshToken(refreshToken: string) {
    this.manager.refreshToken = refreshToken;
  }

  get isImpersonationToken() {
    return this.manager.isImpersonationToken;
  }

  async isValidToken(): Promise<boolean> {
    return await this.manager.isValidToken();
  }

  clearTokens() {
    this.manager.clearTokens();
  }

  disable() {
    this.manager.disable();
  }

  async getValidToken(): Promise<string> {
    return await this.manager.getValidToken();
  }

  async refreshTokenAction(): Promise<boolean> {
    return await this.manager.refreshTokenAction();
  }

  use(m: IJwtManager) {
    this.manager = m;
  }
}


const migrate = (encryptedv1Manager: ILocalStorageManager, encryptedv2Manager: ILocalStorageManager): ILocalStorageManager => {
  if (encryptedv1Manager.token) {
    encryptedv2Manager.token = encryptedv1Manager.token;
  }
  if (encryptedv1Manager.refreshToken) {
    encryptedv2Manager.refreshToken = encryptedv1Manager.refreshToken;
  }
  encryptedv1Manager.clearTokens();
  return encryptedv2Manager;
};

let storageManager: ILocalStorageManager = new LocalStorageManager();
if (process.env.VUE_APP_SECRET_KEY_LOC) {
  storageManager = migrate(new EncryptedLocalStorageManager(process.env.VUE_APP_SECRET_KEY_LOC, 'CRM'), new EncryptedLocalStorageManager(process.env.VUE_APP_SECRET_KEY_LOC, 'CRM_'));
}

const mgr: IJwtManager = new JwtManagerProxy(new LocalStorageJwtManager(storageManager));
const use = (m: IJwtManager) => {
  (mgr as JwtManagerProxy).use(m);
};
export { use };
// export default new LocalStorageJwtManager();
export default mgr;
