<template>
<div class="text text-primary text-right">
  {{ $n(row.value, 'currency') }}
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
  IRepeaterColumn,
  IRowDataInfo,
} from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import TDefaultCell from './DefaultCell.vue';

@Component({
  name: 'MoneyCell',
  components: {
    TDefaultCell,
  },
})
export default class MoneyCell extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  row: IRowDataInfo;
}
</script>
