//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExchangeTustenaRuleForNewExchangeContactItem {
	CreateNewCompany = 0,
	CreateNewContact = 1,
	CreateNewLead = 2,
	PushInQueue = -1
}
