//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SaleDocumentsPermits {
	AllowEditCostField = 68,
	AllowEditPriceOverDiscount = 69,
	AllowViewCostField = 71,
	AllowCloneDdt = 76,
	AllowPriceListChange = 110,
	AllowViewFinalPriceListField = 111,
	AllowInsertManualProducts = 112,
	AllowIncreaseFinalPriceList = 128,
	AllowPreviewPrint = 158,
	AllowGeneratePrint = 159,
	AllowDownloadPrint = 160,
	AllowSendEMailWithPrint = 161,
	AllowExportFromGrids = 197
}
