<template>
  <div>
    <el-table v-if="data && data.length > 0" :border="border" :stripe="stripe"
      :highlight-current-row="highlightCurrentRow" :data="data">
      <el-table-column width="48">
        <template #default="{ row }">
          <i class="icon-2x cursor-pointer" :class="row.icon" v-if="row.icon" @click="() => $emit('details', row)"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('storage.headers.description')" :min-width="200" resizable>
        <template #default="{ row }">
          <div v-if="showFilePath && row.category > 0">
            <t-storage-category-path-label class="text" :value="row.category"></t-storage-category-path-label>
          </div>
          <a @click="handleClick(row)" class="file">{{ row.description }}</a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('storage.headers.lastModifiedDate')" :min-width="220" resizable v-if="lastModifiedDate">
        <template #default="{ row }">{{ $moment(row.lastModifiedDate).fromNow() }}</template>
      </el-table-column>
      <el-table-column :label="$t('storage.headers.lastModifiedBy')" :min-width="220" resizable v-if="lastModifiedById">
        <template #default="{ row }">
          <t-resolve-label :value="row.lastModifiedById" :type="$enums.ListsType.ListOwner"></t-resolve-label>
        </template>
      </el-table-column>
      <el-table-column :label="$t('storage.headers.size')" :min-width="80" resizable v-if="size">
        <template #default="{ row }">{{ row.size }}</template>
      </el-table-column>
      <el-table-column width="36" v-if="commands">
        <template #default="{ row }">
          <slot name="commands" :row="row"></slot>
        </template>
      </el-table-column>
    </el-table>
    <slot name="append"></slot>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';
import TStorageCategoryPathLabel from './StorageCategoryPathLabel.vue';

// tslint:disable-next-line:variable-name
const StorageAction = namespace('storage').Action;
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageBrowserTable',
  components: {
    TStorageCategoryPathLabel,
  },
})
export default class StorageBrowserTable extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  border: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  stripe: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  highlightCurrentRow: boolean;

  @Prop({ type: Array, required: true })
  data: string;

  @Prop({ type: Boolean, required: false, default: true })
  lastModifiedById: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  lastModifiedDate: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  size: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  commands: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  showFilePath: boolean;

  handleClick(row) {
    this.$emit('input', row);
  }
}
</script>
<style lang="scss" scoped>
.file {
  cursor: pointer;
}

.commands {
  cursor: pointer;
}
</style>
