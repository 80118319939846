<template>
  <div class="h-100">
    <router-view v-cloak></router-view>
    <div class="portrait-device-advice" v-if="!allowXs">
      <div class="lock"></div>
      <el-row type="flex" justify="center" align="middle" class="h-100 rotate-message">
        <el-col>
          <span class="el-icon-fa-mobile-alt fas icon-5x icon"></span>
          <div class="text-primary m-t text-1_5x">{{ $t('commands.rotateDevice') }}</div>
          <div class="text-center m-t">
            <!--eslint-disable-next-line vuejs-accessibility/anchor-has-content-->
            <a href="itms-apps://itunes.apple.com/app/id1493170113">
              <img src="/app/apple-app-store.png" style="width:45%" />
            </a>
            <!--eslint-disable-next-line vuejs-accessibility/anchor-has-content-->
            <a href="market://details?id=com.teamsystem.crmincloud">
              <img src="/app/google-play-badge.png" style="width:45%" />
            </a>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog :title="$t('lock.title', brand)" :visible="locked" width="450px" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false">
      <span>
        <t-icon-label icon="el-icon-fa-skull-crossbones fad icon-2x text-primary">{{
          $t('lock.locked', brand)
        }}</t-icon-label>
      </span>
      <template #footer><span class="dialog-footer">
          <el-button @click="logout" icon="el-icon-icon-esc">{{ $t('layout.logout') }}</el-button>
          <el-button type="primary" icon="el-icon-fa-rocket-launch fad" @click="unlock">{{
            $t('lock.unlock')
          }}</el-button>
        </span></template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';

@Component({
  name: 'App',
})
export default class App extends Vue {
  @Getter locked;

  @Getter brand;

  @Action unlock;

  @Action logout;

  get allowXs() {
    return true;
    /*
    if (this.$route.meta && this.$route.meta.xs) {
      return true;
    }
    return false;
    */
  }
}
</script>
<style lang="scss">
@import './reset.scss';
@import '../scss/icomoon/style';
@import '../scss/fontawesome/style';
@import '../scss/filters/filter';
@import '../scss/modifiers';
@import '../scss/transitions';

[v-cloak] {
  display: none;
}

[v-cloak]::before {
  content: 'loading...';
}
</style>
