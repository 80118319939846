//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum UnifiedMessageService {
	Sms = 1,
	Fax = 2,
	Letter = 4,
	EMail = 8,
	MailingList = 16,
	Map = 32
}
