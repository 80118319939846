//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { ISearchQuery } from './ISearchQuery';
import { INaturalLanguageResponse } from '../Processing/Response/INaturalLanguageResponse';
import { INaturalLanguageSuggestion } from '../Processing/Response/Suggestion/INaturalLanguageSuggestion';
import { IIntentDescriptor } from '../Processing/IIntentDescriptor';
import { INaturalLanguageCustomExpressionDescriptor } from '../Processing/Parsing/INaturalLanguageCustomExpressionDescriptor';
import { INaturalLanguageCustomExpressionParserResult } from '../Processing/Parsing/INaturalLanguageCustomExpressionParserResult';
import { IINaturalLanguageCustomExpressionDescriptor } from '../Processing/Parsing/IINaturalLanguageCustomExpressionDescriptor';

//element.Name=NaturalLanguageController result.Name=NaturalLanguageController element.FullName=Digita.Tustena.NaturalLanguage.Controllers.NaturalLanguageController);
/** Result of WebApiControllerGenerator activity */
export class NaturalLanguageController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) 
		    throw 'axios argument is required in NaturalLanguageController constructor class, and cannot be empty or undefined';
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public NaturalSearch(query: ISearchQuery, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<INaturalLanguageResponse>
	{
		return (this.axios.post('NaturalLanguage/NaturalSearch', query, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Help(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<INaturalLanguageSuggestion[]>
	{
		return (this.axios.get('NaturalLanguage/Help', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetIntents(culture?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IIntentDescriptor[]>
	{
		return (this.axios.get('NaturalLanguage/GetIntents?'+((culture === null || culture === undefined) ? '' : ('&culture='+encodeURIComponent(culture))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LearnCustomExpression(expressionDescriptor: INaturalLanguageCustomExpressionDescriptor, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<INaturalLanguageCustomExpressionParserResult>
	{
		return (this.axios.post('NaturalLanguage/LearnCustomExpression', expressionDescriptor, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LearnCustomScript(script: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<INaturalLanguageCustomExpressionParserResult[]>
	{
		return (this.axios.post('NaturalLanguage/LearnCustomScript', JSON.stringify(script), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public ForgetAllCustomExpressions(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.get('NaturalLanguage/ForgetAllCustomExpressions', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetKnownCustomExpressions(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IINaturalLanguageCustomExpressionDescriptor[]>
	{
		return (this.axios.get('NaturalLanguage/GetKnownCustomExpressions', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('NaturalLanguage/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('NaturalLanguage/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
