<template>
  <el-popover>
    <template #reference>
      <div class="color-button">
        <div>
          <span class="color-box" :style="{ backgroundColor: value }">
            <i class="el-icon-fa-chevron-down" v-if="value != null"></i>
            <i class="el-icon-fa-times" v-else></i>
          </span>
        </div>
      </div>
    </template>
    <sketch-picker :value="value || ''" @input="setColor" disable-alpha />
  </el-popover>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Sketch } from 'vue-color';

@Component({
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
})
export default class ColorPicker extends Vue {
  @Prop({
    type: String,
  })
  value: string;

  setColor(c) {
    if (c.hex8 === '#00000000') {
      this.$emit('input', null);
    } else {
      this.$emit('input', c?.hex);
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'element-variables';

.color-button {
  border: $--border-base;
  border-radius: 3px;
  padding: 2px;
  display: inline-block;

  .color-box {
    height: 18px;
    width: 18px;
    display: block;
    border: $--border-base;
    border-radius: 3px;
    text-align: center;

    i {
      vertical-align: top;
      padding-top: 2px;
      color: $--color-white;

      &.el-icon-fa-times {
        color: $--border-color-base;
      }
    }
  }
}
</style>
