import Vue, { PluginObject } from 'vue';
import i18nUtils from 'utils/i18nUtils';

class FreeFieldComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
    vue.component('t-storage-browser', () => import(/* webpackChunkName: "storage" */ './StorageBrowser.vue'));
    vue.component('t-storage-crossed-browser', () => import(/* webpackChunkName: "storage" */ './StorageCrossedBrowser.vue'));
  }
}
export default new FreeFieldComponents();
