//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LocalizeType {
	KBDescr = 0,
	KBCategory = 1,
	PredefinedTitle = 2,
	AreaSkill = 3,
	CatalogProduct = 4,
	CatalogCategories = 5,
	AreaDescription = 6,
	Status_RMA = 7,
	FreeField = 8,
	WorkGroup = 9,
	Zone = 10,
	DynamoRepeater = 11,
	DynamoPage = 12,
	DynamoInput = 13,
	DynamoChart = 14,
	DynamoContent = 15,
	DynamoSelect = 16,
	DynamoRepeaterNoData = 17,
	DynamoInputSeachButtonText = 18,
	DynamoChartXAxisLabel = 19,
	DynamoChartYAxisLabel = 20,
	DynamoSelectSeachButtonText = 21,
	DynamoSelectCaptionText = 22,
	ActivitySubType = 23,
	EventItemStatus = 24,
	TustenaDBName = 25,
	TustenaDBColumnDisplayName = 26,
	TustenaDBColumnDefText = 27,
	TustenaDBColumnQuestion = 28,
	SurveyTitle = 29,
	SurveyWelcomeMessage = 30,
	SurveyThanksMessage = 31,
	EventTitle = 32,
	EventLanding = 33,
	TicketType = 34,
	TicketStatus = 35,
	TicketPriority = 36,
	Payment = 37,
	TaxValue = 38,
	DeliveryCarriage = 39,
	DeliveryAppearance = 40,
	DeliveryCause = 41,
	Currency = 42,
	PriceListDescription = 43,
	CustomErpDocumentType = 44,
	AlternativeUnit = 45,
	OpportunityPhase = 46,
	OpportunityCategory = 47,
	OpportunityLostReason = 48,
	EMailType = 49,
	BusinessRole = 50,
	AnagraphicStatus = 51,
	AnagraphicRating = 52,
	AnagraphicInterest = 53,
	AnagraphicSource = 54,
	AnagraphicPriority = 55,
	AnagraphicIndustry = 56,
	AnagraphicCompanyType = 57,
	MailCategory = 58,
	JobOrderPriority = 59,
	JobOrderTemplate = 60,
	JobOrderStatus = 61,
	JobOrderTaskStatus = 62,
	JobOrderType = 63,
	PhoneType = 64,
	Report = 65,
	PrivacyTypeManagement = 66,
	PrivacyMotivationsImplicitConsent = 67,
	PaymentMode = 68,
	WorkPlan = 69,
	WorkPlanStatus = 70
}
