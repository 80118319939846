<template>
  <div>
    <t-printer :value="value.id" module="storage" preview-type="pdf" :enable-change-type="false"
      :enable-select="enableSelect" @select="$emit('select', value)">
      <template #info>
        <strong class="m-r-h">{{ value.size | approx({ capital: true, suffix: 'b' }) }}</strong>
        <span class="m-r-h" v-if="$options.filters.valid(value.receptionDate)">{{
          $moment(value.receptionDate).format('LL LT')
        }}</span>
        <t-resolve-label :type="$enums.ListsType.ListOwner" :value="value.createdById"></t-resolve-label>
      </template>
    </t-printer>
    <div class="m-t" v-if="references && references.length > 0">
      <h3>{{ $tc('storage.usage.link', references.length) }}</h3>
      <div class="m-t">
        <t-bo-tag class="m-r-q m-b-q" v-for="(fc, index) in references" :key="index" :value="fc.crossId"
          :type="fc.crossType" :closable="user.isAdmin" @close="() => handleRemoveLink(fc)"></t-bo-tag>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { Getter, Action, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { StorageController } from 'rt/UIApiControllers/BusinessObject/DTO/StorageController';
import _ from 'lodash';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

// tslint:disable-next-line:variable-name
const StorageAction = namespace('storage').Action;
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageDetail',
})
export default class StorageDetail extends Vue {
  @Getter user: IUser;

  @Prop({
    type: Object,
    required: true,
  })
  value: IStorageDTO;

  @Prop({ type: Boolean, required: false, default: false })
  enableSelect: boolean;

  @Watch('value') async handleValueChange() {
    await this.loadReferences();
  }

  @StorageGetter('controller') controller: StorageController;

  async mounted() {
    await this.loadReferences();
  }

  references: { crossId: number; crossType: number }[] = null;

  async loadReferences() {
    this.references = null;
    this.references = await this.controller.References(this.value.id);
  }

  async handleRemoveLink(ref: { crossId: number; crossType: number }) {
    this.$confirm(this.$t('storage.references.unlink') as string, this.$t('commands.confirm') as string, {
      distinguishCancelAndClose: false,
      type: 'warning',
    })
      .then(async () => {
        await this.controller.DeleteReference(this.value.id, ref.crossId, ref.crossType);
        this.references = [...this.references].filter((r) => r.crossId !== ref.crossId && r.crossType !== ref.crossType);
      })
      .catch();
  }
}
</script>
