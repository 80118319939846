<template>
  <el-button @click="$emit('click')" class="side-button m-b-q" v-indexed>
    <el-row type="flex" align="middle" justify="center" class="content w-100 h-100">
      <el-col :span="6" class="text-left">
        <slot name="icon">
          <i :class="icon"></i>
        </slot>
      </el-col>
      <el-col class="text-left" :span="18">
        <div class="text">
          <slot></slot>
        </div>
      </el-col>
    </el-row>
  </el-button>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TASideButton',
})
export default class TASideButton extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  icon: string;
}
</script>
<style lang="scss" scoped>
button {
  &.side-button {
    width: 100%;
    position: relative;
  }
  & .content {
    & i {
      font-size: 2.5em !important;
    }
    & .text {
      white-space: normal;
    }
  }
}
.el-button + .el-button {
  margin-left: initial;
}
</style>
