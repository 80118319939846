//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MailListStatus {
	Undefined = 0,
	InSpoolerWaiting = 1,
	Sent = 2,
	SpoolerError = 3,
	SpoolerStopped = 4,
	SpoolerPaused = 5,
	UnifiedMessagePreLoaded = 6,
	Unknown = 10,
	Quota = 11,
	Spam = 12,
	Bounce = 13,
	Undelivered = 14,
	Delay = 15,
	Failed = 16,
	BadAddress = 17,
	BadSender = 19,
	ProtocolError = 20,
	NoEMailFound = 40,
	DoubleEMailFound = 41,
	AnagraphicAccessDenied = 42,
	DeliveryLimitReached = 43,
	MailingListFlagNotChecked = 44,
	PrivacySettingsAllowMassiveEmailNotChecked = 45,
	UnifiedMessageSendingProfileError = 60
}
