import Vue, { PluginObject } from 'vue';
import CrmGrid from './components/grid/CrmGrid.vue';
import SheetPaging from './components/grid/misc/SheetPaging.vue';
import Skin from './components/form/Skin.vue';
import Widget from './components/form/Widget.vue';
import Widgets from './components/form/Widgets.vue';
import AggregateInput from './components/form/AggregateInput.vue';
import TemplateWidget from './components/form/TemplateWidget.vue';
import Div from './components/form/Div.vue';
import FormButton from './components/form/FormButton.vue';

import Tabs from './components/form/Tabs.vue';
import LsForm from './components/form/LsForm.vue';
import SimpleLsForm from './components/form/SimpleLsForm.vue';
import LayoutStudioInput from './components/form/LayoutStudioInput.vue';

import LsSection from './components/form/LsSection.vue';

import Input from './components/form/Input.vue';
import CityInput from './components/form/CityInput.vue';
import VatInput from './components/form/VatInput.vue';
import HyperlinkInput from './components/form/HyperlinkInput.vue';

import TaxIdentificationNumberInput from './components/form/TaxIdentificationNumberInput.vue';

import PasswordInput from './components/form/PasswordInput.vue';
import Freefield from './components/form/Freefield.vue';
import DateInput from './components/form/DateInput.vue';
import ListSelect from './components/form/ListSelect.vue';
import EnumListSelect from './components/form/EnumListSelect.vue';
import CheckboxInput from './components/form/CheckboxInput.vue';
import SwitchInput from './components/form/SwitchInput.vue';
import NumericInput from './components/form/NumericInput.vue';
import CurrencyInput from './components/form/CurrencyInput.vue';
import IconInput from './components/form/IconInput.vue';
import ProbabilityInput from './components/form/ProbabilityInput.vue';
import Geo from './components/form/Geo.vue';
import Nbsp from './components/form/Nbsp.vue';
import Alert from './components/form/Alert.vue';
import Repeater from './components/form/Repeater.vue';
import EmailNormalizedInput from './components/form/EmailNormalizedInput.vue';
import PhoneNormalizedInput from './components/form/PhoneNormalizedInput.vue';
import AccountInput from './components/form/AccountInput.vue';
import CatalogCategoryInput from './components/form/CatalogCategoryInput.vue';
import ButtonsWidgets from './components/form/buttons/ButtonsWidgets.vue';
import WebApiActionButton from './components/form/buttons/WebApiActionButton.vue';
import RedirectButton from './components/form/buttons/RedirectButton.vue';

import FormItem from './components/form/FormItem.vue';
import ZoneInput from './components/form/ZoneInput.vue';
import CompanyInput from './components/form/CompanyInput.vue';
import ContactInput from './components/form/ContactInput.vue';
import LeadInput from './components/form/LeadInput.vue';
import CatalogInput from './components/form/CatalogInput.vue';
import WorkPlanInput from './components/form/WorkPlanInput.vue';
import OpportunityInput from './components/form/OpportunityInput.vue';
import StorageInput from './components/form/StorageInput.vue';
import MailTemplateInput from './components/form/MailTemplateInput.vue';
import ListInput from './components/form/ListInput.vue';
import ActivityInput from './components/form/ActivityInput.vue';
import CrossAnagraphicInput from './components/form/CrossAnagraphicInput.vue';
import ContactCategoryInput from './components/form/ContactCategoryInput.vue';
import ReferrerCategoryInput from './components/form/ReferrerCategoryInput.vue';
import FlowTriggerInput from './components/form/FlowTriggerInput.vue';
import GroupsInput from './components/form/GroupsInput.vue';
import TernaryAnagraphicInput from './components/form/TernaryAnagraphicInput.vue';
import AlarmInput from './components/form/AlarmInput.vue';
import DurationInput from './components/form/DurationInput.vue';
import MessagesInput from './components/form/MessagesInput.vue';
import ColorPickerInput from './components/form/ColorPickerInput.vue';
import DiscountFormulaInput from './components/form/DiscountFormulaInput.vue';
import MailingListInput from './components/form/MailingListInput.vue';
import TagsInput from './components/form/TagsInput.vue';
import SurnameInput from './components/form/SurnameInput.vue';
import ParameterVariableInput from './components/form/ParameterVariableInput.vue';

import AccountTree from './components/tree/AccountTree.vue';
import CatalogCategoryTree from './components/tree/CatalogCategoryTree.vue';
import ZoneTree from './components/tree/ZoneTree.vue';

import AccountLookup from './components/lookup/AccountLookup.vue';
import CatalogCategoryLookup from './components/lookup/CatalogCategoryLookup.vue';
import ZoneLookup from './components/lookup/ZoneLookup.vue';
import CompanyLookup from './components/lookup/CompanyLookup.vue';
import ContactLookup from './components/lookup/ContactLookup.vue';
import CatalogLookup from './components/lookup/CatalogLookup.vue';
import WorkPlanLookup from './components/lookup/WorkPlanLookup.vue';
import LeadLookup from './components/lookup/LeadLookup.vue';
import OpportunityLookup from './components/lookup/OpportunityLookup.vue';
import StorageLookup from './components/lookup/StorageLookup.vue';
import ListLookup from './components/lookup/ListLookup.vue';
import FlowTriggerLookup from './components/lookup/FlowTriggerLookup.vue';

import MailTemplateLookup from './components/lookup/MailTemplateLookup.vue';
import ActivityLookup from './components/lookup/ActivityLookup.vue';
import CrossAnagraphicLookup from './components/lookup/CrossAnagraphicLookup.vue';
import LookupTag from './components/lookup/LookupTag.vue';
import LookupSkin from './components/lookup/LookupSkin.vue';
import EMailLookup from './components/lookup/EMailLookup.vue';

import TTags from './components/tag/TTags.vue';
import BoTag from './components/tag/BoTag.vue';
import GroupTag from './components/tag/GroupTag.vue';
import ContactCategoryTag from './components/tag/ContactCategoryTag.vue';
import ReferrerCategoryTag from './components/tag/ReferrerCategoryTag.vue';

import TDeleteButton from './components/buttons/TDeleteButton.vue';
import TDeleteDropdownItem from './components/buttons/TDeleteDropdownItem.vue';
import TPrintDropdownItem from './components/buttons/TPrintDropdownItem.vue';
import TDropdownActions from './components/buttons/TDropdownActions.vue';
import TASideButton from './components/buttons/TASideButton.vue';

import TSaveButton from './components/buttons/TSaveButton.vue';
import TFloatSaveButton from './components/buttons/TFloatSaveButton.vue';
import TNewButton from './components/buttons/TNewButton.vue';
import TBackButton from './components/buttons/TBackButton.vue';
import TBackButtonHeader from './components/buttons/TBackButtonHeader.vue';
import TCircleButton from './components/buttons/TCircleButton.vue';
import TButton from './components/buttons/TButton.vue';
import TTooltipButton from './components/buttons/TTooltipButton.vue';
import TButtonGroup from './components/buttons/TButtonGroup.vue';
import MyAreaStoreButton from './components/buttons/MyAreaStoreButton.vue';

import Base64AvatarUpload from './components/misc/Base64AvatarUpload.vue';
import AppendDrawer from './components/misc/AppendDrawer.vue';
import TweeningNumber from './components/misc/TweeningNumber.vue';
import EmailGravatar from './components/misc/EmailGravatar.vue';
import RemoteSelect from './components/misc/RemoteSelect.vue';
import IFrameContent from './components/misc/IFrameContent';
import ProfileImage from './components/misc/ProfileImage.vue';
import FloatProfileUsers from './components/misc/FloatProfileUsers.vue';
import ParameterVariable from './components/misc/ParameterVariable.vue';
import KanbanItem from './components/misc/KanbanItem.vue';
import BouncedInput from './components/misc/BouncedInput.vue';
import DiscountFormula from './components/misc/DiscountFormula.vue';
import ColorPicker from './components/misc/ColorPicker.vue';
import DynamicComponent from './components/misc/DynamicComponent.vue';
import ExternalTemplate from './components/misc/ExternalTemplate.vue';
import SearchInput from './components/misc/SearchInput.vue';
import TriStateInput from './components/misc/TriStateInput.vue';
import VueTitle from './components/misc/VueTitle';
import ContinuousScrolling from './components/misc/ContinuousScrolling.vue';
import FullSizeColumn from './components/misc/FullSizeColumn.vue';
import BreadcrumbPhases from './components/misc/BreadcrumbPhases.vue';
import FilePreview from './components/files/FilePreview.vue';
import Printer from './components/files/Printer.vue';
import Markdown from './components/misc/Markdown.vue';
import UnauthorizedMessage from './components/misc/UnauthorizedMessage.vue';
import WhenVisibile from './components/misc/WhenVisibile.vue';
import TCurrencyValue from './components/misc/CurrencyValue.vue';
import TImage from './components/misc/TImage.vue';
import ChartComponents from './components/charts';
import FreeFieldsComponents from './components/freefield';

import PhoneLabel from './components/labels/PhoneLabel.vue';
import EMailLabel from './components/labels/EMailLabel.vue';
import IconLabel from './components/labels/IconLabel.vue';

import SummaryLabel from './components/labels/SummaryLabel.vue';
import ResolveLabel from './components/labels/ResolveLabel.vue';
import BoResolveLabel from './components/labels/BoResolveLabel.vue';

import TooltipLabel from './components/labels/TooltipLabel.vue';
import AppointmentDateRangeLabel from './components/labels/AppointmentDateRangeLabel.vue';

import DetailPage from './components/layout/DetailPage.vue';
import HeaderPage from './components/layout/HeaderPage.vue';
import FloatingPanel from './components/layout/FloatingPanel.vue';

import AdvancedSearch from './components/search';
import Timeline from './components/timeline';
import Storage from './components/storage';
import Flow from './components/flow';
import NaturalSearchInput from './components/naturalsearch/NaturalSearchInput.vue';
import GlobalSearchInput from './components/search/GlobalSearchInput.vue';

import eComposerComponentsRegistration from './components/emailEditor/index';
import Mail from './components/mail';
import FullCalendar from './components/calendar';
import ImageManager from './components/images';
import Maps from './components/maps';
import Parallax from './components/parallax';
import ErpRow from './components/erpRow';
import Loadings from './components/loading';
import Security from './components/security';
import AnagraphicCerved from './components/cerved/AnagraphicCerved.vue';
import SimilarityItem from './components/similarity/SimilarityItem.vue';
import SimilarityPopOver from './components/similarity/SimilarityPopOver.vue';
import LiquidBrowser from './components/liquid/LiquidBrowser.vue';
import Duration from './components/misc/Duration.vue';
import MassiveUpdatePendingReport from './components/misc/MassiveUpdatePendingReport.vue';
import Archive from './components/archive';
import CookiesDialog from './components/misc/TCookiesDialog.vue';

class Components implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-grid', CrmGrid);
    vue.component('t-sheet-paging', SheetPaging);

    vue.component('t-skin', Skin);
    vue.component('t-widget', Widget);
    vue.component('t-widgets', Widgets);
    vue.component('t-aggregate-input', AggregateInput);
    vue.component('t-template-widget', TemplateWidget);
    vue.component('t-div', Div);
    vue.component('t-form-button', FormButton);
    vue.component('t-tabs', Tabs);
    vue.component('t-ls-form', LsForm);
    vue.component('t-simple-ls-form', SimpleLsForm);
    vue.component('t-layout-studio-input', LayoutStudioInput);
    vue.component('t-ls-section', LsSection);
    vue.component('t-input', Input);
    vue.component('t-city-input', CityInput);
    vue.component('t-vat-input', VatInput);
    vue.component('t-hyperlink-input', HyperlinkInput);

    vue.component('t-bounced-input', BouncedInput);
    vue.component('t-tax-identification-number-input', TaxIdentificationNumberInput);
    vue.component('t-discount-formula-input', DiscountFormulaInput);
    vue.component('t-color-picker', ColorPicker);

    vue.component('t-mailing-list-input', MailingListInput);
    vue.component('t-tags-input', TagsInput);
    vue.component('t-password-input', PasswordInput);
    vue.component('t-date-input', DateInput);
    vue.component('t-geo', Geo);
    vue.component('t-freefield', Freefield);
    vue.component('t-list-select', ListSelect);
    vue.component('t-enum-list-select', EnumListSelect);
    vue.component('t-select', EnumListSelect);
    vue.component('t-checkbox-input', CheckboxInput);
    vue.component('t-switch-input', SwitchInput);
    vue.component('t-numeric-input', NumericInput);
    vue.component('t-currency-input', CurrencyInput);
    vue.component('t-probability-input', ProbabilityInput);
    vue.component('t-form-item', FormItem);
    vue.component('t-nbsp', Nbsp);
    vue.component('t-alert', Alert);
    vue.component('t-repeater', Repeater);
    vue.component('t-email-normalized-input', EmailNormalizedInput);
    vue.component('t-phone-normalized-input', PhoneNormalizedInput);
    vue.component('t-account-input', AccountInput);
    vue.component('t-catalog-category-input', CatalogCategoryInput);
    vue.component('t-zone-input', ZoneInput);
    vue.component('t-company-input', CompanyInput);
    vue.component('t-contact-input', ContactInput);
    vue.component('t-lead-input', LeadInput);
    vue.component('t-catalog-input', CatalogInput);
    vue.component('t-work-plan-input', WorkPlanInput);
    vue.component('t-opportunity-input', OpportunityInput);
    vue.component('t-storage-input', StorageInput);
    vue.component('t-mail-template-input', MailTemplateInput);
    vue.component('t-list-input', ListInput);
    vue.component('t-activity-input', ActivityInput);
    vue.component('t-cross-anagraphic-input', CrossAnagraphicInput);
    vue.component('t-contact-category-input', ContactCategoryInput);
    vue.component('t-referrer-category-input', ReferrerCategoryInput);
    vue.component('t-ternary-anagraphic-input', TernaryAnagraphicInput);
    vue.component('t-flow-trigger-input', FlowTriggerInput);
    vue.component('t-alarm-input', AlarmInput);
    vue.component('t-duration-input', DurationInput);
    vue.component('t-messages-input', MessagesInput);
    vue.component('t-color-picker-input', ColorPickerInput);
    vue.component('t-discount-formula', DiscountFormula);
    vue.component('t-icon-input', IconInput);
    vue.component('t-surname-input', SurnameInput);
    vue.component('t-parameter-variable-input', ParameterVariableInput);

    vue.component('t-account-tree', AccountTree);
    vue.component('t-zone-tree', ZoneTree);
    vue.component('t-catalog-category-tree', CatalogCategoryTree);
    vue.component('t-groups', GroupsInput);

    vue.component('t-account-lookup', AccountLookup);
    vue.component('t-zone-lookup', ZoneLookup);
    vue.component('t-company-lookup', CompanyLookup);
    vue.component('t-contact-lookup', ContactLookup);
    vue.component('t-lead-lookup', LeadLookup);
    vue.component('t-catalog-lookup', CatalogLookup);
    vue.component('t-work-plan-lookup', WorkPlanLookup);
    vue.component('t-opportunity-lookup', OpportunityLookup);
    vue.component('t-storage-lookup', StorageLookup);
    vue.component('t-list-lookup', ListLookup);
    vue.component('t-flow-trigger-lookup', FlowTriggerLookup);
    vue.component('t-mail-template-lookup', MailTemplateLookup);
    vue.component('t-activity-lookup', ActivityLookup);
    vue.component('t-cross-anagraphic-lookup', CrossAnagraphicLookup);
    vue.component('t-lookup-tag', LookupTag);
    vue.component('t-catalog-category-lookup', CatalogCategoryLookup);
    vue.component('t-lookup-skin', LookupSkin);

    vue.component('t-email-lookup', EMailLookup);

    vue.component('t-tags', TTags);
    vue.component('t-bo-tag', BoTag);
    vue.component('t-group-tag', GroupTag);
    vue.component('t-contact-category-tag', ContactCategoryTag);
    vue.component('t-referrer-category-tag', ReferrerCategoryTag);

    vue.component('t-delete-button', TDeleteButton);
    vue.component('t-delete-dropdown-item', TDeleteDropdownItem);
    vue.component('t-print-dropdown-item', TPrintDropdownItem);

    vue.component('t-dropdown-actions', TDropdownActions);
    vue.component('t-aside-button', TASideButton);
    vue.component('t-my-area-store-button', MyAreaStoreButton);
    vue.component('t-save-button', TSaveButton);
    vue.component('t-float-save-button', TFloatSaveButton);
    vue.component('t-buttons-widgets', ButtonsWidgets);
    vue.component('t-web-api-action-button', WebApiActionButton);
    vue.component('t-redirect-button', RedirectButton);

    vue.component('t-new-button', TNewButton);
    vue.component('t-back-button', TBackButton);
    vue.component('t-back-button-header', TBackButtonHeader);
    vue.component('t-circle-button', TCircleButton);
    vue.component('t-button', TButton);
    vue.component('t-tooltip-button', TTooltipButton);

    vue.component('t-button-group', TButtonGroup);

    vue.component('t-avatar-upload', Base64AvatarUpload);
    vue.component('t-append-drawer', AppendDrawer);
    vue.component('t-tweening-number', TweeningNumber);
    vue.component('t-email-gravatar', EmailGravatar);
    vue.component('t-remote-select', RemoteSelect);
    vue.component('t-search-input', SearchInput);
    vue.component('t-tri-state-input', TriStateInput);

    vue.component('t-vue-title', VueTitle);
    vue.component('t-continuous-scrolling', ContinuousScrolling);
    vue.component('t-full-size-column', FullSizeColumn);

    vue.component('t-breadcrumb-phases', BreadcrumbPhases);

    vue.component('t-iframe-content', IFrameContent);
    vue.component('t-file-preview', FilePreview);
    vue.component('t-printer', Printer);

    vue.component('t-currency-value', TCurrencyValue);

    vue.component('t-markdown', Markdown);
    vue.component('t-unauthorized-message', UnauthorizedMessage);
    vue.component('t-when-visible', WhenVisibile);

    vue.component('t-image', TImage);

    vue.component('t-date-picker', () => import(/* webpackChunkName: "date-picker" */ './components/misc/DatePicker.vue'));
    vue.component('t-time-picker', () => import(/* webpackChunkName: "date-picker" */ './components/misc/TimePicker.vue'));
    vue.component('t-duration', Duration);

    vue.component('t-profile-image', ProfileImage);
    vue.component('t-float-profile-users', FloatProfileUsers);

    vue.component('t-parameter-variable', ParameterVariable);
    vue.component('t-dynamic-component', DynamicComponent);
    vue.component('t-external-component', ExternalTemplate);
    vue.component('t-kanban-item', KanbanItem);
    vue.component('t-org-chart', () => import(/* webpackChunkName: "t-org-chart" */ './components/misc/TOrgChart.vue'));

    vue.component('t-phone-label', PhoneLabel);
    vue.component('t-email-label', EMailLabel);
    vue.component('t-icon-label', IconLabel);
    vue.component('t-appointment-date-range-label', AppointmentDateRangeLabel);

    vue.component('t-summary-label', SummaryLabel);
    vue.component('t-resolve-label', ResolveLabel);
    vue.component('t-bo-resolve-label', BoResolveLabel);
    vue.component('t-tooltip-label', TooltipLabel);
    vue.component('t-detail-page', DetailPage);
    vue.component('t-header-page', HeaderPage);
    vue.component('t-floating-panel', FloatingPanel);
    vue.component('t-anagraphic-cerved', AnagraphicCerved);

    vue.component('t-similarity-item', SimilarityItem);
    vue.component('t-similarity-pop-over', SimilarityPopOver);
    vue.component('t-liquid-browser', LiquidBrowser);
    vue.component('t-massive-update-pending-report', MassiveUpdatePendingReport);

    vue.component('t-textcomplete', () => import(/* webpackChunkName: "crm" */ './components/misc/TextComplete.vue'));
    vue.component('t-message-list', () => import(/* webpackChunkName: "crm" */ './components/message/MessageList.vue'));
    vue.component('t-message', () => import(/* webpackChunkName: "crm" */ './components/message/Message.vue'));
    vue.component('t-message-editor', () => import(/* webpackChunkName: "crm" */ './components/message/MessageEditor.vue'));
    vue.component('t-message-channels', () => import(/* webpackChunkName: "crm" */ './components/message/MessageChannels.vue'));
    vue.component('t-message-textcomplete', () => import(/* webpackChunkName: "crm" */ './components/message/MessageTextComplete.vue'));

    vue.component('t-font-family-editor', () => import(/* webpackChunkName: "emailEditor" */ './components/misc/FontFamilyInput.vue'));

    vue.component('t-natural-search-input', NaturalSearchInput);
    vue.component('t-global-search-input', GlobalSearchInput);
    vue.component('t-vue-particles', () => import(/* webpackChunkName: "particles" */ './components/particles/vue-particles.vue'));

    vue.component('t-cookies-dialog', CookiesDialog);
    vue.component('ace-editor', () => import(/* webpackChunkName: "ace-editor" */ './components/misc/AceEditor'));

    Vue.use(eComposerComponentsRegistration);
    Vue.use(ChartComponents);
    Vue.use(FreeFieldsComponents);
    Vue.use(AdvancedSearch);
    Vue.use(Storage);
    Vue.use(Timeline);
    Vue.use(Mail);
    Vue.use(FullCalendar);
    Vue.use(ImageManager);
    Vue.use(Maps);
    Vue.use(Flow);
    Vue.use(Security);
    Vue.use(Parallax);
    Vue.use(ErpRow);
    Vue.use(Loadings);
    Vue.use(Archive);
  }
}

export default new Components();
