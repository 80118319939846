<template>
  <el-row :gutter="5" type="flex" align="middle" justify="center">
    <el-col v-if="rtl">
      <slot></slot>
    </el-col>
    <el-col :span="-1">
      <slot name="icon">
        <span v-if="content" :class="shake">
          <t-tooltip-label v-bind="$props" v-if="icon">
            <i :class="icon" :style="iconStyle"></i>
          </t-tooltip-label>
        </span>
        <span v-else :class="shake">
          <i :class="icon" :style="iconStyle"></i>
        </span>
      </slot>
    </el-col>
    <el-col v-if="!rtl">
      <slot></slot>
    </el-col>
  </el-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'IconLabel',
})
export default class IconLabel extends Vue {
  @Prop({
    type: String,
    required: false,
    default: null,
  })
  icon: string;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  content: string;

  @Prop({
    type: String,
    required: false,
    default: 'top',
  })
  placement: string;

  @Prop({
    type: [Object, String],
    required: false,
  })
  iconStyle: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  rtl: boolean;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  shake: string;
}
</script>
