//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RecurrenceFrequency {
	First = 1,
	Second = 2,
	Third = 3,
	Fourth = 4,
	Last = 5
}
