//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivityPermits {
	AllowApprovedPendingEmail = 54,
	AllowCancelActivity = 59,
	AllowViewActivityTicket = 77,
	AllowViewActivityOpportunity = 78,
	AllowViewActivityDebtRecovery = 79,
	AllowViewActivityLocations = 82,
	AllowApprovedPendingFax = 83,
	AllowViewActivityJobOrder = 86,
	AllowMassChange = 91,
	AllowRepeaterExport = 113,
	AllowSearchForCampaign = 135,
	AllowCostsView = 149,
	AllowViewActivityEvent = 163
}
