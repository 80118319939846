//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PortalDocumentPermissionSet {
	Visible = 1,
	PublicFolder = 2,
	UploadFile = 4,
	ShowFileCategory = 8,
	ShowFreeFields = 16
}
