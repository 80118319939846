import { Store } from 'vuex';
import i18nUtils from 'utils/i18nUtils';

import Vue, { PluginObject } from 'vue';

class ChartComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-timeline', () => import(/* webpackChunkName: "timeline" */ './Timeline.vue'));
    vue.component('t-timeline-activity-created', () => import(/* webpackChunkName: "timeline" */ './TimelineActivityCreated.vue'));
    vue.component('t-timeline-opportunity-created', () => import(/* webpackChunkName: "timeline" */ './TimelineOpportunityCreated.vue'));
    vue.component('t-timeline-item-changes', () => import(/* webpackChunkName: "timeline" */ './TimelineItemChanges.vue'));
    vue.component('t-timeline-item-created', () => import(/* webpackChunkName: "timeline" */ './TimelineItemCreated.vue'));
    vue.component('t-timeline-activity-item', () => import(/* webpackChunkName: "timeline" */ './TimelineActivityItem.vue'));
    vue.component('t-timeline-opportunity-item', () => import(/* webpackChunkName: "timeline" */ './TimelineOpportunityItem.vue'));
    vue.component('t-timeline-mailing-delivery', () => import(/* webpackChunkName: "timeline" */ './TimelineMailingDelivery.vue'));
    vue.component('t-timeline-mailing-created', () => import(/* webpackChunkName: "timeline" */ './TimelineMailingCreated.vue'));
    vue.component('t-timeline-print-item', () => import(/* webpackChunkName: "timeline" */ './TimelinePrintItem.vue'));
    vue.component('t-timeline-sharing-download-item', () => import(/* webpackChunkName: "timeline" */ './TimelineSharingDownloadItem.vue'));
    vue.component('t-timeline-quote-stage-change-item', () => import(/* webpackChunkName: "timeline" */ './TimelineQuoteStageChangeItem.vue'));
    vue.component('t-timeline-work-plan-status-change-item', () => import(/* webpackChunkName: "timeline" */ './TimelineWorkPlanStatusChangeItem.vue'));
    vue.component('t-message-item', () => import(/* webpackChunkName: "timeline" */ './TimelineMessageItem.vue'));
    vue.component('t-appointment-item', () => import(/* webpackChunkName: "timeline" */ './TimelineAppointmentItem.vue'));
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
  }
}
export default new ChartComponents();
