//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SharingTemplateGroupMode {
	Group = 0,
	CurrentGroup = 1,
	CurrentManagerGroup = 2,
	CurrentSubGroup = 3,
	OwnerGroup = 4,
	OwnerManagerGroup = 5,
	OwnerSubGroups = 6,
	SalesPersonGroups = 7,
	SalesPersonManagerGroups = 8,
	SalesPersonSubGroups = 9
}
