//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SecurityPageMenu {
	Calendar = 2,
	UsersAdmin = 8,
	Notes = 10,
	MessageCenter = 11,
	UserManagement = 24,
	Companies = 29,
	Contacts = 31,
	CompanyProfileAdministration = 33,
	KnowledgeBase = 34,
	Promotions = 37,
	Activities = 38,
	Alarms = 41,
	Documents = 45,
	ImportExternalData = 47,
	DeletedEntities = 49,
	Mailinglist = 51,
	Leads = 53,
	CatalogProducts = 59,
	WebMail = 63,
	Today = 64,
	GlobalConfiguration = 68,
	QuoteList = 69,
	DatabaseManagement = 71,
	OrderList = 72,
	CatalogList = 73,
	Tickets = 77,
	TicketReport = 78,
	ReportWizard = 80,
	StaticReports = 81,
	AdvertisingCampaigns = 82,
	Opportunities = 83,
	Invoicelist = 84,
	TicketConfiguration = 85,
	CategoriesKnowledgeBase = 86,
	DdtList = 87,
	PrimaNota = 88,
	SpoolerMailingList = 91,
	RollingStock = 92,
	Contracts = 94,
	ContractTemplate = 95,
	EMailsSended = 100,
	Graphics = 101,
	DocumentAutomation = 103,
	TemplatesPrintEditor = 104,
	EmployeeActivitiesReport = 105,
	SurveyManager = 106,
	ScheduledOperations = 107,
	IncomeDocument = 108,
	LayoutStudioEditor = 110,
	DatabaseDataImport = 111,
	PortalAdmin = 113,
	Sla = 114,
	SmsSended = 117,
	CatiEditor = 119,
	DistributionLists = 120,
	Ideas = 121,
	SalesPerformanceReport = 122,
	FlowReport = 123,
	JobOrders = 124,
	JobOrderTasks = 125,
	JobOrderTemplate = 126,
	JobOrderToBill = 127,
	ApprovalsList = 129,
	CallLog = 130,
	JobOrderTaskPlanner = 131,
	MergeItems = 132,
	ExpenseReport = 133,
	UserSkills = 136,
	TemplateEmails = 137,
	RenewContracts = 138,
	DynamoPageEditor = 139,
	CustomErpDocumentList = 140,
	Events = 141,
	GraphSales = 142,
	TemplateSms = 143,
	FlowAdmin = 144
}
