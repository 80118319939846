//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WebMailProtocol {
	POP3 = 0,
	POP3s = 1,
	IMAP4 = 2,
	IMAP4s = 3
}
