//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivitySource {
	Manual = 0,
	SystemFirstActivity = 1,
	System = 2,
	Flow = 3,
	Rules = 4,
	MassMailingSpooler = 5,
	Calendar = 6
}
