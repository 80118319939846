//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TitleListType {
	All = 0,
	OnlyActivity = 1,
	OnlyTicket = 2,
	OnlyRequest = 3,
	Campaign = 4
}
