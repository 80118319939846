//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IQuoteDTO } from '../../../DTO/IQuoteDTO';
import { IERPRowDTO } from '../../../DTO/IERPRowDTO';
import { IFilePreview } from './IFilePreview';

//element.Name=QuoteController result.Name=QuoteController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.QuoteController);
/** Result of WebApiControllerGenerator activity */
export class QuoteController extends DefaultDtoControllerProxy<IQuoteDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Quotes', BusinessObjectType.Quote);
	}
	public ConvertToOrder(dto: IQuoteDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Quotes/ConvertToOrder', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StoreDocument(req: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Quotes/StoreDocument', req, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Copy(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Quotes/Copy?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Review(id: number, useNewNumber: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Quotes/Review?'+'&id='+id+'&useNewNumber='+useNewNumber, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetNewRowInstance(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.get('Quotes/GetNewRowInstance', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ApplyCalculateTotalDocument(dto: IQuoteDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IQuoteDTO>
	{
		return (this.axios.post('Quotes/ApplyCalculateTotalDocument', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public NewEmptyRow(dto: IQuoteDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/NewEmptyRow', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogCodeChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/CatalogCodeChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PriceListChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/PriceListChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnitPriceChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/UnitPriceChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public QtaChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/QtaChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnitMeasureChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/UnitMeasureChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public TaxCodeChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/TaxCodeChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DiscountFormulaChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/DiscountFormulaChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CostChanged(dto: IQuoteDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Quotes/CostChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProcessOnChangeListId(dto: IQuoteDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IQuoteDTO>
	{
		return (this.axios.post('Quotes/ProcessOnChangeListId', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProcessOnChangeCurrency(dto: IQuoteDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IQuoteDTO>
	{
		return (this.axios.post('Quotes/ProcessOnChangeCurrency', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Quotes/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Quotes/Download?'+'&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Quotes/HtmlPreview?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Quotes/Previews?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Quotes/Preview?'+'&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Quotes/GetDownloadUrl?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Quotes/Archive?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Quotes/PrintParameters?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IQuoteDTO>
	{
		return (this.axios.get('Quotes/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
