<template>
  <el-card :body-style="{ padding: 0 }">
    <template #header>
      <div class="loading-box-loading">
        <el-row type="flex" :gutter="10">
          <el-col v-for="j in 6" :key="j">
            <div class="loading-box-line-xl" style="margin-bottom: 0px"></div>
          </el-col>
        </el-row>
      </div>
    </template>
    <div class="loading-box-loading">
      <el-row type="flex" :gutter="10" v-for="i in 10" :key="i" class="loading-row" :class="{ odd: i % 2 === 0 }">
        <el-col v-for="j in 6" :key="j" class="p-t">
          <div :class="`loading-box-line-${randomizeSize()}`"></div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingMixin from './loadingMixin';

@Component({
  name: 'LoadingTable',
})
export default class LoadingTable extends LoadingMixin { }
</script>
<style lang="scss">
@import './loading.scss';
@import 'element-variables';

.loading-row {
  border-bottom: $--border-base;

  &.odd {
    & .el-col {
      background-color: #fafafa !important;
    }
  }
}
</style>
