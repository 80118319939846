<template>
  <el-button
    :loading="loading"
    :type="type"
    :icon="icon"
    :size="size"
    round
    class="sidebar-button"
    :class="{ [size]: true, 'pulse-button': pulse, [type]: true } "
    @click="$emit('click')"
  >
    <slot></slot>
  </el-button>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TCircleButton',
})
export default class TCircleButton extends Vue {
  @Prop({
    type: String,
    default: '',
    required: false,
  })
  icon: string;

  @Prop({
    type: String,
    default: 'small',
    required: false,
  })
  size: string;

  @Prop({
    type: String,
    default: 'default',
    required: false,
  })
  type: string;

  @Prop({
    type: String,
    default: 'default',
    required: false,
  })
  image: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  pulse: boolean;
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.sidebar-button {
  padding: 2px !important;
  overflow: hidden;
  &.small {
    height: 40px;
    width: 40px;
    border-radius: 40px !important;
    font-size: 1.2em;
  }
  &.medium {
    height: 50px;
    width: 50px;
    border-radius: 50px !important;
    font-size: 1.4em;
  }
  &.big {
    height: 60px;
    width: 60px;
    border-radius: 60px !important;
    font-size: 1.6em;
  }

  &.pulse-button {
    box-shadow: 0 0 0 0 rgba($--color-white, 0.5);
    backface-visibility: hidden;
    -webkit-animation: pulse-default 2.5s infinite;
    &.primary {
      box-shadow: 0 0 0 0 rgba($--color-primary, 0.5);
      -webkit-animation: pulse-primary 2.5s infinite;
    }
  }
  &.pulse-button:hover {
    -webkit-animation: none;
  }
}

@-webkit-keyframes pulse-default {
  0% {
  }
  70% {
    box-shadow: 0 0 0 15px rgba($--color-white, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($--color-white, 0);
  }
}

@-webkit-keyframes pulse-primary {
  0% {
  }
  70% {
    box-shadow: 0 0 0 15px rgba($--color-primary, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($--color-primary, 0);
  }
}
</style>
