<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { IContactDTO } from 'dto/IContactDTO';
import LayoutStudioMixinView from 'modules/mixin/views/LayoutStudioMixin.vue';
import ContactMixin from './contactMixin';

@Component({
  name: 'ContactLayoutStudioView',
  mixins: [ContactMixin],
})
export default class ContactLayoutStudioView extends LayoutStudioMixinView<
  IContactDTO
> {
  protected get module(): string {
    return 'contact';
  }

  protected get routeName(): string {
    return this.$routeNames.contact;
  }

  protected get backRouteName(): string {
    return this.$routeNames.contacts;
  }
}
</script>
