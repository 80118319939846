//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TicketStatusType {
	New = 0,
	Open = 1,
	Suspended = 2,
	Closed = 3,
	Archived = 4,
	ReOpened = 6,
	OnCustomer = 7,
	OnOperator = 8
}
