<template>
  <span>
    <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required" v-if="!simple"
      :important="schema.important">
      <t-storage-lookup :enable-upload="!schema.readOnly" :value="files" @input="change">
        <template #append>
          <slot name="append"></slot>
        </template>
      </t-storage-lookup>
    </t-form-item>
    <t-storage-lookup v-else :value="files" :enable-upload="!schema.readOnly" @input="change">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-storage-lookup>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import ElementUI from 'element-ui';
import { Prop, Watch, Inject } from 'vue-property-decorator';

@Component({
  name: 'StorageInput',
})
export default class StorageInput extends Vue {
  @Inject('elForm') elForm: ElementUI.Form;

  @Prop({
    type: Object,
    required: false,
    default: () => ({
        text: 'schema.text',
        propertyName: 'schema.propertyName',
        multiple: false,
        readOnly: false,
      }),
  })
  schema: {
    multiple: boolean;
    readOnly: boolean;
  };

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  multiple: boolean;

  @Prop({
    type: Object,
    required: false,
    default: () => ({}),
  })
  data: any;

  @Prop({
    type: [Object, Number, Array],
    required: false,
  })
  value: number | number[];

  get files() {
    if (this.schema.multiple || this.multiple) {
      return this.value;
    }
    if (this.value > 0) {
      return [this.value];
    }
    return [];
  }

  change(v) {
    if (this.schema.multiple || this.multiple) {
      this.$emit('input', v);
    } else if (v.length) {
      this.$emit('input', v[0]);
    } else {
      this.$emit('input', null);
    }
  }
}
</script>
