<template>
  <div class="drag-item merge-item" :key="value.name" @click="$emit('click')">
    <div class="p-l-q">
      <span v-if="search">
        <span>{{ text[0] }}</span>
        <strong>{{ text[1] }}</strong>
        <span>{{ text[2] }}</span>
      </span>
      <span v-else>
        {{ text }}
      </span>
      <span class="el-icon-fa-plus-circle fas dragging-icon"></span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ILiquidStruct } from 'rt/Base/Utility/ILiquidStruct';

@Component({
  name: 'LiquidField',
})
export default class LiquidField extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: any;

  @Prop({
    type: String,
    required: false,
  })
  search: string;

  get text() {
    if (this.search && this.search.length > 0 && this.value.resourceName) {
      const pattern = new RegExp(this.search, 'ig');
      const match = pattern.exec(this.value.resourceName);
      if (match) {
        return [this.value.resourceName.substring(0, match.index), this.value.resourceName.substring(match.index, match.index + this.search.length), this.value.resourceName.substring(match.index + this.search.length)];
      }
    }
    return this.value.resourceName;
  }
}
</script>
