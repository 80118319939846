<template>
  <el-drawer
    :visible="visible"
    @update:visible="handleVisibleChange"
    v-bind="inheritedProperties"
    :style="styleModifier"
    v-if="reference != null"
  >
    <slot></slot>
  </el-drawer>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { Drawer } from 'element-ui';

@Component({
  name: 'AppendDrawer',
})
export default class AppendDrawer extends Vue {
  @Prop({
    type: Boolean,
  })
  visible: number;

  @Prop({
    type: String,
  })
  size: string;

  @Prop({
    type: String,
  })
  title: string;

  referenceWidth: number = null;

  referenceHeight: number = null;

  reference: Drawer = null;

  get inheritedProperties() {
    const props = this.reference.$options.propsData;
    return {
      ...props,
      size: this.size,
      visible: this.visible,
      showClose: true,
      title: this.title,
    };
  }

  get styleModifier() {
    const drawer = this.reference.$refs.drawer as HTMLElement;
    if (drawer) {
      const direction = this.reference.$options.propsData?.['direction'] ?? 'rtl';
      switch (direction) {
        case 'rtl':
          return {
            right: `${this.referenceWidth}px`,
          };
        case 'ltr':
          return {
            left: `${this.referenceWidth}px`,
          };
        default:
          return {};
      }
    }
    return null;
  }

  handleVisibleChange(v: boolean) {
    this.$emit('update:visible', v);
  }

  updated() {
    let p = this as Vue;
    do {
      p = p.$parent;
    } while (p.$options.name !== 'ElDrawer');
    if (!p) {
      throw new Error('Cannot use AppendDrawer not inside a ElDrawer element');
    }
    this.reference = p as Drawer;
    this.referenceWidth = (this.reference?.$refs?.drawer as HTMLElement)?.clientWidth;
    this.referenceHeight = (this.reference?.$refs?.drawer as HTMLElement)?.clientHeight;
  }
}
</script>
