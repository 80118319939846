//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SalesTargetReference {
	Weekly = 1,
	Monthly = 2,
	BiMonthly = 3,
	Quarterly = 4,
	SixMonthly = 5,
	Yearly = 6
}
