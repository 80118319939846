//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IERPRowDTO } from '../../../DTO/IERPRowDTO';
import { IOrderDTO } from '../../../DTO/IOrderDTO';
import { IFilePreview } from './IFilePreview';

//element.Name=OrderController result.Name=OrderController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.OrderController);
/** Result of WebApiControllerGenerator activity */
export class OrderController extends DefaultDtoControllerProxy<IOrderDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Orders', BusinessObjectType.Order);
	}
	public GetNewRowInstance(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.get('Orders/GetNewRowInstance', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ApplyCalculateTotalDocument(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ApplyCalculateTotalDocument', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public NewEmptyRow(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/NewEmptyRow', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogCodeChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/CatalogCodeChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PriceListChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/PriceListChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnitPriceChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/UnitPriceChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public QtaChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/QtaChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnitMeasureChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/UnitMeasureChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public TaxCodeChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/TaxCodeChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DiscountFormulaChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/DiscountFormulaChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CostChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/CostChanged?'+'&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProcessOnChangeListId(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ProcessOnChangeListId', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProcessOnChangeCurrency(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ProcessOnChangeCurrency', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Orders/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Orders/Download?'+'&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Orders/HtmlPreview?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Orders/Previews?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Orders/Preview?'+'&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Orders/GetDownloadUrl?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Orders/Archive?'+'&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Orders/PrintParameters?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.get('Orders/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
