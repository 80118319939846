import Vue from 'vue';
import components from '../plugins';
import enums from 'rt/Core';
import vuedraggable from 'vuedraggable';
import { Sortable, MultiDrag } from 'sortablejs';

export default () => {
  Vue.use(components);
  Vue.use((v) => {
    v.enums = enums;
    v.prototype.$enums = enums;
  });
  Vue.use((vue) => {
    // Sortable.mount(new MultiDrag());
    // eslint-disable-next-line vue/multi-word-component-names
    vue.component('draggable', vuedraggable);
  });
};
