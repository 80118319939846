<template>
  <span v-if="hasItemsInQueue">
    <el-popover ref="popover-webdav-queue" placement="left">
      <h4 class="text text-primary text-right">{{ $t('profile.sync.queue.last') }}</h4>
      <div class="m-t-q">
        <transition-group name="fade-jump-bottom">
          <el-row v-for="item in queueStats.lastItems" :key="item.name" class="m-b-q">
            <el-col class="text text-right">
              <el-dropdown class="text" trigger="click" @command="(cmd) => handleRowCommandClick(cmd, item.uniqueId)">
                <span>
                  {{ item.name }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown><el-dropdown-menu>
                    <el-dropdown-item command="importAsCompany">
                      <t-icon-label :icon="'el-icon-' + $boMapping.toIcon($enums.BusinessObjectType.Company)">{{
                        $t('profile.sync.queue.importAsCompany')
                      }}</t-icon-label>
                    </el-dropdown-item>
                    <el-dropdown-item command="importAsContact">
                      <t-icon-label :icon="'el-icon-' + $boMapping.toIcon($enums.BusinessObjectType.Contact)">{{
                        $t('profile.sync.queue.importAsContact')
                      }}</t-icon-label>
                    </el-dropdown-item>
                    <el-dropdown-item command="importAsLead">
                      <t-icon-label :icon="'el-icon-' + $boMapping.toIcon($enums.BusinessObjectType.Lead)">{{
                        $t('profile.sync.queue.importAsLead')
                      }}</t-icon-label>
                    </el-dropdown-item>
                  </el-dropdown-menu></template>
              </el-dropdown>
            </el-col>
          </el-row>
        </transition-group>
        <div class="text text-right">{{
          $tc('profile.sync.queue.more', queueStats.pendingItems -
            queueStats.lastItems.length)
        }}</div>
      </div>
      <div class="text-center m-t">
        <router-link :to="{ name: $routeNames.profileWebDavQueue }">
          <el-button type="primary">{{ $t('profile.sync.queue.goto') }}</el-button>
        </router-link>
      </div>
    </el-popover>
    <div class="spooler-button" v-popover:popover-webdav-queue>
      <el-badge :value="queueStats.pendingItems" :max="99" class="sidebar-badge">
        <t-circle-button type="default" icon="el-icon-icon-stack-user"></t-circle-button>
      </el-badge>
    </div>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { IWebDavQueueStats } from 'rt/UIApiControllers/BusinessObject/DTO/IWebDavQueueStats';

// tslint:disable-next-line:variable-name
const ProfileGetter = namespace('profile').Getter;
// tslint:disable-next-line:variable-name
const ProfileAction = namespace('profile').Action;

@Component({
  name: 'WebdavQueueStatus',
  components: {},
})
export default class WebdavQueueStatus extends Vue {
  @ProfileAction loadQueueStats: () => Promise<void>;

  @ProfileAction importAsCompany: (id: string) => Promise<boolean>;

  @ProfileAction importAsContact: (id: string) => Promise<boolean>;

  @ProfileAction importAsLead: (id: string) => Promise<boolean>;

  @ProfileGetter queueStats: IWebDavQueueStats;

  async mounted() {
    await this.loadQueueStats();
  }

  get hasItemsInQueue() {
    return this.queueStats != null && this.queueStats.pendingItems > 0;
  }

  async handleRowCommandClick(cmd, uniqueId) {
    await this[cmd](uniqueId);
  }
}
</script>
