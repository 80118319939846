<template>
  <t-button
    type="primary"
    :icon="icon"
    @click="()=>$emit('new')"
    :loading="loading"
    :disabled="disabled"
    v-indexed
  >
    <slot></slot>
  </t-button>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'TNewButton',
})
export default class TNewButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-file-empty',
  })
  icon: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  disabled: boolean;

  mounted() {}
}
</script>
<style lang="scss" scoped>
.el-button + .el-button {
  // margin-top: 2px;
}
</style>
