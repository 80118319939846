<template>
  <el-form autocomplete="off" ref="globalSearch" :model="globalSearch" @submit.native.prevent :label-width="null">
    <input autocomplete="false" name="hidden" type="text" style="display:none;" />
    <el-form-item prop="term" :show-message="false" required>
      <div>
        <el-input ref="naturalSearch" :value="naturalSearch.phrase" @input="handleNaturalSearchPhraseInput"
          @keyup.enter.native="() => handleNaturalLanguageSearch({ value: naturalSearch.phrase })"
          @keyup.native="queryNaturalLanguageSearchOnDelay">
          <template #suffix>
            <span :class="{ 'el-icon-icon-search': !speeching, 'el-icon-icon-mic2': speeching, }"></span>
          </template>
        </el-input>
        <div v-if="suggestions && suggestions.actions.length">
          <div class="text text-primary m-t-h m-l-h cursor-pointer"
            @click="() => handleNaturalLanguageSearch({ value: naturalSearch.phrase })">{{
  suggestions.actions[0].hint
            }}</div>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { Action, Getter, namespace } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import HashIds from 'hashids';
import store from '@/store';
import { AdvancedSearchController } from 'rt/UIApiControllers/AdvancedSearch/AdvancedSearchController';
import { IReportSearchConditionDTO } from 'dto/IReportSearchConditionDTO';
import { NaturalLanguageController } from 'rt/NaturalLanguage/Controllers/NaturalLanguageController';
import { INaturalLanguageResponse } from 'rt/NaturalLanguage/Processing/Response/INaturalLanguageResponse';
import _ from 'lodash';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

enum NaturalLanguageResponseActionKind {
  Script = 0,
  GoToPage = 1,
  GoToBO = 2,
  GoToUrl = 3,
  GoToRoute = 4,
  GoToRouteAdvancedSearch = 5,
}

@Component({
  name: 'BSidebar',
})
export default class NaturalSearchInput extends Vue {
  @Getter user: IUser;

  @Getter baseUrl: string;

  @Getter('advancedSearch/controller')
  advancedSearchController: AdvancedSearchController;

  private naturalLanguageController = new NaturalLanguageController(Vue.axios);

  globalSearch: { term: string } = { term: null };

  naturalSearch: { phrase: string } = { phrase: null };

  mounted() { }

  executeGlobalSearch() {
    (this.$refs.globalSearch as ElementUI.Form).validate((valid: boolean) => {
      if (valid) {
        this.$router.push({
          name: this.$routeNames.globalSearch,
          query: { q: this.globalSearch.term },
        });
      }
    });
  }

  queryNaturalLanguageSearchOnDelay = _.debounce(() => {
    if (this.naturalSearch.phrase) {
      this.queryNaturalLanguageSearch(this.naturalSearch.phrase, null);
    }
  }, 400);

  suggestions: INaturalLanguageResponse = null;

  async queryNaturalLanguageSearch(phrase: string, cb: (payload: any) => void) {
    this.suggestions = null;
    this.suggestions = await this.naturalLanguageController.NaturalSearch(
      {
        phrase,
      },
      null,
    );
    if (cb) {
      if (this.suggestions.actions.length) {
        cb(this.suggestions.actions);
      } else {
        cb(this.suggestions.suggestions);
      }
    }
  }

  handleNaturalLanguageSearch(action: { value?: string; name: string; actionKind: any; actionParameters: any }) {
    if (action.value) {
      if (this.suggestions) {
        if (this.suggestions.actions.length || this.suggestions.suggestions.length) {
          if (this.suggestions.actions.length) {
            this.handleNaturalLanguageSearch(this.suggestions.actions[0] as any);
          }
        } else {
          if (action.value) {
            this.globalSearch.term = action.value;
            this.executeGlobalSearch();
          }
        }
      }
    } else {
      if (Object.prototype.hasOwnProperty.call(action, 'actionKind')) {
        this.naturalSearch.phrase = '';
        this.suggestions = null;
        switch (action.actionKind as NaturalLanguageResponseActionKind) {
          case NaturalLanguageResponseActionKind.GoToPage:
            this.goToPageRedirect(action.actionParameters);
            break;
          case NaturalLanguageResponseActionKind.GoToBO:
            this.goToBORedirect(action.actionParameters);
            break;
          case NaturalLanguageResponseActionKind.GoToUrl:
            this.goToUrlRedirect(action.name, action.actionParameters);
            break;
          case NaturalLanguageResponseActionKind.GoToRoute:
            this.goToRouteRedirect(action.actionParameters);
            break;
          case NaturalLanguageResponseActionKind.GoToRouteAdvancedSearch:
            this.goToRouteWithAdvancedSearchRedirect(action.actionParameters);
            break;
          case NaturalLanguageResponseActionKind.Script:
            this.scriptExecution(action.name, action.actionParameters);
            break;
        }
      }
    }
  }

  scriptExecution(name: string, parameters: { value: string }) {
    switch (name) {
      case 'GlobalSearch':
        {
          this.globalSearch.term = parameters.value;
          this.executeGlobalSearch();
        }
        break;
    }
  }

  private pageRedirectMapping: { [key: string]: string } = {
    naturalLanguageSearchHelp: this.$routeNames.naturalLanguageHelp,
    activities: this.$routeNames.activities,
    calendar: this.$routeNames.calendar,
    today: this.$routeNames.today,
    tags: null,
    createAppointment: null,
    createOpportunity: this.$routeNames.opportunity,
    createLead: this.$routeNames.lead,
    createCompany: this.$routeNames.company,
    createActivity: this.$routeNames.activity,
    createContact: this.$routeNames.contact,
  };

  async goToRouteWithAdvancedSearchRedirect(parameters: { route: string; parameters?: any; reportName: string; conditions: IReportSearchConditionDTO[] }) {
    const asId = await this.advancedSearchController.EnqueueConditions(parameters.reportName, parameters.conditions);
    const redirectparameters = {
      ...parameters.parameters,
      as: asId,
    };
    this.goToRouteRedirect({
      route: parameters.route,
      parameters: redirectparameters,
    });
  }

  async goToRouteRedirect(parameters: { route: string; parameters?: any }) {
    this.$router.push({
      path: parameters.route,
      query: parameters.parameters,
    });
  }

  goToBORedirect(parameters: { id: number; boType: number }) {
    const routeType = this.$enums.BusinessObjectType[parameters.boType].toLowerCase();
    const routeName = this.$routeNames[routeType];
    if (routeName) {
      this.$router.push({
        name: routeName,
        params: {
          id: this.$hashids.encode(parameters.id),
        },
      });
    }
  }

  goToPageRedirect(parameters: { name: string }) {
    const routeName = this.pageRedirectMapping[parameters.name];
    if (routeName) {
      this.$router.push({
        name: routeName,
        params: {
          id: this.$hashids.encode(0),
        },
      });
    }
  }

  goToUrlRedirect(name: string, parameters: { value: string }) {
    if (name === 'Google') {
      window.open(`https://www.google.com/search?q=${encodeURIComponent(parameters.value)}`);
    }
    if (name === 'Web') window.open(parameters.value);
  }

  @Prop({ default: false })
  speeching: boolean;

  recognition: any;

  @Watch('speeching')
  startHandleSpeechRecognition(v) {
    if (v) {
      this.naturalSearch.phrase = '';
      const w = window as any;
      const Recognition = w.SpeechRecognition || w.webkitSpeechRecognition || w.mozSpeechRecognition || w.msSpeechRecognition;
      if (Recognition) {
        this.$emit('update:speeching', true);
        this.recognition = new Recognition();
        const sr = this.recognition;
        sr.lang = this.user.culture;
        sr.interimResults = false;
        sr.maxAlternatives = 5;
        sr.start();
        sr.onresult = (event) => {
          if (!this.naturalSearch.phrase) {
            this.naturalSearch.phrase = event.results[0][0].transcript;
            this.$emit('update:speeching', false);
            window.setTimeout(() => {
              (this.$refs.naturalSearch as any).focus();
            }, 100);
          }
        };
      }
      (this.$refs.naturalSearch as any).focus();
    } else {
      if (this.recognition) {
        this.recognition.stop();
      }
      this.$emit('update:speeching', false);
    }
  }

  handleNaturalSearchPhraseInput(v: string) {
    this.naturalSearch.phrase = v;
    if (this.speeching && this.recognition && this.recognition.stop) {
      this.recognition.stop();
      this.$emit('update:speeching', false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~element-variables';

.el-aside {
  overflow-x: hidden;
  background: $--color-primary;

  .el-row {
    height: 60px;
  }
}

.nl-suggest {
  margin-bottom: 8px;

  >div {
    line-height: 1em;
  }
}
</style>
<style lang="scss">
@import '~element-variables';

.WHF {
  color: $--color-warning;
}

.PHF {
  color: $--color-primary;
  padding-right: 4px;
  padding-left: 4px;
}

.bside {
  position: relative;

  .commands {
    position: fixed;
    top: 2px;
    right: 2px;

    & .sidebar-badge {
      & .el-badge__content.is-fixed {
        top: -6px;
        right: unset;
        left: -6px;
        transform: none;
        -webkit-transform: none;
      }
    }
  }
}
</style>
