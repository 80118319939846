import Vue from 'vue';
// import vuex from './vuex';
import hashids from './hashids';
import element from './element';
import components from './components';
import axios from './axios';
import moment from './moment';
import tinymce from './tinymce';
import simplemde from './simplemde';
import viewport from './viewport';
import masonry from './masonry';
import i18nInit from './i18n';
import filters from './filters';
import directives from './directives';
import error from './error';
import modules from './modules';
import download from './download';
import beep from './beep';
import chat from './chat';
import oauth2 from './oauth2';
import VueI18n from 'vue-i18n';
import businessObjectTypeMapping from 'utils/businessObjectTypeMapping';
import Component from 'vue-class-component';
import routeHelper from 'utils/routeHelper';
import { eventHub } from '@/signalr';
import indexer from 'utils/indexer';

export default (baseUrl: string, maxRequests: number): { i18n: VueI18n } => {
  const i18n = i18nInit();
  element(i18n);
  error();
  components();
  axios(baseUrl, maxRequests);
  moment();
  filters();
  directives();
  hashids();
  tinymce();
  simplemde();
  viewport();
  masonry();
  modules();
  download();
  beep();
  chat();
  oauth2();
  Vue.use((v) => {
    v.prototype.$boMapping = businessObjectTypeMapping;
    v.routeHelper = v.prototype.$routeHelper = routeHelper;
    v.prototype.$signalRHub = v.signalRHub = eventHub;
    v.indexer = v.prototype.$indexer = indexer;
  });
  // Register the router hooks with their names
  Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate', // for vue-router 2.2+
  ]);
  return {
    i18n,
  };
};
