//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IReportSearchConditionDTO } from '../../../DTO/IReportSearchConditionDTO';
import { EMailNormalizeType } from '../../../Core/EMailNormalizeType';
import { IListPreset } from '../../../Interfaces/TargetList/Presets/IListPreset';
import { IListDTO } from '../../../DTO/IListDTO';

//element.Name=ListController result.Name=ListController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.ListController);
/** Result of WebApiControllerGenerator activity */
export class ListController extends DefaultDtoControllerProxy<IListDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'List', BusinessObjectType.List);
	}
	public InsertCompanies(id: number, items: number[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('List/InsertCompanies?'+'&id='+id, items, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteCompanies(id: number, items: number[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.delete('List/DeleteCompanies?'+'&id='+id+((items === null || items === undefined) ? '' : ('&items=['+items.join(',')+']')), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public InsertContacts(id: number, items: number[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('List/InsertContacts?'+'&id='+id, items, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteContacts(id: number, items: number[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.delete('List/DeleteContacts?'+'&id='+id+((items === null || items === undefined) ? '' : ('&items=['+items.join(',')+']')), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public InsertLeads(id: number, items: number[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('List/InsertLeads?'+'&id='+id, items, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteLeads(id: number, items: number[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.delete('List/DeleteLeads?'+'&id='+id+((items === null || items === undefined) ? '' : ('&items=['+items.join(',')+']')), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveCompaniesConditions(id: number, conditions: IReportSearchConditionDTO[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('List/SaveCompaniesConditions?'+'&id='+id, conditions, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveContactsConditions(id: number, conditions: IReportSearchConditionDTO[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('List/SaveContactsConditions?'+'&id='+id, conditions, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveLeadsConditions(id: number, conditions: IReportSearchConditionDTO[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('List/SaveLeadsConditions?'+'&id='+id, conditions, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteCompaniesConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('List/CompaniesConditions?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteContactsConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('List/ContactsConditions?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteLeadsConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('List/LeadsConditions?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Companies(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/Companies?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchCompaniesEmails(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.post('List/SearchCompaniesEmails', filter, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetCompaniesEmails(id: number, emailType: EMailNormalizeType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetCompaniesEmails?'+'&id='+id+'&emailType='+emailType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetCompaniesEmail(id: number, emailType: EMailNormalizeType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetCompaniesEmail?'+'&id='+id+'&emailType='+emailType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetCompanyEmails(id: number, emailType: EMailNormalizeType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetCompanyEmails?'+'&id='+id+'&emailType='+emailType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetCompanyEmail(id: number, emailType: EMailNormalizeType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('List/GetCompanyEmail?'+'&id='+id+'&emailType='+emailType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Contacts(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/Contacts?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchContactsEmails(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.post('List/SearchContactsEmails', filter, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetContactsEmails(id: number, emailType: EMailNormalizeType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetContactsEmails?'+'&id='+id+'&emailType='+emailType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetContactsEmail(id: number, emailType: EMailNormalizeType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetContactsEmail?'+'&id='+id+'&emailType='+emailType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetContactEmails(id: number, emailType: EMailNormalizeType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetContactEmails?'+'&id='+id+'&emailType='+emailType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetContactEmail(id: number, emailType: EMailNormalizeType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('List/GetContactEmail?'+'&id='+id+'&emailType='+emailType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Leads(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/Leads?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchLeadsEmails(filter: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.post('List/SearchLeadsEmails', filter, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetLeadsEmails(id: number, emailType: EMailNormalizeType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetLeadsEmails?'+'&id='+id+'&emailType='+emailType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetLeadsEmail(id: number, emailType: EMailNormalizeType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetLeadsEmail?'+'&id='+id+'&emailType='+emailType, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetLeadEmails(id: number, emailType: EMailNormalizeType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('List/GetLeadEmails?'+'&id='+id+'&emailType='+emailType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetLeadEmail(id: number, emailType: EMailNormalizeType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('List/GetLeadEmail?'+'&id='+id+'&emailType='+emailType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CompaniesIds(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('List/CompaniesIds?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ContactsIds(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('List/ContactsIds?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LeadsIds(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('List/LeadsIds?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CompaniesConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReportSearchConditionDTO[]>
	{
		return (this.axios.get('List/CompaniesConditions?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ContactsConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReportSearchConditionDTO[]>
	{
		return (this.axios.post('List/ContactsConditions?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LeadsConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReportSearchConditionDTO[]>
	{
		return (this.axios.post('List/LeadsConditions?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Stats(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('List/Stats?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Presets(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListPreset[]>
	{
		return (this.axios.get('List/Presets', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SetPreset(id: number, preset: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('List/SetPreset?'+'&id='+id+((preset === null || preset === undefined) ? '' : ('&preset='+encodeURIComponent(preset))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ImportElementsFromCache(id: number, cacheKey: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('List/ImportElementsFromCache?'+'&id='+id+((cacheKey === null || cacheKey === undefined) ? '' : ('&cacheKey='+encodeURIComponent(cacheKey))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListDTO>
	{
		return (this.axios.get('List/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
