<template>
  <span>
    <div>
      <h3 class="m-b">{{ schema.text }}</h3>
    </div>
    <t-message-list
      :originalMessage="message"
      :crossIdFilter="true"
      :crossType="schema.crossType"
      :crossId="data.id"
      :full="true"
      :read-only="schema.readOnly"
      @input="handleTempMessage"
    ></t-message-list>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'MessagesInput',
})
export default class MessagesInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  context: any;

  get message() {
    const m = this.data['__message'];
    if (m) {
      return m;
    }
    return null;
  }

  handleTempMessage(m) {
    this.$emit('input', { ...this.data, __message: m });
  }
}
</script>
