<template>
  <span>
    <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required" v-if="!simple"
      :important="schema.important">
      <t-zone-lookup v-if="!isReadOnly" :value="selectedData" @input="change" :multipleSelection="schema.multiple"
        ref="lookup">
        <template #append>
          <slot name="append"></slot>
        </template>
      </t-zone-lookup>
      <t-lookup-tag v-else :value="val" :key="val.key" v-for="val in selectedData" :closable="false"></t-lookup-tag>
    </t-form-item>
    <t-zone-lookup v-else :value="selectedData" @input="change" :multipleSelection="multiple" :read-only="isReadOnly"
      ref="lookup">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-zone-lookup>
  </span>
</template>
<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 't-zone-input',
})
export default class ZoneInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  async resolve(id: number): Promise<string> {
    return (await this.resolveController.Zone(id)).name;
  }

  protected get identifier(): string {
    return 'Zone';
  }
}
</script>
