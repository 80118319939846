//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum StorageLogType {
	Download = 0,
	FileShared = 1,
	DownloadShared = 2,
	ChangeStatus = 3,
	ChangeFolder = 4
}
