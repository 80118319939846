<template>
  <span class="gravatar">
    <img
      :src="`${baseUrl}/Avatar/Gravatar/${encodeURIComponent(safeEmail)}/${$viewport.isRetina ? size * 2 : size}?${ticks}`"
      class="gravatar" :class="{ grayed }" :style="`width: ${size}px;border-radius: ${size}px`" />
    <div class="overlay">
      <slot name="overlay"></slot>
    </div>
    <span class="m-l-q">
      <slot></slot>
    </span>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';

@Component({
  name: 'EmailGravatar',
})
export default class EmailGravatar extends Vue {
  @Getter ticks: number;

  @Getter('baseUrl') baseUrl;

  @Prop({
    type: Number,
    default: 24,
  })
  size: number;

  @Prop({
    type: String,
    required: true,
  })
  email: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  grayed: boolean;

  @Prop({
    type: Number,
    required: false,
  })
  id: number;

  get safeEmail(): string {
    if (this.email && this.email.length) return this.email;
    if (this.id) return this.$hashids.encode(this.id);
    return '-';
  }
}
</script>
<style lang="scss" scoped>
span.gravatar {
  white-space: nowrap;
  position: relative;

  & .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
}

img.gravatar {
  vertical-align: middle;
  display: inline-block;
}

img.grayed {
  filter: grayscale(0.7);
}
</style>
