//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AnagraphicStatusKind {
	Open = 0,
	Suspended = 1,
	Qualified = 2,
	Junk = 3,
	Ignored = 4
}
