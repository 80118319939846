//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CostType {
	Activity = 0,
	FixedCost = 1,
	ContractTemplate = 2,
	Contract = 3,
	Company = 4,
	Contact = 5,
	ContractService = 6,
	Standard = 7,
	JobOrder = 8,
	Opportunity = 9,
	Quote = 10
}
