//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderTaskMode {
	Hourly = 0,
	AllDay = 1,
	HourlyRecurrent = 2,
	AllDayRecurrent = 3
}
