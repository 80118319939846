<template>
  <div class="float-users" :style="{ height: `${size}px` }">
    <div class="users" :style="{ height: `${size}px` }">
      <t-profile-image :class="`user-image-${size} user-image-overlay`" :size="size" :userId="ac"
        v-for="(ac, index) in accounts.filter(a => !isNaN(a))" :key="ac"
        :style="{ right: `${index * (size * 2 / 3)}px` }"></t-profile-image>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import HashIds from 'hashids';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'FloatProfileUsers',
})
export default class FloatProfileUsers extends Vue {
  @Prop({
    type: [Number, Array],
    required: true,
  })
  value: number | number[];

  @Prop({
    type: Number,
    required: false,
    default: 40,
  })
  size: number;

  get accounts() {
    if (Array.isArray(this.value)) return _.uniq(this.value);
    return [this.value];
  }
}
</script>
<style lang="scss" scoped>
.float-users {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 50px;
  overflow: hidden;

  & .users {
    position: absolute;
    right: -6px;
    bottom: -6px;
    width: 100%;

    &>img {
      position: absolute;
      bottom: 0;
      right: 0;

      &.user-image-overlay {
        border: 1px solid white;
        opacity: 0.8;
      }
    }
  }
}
</style>
