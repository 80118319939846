import Vue, { PluginObject } from 'vue';
import TAppointmentIntegration from './AppointmentIntegration.vue';

class FullCalendarComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('full-calendar', () => import(/* webpackChunkName: "fullcalendar" */ './FullCalendarNative.vue'));
    vue.component('t-appointment-integration', TAppointmentIntegration);
  }
}
export default new FullCalendarComponents();
