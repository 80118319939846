//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum StorageShareType {
	Account = 1,
	Group = 2,
	Office = 3,
	Anagraphic = 4
}
