<template>
  <span>
    <transition name="fade">
      <div class="lock"></div>
    </transition>

    <el-row type="flex" class="h-100 loading" justify="center" align="middle">
      <el-col :xs="20" :sm="12" :md="8" :lg="4" :xl="3">
        <el-card class="box-card">
          <template #header>
            <div class="clearfix">
              <span>
                <transition mode="out-in" name="el-zoom-in-center">
                  <img alt="logo" :src="`/${brand.id}/img/logo.svg`" v-if="companyPhoto == null" key="defaultPhoto"
                    width="100%" />
                  <img alt="photo" :src="companyPhoto" class="companyPhoto" v-else key="companyPhoto" />
                </transition>
              </span>
            </div>
          </template>
          <div v-loading="true" class="m-b" style="min-height: 40px"
            :element-loading-text="$t(`loading.${splashMessage}`)"></div>
        </el-card>
      </el-col>
    </el-row>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'Loading',
})
export default class Loading extends Vue {
  @Getter user: IUser;

  @Getter('initialized') initialized: boolean;

  @Getter splashMessage: string;

  @Getter brand: string;

  @Watch('initialized')
  handleInitializedChange(newVal: boolean) {
    if (newVal) {
      this.goToLandingPage();
    }
  }

  mounted() {
    if (this.initialized) {
      this.goToLandingPage();
    }
  }

  get companyPhoto(): string {
    if (this.user) {
      return this.user.companyPhoto;
    }
    return null;
  }

  goToLandingPage() {
    if (this.$route.query.reenton) {
      const query = { ...this.$route.query };
      delete query.reenton;
      this.$router.replace({
        query,
        path: this.$route.query.reenton as string,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.lock {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.7;
  z-index: 100;
}

.loading {
  z-index: 101;
}

.companyPhoto {
  max-width: 100%;
}
</style>
