<template>
  <el-alert
    class="compact"
        :type="expirationAlertType"
        :closable="false"
        :title="expirationDays > 30 ? $t('tustenaCompany.licence.valid') : $tc('tustenaCompany.licence.expiration', expirationDays, { expirationDays, expirationDate: $moment(value.expirationDate).format('L') })"
      ></el-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ITustenaCompanyDTO } from 'dto/ITustenaCompanyDTO';

@Component({
  name: 'ExpirationDateAlert',
})
export default class ExpirationDateAlert extends Vue {
  @Prop({ required: true, type: Object }) value: ITustenaCompanyDTO;

  get expirationAlertType() {
    const diff = this.expirationDays;
    if (diff < 30) {
      return 'warning';
    }
    if (diff < 15) {
      return 'danger';
    }
    return 'success';
  }

  get expirationDays() {
    const expirationDate = this.$moment(this.value.expirationDate);
    return expirationDate.diff(this.$moment(), 'days');
  }
}
</script>

