<template>
  <span>
    <el-dropdown class="commands" @command="command => $emit('command', command)" trigger="click">
      <span class="el-icon-icon-more2"></span>
      <template #dropdown><el-dropdown-menu>
          <el-dropdown-item command="download"
            v-if="user.isAuthorizedToRead($enums.BusinessObjectType.Storage) && download">{{
              $t('storage.commands.download')
            }}</el-dropdown-item>
          <el-dropdown-item command="rename"
            v-if="user.isAuthorizedToUpdate($enums.BusinessObjectType.Storage) && rename">{{
              $t('storage.commands.rename')
            }}</el-dropdown-item>
          <el-dropdown-item command="move"
            v-if="user.isAuthorizedToUpdate($enums.BusinessObjectType.Storage) && move">{{
              $t('storage.commands.move')
            }}</el-dropdown-item>
          <el-dropdown-item command="delete"
            v-if="user.isAuthorizedToDelete($enums.BusinessObjectType.Storage) && allowDelete">{{
              $t('storage.commands.delete')
            }}</el-dropdown-item>
          <slot></slot>
          <el-dropdown-item divided command="details"
            v-if="user.isAuthorizedToRead($enums.BusinessObjectType.Storage) && details">{{
  $t('storage.commands.details')
            }}</el-dropdown-item>
        </el-dropdown-menu></template>
    </el-dropdown>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'StorageFileCommands',
})
export default class StorageFileCommands extends Vue {
  @Getter user: IUser;

  @Prop({ type: Boolean, required: false, default: true })
  download: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  rename: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  move: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  delete: boolean;

  get allowDelete() {
    return this.delete;
  }

  @Prop({ type: Boolean, required: false, default: true })
  details: boolean;
}
</script>
<style lang="scss" scoped>
.commands {
  cursor: pointer;
}
</style>
