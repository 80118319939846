//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { ISchemaDataDTO } from '../../../DTO/ISchemaDataDTO';
import { IFlowTriggerDTO } from '../../../DTO/IFlowTriggerDTO';
import { IFlowDTO } from '../../../DTO/IFlowDTO';
import { IFlowTemplateDTO } from '../../../DTO/IFlowTemplateDTO';

//element.Name=FlowTemplateController result.Name=FlowTemplateController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.FlowTemplateController);
/** Result of WebApiControllerGenerator activity */
export class FlowTemplateController extends DefaultDtoControllerProxy<IFlowTemplateDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'FlowsTemplates', BusinessObjectType.FlowTemplate);
	}
	public Schemes(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISchemaDataDTO[]>
	{
		return (this.axios.get('FlowsTemplates/Schemes', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StartupTypes(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('FlowsTemplates/StartupTypes', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Triggers(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowTriggerDTO[]>
	{
		return (this.axios.get('FlowsTemplates/Triggers?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Flows(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowDTO[]>
	{
		return (this.axios.get('FlowsTemplates/Flows?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('FlowsTemplates/FlowsIds?'+'&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Clone(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('FlowsTemplates/Clone?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Export(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('FlowsTemplates/Export?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Import(data: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('FlowsTemplates/Import', data, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowTemplateDTO>
	{
		return (this.axios.get('FlowsTemplates/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
