//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OperationType {
	Ridflow = 0,
	Sap = 1,
	Print = 2,
	AEAflow = 3,
	SapCollections = 4,
	Email = 5,
	PrintAll = 6,
	InvoicesGeneration = 7,
	CustomType = 99
}
