//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum StoragePermits {
	AllowSharingFile = 60,
	AllowSharingFileToExternal = 61,
	AllowDeleteSharing = 62,
	AllowEditSharingParameters = 63,
	EnableGroupsFilter = 205,
	ShowOnlyLinkedOrUploadedFiles = 211
}
