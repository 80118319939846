<template>
  <el-alert effect="dark" :closable="false" show-icon type="success">
    {{ $t('mailing.updated') }}
  </el-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'MailingUnsubscribed',
})
export default class MailingUnsubscribed extends Vue {}
</script>
