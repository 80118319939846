import Vue, { PluginObject } from 'vue';
import i18nUtils from 'utils/i18nUtils';

class MapsComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
    vue.component('t-map-waypoints', () => import(/* webpackChunkName: "map" */ './TMapWaypoints.vue'));
    vue.component('t-map', () => import(/* webpackChunkName: "map" */ './TMap.vue'));
    vue.component('t-leaf-let-map', () => import(/* webpackChunkName: "map" */ './TLeafLetMap.vue'));
    vue.component('t-map-search', () => import(/* webpackChunkName: "map" */ './TMapSearch.vue'));
    vue.component('t-map-source', () => import(/* webpackChunkName: "map" */ './TMapSource.vue'));
    vue.component('t-map-dynamic-mark', () => import(/* webpackChunkName: "map" */ './TMapDynamicMark.vue'));
    vue.component('t-map-distance', () => import(/* webpackChunkName: "map" */ './TMapDistance.vue'));
  }
}
export default new MapsComponents();
