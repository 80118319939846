<template>
  <span>
    <el-form-item :label="schema.text" :prop="schema.propertyName" v-if="alarm">
      <div v-if="alarm.notifications">
        <el-row v-for="(notification, index) in alarm.notifications" :key="index" class="m-b" type="flex" align="middle"
          justify="center">
          <el-col>
            <el-dropdown trigger="click" @command="(v) => handleNotificationChange(index, 'actionType', v)">
              <span class="text">
                {{ $enums.AlarmActionType[notification.actionType] }}
                <i class="el-icon-icon-arrow-down4 text text-info el-icon--right"></i>
              </span>
              <template #dropdown><el-dropdown-menu>
                  <el-dropdown-item :command="$enums.AlarmActionType.PopUp">PopUp</el-dropdown-item>
                  <el-dropdown-item :command="$enums.AlarmActionType.EMail">EMail</el-dropdown-item>
                </el-dropdown-menu></template>
            </el-dropdown>
            <span class="text m-l-h">
              <el-input-number max-length="2" :style="{ width: '50px' }" :value="notification.timeValue" :max="99"
                :min="1" :controls="false"
                @input="(v) => handleNotificationChange(index, 'timeValue', +v)"></el-input-number>
            </span>
            <el-dropdown class="m-l-h" trigger="click"
              @command="(v) => handleNotificationChange(index, 'timeValueType', v)">
              <span class="text">
                {{ $t(`notification.${$enums.AlarmTimeValueType[notification.timeValueType].toLowerCase() }`) }}
                <i class="el-icon-icon-arrow-down4 text text-info el-icon--right"></i>
              </span>
              <template #dropdown><el-dropdown-menu>
                  <el-dropdown-item :command="$enums.AlarmTimeValueType.Minutes">{{ $t('notification.minutes')
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="$enums.AlarmTimeValueType.Hours">{{ $t('notification.hours')
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="$enums.AlarmTimeValueType.Days">{{ $t('notification.days')
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="$enums.AlarmTimeValueType.Weeks">{{ $t('notification.weeks')
                  }}</el-dropdown-item>
                </el-dropdown-menu></template>
            </el-dropdown>
            <span class="text m-l-q">{{ $t('notification.before') }}</span>
          </el-col>
          <el-col :span="-1">
            <t-delete-button :type="null" :circle="false" :button="false"
              @delete="() => handleRemoveNotification(index)"></t-delete-button>
          </el-col>
        </el-row>
      </div>
      <div class="text text-right" v-if="alarm.notifications.length < 5">
        <el-button @click="handlePlusClick" type="primary">{{ $t('notification.add') }}</el-button>
      </div>
    </el-form-item>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { IAlarmDTO } from 'dto/IAlarmDTO';
import { IAlarmNotificationDTO } from 'dto/IAlarmNotificationDTO';
import { AlarmController } from 'rt/UIApiControllers/BusinessObject/DTO';

@Component({
  name: 'AlarmInput',
})
export default class AlarmInput extends simpleMixin {
  alarmController = new AlarmController(Vue.axios);

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Object,
    required: false,
  })
  value: IAlarmDTO;

  emptyValue: IAlarmDTO = null;

  get alarm() {
    return this.value || this.emptyValue;
  }

  async mounted() {
    if (this.value == null) {
      this.emptyValue = await this.alarmController.GetNewInstance();
    }
  }

  handlePlusClick() {
    const newNotification = {
      actionType: this.$enums.AlarmActionType.PopUp,
      timeValue: 10,
      timeValueType: this.$enums.AlarmTimeValueType.Minutes,
    };
    this.simpleEmit({
      ...this.value,
      crossId: 1,
      notifications: [...this.alarm.notifications, newNotification],
    });
  }

  handleNotificationChange(idx: number, prop: string, v: any) {
    const notifications = [...this.alarm.notifications];
    const nn = [...notifications.slice(0, idx), { ...notifications[idx], [prop]: v }, ...notifications.slice(idx + 1)];
    this.simpleEmit({
      ...this.value,
      notifications: nn,
    });
  }

  handleRemoveNotification(idx) {
    const notifications = [...this.alarm.notifications];
    this.$emit('input', {
      ...this.value,
      notifications: [...notifications.slice(0, idx), ...notifications.slice(idx + 1)],
    });
  }
}
</script>

<style lang="scss">
@import '../../../../scss/modifiers.scss';

.t-alarm-input {
  @extend .inline-input;
}
</style>
