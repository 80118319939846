<template>
  <div class="drag-item" :class="{ 'draggable': draggable }">
    <div class="content">
      <span class="text">
        <slot></slot>
      </span>
    </div>
    <span class="fas dragging-icon el-icon-fa-plus-circle"></span>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter, Action, namespace } from 'vuex-class';

@Component({
  name: 'KanbanItem',
})
export default class KanbanItem extends Vue {
  @Prop({
    required: false,
    default: true,
    type: Boolean,
  })
  draggable: boolean;
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
.item {
  & .inner {
    padding: 4px;
    background-color: $--color-white;
    margin: 4px;
    display: block;
    border: 1px solid silver;
    transition: all 0.4s ease;
    & > div {
      margin-bottom: 6px;
    }
    overflow: hidden;
  }
}
</style>
