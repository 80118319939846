//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CrossActivityWith {
	Contract = 0,
	Request = 1,
	Invoice = 3,
	Relation = 4,
	Project = 5,
	Order = 6,
	CustomErpDocument = 7
}
