<template>
  <el-form-item :label="schema.text" :prop="schema.propertyName">
    <div class="text-info">
      <t-group-tag
        :value="value"
        @input="v => $emit('input', v)"
        :readOnly="schema.readOnly"
        :global="schema.global"
        :system-administrator="schema.systemAdministrator"
      ></t-group-tag>
    </div>
  </el-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Groups',
})
export default class Groups extends Vue {
  @Getter('lists/list') list: (type: ListsType) => Array<IInt64ListResult>;

  @Getter('user') user: IUser;

  @Prop({
    type: Object,
    required: true,
  })
  schema: {
    listType: ListsType;
    text: string;
    captionValue: any;
    captionText: string;
  };

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: true,
  })
  value: any;
}
</script>
