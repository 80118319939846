//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ERCostType {
	QtaKm = 0,
	OtherPrice = 1,
	Call = 2,
	ContractRate = 3,
	ReplacementCode = 4,
	Rental = 5,
	Accomodation = 6,
	Food = 7,
	Transport = 8,
	Parking = 9
}
