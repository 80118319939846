//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InfluencerRoleType {
	None = 0,
	Initiator = 1,
	Decider = 2,
	Buyer = 3,
	User = 4,
	Gatekeeper = 5
}
