//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ImportErrorType {
	NotFoundInDb = 0,
	TypeMismatch = 1,
	NullValue = 2,
	RequiredFieldIsNull = 3
}
