<template>
  <div class="performance">
    <div class="m-r-h m-l-h m-t-h">
      <div v-for="(perf, index) in performances" :key="index" class="m-b-h">
        <h4 class="text">
          <el-row type="flex">
            <el-col>{{ perf.name }}</el-col>
            <el-col :span="2" class="text-right">{{ parseInt(perf.duration) }}ms</el-col>
            <el-col
              :span="2"
              class="text-right"
            >{{ perf.encodedBodySize | approx({ capital: true, suffix: 'B' }) }}</el-col>
            <el-col :span="1" class="text-right cursor-pointer">
              <i @click="exclude(perf.id)" class="el-icon-fa-times fas close"></i>
            </el-col>
          </el-row>
        </h4>
        <ul class="m-r">
          <li v-for="(time, index) in perf.serverTiming" :key="index" class="text">
            <el-row :gutter="6" align="middle" justify="center">
              <el-col :span="14">{{ time.name }}</el-col>
              <el-col
                :span="4"
                class="text-right"
                :class="{ 'text-danger': time.duration > 600, 'text-warning': time.duration > 300 }"
              >{{ parseInt(time.duration) }}ms</el-col>
              <el-col :span="6" class="text-right">{{ time.description }}</el-col>
            </el-row>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ElementUI from 'element-ui';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'Performance',
})
export default class Performance extends Vue {
  performances: any[] = null;

  interval: number = null;

  excludes: number[] = [];

  created() {
    this.updatePerformances();
    this.interval = window.setInterval(() => this.updatePerformances(), 1000);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  exclude(id: number) {
    this.excludes.push(id);
  }

  updatePerformances() {
    this.performances = window.performance
      .getEntriesByType('resource')
      .map((e, index) => {
        (e as any).id = index;
        return e;
      })
      .filter((e) => !this.excludes.includes((e as any).id))
      .filter((e) => (e as any).serverTiming.length)
      .reverse()
      .slice(0, 10);
  }
}
</script>
<style lang="scss" scoped>
.performance {
  position: fixed;
  top: 10px;
  bottom: 10px;
  left: 10px;
  background-color: rgba($color: white, $alpha: 0.7);
  width: 600px;
  border: 1px solid red;
  overflow: hidden;
  z-index: 1459878;
  word-break: break-all;
  pointer-events: none;
  & .close {
    pointer-events: all;
  }
}
</style>
