//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderSparePart {
	CatalogProduct = 0,
	ManualProduct = 1,
	RollingStock = 2,
	Note = 3
}
