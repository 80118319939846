import Vue from 'vue';
import VueRouter from 'vue-router';
import { RootState } from '@/store/state';
import { Store } from 'vuex';
import jwtManager from 'utils/jwtManager';
import routes from './routes';
import routeNames from './routeNames';
import anonymousRoutes from '../modules/anonymous/routes';

Vue.use(VueRouter);
Vue.use((v) => {
  v.routeNames = routeNames;
  v.prototype.$routeNames = routeNames;
});
const createRouter = () => new VueRouter({
    routes: [...routes, ...anonymousRoutes],
    mode: 'history',
  });
const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // the relevant part
  return newRouter;
}

export class RouterInitializer {
  init(store: Store<RootState>): VueRouter {
    router.beforeEach(async (to, from, next) => {
      store.commit('setUserLanguage');
      if (!store.getters.initialized) {
        if (to.meta && !to.meta.splash) {
          next({
            name: jwtManager.token ? routeNames.reenter : routeNames.loading,
            query: {
              ...to.query,
              reenton: to.fullPath,
            },
          });
          return;
        }
      }
      if (to.matched.length === 0) {
        next({
          name: routeNames.error4xx,
          params: {
            code: '404',
            error: 'NotFound',
          },
        });
        return;
      }
      if (!to.meta.anonymous) {
        const tokenValid = await jwtManager.isValidToken();
        if (!tokenValid) {
          const query = <{ reenton?: string }>{};
          if (to.name !== routeNames.error4xx) {
            query.reenton = to.fullPath;
          }
          next({
            query,
            name: routeNames.login,
          });
        } else {
          next();
        }
      } else {
        next();
      }
    });
    return router;
  }
}
const routerInitializer = new RouterInitializer();
export { routerInitializer };
export default router;
