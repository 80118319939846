//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TicketPermits {
	AllowMoveTicket = 48,
	AllowInsertKnowledgeBase = 52,
	AllowEditKnowledgeBase = 53,
	AllowDeleteDocuments = 55,
	AllowViewOtherUsersTicketInMyTicketList = 57,
	AllowViewChildRelationTicket = 85,
	AllowMassChange = 92,
	AllowCreateJobOrderTask = 101,
	AllowViewConfigurationSLA = 121,
	AllowViewConfigurationWorkRange = 122,
	AllowLogView = 195,
	AllowAdvancedSearch = 201,
	EnableTicketAutoAssign = 209
}
