//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WebDavService {
	GoogleContact = 5,
	ICloudContact = 6,
	ICloudCalendar = 7,
	GoogleCalendar = 8,
	RadicaleCalendar = 9,
	RadicaleContact = 10,
	DavCalendar = 11,
	DavContact = 12
}
