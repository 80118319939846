//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PortalCatalogPermissionSet {
	Visible = 1,
	ViewPrices = 2,
	ViewDocuments = 4,
	ViewLinkedProducts = 8
}
