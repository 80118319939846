<template>
  <div v-html="marked"></div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import XXH from 'xxhashjs';
import { MarkdownController } from 'rt/UIApiControllers/Utils/MarkdownController';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import _ from 'lodash';

@Component({
  name: 'Markdown',
})
export default class SearchInput extends Vue {
  markdownController = new MarkdownController(Vue.axios);

  @Prop({ required: false, type: String })
  value: string;

  @Prop({ required: false, type: Boolean, default: false })
  legacyHeading: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  unsafe: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  cache: boolean;

  @Getter markdownCache: { [key: string]: string };

  @Mutation setMarkdownCache: (payload: { key: string; value: string }) => void;

  @Watch('value')
  handleValueChange(v) {
    if (this.markTransformDebounced) {
      this.markTransformDebounced(v);
    }
  }

  marked: string = null;

  markTransformDebounced: (s) => void;

  cancellationTokenSource: CancelTokenSource;

  mounted() {
    this.markTransformDebounced = _.debounce(async (html: string) => {
      await this.markdownTransform(html);
    }, 300);
    this.markTransformDebounced(this.value);
  }

  async markdownTransform(markdown: string) {
    if (this.cancellationTokenSource) {
      this.cancellationTokenSource.cancel('aborted for changing timeline options or paging');
    }
    if (markdown && markdown.length) {
      const resolve = async () => {
        this.cancellationTokenSource = axios.CancelToken.source();
        return await this.markdownController.Transform(markdown, this.legacyHeading, this.unsafe, this.cancellationTokenSource.token);
      };
      if (this.cache) {
        const xh = XXH.h64(markdown, 0xabcd);
        const hash = xh.toString(16);
        if (Object.prototype.hasOwnProperty.call(this.markdownCache, hash)) {
          this.marked = this.markdownCache[hash];
          return;
        }
        this.marked = await resolve();
        this.setMarkdownCache({ key: hash, value: this.marked });
        return;
      }
      this.marked = await resolve();
    } else {
      this.marked = null;
    }
  }

  beforeDestroy() {
    if (this.cancellationTokenSource) {
      this.cancellationTokenSource.cancel();
    }
  }
}
</script>
