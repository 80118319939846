//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ApprovalRuleCalcType {
	WeightDiscountTotalDocument = 0,
	DiscountForSingleRow = 1,
	WeightDiscountForCatalogCategory = 2,
	WeightDiscountForManualCatalog = 3,
	SubTotalDocument = 4,
	WeightDiscountTotalExtensionDocument = 5,
	DiscountForSingleExtensionRow = 6,
	OpportunityAmount = 7,
	AnagraphicLocked = 8,
	CustomRule = 9,
	ExpenseReportAmount = 10
}
