import routeNames from './routeNames';
import Particles from '../views/Particles.vue';
import Loading from '../views/Loading.vue';
import Init from '../views/Init.vue';
import Container from '../modules/base/views/Container.vue';
import jwtManager from 'utils/jwtManager';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { MessageBox } from 'element-ui';

const routes = [
  {
    path: '/splash',
    component: Particles,
    children: [
      {
        path: 'loading',
        name: routeNames.loading,
        component: Loading,
        meta: {
          anonymous: true,
          splash: true,
        },
      },
    ],
  },
  {
    path: '/splash',
    component: Container,
    children: [
      {
        path: 'reenter',
        name: routeNames.reenter,
        component: Loading,
        meta: {
          anonymous: true,
          splash: true,
        },
      },
    ],
  },
  {
    path: '/splash',
    component: Particles,
    children: [
      {
        name: routeNames.init,
        path: 'init',
        component: Init,
        meta: {
          anonymous: true,
          splash: true,
        },
      },
    ],
  },
  {
    path: '/impersonate',
    component: Particles,
    children: [
      {
        path: 'logon',
        alias: '',
        component: Init,
        meta: {
          anonymous: true,
          splash: true,
        },
        beforeEnter: async (to, from, next) => {
          jwtManager.clearTokens();
          try {
            const decoded = jwtDecode<JwtPayload>(to.query.token);
            if (decoded) {
              const expDate = new Date(decoded.exp * 1000);
              if (new Date().getTime() < decoded.exp * 1000) {
                jwtManager.token = to.query.token;
                await MessageBox.alert(`Temporary Impersonate Authentication. Limited Login time ${expDate.toDateString()} ${expDate.toLocaleTimeString()}`, {
                  type: 'warning',
                });
              }
            }
          } catch (e) {
          } finally {
            next();
          }
        },
      },
    ],
  },
];

export default routes;
