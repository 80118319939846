//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TypePrivacy {
	Custom = 0,
	Management = 1,
	Survey = 2,
	Marketing = 3
}
