<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import LayoutStudioMixinView from 'modules/mixin/views/LayoutStudioMixin.vue';
import CompanyMixin from './companyMixin';
import { SimilarityController } from 'rt/UIApiControllers/UI/Smart/SimilarityController';
import { ISimilarityRequest } from 'rt/UIApiControllers/UI/Smart/ISimilarityRequest';
import { ISimilarityResponse } from 'rt/UIApiControllers/UI/Smart/ISimilarityResponse';

@Component({
  name: 'CompanyLayoutStudioView',
  mixins: [CompanyMixin],
})
export default class CompanyLayoutStudioView extends LayoutStudioMixinView<ICompanyDTO> {
  protected get module(): string {
    return 'company';
  }

  protected get routeName(): string {
    return this.$routeNames.company;
  }

  protected get backRouteName(): string {
    return this.$routeNames.companies;
  }
}
</script>
