<template>
  <div class="lookup-box" :class="{ focused }" v-if="inputVisible" @click="handleBoxClick">
    <span class="pull-left">
      <slot></slot>
    </span>
    <span v-if="!readOnly">
      <span class="pull-left lookup-handle m-l-q">
        <el-autocomplete ref="input-element" size="mini" :value="value" @input="v => $emit('input', v)"
          @select="v => $emit('select', v)" :fetch-suggestions="fetchSuggestions" v-autowidth="{ comfortZone: 10 }"
          :trigger-on-focus="autostart" @blur="handleBlur" @focus="handleFocus" highlight-first-item
          popper-class="lookup-suggest-popper">
          <template #default="{ item }">
            <slot :item="item" name="autocomplete">{{ item.value }}</slot>
          </template>
        </el-autocomplete>
      </span>
      <span class="pull-right">
        <slot name="dialog-search">
          <i class="el-icon-search cursor-pointer" @click="e => $emit('openDialog', e)" />
        </slot>
        <slot name="append"></slot>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'LookupSkin',
  props: {
    value: {
      type: String,
      required: true,
    },
    inputVisible: {
      type: Boolean,
      required: true,
      default: true,
    },
    fetchSuggestions: {
      type: Function,
      required: false,
      default: null,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    autostart: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
})
export default class LookupSkin extends Vue {
  focused = false;

  handleBlur() {
    this.focused = false;
  }

  handleFocus() {
    this.focused = true;
  }

  handleBoxClick() {
    (this.$refs['input-element'] as any).focus();
  }

  focus() {
    (this.$refs['input-element'] as any).focus();
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.el-tag {
  margin-bottom: $-margin-size-quarter;
}

.el-tag+.el-tag {
  margin-left: $-margin-size-quarter;
}

.lookup-box {
  border-bottom: 1px solid $--border-color-base;
  clear: right;
  overflow: hidden;

  &.focus {
    border-color: $--input-focus-border;
  }

  .lookup-handle {
    cursor: text;

    ::v-deep{ .el-input__inner {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }
  }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .lookup-box {
    clear: both;
  }
}
</style>
<style lang="scss">
.lookup-suggest-popper {
  min-width: 200px;
}

@media screen and (min-width: 992px) {
  .lookup-suggest-popper {
    min-width: 400px;
  }
}
</style>
