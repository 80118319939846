<template>
  <span>
    <span v-if="allDay" key="allDaySpan" class="block">
      {{ $moment(start).format('LL') }}
      <span v-if="$moment(end).isAfter($moment(start), 'day')">~ {{ $moment(end).format('LL') }}</span>
    </span>
    <span v-else-if="$moment(end).isSame($moment(start), 'day')" key="sameDaySpan">
      {{ $moment(start).format('LLL') }}
      <span v-if="$moment(end).isAfter($moment(start))" class="block">~ {{
        $moment.duration($moment(end).diff($moment(start))).humanize()
      }}</span>
    </span>
    <span v-else key="moreDaySpan" class="inline-block m-t-q m-l-q">
      <span v-if="start" class="block">{{ $moment(start).format('LLL') }}</span>
      <span class="graph">[</span>
      <span v-if="end" class="block">{{ $moment(end).format('LLL') }}</span>
    </span>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'TAppointmentDateRangeLabel',
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    allDay: {
      type: Boolean,
      required: true,
    },
    split: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
export default class TAppointmentDateRangeLabel extends Vue {
  start: Date;

  end: Date;

  allDay: boolean;
}
</script>
<style lang="scss" scoped>
.graph {
  position: absolute;
  margin-left: -7px;
  line-height: 1px;
  display: block;
  font-size: 1.4em;
}
</style>
