//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IPaymentDTO } from '../../../DTO/IPaymentDTO';

//element.Name=PaymentController result.Name=PaymentController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.PaymentController);
/** Result of WebApiControllerGenerator activity */
export class PaymentController extends DefaultDtoControllerProxy<IPaymentDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Payments', BusinessObjectType.Payment);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IPaymentDTO>
	{
		return (this.axios.get('Payments/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
