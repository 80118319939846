//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LeadLoadPerformanceSettings {
	All = 1,
	Minimal = 2,
	LoadSync = 4,
	LoadFreeFields = 8,
	LoadCategories = 16,
	LoadMailingListRights = 32,
	LoadPhoto = 64,
	LoadSpecificInfo = 128,
	LoadCityAddressNormalizer = 256,
	LoadSharing = 512,
	LoadSalesPersons = 1024,
	LoadNormalizeEMail = 2048,
	LoadNormalizePhone = 4096,
	LoadPortalAccount = 8192,
	LoadPrivacy = 16384,
	LoadGeo = 32768
}
