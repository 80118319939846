<template>
  <fragment>
    <slot name="content">
      <t-circle-button :type="type" :icon="icon" :loading="loading" v-if="circle" @click.native.stop.prevent v-indexed>
        <template class="hidden-sm-and-down">
          <slot></slot>
        </template>
      </t-circle-button>
      <t-button v-else-if="button" :type="type" :icon="icon" :loading="loading" v-popover:popover
        @click.native.stop.prevent v-indexed>
        <template class="hidden-sm-and-down">
          <slot></slot>
        </template>
      </t-button>
      <span v-else :class="icon" v-popover:popover class="cursor-pointer text">
        <template class="hidden-sm-and-down">
          <slot></slot>
        </template>
      </span>
    </slot>
    <el-popover width="160" v-model="confirmationVisible" ref="popover">
      <p>
        <slot name="message">{{ $t('commands.deleteConfirm') }}</slot>
      </p>
      <slot name="buttons">
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="confirmationVisible = false" v-indexed>{{
            $t('commands.no')
          }}</el-button>
          <el-button size="mini" type="primary" @click="deleteConfirmed">{{ $t('commands.yes') }}</el-button>
        </div>
      </slot>
      <template #reference>
        <slot name="reference"></slot>
      </template>
    </el-popover>
  </fragment>
</template>
<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'TDeleteButton',
})
export default class TDeleteButton extends Vue {
  @Prop({ type: Boolean, default: true, required: false }) circle;

  @Prop({ type: Boolean, default: true, required: false }) button;

  @Prop({ type: Boolean, default: false, required: false }) loading;

  @Prop({ type: String, default: 'el-icon-icon-bin', required: false }) icon;

  @Prop({ type: String, default: 'danger', required: false }) type: string;

  confirmationVisible = false;

  deleteConfirmed() {
    this.confirmationVisible = false;
    this.$emit('delete');
  }
}
</script>
