<template>
  <div class="ls-section">
    <el-row
      v-if="(index > 0 || schema.showText) && schema.text"
      class="m-b"
      type="flex"
      :gutter="10"
      align="middle"
    >
      <el-col :span="-1" class="text text-info">
        <transition name="el-zoom-in-top">
          <span
            :class="{ 'el-icon-icon-arrow-down3': collapsed, 'el-icon-icon-arrow-up3': !collapsed }"
          ></span>
        </transition>
      </el-col>
      <el-col>
        <span class="text text-primary section-label" @click="toggleVisibility">{{ schema.text }}</span>
      </el-col>
    </el-row>
    <transition name="el-zoom-in-top">
      <div v-if="!collapsed">
        <!--{{ schema.columns[0][0].colSpan || 1 }}-->
        <el-row :gutter="10">
          <slot>
            <el-col
              v-for="(columns, index) in schema.columns"
              :key="index"
              :span="getCurrentDynamicSpan(index, schema.columns.length)"
              class="ls-col"
            >
              <t-widgets :schema="columns" :data="data" @input="simpleEmit" :context="context"></t-widgets>
            </el-col>
          </slot>
        </el-row>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'LsSection',
  mixins: [simpleMixin],
})
export default class LsSection extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: [Object, Array],
    required: true,
  })
  data: any;

  @Prop({
    type: Number,
    required: true,
  })
  index: number;

  @Prop({
    type: Object,
    required: false,
  })
  context: any;

  collapsed = false;

  mounted() {
    this.collapsed = this.schema.collapsed;
  }

  private fullSizeSpan = 24;

  getCurrentDynamicSpan(index: number, columns: number): number {
    if (columns === 1) return this.fullSizeSpan;
    if (this.$viewport.xs) return this.fullSizeSpan;
    return Math.floor(this.fullSizeSpan / columns);
  }

  toggleVisibility() {
    this.collapsed = !this.collapsed;
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 1920px) {
  .ls-section {
    // max-width: 1400px;
  }
}
@media only screen and (min-width: 2560px) {
  .ls-section {
    width: 900px;
    padding-left: 10px;
  }
}
.ls-section {
  & .section-label {
    cursor: pointer;
  }
  & .ls-col {
    min-height: 1px;
  }
}
</style>
