//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PortalPersonalDataPermissionSet {
	Visible = 1,
	ChangePersonalData = 2,
	ViewAddedFields = 4,
	ViewDistributionLists = 8
}
