<template>
  <span>
    <div v-if="schema.sections" class="ls-form">
      <t-ls-section
        v-for="(section, index) in notEmptySection"
        :index="index"
        :key="index"
        :schema="section"
        :data="data"
        :context="context"
        @input="simpleEmit"
      ></t-ls-section>
    </div>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'LsForm',
  mixins: [simpleMixin],
})
export default class LsForm extends Vue {
  @Prop({ type: Object, required: true })
  schema: any;

  @Prop({ type: Object, required: true })
  data: any;

  @Prop({ type: Object, required: false })
  context: any;

  @Provide() get formSchema() {
    return this.schema;
  }

  valid = false;

  get notEmptySection() {
    return this.schema.sections.filter((s) => s.columns.some((c) => c.length));
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 2560px) {
  .ls-form {
    display: flex;
    flex-flow: row wrap;
  }
}
</style>
