//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BusinessObjectInvoker {
	Page = 1,
	Import = 2,
	Massive = 4,
	App = 8,
	WebService = 16,
	Scheduler = 32,
	Flow = 64,
	MailMerge = 128,
	Polyedro = 256,
	Custom = 1024,
	ReadOnly = 2048,
	Exchange = 4096,
	WebDav = 8192,
	WebApi = 16384
}
