//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CatalogFlags {
	Active = 0,
	Public = 1,
	Updatable = 2,
	Renewable = 3,
	Bundle = 4
}
