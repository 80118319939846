<template>
  <span>
    <t-dynamic-component :module="schema.module" :value="schema.id" :props="{ schema, data }"></t-dynamic-component>
  </span>
</template>

  <script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IWidget } from 'rt/Contracts/UI/IWidget';
import { IHasKey } from 'rt/Digita/Contracts/IHasKey';

interface ITemplateWidget extends IWidget, IHasKey<string> {
  template: any;
}
@Component({
  name: 'TemplateWidget',
})
export default class TemplateWidget extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: ITemplateWidget;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;
}
</script>
