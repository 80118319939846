//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IWebMailAccountDTO } from '../../../DTO/IWebMailAccountDTO';

//element.Name=WebMailAccountController result.Name=WebMailAccountController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.WebMailAccountController);
/** Result of WebApiControllerGenerator activity */
export class WebMailAccountController extends DefaultDtoControllerProxy<IWebMailAccountDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'WebMailsAccounts', BusinessObjectType.WebMailAccount);
	}
	public All(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IWebMailAccountDTO[]>
	{
		return (this.axios.get('WebMailsAccounts/All', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ClearCache(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IWebMailAccountDTO[]>
	{
		return (this.axios.post('WebMailsAccounts/ClearCache?'+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SmtpTest(dto: IWebMailAccountDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('WebMailsAccounts/SmtpTest', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IWebMailAccountDTO>
	{
		return (this.axios.get('WebMailsAccounts/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
