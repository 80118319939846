//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AnagraphicStatus {
	Normal = 0,
	Locked = 1,
	Suspended = 2,
	Abandoned = 3
}
