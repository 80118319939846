//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AndOrErrors {
	Ok = 0,
	InvalidOperands = 1,
	MissingSpaces = 2,
	ValueExceedRows = 3,
	SyntaxError = 4,
	SequenceError = 5,
	StartWithClosedParentheses = 6,
	EndWithOpenedParentheses = 7,
	UnclosedParentheses = 8
}
