<template>
  <span v-if="spoolerStatus != null && spoolerStatus.profile" class="text">
    <el-row :gutter="10">
      <el-col :md="24" :lg="mini ? 24 : 12" class="m-b-q">
        <t-mailing-profile-info :value="spoolerStatus.profile"></t-mailing-profile-info>
      </el-col>
      <el-col class="m-b-q" :md="24" :lg="mini ? 24 : 12" :class="{ 'text-right': !mini, 'm-t': mini }">
        <div v-if="user.isAdmin && redirect">
          <div class="text-primary">
            <div class="m-b-q">
              <u>{{ $t('mailing.profile.more') }}</u>
            </div>
            <router-link :to="{
              name: $routeNames.mailingListPlan
            }">
              <el-button type="primary" icon="el-icon-fa-level-up">{{ $t('mailing.profile.choose') }}</el-button>
            </router-link>
          </div>
        </div>
        <div v-else>
          <a href="https://help.crmincloud.it/it/article/exo2be" target="_blank">{{ $t('mailing.profile.info') }}</a>
        </div>
      </el-col>
    </el-row>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { ISideBarSpoolerStatusResponse } from 'rt/UIApiControllers/UI/SideBar/ISideBarSpoolerStatusResponse';
import TMailingProfileInfo from './MailingProfileInfo.vue';

// tslint:disable-next-line:variable-name
const MailingGetter = namespace('mailing').Getter;

@Component({
  name: 'SpoolerProfileInfo',
  components: {
    TMailingProfileInfo,
  },
})
export default class SpoolerProfileInfo extends Vue {
  @Getter user;

  @Prop({
    required: false,
    type: Boolean,
    default: true,
  })
  redirect: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  mini: boolean;

  @MailingGetter spoolerStatus: ISideBarSpoolerStatusResponse;

  get dailyLeft() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const dl = this.spoolerStatus?.profile?.dailyLimit - this?.spoolerStatus.profile?.dayUsage;
    if (!isNaN(dl)) {
      if (dl > 0) {
        const pl = this.periodLeft;
        if (pl !== null && pl < dl) {
          return pl;
        }
        return dl;
      }
      return 0;
    }
    return null;
  }

  get periodLeft() {
    if (this.spoolerStatus?.profile?.periodLimit > 0 && this.spoolerStatus.profile.periodUsage != null) {
      const dl = this.spoolerStatus.profile.periodLimit - this.spoolerStatus.profile.periodUsage;
      if (!isNaN(dl)) {
        if (dl > 0) {
          return dl;
        }
        return 0;
      }
    }
    return null;
  }

  get periodPercentage() {
    if (this.spoolerStatus?.profile?.periodLimit > 0 && this.spoolerStatus?.profile?.periodLimit != null) {
      return (this.spoolerStatus.profile.periodUsage / this.spoolerStatus.profile.periodLimit) * 100;
    }
    return null;
  }

  get dailyPercentage() {
    if (this.spoolerStatus?.profile?.dailyLimit > 0 && this.spoolerStatus?.profile?.dayUsage != null) {
      return (this.spoolerStatus.profile.dayUsage / this.spoolerStatus.profile.dailyLimit) * 100;
    }
    return null;
  }

  get limitPercentage() {
    const pp = this.periodPercentage ?? -1;
    const dp = this.dailyPercentage ?? -1;
    const lp = Math.max(pp, dp);
    if (lp >= 0) {
      return Math.ceil(lp);
    }
    return null;
  }

  get alertClass() {
    const lp = this.limitPercentage;
    if (lp > 90) {
      return 'text-danger';
    }
    if (lp > 50) {
      return 'text-warning';
    }
    // return 'text-primary';
    return null;
  }

  get percentageStatus() {
    const lp = this.limitPercentage;
    if (lp > 90) {
      return 'exception';
    }
    if (lp > 50) {
      return 'warning';
    }
    // return 'text-primary';
    return null;
  }
}
</script>
