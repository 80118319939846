//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ReportConditions {
	Equal = 1,
	NotEqual = 2,
	GreaterThan = 3,
	LessThan = 4,
	GreaterOrEqual = 5,
	LessOrEqual = 6,
	Contain = 7,
	NotContain = 8,
	StartWith = 9,
	PipeContain = 10,
	PipeNotContain = 11,
	IntervalFromExecution = 12,
	InList = 14,
	IsEmpty = 15,
	IsNotEmpty = 16,
	EndsWith = 17,
	NotInList = 18,
	Recurrent = 19
}
