//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IRepeaterRequestStructure } from './RepeaterStructure/IRepeaterRequestStructure';
import { IAdvancedSearchSaveRequest } from './RepeaterStructure/IAdvancedSearchSaveRequest';
import { IRepeaterSavedSearch } from './RepeaterStructure/IRepeaterSavedSearch';
import { ISavedSearchSharingRequest } from './RepeaterStructure/ISavedSearchSharingRequest';
import { ISavedSearchSharingResponse } from './RepeaterStructure/ISavedSearchSharingResponse';
import { IReportSearchConditionDTO } from '../../DTO/IReportSearchConditionDTO';
import { IRepeaterSavedSchema } from './RepeaterStructure/IRepeaterSavedSchema';
import { IRepeaterSchemes } from './RepeaterStructure/IRepeaterSchemes';
import { IRepeaterSchema } from '../../LayoutStudio/Repeater/Schema/IRepeaterSchema';
import { IRepeaterDeleteSelectedGroupInfoRequestStructure } from './RepeaterStructure/IRepeaterDeleteSelectedGroupInfoRequestStructure';
import { DataSetExportType } from '../../Base/Report/DataSetExportType';
import { IRepeaterResponseStructure } from './RepeaterStructure/IRepeaterResponseStructure';
import { IRepeaterUpdateSelectedGroupInfoRequestStructure } from './RepeaterStructure/IRepeaterUpdateSelectedGroupInfoRequestStructure';
import { IRowDataInfo } from './RepeaterStructure/IRowDataInfo';
import { IRepeaterResponseDataStructure } from './RepeaterStructure/IRepeaterResponseDataStructure';

//element.Name=BaseRepeaterController result.Name=BaseRepeaterController element.FullName=Digita.Tustena.UIApiControllers.RepeaterControllers.BaseRepeaterController);
/** Result of WebApiControllerGenerator activity */
export class BaseRepeaterController
{
	constructor (axios: AxiosInstance, controller: string)
	{
		
		this.axios = axios;
		this.controller = controller;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	/** Name of endpoint controller that implement BaseRepeaterController */
	private controller: string;
	public SelectAll(rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.post(this.controller+'/SelectAll', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteSavedSearch(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.get(this.controller+'/DeleteSavedSearch?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveAdvancedSearch(ppd: IAdvancedSearchSaveRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post(this.controller+'/SaveAdvancedSearch', ppd, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PinAsMyDefault(rrq: IRepeaterRequestStructure, id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterSavedSearch[]>
	{
		return (this.axios.post(this.controller+'/PinAsMyDefault?'+'&id='+id, rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RemovePinAsMyDefault(rrq: IRepeaterRequestStructure, id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterSavedSearch[]>
	{
		return (this.axios.post(this.controller+'/RemovePinAsMyDefault?'+'&id='+id, rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSearchSharingParameters(req: ISavedSearchSharingRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISavedSearchSharingResponse>
	{
		return (this.axios.post(this.controller+'/GetSearchSharingParameters', req, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SetSearchSharingParameters(req: ISavedSearchSharingResponse, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/SetSearchSharingParameters', req, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LoadSavedSearchConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReportSearchConditionDTO[]>
	{
		return (this.axios.get(this.controller+'/LoadSavedSearchConditions?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LoadAdvancedSearchConditions(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReportSearchConditionDTO[]>
	{
		return (this.axios.get(this.controller+'/LoadAdvancedSearchConditions?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateSavedSearchConditions(id: number, conditions: IReportSearchConditionDTO[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/UpdateSavedSearchConditions', JSON.stringify(id), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PinSchemaAsMyDefault(name: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterSavedSchema[]>
	{
		return (this.axios.post(this.controller+'/PinSchemaAsMyDefault', JSON.stringify(name), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RemovePinSchemaAsMyDefault(name: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterSavedSchema[]>
	{
		return (this.axios.post(this.controller+'/RemovePinSchemaAsMyDefault', JSON.stringify(name), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Schemes(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterSchemes>
	{
		return (this.axios.post(this.controller+'/Schemes', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveSchemes(schemes: IRepeaterSchema[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/SaveSchemes', schemes, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveRenderingMode(m: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/SaveRenderingMode', JSON.stringify(m), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SavePagingSize(size: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/SavePagingSize', JSON.stringify(size), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveColumnWidth(columnName: string, width: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post(this.controller+'/SaveColumnWidth?'+((columnName === null || columnName === undefined) ? '' : ('&columnName='+encodeURIComponent(columnName)))+'&width='+width, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveColumnCustomWidth(widths: { [key:string]: number }, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.post(this.controller+'/SaveColumnCustomWidth', widths, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RestoreFactorySettings(deleteAllMyCustomSchemes: boolean, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/RestoreFactorySettings?'+'&deleteAllMyCustomSchemes='+deleteAllMyCustomSchemes, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteSelected(request: IRepeaterDeleteSelectedGroupInfoRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/DeleteSelected', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Delete(deleteIds: any[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post(this.controller+'/Delete', deleteIds, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DownloadData(rrq: any, type: DataSetExportType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post(this.controller+'/DownloadData?'+'&type='+type, rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Next(id: string, rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post(this.controller+'/Next?'+((id === null || id === undefined) ? '' : ('&id='+encodeURIComponent(id))), rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previous(id: string, rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post(this.controller+'/Previous?'+((id === null || id === undefined) ? '' : ('&id='+encodeURIComponent(id))), rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDataAndStructure(rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterResponseStructure>
	{
		return (this.axios.post(this.controller+'/GetDataAndStructure', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateDataAndStructure(rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterResponseStructure>
	{
		return (this.axios.post(this.controller+'/UpdateDataAndStructure', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateSelectedData(rrq: IRepeaterUpdateSelectedGroupInfoRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRowDataInfo[]>
	{
		return (this.axios.post(this.controller+'/UpdateSelectedData', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateData(rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRepeaterResponseDataStructure>
	{
		return (this.axios.post(this.controller+'/UpdateData', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateGroupInfo(rrq: IRepeaterRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRowDataInfo>
	{
		return (this.axios.post(this.controller+'/UpdateGroupInfo', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdateSelectedGroupInfo(rrq: IRepeaterUpdateSelectedGroupInfoRequestStructure, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRowDataInfo>
	{
		return (this.axios.post(this.controller+'/UpdateSelectedGroupInfo', rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post(this.controller+'/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get(this.controller+'/GetSwagger?'+'&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
