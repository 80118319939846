//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MergePropertyStringJoinChar {
	Space = 0,
	Semicolon = 2,
	Pipe = 3,
	NewLine = 4,
	Comma = 5
}
