//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PasswordRequirements {
	None = 0,
	NotUsername = 2,
	PassLength = 4,
	Capital = 8,
	Lower = 16,
	Symbol = 32,
	Number = 64,
	InvalidCharacter = 128,
	NotAsLast5 = 256
}
