<template>
  <el-container class="main-container">
    <el-main class="center">
      <el-row type="flex" align="middle" justify="center">
        <el-col :xl="6" :lg="8" :md="12" :sm="18" :xs="24">
          <el-main>
            <div class="m-b-q">
              <router-view name="header"></router-view>
            </div>
            <el-card>
              <router-view></router-view>
            </el-card>
          </el-main>
          <el-footer class="m-t">
            <router-view name="footer"></router-view>
          </el-footer>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import jwtManager from 'utils/jwtManager';

@Component({
  name: 'AnonymousLanding',
})
export default class AnonymousLanding extends Vue {
  mounted() {
    jwtManager.disable();
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
.main-container {
  width: 100%;
  & .center {
    width: 100%;
  }
}
</style>
