<template>
  <div class="email-box" :class="{ focus }" @click="handleBoxClick">
    <draggable class="drag-box" :value="value" @input="v => $emit('input', v)" draggable=".email-tag"
      :options="{ forceFallback: false, draggable: '.email-tag', group: 'email-tag', animation: 100, delay: $viewport.isTouch ? 400 : 0 }">
      <t-email-tag v-for="tag in value" :key="tag.address" :value="tag" class="email-tag simple-drag-item" closable
        @close="removeEMail(tag)" size="medium">
        <span class="dragging-icon el-icon-fa-arrows-alt fas warning"></span>
      </t-email-tag>
      <template #footer>
        <el-autocomplete ref="input-element" class="email-input m-l-h" popper-class="email-suggestion"
          v-autowidth="{ comfortZone: 10 }" @keyup.enter.native="handleEmailConfirm" @blur="handleBlur"
          @focus="handleFocus" :debounce="500" v-model="emailValue" :fetch-suggestions="querySearchAsync"
          @select="handleSelect" value-key="email">
          <template #default="{ item }">
            <el-row type="flex" class="text" align="middle" justify="center">
              <el-col>
                <span :class="`text-${$boMapping.toTagType(item.crossType) || 'primary'}`">
                  <i :class="`el-icon-${$boMapping.toIcon(item.crossType)} m-r-q`"></i>
                  <t-email-address-label :value="({
                    address: item.email,
                    displayName: item.displayName
                  })" expanded reverse></t-email-address-label>
                </span>
              </el-col>
              <el-col class="type text-info text-right nowrap" :span="6">
                <span v-if="item.namedType">{{ item.namedType }}</span>
              </el-col>
            </el-row>
            <span></span>
          </template>
        </el-autocomplete>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import BaseLookup from './BaseLookup';
import addrs, { ParsedMailbox } from 'email-addresses';
import _ from 'lodash/array';
import { IMailAddressDTO } from 'dto/IMailAddressDTO';
import { AddressBookController } from '@/plugins/typings/UIApiControllers/UI/Smart/AddressBookController';

@Component({
  name: 'EMailLookup',
})
export default class EMailLookup extends Vue {
  /* component */
  @Prop({
    type: Array,
    required: true,
  })
  value: IMailAddressDTO[];

  emailValue: string = null;

  focus = false;

  async handleEmailConfirm() {
    const inputValue = this.emailValue;
    if (inputValue) {
      const mails = inputValue.split(/[;,]/g);
      if (mails) {
        const value = [...(this.value ?? [])];
        let newValue = null;
        for (const mail of mails) {
          if (mail && mail.trim().length) {
            const part = addrs.parseOneAddress(mail.trim()) as ParsedMailbox;
            if (part != null && part.parts != null && part.parts.address != null) {
              if (!value.some((m) => new RegExp(`^${m.address}$`, 'i').test(part.parts.address.semantic?.trim()))) {
                newValue = {
                  address: part.parts.address?.semantic?.trim(),
                  displayName: part.parts.name?.semantic?.trim(),
                };

                if (!newValue.displayName) {
                  const res = await this.addressBookController.Email(newValue.address, 1);
                  if (res.length) {
                    newValue.displayName = res[0].displayName;
                  }
                }
              }
            } else {
              newValue = {
                address: mail,
                displayName: '',
              };
            }
          }
        }
        if (newValue != null) {
          value.push(newValue);
          this.$emit('input', value);
        }
      }
    }
    this.emailValue = '';
  }

  handleBlur() {
    window.setTimeout(() => {
      this.handleEmailConfirm();
    }, 200);
    this.focus = false;
  }

  handleFocus() {
    this.focus = true;
  }

  removeEMail(email: IMailAddressDTO) {
    const na = [...this.value];
    _.remove(na, (m) => m.address === email.address);
    this.$emit('input', na);
  }

  cancellationTokenSource: CancelTokenSource;

  addressBookController: AddressBookController = new AddressBookController(this.$http);

  async querySearchAsync(term, cb) {
    // debounce
    if (term && term.length > 2) {
      if (this.cancellationTokenSource) {
        this.cancellationTokenSource.cancel();
      }
      this.cancellationTokenSource = axios.CancelToken.source();
      const res = await this.addressBookController.Email(term, 10, this.cancellationTokenSource.token);
      cb(res ?? []);
    } else {
      cb([]);
    }
  }

  handleSelect(item) {
    this.emailValue = '';
    const value = [...(this.value ?? [])];
    if (!value.some((m) => new RegExp(`^${m.address}$`, 'i').test(item.email))) {
      value.push({
        address: item.email,
        displayName: item.displayName,
      });
    }
    this.$emit('input', value);
  }

  handleBoxClick() {
    (this.$refs['input-element'] as any).focus();
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.el-tag {
  margin-bottom: $-margin-size-quarter;
}

.email-tag+.email-tag {
  margin-left: $-margin-size-quarter;
}

.email-box {
  border-bottom: 1px solid $--border-color-base;
  cursor: text;

  .email-input {
    ::v-deep{ .el-input__inner {
      padding-left: 0;
      padding-right: 0;
      border-bottom: none;
    }
  }
  }

  .drag-box {
    min-height: 20px;
  }

  &.focus {
    border-color: $--input-focus-border;
  }
}
</style>
<style lang="scss">
@import '~element-variables';

.email-suggestion {
  min-width: 340px;

  & ul {
    & li {
      padding-left: $-margin-size-quarter;
    }
  }
}
</style>
