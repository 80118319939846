<template>
  <component :is="compiled"></component>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ExternalTemplate',
})
export default class ExternalTemplate extends Vue {
  compiled = null;

  @Prop({ required: true }) value: string;

  mounted() {
    this.compiled = Vue.compile(this.value);
  }
}
</script>
