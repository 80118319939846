//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivityClassification {
	FirstContact = 1,
	OngoingNegotiation = 2,
	Signal = 3,
	Close = 4,
	CloseNotInteresting = 5
}
