<script lang="ts">
import Component from 'vue-class-component';
import { IListDTO } from 'dto/IListDTO';
import LayoutStudioMixinView from 'modules/mixin/views/LayoutStudioMixin.vue';
import { ListController } from 'rt/UIApiControllers/BusinessObject/DTO/ListController';
import ListMixin from './ListMixin';

@Component({
  name: 'ListLayoutStudioView',
  mixins: [ListMixin],
})
export default class ListLayoutStudioView extends LayoutStudioMixinView<IListDTO> {
  protected get module(): string {
    return 'list';
  }

  protected get routeName(): string {
    return this.$routeNames.list;
  }

  protected get backRouteName(): string {
    return this.$routeNames.lists;
  }

  protected async saved(id: number): Promise<void> {
    if (this.$route.query.fr) {
      await new ListController(this.$http).ImportElementsFromCache(id, this.$route.query.fr as string);
    }
  }
}
</script>
