<template>
  <span>
    <el-dialog :width="$viewport.dialog" :visible.sync="dialogVisible" :append-to-body="isNestedDialog">
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
      </el-row>
      <t-grid :showCommands="false" repeater="PopMailTemplateRepeater" @linkClick="linkClick"
        :customParameterData="{ search, template }" />
    </el-dialog>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :route="$modules.isModuleLoaded('mailTemplate')" :value="mailTemplate" :key="mailTemplate.key"
        v-for="mailTemplate in selectedData" :closable="!readOnly" :disable-transitions="false"
        @close="handleRemoveItem(mailTemplate)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import { Prop } from 'vue-property-decorator';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'MailTemplateLookup',
  components: {
    TLookupSkin,
  },
})
export default class MailTemplateLookup extends BaseLookup {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  template: boolean;

  dialogVisible = false;

  buildSuggestionParams(): any {
    return {
      template: this.template,
    };
  }

  suggestionControllerName() {
    return 'MailTemplate';
  }

  keyPrefix() {
    return 'MailTemplate';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }

  linkClick(mailTemplateId, row) {
    const item = {
      key: `${this.keyPrefix()}-${mailTemplateId}`,
      title: row.SUBJECT.value,
    };

    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }
}
</script>
