import Router, { RouteConfig } from 'vue-router';
import HashIds from 'hashids';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';

const hashids = new HashIds('', 8);
export class RouteHelpers {
  findRoute(routes: RouteConfig[], parent: { name: string }): RouteConfig {
    for (const route of routes) {
      if (route === parent) return route;
      if (Object.prototype.hasOwnProperty.call(parent, 'name') && route.name === parent.name) {
        return route;
      }
      if (route.children && route.children.length) {
        const parentRoute = this.findRoute(route.children, parent);
        if (parentRoute != null) return parentRoute;
      }
    }
    return null;
  }

  findRouteFromBusinessObjectType(routes: RouteConfig[], type: BusinessObjectType, id: number): { params: { [key: string]: string }; query: { [key: string]: string } } & RouteConfig {
    if (routes != null) {
      for (const route of routes) {
        if (route.meta && Object.prototype.hasOwnProperty.call(route.meta, 'type') && route.meta.type === type) {
          return {
            path: null,
            query: null,
            name: route.name,
            params: {
              id: hashids.encode(id),
            },
          };
        }
        if (route.children) {
          const rn = this.findRouteFromBusinessObjectType(route.children, type, id);
          if (rn != null) {
            return rn;
          }
        }
      }
    }
    return null;
  }
}

export default new RouteHelpers();
