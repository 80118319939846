//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PredefinedTitleMode {
	Hybrid = 0,
	FixedCode = 1,
	FixedTitle = 2,
	SplittedTitle = 3,
	None = -1
}
