<template>
  <t-field-header v-bind="$props" :value="value" :operator="operator" @operator-change="changeOperatorFilter" :valid="isValidFilter" @sort-change="v => $emit('sort-change', v)">
    <t-date-picker v-model="value" @input="changeFilter" type="date" :read-only="isValidOperationFilter"></t-date-picker>
  </t-field-header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'textFieldHeader',
})
export default class TextFieldHeader extends HeaderMixin {
  get isValidValueFilter(): boolean {
    return this.value != null;
  }
}
</script>
