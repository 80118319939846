//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FreeFieldsType {
	InputText = 1,
	InputNumber = 2,
	InputDate = 3,
	Select = 4,
	Username = 5,
	Password = 6,
	Checkbox = 7,
	ReadonlyText = 8,
	Currency = 9,
	Crypto = 10,
	MultiSelect = 11,
	HttpLink = 12,
	Iframe = 13,
	Html = 14,
	WriteOnce = 15,
	Progressive = 16,
	Incremental = 17,
	AutoDate = 18,
	AutoTime = 19,
	Timer = 20,
	TimerContinue = 21,
	SelectFromQuery = 22,
	ReadonlyFromQuery = 23,
	SqlTable = 24,
	InputHour = 25,
	Anagraphic = 26,
	MultiSelectTree = 27,
	Account = 28,
	InputArea = 29,
	SelectMultipleFromQuery = 30
}
