//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivityRenderType {
	Default = 0,
	Anagraphic = 1,
	Ticket = 2,
	JobOrders = 3,
	JobOrderTasks = 4
}
