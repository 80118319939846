<template>
  <el-dropdown-item v-if="asDropItem" v-loading="loading" @click.native="click">
    <t-icon-label v-if="icon" :icon="icon">
      <slot></slot>
    </t-icon-label>
    <slot v-else></slot>
  </el-dropdown-item>
  <el-button
    v-else
    :icon="icon"
    @click="click"
    :loading="loading"
    :type="type"
    :disabled="disabled"
    v-indexed
  >
    <span class="hidden-sm-and-down" v-if="$slots.default">
      <slot></slot>
    </span>
  </el-button>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';

@Component({
  name: 'TButton',
})
export default class BackButton extends Vue {
  @Inject({
    default: false,
  })
  asDropItem: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: String,
  })
  type: string;

  @Prop({
    type: String,
    required: false,
  })
  icon: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  disabled: boolean;

  click() {
    this.$emit('click');
  }
}
</script>
