//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CurrencyFormat {
	CommaFollowedBySymbol = 0,
	DotFollowedBySymbol = 1,
	CommaPrecededBySymbol = 2,
	DotPrecededBySymbol = 3
}
