/* eslint-disable max-classes-per-file */
import { IRepeaterResponseStructure } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure/IRepeaterResponseStructure';

export interface IGridState {
  filters: any;
  pagination: {
    pageSize: number;
    total: number;
    currentPage: number;
  };
  trackedCustomParameterData: any;
  advancedSearchId: number;
  savedSearchId: number;
  orders: any[];
}
export class GridState implements IGridState {
  filters: any = {};

  pagination: { pageSize: number; total: number; currentPage: number } = {
    pageSize: 0,
    total: 0,
    currentPage: 0,
  };

  trackedCustomParameterData: any = null;

  advancedSearchId = 0;

  savedSearchId = 0;

  orders: any[] = [];
}
export class GridsState {
  states: { [key: string]: IGridState } = {};

  structures: { [key: string]: Partial<IRepeaterResponseStructure> } = {};

  signalRUpdateMessage = null;
}
