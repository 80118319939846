//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AlarmActionType {
	PopUp = 0,
	EMail = 1,
	Procedure = 2,
	Push = 3
}
