import Vue from 'vue';
import { OAuth2Controller } from 'rt/UIApiControllers/Authentication/OAuth2Controller';
import { eventHub as signalREventHub } from '../signalr';

export class OAuthUtils {
  get oAuth2Controller() {
    return new OAuth2Controller(Vue.axios);
  }

  async openOAuthAuthenticationPage(oauthIdentifier: string, server: string, w = 600, h = 800): Promise<any> {
    return this.openOAuthSSOPage(async () => await this.oAuth2Controller.GetLoginLinkUri(oauthIdentifier, server), w, h);
  }

  async openOAuthRefreshToken(crossId: number, crossType: number, davsettings: number, server: string, w = 600, h = 800): Promise<any> {
    return this.openOAuthSSOPage(async () => await this.oAuth2Controller.GetLoginLinkUriToRefreshTokenAccess(`oauth_refresh_${server}_${crossId}_${crossType}_${davsettings}`, crossId, server, crossType, davsettings), w, h);
  }

  async openOAuth2AdminConsent(url: string, w = 600, h = 800): Promise<boolean> {
    const oauthWindow = await this.openOAuthSSOPage(async () => url, w, h);
    return new Promise((resolve, reject) => {
      const timer = setInterval(() => {
        if (oauthWindow.closed) {
          clearInterval(timer);
          reject();
        }
      }, 1000);
      signalREventHub.$on('message', (message) => {
        if (message && message.type === 'caca') {
          clearInterval(timer);
          this.closeWindow(oauthWindow);
          resolve(true);
          signalREventHub.$off('message');
        }
        if (message && message.type === 'cacf') {
          clearInterval(timer);
          this.closeWindow(oauthWindow);
          resolve(false);
          signalREventHub.$off('message');
        }
      });
    });
  }

  async openOAuthSSOPage(cb: () => Promise<string>, w = 600, h = 800): Promise<any> {
    // Fixes dual-screen position                         Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    const oauthWindow = window.open('about:blank', '_blank', `directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=${w},height=${h},top=${top},left=${left}`);
    const oAuthUri = await cb();
    if (oAuthUri) {
      if (oauthWindow == null) {
        console.log(Vue.i18n.t('popupBlocker.deactivate'));
        Vue.prototype.$alert(Vue.i18n.t('popupBlocker.deactivate'), Vue.i18n.t('popupBlocker.detected'), {
          type: 'warning',
        });
      } else {
        oauthWindow.location.href = oAuthUri;
      }
      return oauthWindow;
    }
    this.closeWindow(oauthWindow);
    return null;
  }

  async closeWindow(oauthWindow: Window, tentative: number = 1, retryTimeout: number = 2000, maxRetry: number = 5) {
    if (tentative > maxRetry) return;
    setTimeout(() => {
      try {
        if (oauthWindow.closed) {
          return;
        }
      } catch {}
      try {
        console.log('close-window');
        oauthWindow.close();
      } catch (e) {
        console.log('close-window-error');
        this.closeWindow(oauthWindow, tentative + 1);
      }
    }, retryTimeout);
  }
}
const utils = new OAuthUtils();
export default utils;
