<template>
  <div v-loading="updating">
    <transition name="fade-jump-bottom" mode="out-in">
      <div v-if="mailing === null" key="loading">
        <el-card class="m-b">
          <t-loading-card></t-loading-card>
        </el-card>
      </div>
      <div v-else key="lists">
        <h1>{{ $t('mailing.title') }}</h1>
        <div class="m-t m-b text lh-2x">{{ $t('mailing.info', { name: mailing.friendlyName }) }}</div>
        <div>
          <div v-for="list in mailing.lists" :key="list.id">
            <t-list-subscribe
              :value="!removedLists.some(l => l.listId === list.id)"
              :reason="(removedLists.find(l => l.listId === list.id) || {}).reason"
              @update:reason="r => handleReasonUpdate(list.id, r)"
              @input="v => handleListSubscription(list.id, v)"
              :list="list"
              :abuse="mailing.rights.isAbuse"
            ></t-list-subscribe>
          </div>
          <el-card class="m-b">
            <el-row type="flex" align="top" justify="left" :gutter="10">
              <el-col :span="-1">
                <el-switch
                  :value="mailing.rights.isAbuse"
                  @input="v => (mailing.rights.isAbuse = mailing.rights.removedRights = v)"
                ></el-switch>
              </el-col>
              <el-col>
                <h4>{{ $t('mailing.abuse') }}</h4>
                <div class="text m-t-h">{{ $t('mailing.abuseText') }}</div>
              </el-col>
            </el-row>
            <transition name="el-zoom-in-top">
              <div class="m-t-h" v-if="mailing.rights.isAbuse">
                <el-input
                  v-model="mailing.rights.reason"
                  :rows="3"
                  type="textarea"
                  :placeholder="$t('mailing.abuseReason')"
                ></el-input>
              </div>
            </transition>
          </el-card>
          <div class="m-t">
            <el-button
              type="primary"
              class="update-button"
              @click="updatePreferences"
            >{{ $t('mailing.update') }}</el-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {
 Action, Getter, Mutation, namespace,
} from 'vuex-class';
import axios, { AxiosResponse } from 'axios';
import { IAxiosRequestConfigRetry } from 'utils/types';
import Component from 'vue-class-component';
import { UnsubscribeController } from 'rt/UIApiControllers/UI/MailingList/UnsubscribeController';
import { IProfile } from 'rt/UIApiControllers/UI/MailingList/IProfile';
import { IMailingDeliveryDTO } from 'dto/IMailingDeliveryDTO';
import TListSubscribe from '../components/ListSubscribe.vue';

// tslint:disable-next-line:variable-name
const AnonymousMutation = namespace('anonymous').Mutation;

@Component({
  name: 'MailingUnsubscribe',
  components: {
    TListSubscribe,
  },
})
export default class MailingUnsubscribe extends Vue {
  unsubscribeController = new UnsubscribeController(Vue.axios);

  mailing: IProfile = null;

  updating = false;

  @AnonymousMutation setLogoUrl;

  async mounted() {
    const token = this.$route.params.id;
    this.$http.interceptors.request.use((r: IAxiosRequestConfigRetry) => {
      r.headers.Authorization = `Mailing ${token}`;
      r.headers['NullValueHandling'] = 'Include';
      return r;
    });
    try {
      this.mailing = await this.unsubscribeController.Get();
      this.setLogoUrl(this.mailing.photoUrl);
    } catch (e) {
      this.mailing = null;
      this.$router.push({
        name: this.$routeNames.mailingLandingExpired,
      });
    }
  }

  get removedLists() {
    if (!this.mailing) {
      return null;
    }
    const { lists } = this.mailing;
    const rights = this.mailing.rights.listRights;
    return rights.filter((l) => lists.some((r) => r.id === l.listId && l.removedRights));
  }

  handleReasonUpdate(id: number, reason: string) {
    const idx = this.mailing.rights.listRights.findIndex((l) => l.listId === id);
    if (idx >= 0) {
      const r = this.mailing.rights.listRights[idx];
      r.reason = reason;
    }
  }

  handleListSubscription(id: number, v: boolean) {
    const idx = this.mailing.rights.listRights.findIndex((l) => l.listId === id);
    if (v) {
      if (idx < 0) {
        this.mailing.rights.listRights.push({
          listId: 32,
          removedDate: new Date().toISOString(),
          removedRights: true,
          reason: '',
        });
      } else {
        const r = this.mailing.rights.listRights[idx];
        r.removedDate = null;
        r.removedRights = false;
        r.reason = null;
      }
    } else if (idx >= 0) {
        const r = this.mailing.rights.listRights[idx];
        r.removedDate = new Date().toISOString();
        r.removedRights = true;
      }
  }

  async updatePreferences() {
    try {
      this.updating = true;
      await this.unsubscribeController.UpdatePreferences(this.mailing.rights);
      this.$router.push({
        name: this.$routeNames.mailingLandingUnsubscribed,
      });
    } finally {
      this.updating = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.update-button {
  width: 100%;
  font-size: 1.5em;
}
</style>
