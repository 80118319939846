//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OpportunityPermits {
	AllowRepeaterExport = 114,
	AllowMassChange = 117,
	OnlyOwnerCanRemoveDoc = 129,
	AllowRemoveProtocolDocument = 130,
	AllowSearchForCampaign = 136,
	AllowAdvancedSearch = 202,
	EnableGroupsFilter = 203
}
