<template>
  <div>
    <div
      ref="floatingContainer"
      :style="{ minHeight: containerHeight ? containerHeight + 'px' : null }"
    >
      <div
        v-observe-visibility="handleVisibilityChanged"
        class="visibility-detect"
        v-if="top"
      >&nbsp;</div>
      <div
        :class="{ spaced: spaced, opaque: opaque, 'floating': !optionsInPage, top, bottom: !top, scroll }"
        :style="{ width: optionsWidth ? optionsWidth + 'px' : null }"
        ref="floatingPanel"
      >
        <slot></slot>
      </div>
    </div>
    <div v-observe-visibility="handleVisibilityChanged" class="visibility-detect" v-if="!top">&nbsp;</div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'FloatingPanel',
})
export default class FloatingPanel extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  top: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  scroll: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  spaced: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  opaque: boolean;

  optionsInPage = true;

  optionsWidth: number = null;

  containerHeight: number = null;

  handleVisibilityChanged(v: boolean) {
    const r = this.$refs['floatingPanel'] as any;
    const c = this.$refs['floatingContainer'] as any;
    if (!v) {
      this.optionsWidth = r.offsetWidth;
      this.containerHeight = c.offsetHeight;
    } else {
      this.optionsWidth = null;
      this.containerHeight = null;
    }
    this.optionsInPage = v;
  }

  handleVisibilityChangedDebounced: (v: boolean) => void;

  mounted() {
    this.handleVisibilityChangedDebounced = _.debounce((v) => this.handleVisibilityChanged(v), 300);
  }
}
</script>
<style lang="scss" scoped>
@import 'element-variables';
@import '~@/reset.scss';
.visibility-detect {
  height: 1px;
  width: 100%;
}
.floating {
  position: fixed;
  max-height: 100vh;
  z-index: 20;
  &.top {
    top: 0px;
    &.scroll {
      overflow-y: scroll;
    }
  }
  &.bottom {
    bottom: 0px;
  }
  &.opaque {
    background-color: rgba($--color-white, 0.7);
  }
  &.spaced {
    margin: $-margin-size-quarter;
  }
}
</style>
