//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WebMailConnectionResult {
	Success = 0,
	ConnectionError = 1,
	CredentialError = 2,
	CertificateValidationError = 3
}
