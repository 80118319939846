<template>
  <el-input class="form-input" :value="bouncedValue" @input="bouncedValueChange" @change="v => $emit('change', v)"
    :autosize="autosize" :type="type" :disabled="disabled" :placeholder="placeholder">
    <template #append>
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'BouncedInput',
})
export default class BouncedInput extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  type: string;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  @Prop({
    type: String,
    required: false,
  })
  placeholder: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  disabled: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  autosize: boolean;

  @Prop({
    type: Number,
    required: false,
    default: 300,
  })
  bounce: number;

  @Watch('value')
  handleValueChange(value: string) {
    this.bouncedValue = value;
  }

  bouncedValue: string = null;

  mounted() {
    this.bouncedValue = this.value;
  }

  bouncedValueChange(value) {
    this.bouncedValue = value;
    this.bouncedChange(value);
  }

  created() {
    this.bouncedChange = _.debounce((value) => {
      this.$emit('input', value);
    }, this.bounce);
  }

  beforeDestroy() {
    this.bouncedChange.cancel();
  }

  bouncedChange: any = null;
}
</script>
