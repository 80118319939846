<template>
  <t-hyperlink-integration v-bind="$props"></t-hyperlink-integration>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import THyperlinkIntegration from './HyperlinkIntegration.vue';

@Component({
  name: 'ContentIntegration',
  components: {
    THyperlinkIntegration,
  },
})
export default class ContentIntegration extends Vue {
  @Prop({
    type: String,
  })
  value: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  button: boolean;
}
</script>
