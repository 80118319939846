//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum InvoiceConditions {
	TaxDeducted = 0,
	FixedTax = 1,
	FixedBank = 2,
	IBAN = 3,
	BaseListPrice = 4,
	SupplierListPrice = 5,
	Porto = 6,
	SupplierPorto = 7,
	Appearance = 8,
	SupplierAppearance = 9,
	Carrier = 10,
	SupplierCurrier = 11,
	ShippedBy = 12,
	SupplierShippedBy = 13,
	SupplierTax = 14,
	DiscountFormula = 15,
	SupplierDiscountFormula = 16,
	DefaultCurrencyAnagraphic = 17
}
