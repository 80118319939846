<template>
  <span>
    <t-form-item :label="schema.text" :prop="schema.propertyName" :required="schema.required" v-if="!simple"
      :important="schema.important">
      <t-contact-lookup v-if="!isReadOnly" :value="selectedData" @input="change" :multipleSelection="schema.multiple">
        <template #append>
          <slot name="append"></slot>
        </template>
      </t-contact-lookup>
      <t-lookup-tag v-else :value="contact" :route="$modules.isModuleLoaded('contact')" :key="contact.key"
        v-for="contact in selectedData" :closable="false"></t-lookup-tag>
    </t-form-item>
    <t-contact-lookup v-else :value="selectedData" :route="$modules.isModuleLoaded('contact')" @input="change"
      :multipleSelection="multiple" :read-only="isReadOnly">
      <template #append>
        <slot name="append"></slot>
      </template>
    </t-contact-lookup>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 'ContactInput',
})
export default class ContactInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  async resolve(id: number): Promise<string> {
    return (await this.resolveController.Contact(id)).name;
  }

  protected get identifier(): string {
    return 'Contact';
  }
}
</script>
