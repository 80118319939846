//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RecurrenceType {
	Daily = 1,
	Weekly = 2,
	Monthly = 3,
	MonthlyDaily = 4,
	Yearly = 5,
	YearlyDaily = 6
}
