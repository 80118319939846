<template>
  <el-input v-model="tempSearchKey" @keyup.enter.native="handleSearchKeySearch" @blur="handleSearchKeySearch"
    :readonly="readonly" :disabled="disabled" type="search" :placeholder="$t('search')" :auto-complete="'off'">
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <el-button icon="el-icon-icon-search" @click="handleSearchKeySearch"></el-button>
    </template>
  </el-input>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'SearchInput',
})
export default class SearchInput extends Vue {
  @Prop({ required: false, type: String })
  value: string;

  @Prop({ required: false, type: Boolean })
  readonly: boolean;

  @Prop({ required: false, type: Boolean })
  disabled: boolean;

  @Watch('value')
  handleValueChange(v) {
    this.tempSearchKey = v;
  }

  tempSearchKey: string = null;

  mounted() {
    this.tempSearchKey = this.value;
  }

  handleSearchKeySearch() {
    this.$emit('input', this.tempSearchKey);
    this.$emit('search');
  }
}
</script>
