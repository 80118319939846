//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FlowObjectType {
	ActivityAndWait = 1,
	ActivityAndContinue = 2,
	OpportunityAndWait = 3,
	OpportunityAndContinue = 4,
	Wait = 5,
	EMail = 101,
	SMS = 102,
	ChangeOwner = 103,
	ChangeSalesPerson = 104,
	ChangeZone = 105,
	InfoBox = 106,
	EventExtension = 107,
	ExtendActivityTimeout = 108,
	ExtendOpportunityTimeout = 109,
	EditBusinessObjectProperty = 110,
	If = 301,
	IfExtension = 302,
	EndSuccess = 401,
	EndFailure = 402
}
