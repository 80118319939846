//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ToDoStates {
	Scheduled = 0,
	Done = 1,
	Cancelled = 2,
	ToDo = 3
}
