import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';
import _ from 'lodash';

@Component({
  name: 'BaseTag',
})
export default class BaseTag extends Vue {
  @Getter('lists/list') list: (type: ListsType) => IInt64ListResult[];

  @Prop({
    type: Array,
  })
  value: number[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  readOnly: boolean;

  popoverOpen = false;

  listTypes: IInt64ListResult[] = [];

  tagInputValue = '';

  radioGlobalPersonal = 'global';

  getListType(): ListsType {
    throw new Error('You must override getListType in subclasses');
  }

  async createNewTag(test: string, global: boolean): Promise<number> {
    throw new Error('You must override createNewTag in subclasses');
  }

  async mounted() {
    await this.$store.dispatch('lists/load', this.getListType());
    this.listTypes = [...this.list(this.getListType())];
  }

  handleTagSelected(tag: IInt64ListResult) {
    this.tagInputValue = '';
    this.popoverOpen = false;
    this.$emit('input', _.uniq([...this.value, tag.value]));
  }

  handleRemoveTag(tagValue) {
    this.$emit(
      'input',
      this.value.filter((v) => v !== tagValue),
    );
  }

  async handleCreateNewTag(event) {
    if (this.tagInputValue) {
      const existingTag = this.listTypes.find((v) => v.text.toLowerCase() === this.tagInputValue.toLowerCase());
      if (existingTag) {
        this.handleTagSelected(existingTag);
        return;
      }

      const newTagId = await this.createNewTag(event.target.value, this.radioGlobalPersonal === 'global');
      this.tagInputValue = '';
      this.popoverOpen = false;
      await this.$store.dispatch('lists/refresh', this.getListType());
      await this.$store.dispatch('lists/load', this.getListType());
      this.listTypes = [...this.list(this.getListType())];
      this.$emit('input', _.uniq([...this.value, newTagId]));
    }
  }

  tagSearch(queryString, callback) {
    let unselectedTags;
    switch (this.radioGlobalPersonal) {
      case 'global':
        unselectedTags = this.unselectedGlobalTags;
        break;
      case 'personal':
        unselectedTags = this.unselectedPersonalTags;
        break;
      default:
        unselectedTags = this.unselectedTags;
        break;
    }
    let results;
    if (queryString) {
      results = unselectedTags.filter((t) => t.text.toLowerCase().includes(queryString.toLowerCase()));
    } else {
      results = unselectedTags;
    }
    callback(
      results.map((r) => ({
        ...r,
      })),
    );
  }

  get selectedTags(): IInt64ListResult[] {
    return this.value.map((v) => this.listTypes.find((lt) => lt.value === v)).filter((lt) => lt);
  }

  get unselectedGlobalTags(): IInt64ListResult[] {
    const valueValues = this.value.map((v) => v);
    return this.listTypes.filter((lt) => !valueValues.includes(lt.value) && lt.infoData['FlagPersonal'].value !== 'True');
  }

  get unselectedPersonalTags(): IInt64ListResult[] {
    const valueValues = this.value.map((v) => v);
    return this.listTypes.filter((lt) => !valueValues.includes(lt.value) && lt.infoData['FlagPersonal'].value === 'True');
  }

  get unselectedTags(): IInt64ListResult[] {
    const valueValues = this.value.map((v) => v);
    return this.listTypes.filter((lt) => !valueValues.includes(lt.value));
  }
}
