//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SearchOptimizedFor {
	None = 0,
	Code = 1,
	EMail = 2,
	Phone = 4,
	VatId = 8
}
