import Vue, { PluginObject } from 'vue';
import LoadingCard from './LoadingCard.vue';
import LoadingTable from './LoadingTable.vue';
import LoadingTimeline from './LoadingTimeline.vue';
import LoadingHeader from './LoadingHeader.vue';
import LoadingSide from './LoadingSide.vue';
import LoadingLayoutSchema from './LoadingLayoutSchema.vue';
import LoadingItem from './LoadingItem.vue';

class LoadingComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    vue.component('t-loading-card', LoadingCard);
    vue.component('t-loading-table', LoadingTable);
    vue.component('t-loading-timeline', LoadingTimeline);
    vue.component('t-loading-header', LoadingHeader);
    vue.component('t-loading-side', LoadingSide);
    vue.component('t-loading-layout-schema', LoadingLayoutSchema);
    vue.component('t-loading-item', LoadingItem);
  }
}
export default new LoadingComponents();
