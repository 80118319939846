//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { ICalendarEventDTO } from '../../../DTO/ICalendarEventDTO';

//element.Name=CalendarEventController result.Name=CalendarEventController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.CalendarEventController);
/** Result of WebApiControllerGenerator activity */
export class CalendarEventController extends DefaultDtoControllerProxy<ICalendarEventDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'CalendarsEvents', BusinessObjectType.CalendarEvent);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICalendarEventDTO>
	{
		return (this.axios.get('CalendarsEvents/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
