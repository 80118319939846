//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContactPermits {
	AllowPublicCategoryInsert = 2,
	AllowPrivateCategoryInsert = 3,
	AllowWatchContact = 17,
	AllowPrint = 18,
	AllowLogView = 19,
	AllowSendByMail = 20,
	AllowBookMark = 21,
	AllowOptOut = 22,
	AllowViewMaillingListLog = 23,
	AllowCopyAddress = 24,
	AllowLockContact = 25,
	AllowRepeaterExport = 28,
	AllowRepeaterCustomFields = 29,
	AllowMassChange = 89,
	AllowCallLogView = 108,
	AllowViewAllContactsLinkedToMyCompanies = 132,
	AllowOwnerOverrideGroupsFilter = 151,
	EnableGroupsFilter = 155,
	AllowConvertoToCompanyWithParentLocation = 162,
	AllowAdvancedSearch = 199
}
