<template>
  <span>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" :readOnly="readOnly" ref="lookup-skin">
      <template #autocomplete="{ item }">
        <t-profile-image class="user-image-20 m-r-q" :size="20" :userId="+item.key.substring(5)"></t-profile-image>
        {{ item.value }}
      </template>
      <t-lookup-tag :value="account" :key="account.key" v-for="account in selectedData" :closable="!readOnly"
        :disable-transitions="false" @close="handleRemoveItem(account)">
        <template #default="{ title, id }">
          <t-profile-image class="user-image-20 m-r-q" :size="20" :userId="id"></t-profile-image>
          <span>{{ title }}</span>
        </template>
      </t-lookup-tag>
      <template #dialog-search>
        <el-popover v-model="popoverOpen" @hide="handleAccountTreePopoverHide" ref="popover-account-tree"
          placement="right" :title="$t('lookup.account')" trigger="click">
          <t-account-pop :multipleSelection="multipleSelection" :value="selectedData" @input="handleTreeInputChange"
            :onlyWithCalendarRights="onlyWithCalendarRights" :onlySubAccounts="onlySubAccounts"></t-account-pop>
          <template #reference>
            <i class="el-icon-search cursor-pointer" />
          </template>
        </el-popover>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ListsType } from 'rt/Core/ListsType';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';
import TAccountPop from './AccountPop.vue';

@Component({
  name: 'AccountLookup',
  components: {
    TLookupSkin,
    TAccountPop,
  },
})
export default class AccountLookup extends BaseLookup {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlyWithCalendarRights: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlySubAccounts: boolean;

  buildSuggestionParams(): any {
    return {
      impersonate: this.onlyWithCalendarRights,
      onlySubAccounts: this.onlySubAccounts,
    };
  }

  suggestionControllerName() {
    return 'Account';
  }

  keyPrefix() {
    return 'User';
  }

  async handleAccountTreePopoverHide() {
    const accountTree = this.$refs['account-tree'];
    if (accountTree != null) {
      await (accountTree as any).reset();
    }
  }

  handleTreeInputChange(items) {
    if (!this.multipleSelection) {
      this.popoverOpen = false;
    }
    this.changeItems(items);
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
</script>
