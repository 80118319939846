//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LeadPermits {
	AllowWatchLead = 30,
	AllowPrint = 31,
	AllowLogView = 32,
	AllowSendByMail = 33,
	AllowBookMark = 34,
	AllowOptOut = 35,
	AllowViewMaillingListLog = 36,
	AllowCopyAddress = 37,
	AllowLockLead = 38,
	AllowWelcomeMail = 39,
	AllowRepeaterExport = 40,
	AllowRepeaterCustomFields = 41,
	AllowMassChange = 90,
	AllowCallLogView = 109,
	AllowOwnerOverrideGroupsFilter = 152,
	EnableGroupsFilter = 156,
	AllowAdvancedSearch = 200,
	AllowConvertionToCompaniesOrContacts = 210
}
