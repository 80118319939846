//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OpportunityRefStatusType {
	None = 0,
	Mentors = 1,
	Supportes = 2,
	Resistors = 3,
	Neutrals = 4,
	Enemies = 5
}
