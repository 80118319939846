//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SchedulerRequestCalledFroms {
	ApplicationStart = 0,
	IRegisteredObject = 1,
	Diagnostic = 2,
	Other = 3
}
