<template>
  <router-link :to="route">
    <div :class="{ 'mail-item': true, 'mail-seen': mail.seen, 'mail-selected': selected }">
      <div class="nowrap clearfix">
        <el-row type="flex">
          <el-col :span="-1">
            <t-email-gravatar :email="mail.fromAddress">
              <template #overlay v-if="mail.isLinkedActivity">
                <t-tooltip-label :content="$t('mailbox.activityCreated')">
                  <i class="el-icon-icon-checkmark text text-success"></i>
                </t-tooltip-label>
              </template>
            </t-email-gravatar>
          </el-col>
          <el-col>
            <div class="text m-l-q" :style="unSeenStyle">
              <div class="m-b-q">
                <t-email-address-label :value="{
                  displayName: mail.fromName,
                  address: mail.fromAddress,
                }"></t-email-address-label>
              </div>
              <div>
                <span>
                  <i class="el-icon-icon-reply" v-if="mail.answered"></i>
                </span>
                <span class="icon-1x m-r-q" :class="{
                  'el-icon-fa-smile fad fa-swap-opacity': positiveSentiment,
                  'text-success': positiveSentiment,
                  'el-icon-fa-angry fad fa-swap-opacity': negativeSentiment,
                  'text-danger': negativeSentiment
                }"></span>
                <span>{{ mail.subject }}</span>
              </div>
            </div>
            <div class="text text-right smaller date">{{ $moment(mail.date).fromNow() }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IWebMailSmallMessage } from 'rt/Base/WebMail/IWebMailSmallMessage';

@Component({
  name: 'WebMailListSideItem',
  props: {
    mail: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
})
export default class WebMailListSideItem extends Vue {
  mail: IWebMailSmallMessage;

  selected: boolean;

  get unSeenStyle(): any {
    if (this.mail.seen) return {};
    return { fontWeight: 'bold' };
  }

  get route() {
    return {
      name: this.$routeNames.webmailView,
      params: {
        mailboxId: this.$hashids.encode(this.mail.mailBoxId),
        uid: this.$hashids.encode(this.mail.uid),
      },
      query: { ...this.$route.query, folder: this.mail.folder },
    };
  }

  get positiveSentiment() {
    return this.mail.positiveScore > this.mail.negativeScore && this.mail.positiveScore > this.mail.neutralScore;
  }

  get negativeSentiment() {
    return this.mail.negativeScore > this.mail.positiveScore && this.mail.negativeScore > this.mail.neutralScore;
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';

.mail-item {
  background-color: white;
  position: relative;

  &.mail-selected {
    background-color: $--background-color-base;
  }

  padding: 4px;
  border-bottom: $--border-base;

  & .p1 {
    color: $--color-warning;
  }

  & .p2 {
    color: $--color-primary;
  }

  & .p3 {
    color: $--color-success;
  }

  & .date {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
</style>
