//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SyncPropertyFlag {
	Outlook = 1,
	Cloud = 2,
	App = 4,
	Exchange = 8,
	All = 65535
}
