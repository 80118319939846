<template>
  <el-alert effect="dark" :closable="false" show-icon type="error">
    {{ $t('mailing.expired') }}
  </el-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'MailingExpired',
})
export default class MailingExpired extends Vue {}
</script>
