//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RollingInvoiceType {
	Manufacturer = 0,
	Supplier = 1,
	CustomerOwner = 2,
	Renter = 3
}
