<template>
  <span>
    <template v-if="count > 0">
      <el-badge :value="count">
        <slot></slot>
      </el-badge>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'TabBadgePropertiesCounter',
})
export default class TabBadgePropertiesCounter extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  properties: string[];

  @Prop({
    type: Object,
    required: true,
  })
  value: any;

  get count() {
    if (this.properties?.length > 0) {
      return this.properties.reduce((agg, prop) => {
        if (Object.prototype.hasOwnProperty.call(this.value, prop)) {
          if (_.isArray(this.value[prop])) {
            return agg + this.value[prop].length;
          }
          if (!isNaN(this.value[prop])) {
            return agg + +this.value[prop];
          }
        }
        return agg;
      }, 0);
    }
    return null;
  }
}
</script>
