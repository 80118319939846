<template>
  <div class="m-r p-r">
    <el-alert class="m-t m-b m-l" type="error" :closable="false" effect="dark" center>
      <slot>{{ $t('unauthorized.read') }}</slot>
    </el-alert>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import HashIds from 'hashids';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'UnauthorizedMessage',
})
export default class UnauthorizedMessage extends Vue {}
</script>
