import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ILookupItem } from './ILookupItem';
import { SuggestController } from 'rt/UIApiControllers/UI/Smart/SuggestController';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { Getter } from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { ISuggestion } from 'rt/Base/Search/Prompters/ISuggestion';
import _ from 'lodash';
import './lookup.css';

@Component({
  name: 'BaseLookup',
})
export default class BaseLookup extends Vue {
  suggestController = new SuggestController(Vue.axios);

  resolveController = new ResolveController(Vue.axios);

  @Prop({
    default: true,
  })
  inputVisible: boolean;

  @Prop({
    default: false,
  })
  multipleSelection: boolean;

  @Prop({
    default: () => [],
  })
  value: ILookupItem[];

  @Prop({
    default: () => ({}),
  })
  suggestionParams: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  readOnly: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  drawer: boolean;

  @Getter user: IUser;

  @Watch('value')
  handleValueChange(newVal: ILookupItem[], oldVal: ILookupItem[]) {
    if (_.isEqual(newVal, oldVal)) {
      return;
    }
    this.selectedData = newVal.map((v) => ({
      ...v,
    }));
  }

  selectedData: ILookupItem[] = [];

  inputValue = '';

  popoverOpen = false;

  search: string = null;

  get enableCreate(): boolean {
    return false;
  }

  suggestionControllerName(): string {
    console.error('You need to override this method in subclass');
    return 'Error';
  }

  keyPrefix(suggest: ISuggestion): string {
    console.error('You need to override this method in subclass');
    return 'Error';
  }

  created() {}

  mounted() {
    this.selectedData = this.value.map((v) => ({
      ...v,
    }));
    this.search = null;
  }

  buildSuggestionParams(): any {
    return this.suggestionParams;
  }

  public async fetchSuggestion(queryString, callback) {
    const queryResult = await this.suggestController[this.suggestionControllerName()](queryString, this.buildSuggestionParams());
    this.processSuggestion(queryString, queryResult, callback);
  }

  async processSuggestion(queryString: string, queryResult: ISuggestion[], callback: (payload: any) => void) {
    const results = queryResult.map((qr) => ({
      ...qr,
      key: `${this.keyPrefix(qr)}-${qr.id}`,
      value: qr.name,
    }));
    if (this.enableCreate) {
      results.push({
        id: null,
        type: null,
        key: null,
        value: queryString,
        name: null,
      });
    }
    callback(results);
  }

  public handleRemoveItem(item) {
    if (this.readOnly) return;
    const foudnItem = this.selectedData.find((i) => i.key === item.key);
    this.selectedData.splice(this.selectedData.indexOf(foudnItem), 1);
    this.emitInput();
  }

  public changeItems(items: ILookupItem[]) {
    if (this.readOnly) return;
    this.selectedData = items;
    this.emitInput();
  }

  emitInput() {
    if (this.readOnly) return;
    this.$emit(
      'input',
      this.selectedData.map((m) => ({
        ...m,
      })),
    );
  }

  addItem(item: ILookupItem) {
    if (this.readOnly) return;
    if (this.multipleSelection) {
      if (this.selectedData.some((sd) => sd.key === item.key)) {
        return;
      }
      this.changeItems([
        ...this.selectedData,
        {
          key: item.key,
          title: item.title,
        },
      ]);
    } else {
      this.changeItems([
        {
          key: item.key,
          title: item.title,
        },
      ]);
    }
    this.inputValue = '';
  }

  async resolve(id: number, type: BusinessObjectType) {
    return (await this.resolveController.Get(id, type))?.name;
  }

  get isNestedDialog() {
    let p = this.$parent as any;
    while (p) {
      if (p.constructor.options.name === 'ElDialog') {
        return true;
      }
      p = p.$parent;
    }
    return false;
  }

  focus() {
    (this.$refs['lookup-skin'] as any).focus();
  }
}
