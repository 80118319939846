//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ShippedBy {
	None = 0,
	Vector = 1,
	Recipient = 2,
	Sender = 3
}
