<template>
  <span>
    <el-dialog :width="$viewport.dialog" :visible.sync="dialogVisible" :append-to-body="isNestedDialog">
      <el-row class="m-b" type="flex">
        <el-col>
          <t-search-input v-model="search"></t-search-input>
        </el-col>
        <el-col :span="-1" class="nowrap"></el-col>
      </el-row>
      <t-grid :showCommands="false"
        :customParameterData="{ enableCompany: isSearchCompanyEnabled, enableContact: isSearchContactEnabled, enableLead: isSearchLeadEnabled, search, onlyGeo }"
        repeater="PopGlobalAnagraphicRepeater" @linkClick="linkClick">
        <template #cell_COMPANYNAME_prepend="props">
          <i :class="'el-icon-' + $boMapping.toIcon(props.scope.row.TYPE.value)" class="m-r-q"></i>
        </template>
      </t-grid>
    </el-dialog>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" @openDialog="openDialog" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag
        :route="$modules.isModuleLoaded('company') || $modules.isModuleLoaded('contact') || $modules.isModuleLoaded('lead')"
        :value="crossAnagraphic" :key="crossAnagraphic.key" v-for="crossAnagraphic in selectedData" :closable="!readOnly"
        @close="handleRemoveItem(crossAnagraphic)"></t-lookup-tag>
      <template #append>
        <slot name="append"></slot>
      </template>
      <template #autocomplete="{ item }">
        <span v-if="item.key != null">
          <div v-if="item.key.startsWith('Company-')" key="Company" class="text-primary">
            <t-icon-label :icon="`el-icon-${$boMapping.toIcon($enums.BusinessObjectType.Company)}`">{{
              item.value
            }}</t-icon-label>
          </div>
          <div v-else-if="item.key.startsWith('Contact-')" key="Contact" class="text-success">
            <t-icon-label :icon="`el-icon-${$boMapping.toIcon($enums.BusinessObjectType.Contact)}`">{{
              item.value
            }}</t-icon-label>
          </div>
          <div v-else-if="item.key.startsWith('Lead-')" key="Lead" class="text-warning">
            <t-icon-label :icon="`el-icon-${$boMapping.toIcon($enums.BusinessObjectType.Lead)}`">{{
              item.value
            }}</t-icon-label>
          </div>
        </span>
        <el-button v-else type="primary" size="medium" class="w-100">
          <t-icon-label :icon="`el-icon-${$boMapping.toIcon($enums.BusinessObjectType.Lead)}`">{{
            $t('anagraphic.create', {
              name: item.value
            }) }}</t-icon-label>
        </el-button>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ISuggestion } from 'rt/Base/Search/Prompters/ISuggestion';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { ParseController } from 'rt/UIApiControllers/UI/Smart/ParseController';
import TLookupSkin from './LookupSkin.vue';
import { ICloudUserLimit } from '@/plugins/typings/UI/Injector/ICloudUserLimit';

@Component({
  name: 'CrossAnagraphicLookup',
  components: {
    TLookupSkin,
  },
})
export default class CrossAnagraphicLookup extends BaseLookup {
  resolveController = new ResolveController(Vue.axios);

  dialogVisible = false;

  @Prop({
    type: Boolean,
    default: true,
  })
  enableCompany: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  enableContact: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  enableLead: boolean;

  @Prop({
    type: String,
    default: '',
  })
  suggestEMail: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  onlyGeo: boolean;

  enableCompanyPreference = true;

  enableContactPreference = true;

  enableLeadPreference = true;

  get enableCreate(): boolean {
    return this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Company) || this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Contact) || this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Lead);
  }

  get isSearchCompanyEnabled() {
    return this.enableCompany && this.enableCompanyPreference;
  }

  get isSearchContactEnabled() {
    return this.enableContact && this.enableContactPreference;
  }

  get isSearchLeadEnabled() {
    return this.enableLead && this.enableLeadPreference;
  }

  suggestionControllerName() {
    return 'Anagraphic';
  }

  keyPrefix(suggest: ISuggestion): string {
    return BusinessObjectType[suggest.type];
  }

  openDialog() {
    this.dialogVisible = true;
  }

  async handleSelect(val) {
    if ((this.user.limits as ICloudUserLimit).signatureEnrichment && val.key === null) {
      const signature = await new ParseController(Vue.axios).Signature(val.value);
      this.$root.$emit('anagraphic', {
        name: val.value,
        company: this.isSearchCompanyEnabled && this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Company),
        contact: this.isSearchContactEnabled && this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Contact),
        lead: this.isSearchLeadEnabled && this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Lead),
        anagraphic: this.createDefaults(signature) ?? {
          companyName: val.value,
        },
        callback: async (payload: { id: number; type: number; title: string }) => {
          if (payload?.id > 0) {
            await this.getAnagraphicTitleOrResolve(payload);
            this.addItem({
              key: `${this.keyPrefix(payload as any)}-${payload.id}`,
              title: payload.title,
            });
          }
        },
      });
    } else {
      this.addItem({
        key: val.key,
        title: val.value,
      });
    }
  }

  private createDefaults(signature) {
    if (signature) {
      const def: any = {};
      if (signature.name) {
        def.name = signature.name;
      }
      if (signature.surname) {
        def.surname = signature.surname;
      }
      if (signature.companyName) {
        def.companyName = signature.companyName;
      }
      return def;
    }
    return null;
  }

  async linkClick(crossAnagraphicId, row) {
    const item = {
      key: `${this.keyPrefix({
        type: +row.TYPE.value,
      } as ISuggestion)}-${crossAnagraphicId}`,
      title: (await this.resolveController.Get(crossAnagraphicId, +row.TYPE.value)).name,
    };

    this.addItem(item);
    this.dialogVisible = false;
  }

  async getAnagraphicTitleOrResolve(crossAnagraphic) {
    if (!crossAnagraphic.title) {
      crossAnagraphic.title = (await this.resolveController.Get(crossAnagraphic.id, crossAnagraphic.type)).name;
    }
  }
}
</script>
