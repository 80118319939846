<template>
  <div v-if="error">
    <el-alert type="error" title :closable="false" effect="dark">
      <h2>{{ $t('booking.error') }}</h2>
    </el-alert>
  </div>
  <div v-else v-loading="booking === null">
    <transition name="fade-jump-bottom">
      <div v-if="booking != null">
        <h3>
          {{ $t('booking.title') }}
          <h5>{{ booking.title }}</h5>
        </h3>
        <div class="m-t">
          <t-icon-label icon="far el-icon-fa-clock">{{ $t('booking.duration') }}: {{ duration }}</t-icon-label>
        </div>
        <div class="m-t" v-if="booking.location">
          <t-icon-label icon="far el-icon-fa-map-marker-alt">{{ $t('booking.location') }}: {{
            booking.location
          }}</t-icon-label>
        </div>
        <div v-if="confirmed != null" class="m-t">
          <el-alert type="success" title :closable="false">
            <h2>
              {{
  $t('booking.planned', {
    date: $moment(confirmed.start).format('dddd LL'),
  from: $moment(confirmed.start).format('LT'),
    to: $moment(confirmed.end).format('LT'),
              }) }}
            </h2>
          </el-alert>
        </div>
        <div v-else>
          <div class="m-t-h">
            <el-card v-for="(slots, key) in groupedSlots" :key="key" class="m-t-q"
              :body-style="{ 'paddingBottom': '2px', 'paddingTop': '12px' }">
              <template #header>
                <el-row type="flex">
                  <el-col>
                    <b>{{ $moment(key).format('dddd LL') }}</b>
                  </el-col>
                </el-row>
              </template>
              <el-row class="text m-b-q" align="middle" justify="center">
                <el-col v-for="slot in slots" :key="slot.id" :span="6" :xs="12" class="m-b-q">
                  <el-radio v-model="selected" :label="slot.id" border>{{ $moment(slot.start).format('LT') }}</el-radio>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <div class="text-right m-t-h">
            <el-button type="primary" :disabled="selected === null" @click="confirmAppointment">{{
              $t('commands.confirm')
            }}</el-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { IBookingDTO } from '@/plugins/typings/DTO/IBookingDTO';
import { BookingController } from '@/plugins/typings/UIApiControllers/UI/Calendar/BookingController';
import { IAxiosRequestConfigRetry } from '@/utils/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';
import { ResolveController } from '@/plugins/typings/UIApiControllers/UI/Smart/ResolveController';
import { IBookingSlotDTO } from '@/plugins/typings/DTO/IBookingSlotDTO';

@Component({
  name: 'CalendarBooking',
})
export default class CalendarBooking extends Vue {
  controller: BookingController = new BookingController(this.$http);

  booking: IBookingDTO = null;

  selected: number = null;

  error = false;

  async mounted() {
    const token = this.$route.params.token;
    this.$http.interceptors.request.use((r: IAxiosRequestConfigRetry) => {
      r.headers.Authorization = `Booking ${token}`;
      r.headers['NullValueHandling'] = 'Include';
      return r;
    });
    try {
      const booking = await this.controller.Get2();
      if (booking) {
        if (this.$route.query.preference) {
          const date = this.$moment(this.$route.query.preference);
          const prefSlot = booking.slots.find((s) => this.$moment(s.start).isSame(date, 'day'));
          if (prefSlot != null) {
            this.selected = prefSlot.id;
          }
        }
      }
      this.booking = booking;
    } catch {
      this.error = true;
    }
  }

  get confirmed(): IBookingSlotDTO {
    if (this.booking === null) {
      return null;
    }
    return this.booking.slots.find((s) => s.confirmation);
  }

  get groupedSlots() {
    if (this.booking === null) {
      return null;
    }
    return _.groupBy(this.booking.slots, (s) => this.$moment(s.start).startOf('day').toISOString());
  }

  get duration() {
    const s = this.booking.minutes;
    const d = (this.$moment as any).duration(s, 'minutes');
    if (s > 0 && s % 60 === 0) {
      return d.format('h __');
    }
    return d.format('h __, m __');
  }

  async confirmAppointment() {
    if (this.selected > 0) {
      const slot = this.booking.slots.find((s) => s.id === this.selected);
      if (slot) {
        const from = this.$moment(slot.start);
        const to = from.clone().add(this.booking.minutes, 'minutes');
        const result = await this.$confirm(
          this.$t('booking.confirm', {
            date: from.format('dddd LL'),
            from: from.format('LT'),
            to: to.format('LT'),
          }) as string,
          this.$t('booking.header') as string,
          {
            distinguishCancelAndClose: true,
            type: 'success',
          },
        );
        if (result === 'confirm') {
          slot.confirmation = true;
          try {
            await this.controller.Confirm(this.selected);
          } catch {
            this.error = true;
          }
        }
      }
    }
  }
}
</script>
