<template>
  <!-- TOOD: Do with localized resource -->
  <span>
    <t-form-item
      :label="schema.item.name"
      :prop="schema.propertyName"
      :required="schema.required"
      :important="schema.important"
    >
      <span
        v-if="freeComponentName"
        :is="freeComponentName"
        :value="value"
        :item="schema.item"
        @input="simpleEmit"
        :required="schema.required"
        :read-only="schema.readOnly"
      ></span>
      <span v-else>Missing Type {{ schema.item.type }} - {{ schema.item.type }}</span>
    </t-form-item>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { IFreeFieldsBOItemDTO } from 'dto/IFreeFieldsBOItemDTO';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Freefield',
  mixins: [simpleMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    value: {
      required: false,
    },
  },
})
export default class Freefield extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: { item: IFreeFieldsBOItemDTO };

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    required: false,
  })
  value: any;

  get freeComponentName(): string {
    switch (this.schema.item.type) {
      case this.$enums.FreeFieldsType.ReadonlyText:
      case this.$enums.FreeFieldsType.ReadonlyFromQuery:
      case this.$enums.FreeFieldsType.Password:
      case this.$enums.FreeFieldsType.Username:
      case this.$enums.FreeFieldsType.InputText:
        return 't-free-input';
      case this.$enums.FreeFieldsType.InputArea:
        return 't-free-textarea';
      case this.$enums.FreeFieldsType.Currency:
        return 't-free-currency';
      case this.$enums.FreeFieldsType.InputNumber:
        return 't-free-number';
      case this.$enums.FreeFieldsType.Checkbox:
        return 't-free-checkbox';
      case this.$enums.FreeFieldsType.SelectFromQuery:
      case this.$enums.FreeFieldsType.Select:
        return 't-free-select';
      case this.$enums.FreeFieldsType.InputDate:
        return 't-free-date';
      case this.$enums.FreeFieldsType.SelectMultipleFromQuery:
      case this.$enums.FreeFieldsType.MultiSelect:
        return 't-free-multiple-select';
      case this.$enums.FreeFieldsType.Account:
        return 't-free-account';
      case this.$enums.FreeFieldsType.InputHour:
        return 't-free-hour';
      case this.$enums.FreeFieldsType.Iframe:
        return 't-free-iframe';
    }
    return null;
  }
}
</script>
