<template>
  <span>
    <t-lookup-skin v-model="inputValue" :inputVisible="inputVisible" :fetch-suggestions="fetchSuggestion"
      @select="handleSelect" :readOnly="readOnly" ref="lookup-skin">
      <t-lookup-tag :value="zone" :key="zone.key" v-for="zone in selectedData" :closable="!readOnly"
        :disable-transitions="false" @close="handleRemoveItem(zone)"></t-lookup-tag>

      <template #dialog-search>
        <el-popover v-model="popoverOpen" @hide="handleZoneTreePopoverHide" :popper-options="{ gpuAcceleration: false }"
          placement="right" :title="$t('lookup.zone')" trigger="click">
          <t-zone-tree v-if="popoverOpen" ref="zone-tree" :multipleSelection="multipleSelection" :value="treeSelectedData"
            @input="handleTreeInputChange" @expand="recalcPopper" @collapse="recalcPopper" :onlyLeaf="false" />
          <template #reference>
            <i class="el-icon-search cursor-pointer" />
          </template>
        </el-popover>
      </template>
    </t-lookup-skin>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'ZoneLookup',
  components: {
    TLookupSkin,
  },
})
export default class ZoneLookup extends BaseLookup {
  suggestionControllerName() {
    return 'Zone';
  }

  keyPrefix() {
    return 'Zone';
  }

  recalcPopper() {
    /*
    window.setTimeout(() => {
      const popper = <any>this.$refs['popover-zone-tree'];
      popper.updatePopper();
    }, 300); */
  }

  async handleZoneTreePopoverHide() {
    const zoneTree = this.$refs['zone-tree'];
    await (zoneTree as any).reset();
  }

  get treeSelectedData() {
    return this.selectedData.map((k) => ({
      ...k,
      key: k.key.toString().substring(this.keyPrefix().length + 1),
    }));
  }

  handleTreeInputChange(items) {
    if (!this.multipleSelection) {
      this.popoverOpen = false;
    }
    this.changeItems(
      items.map((m) => ({
        ...m,
        key: `${this.keyPrefix}-${m.key}`,
      })),
    );
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
</script>
