<template>
  <t-unauthorized-message v-if="!user.isAuthorizedToRead($enums.BusinessObjectType.Message)"></t-unauthorized-message>
  <div class="clearfix" v-else>
    <div>
      <h3>{{ $t('message.last') }}</h3>
    </div>
    <div v-loading="loading" class="last-messages-box p-r">
      <div v-if="!hasMessages" class="text-center m-t">
        <i class="el-icon-icon-weather-windy icon-1_5x" style="vertical-align: bottom"></i>
        <span class="text text-1_2x m-l-h">{{ $t('messages.nomessages') }}</span>
      </div>
      <div v-else v-for="message in messages" :key="message.id" class="message-box big m-b">
        <div class="message-header">
          <el-row type="flex" align="middle" :gutter="6">
            <el-col :span="-1">
              <span>
                <img class="message-user-image"
                  :src="`${baseUrl}/Avatar/User/${message.senderToken}/${$viewport.isRetina ? 60 : 30}?${ticks}`" />
              </span>
            </el-col>
            <el-col>
              <div class="cleafix">
                <span class="pull-left">{{ message.senderName }}</span>
                <span class="pull-right">{{ time(message) }}</span>
              </div>
              <div>
                <router-link :to="message.url">
                  <el-row>
                    <el-col class="message-body">
                      <t-markdown v-if="message.bodyFormat === 0" :value="message.body"></t-markdown>
                      <div v-if="message.bodyFormat === 1">{{ message.body }}</div>
                      <div v-if="message.bodyFormat === 2" :inner-html.prop="message.body | xss"></div>
                    </el-col>
                  </el-row>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { MessagesController } from 'rt/UIApiControllers/UI/Messages/MessagesController';
import { IMessageInfo } from 'rt/UIApiControllers/UI/Messages/IMessageInfo';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

// tslint:disable-next-line:variable-name
const MessagesAction = namespace('messages').Action;
// tslint:disable-next-line:variable-name
const MessagesGetter = namespace('messages').Getter;

@Component({
  name: 'LastMessages',
})
export default class LastMessages extends Vue {
  @Getter user: IUser;

  @Getter('baseUrl') baseUrl: string;

  @Getter ticks: number;

  @MessagesGetter('messagesController') messagesController: MessagesController;

  @MessagesGetter('lastMessages') messages: IMessageInfo[];

  @MessagesAction('loadLastMessages') loadLastMessages: () => Promise<void>;

  loading = false;

  async mounted() {
    if (this.user.isAuthorizedToRead(this.$enums.BusinessObjectType.Message)) {
      this.loading = true;
      await this.loadLastMessages();
      this.loading = false;
    }
  }

  get hasMessages(): boolean {
    return this.messages && this.messages.length > 0;
  }

  time(message) {
    const createdDate = this.$moment(message.createdDate);
    if (createdDate.isSame(this.$moment(), 'day')) {
      return createdDate.fromNow();
    }
    if (createdDate.isAfter(this.$moment().add(-7, 'd'))) {
      return createdDate.format('dddd, LT');
    }
    return createdDate.format('dddd, LL LT');
  }
}
</script>
<style lang="scss" scoped>
@import '~components/message/message.scss';

.last-messages-box {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .message-body {
    max-height: 80px;
    overflow: hidden;
  }
}
</style>
