//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobOrderTaskPlanningMode {
	RecursiveDays = 0,
	FixedMonths = 1,
	FixedDays = 2,
	FixedDayMonthPermutations = 3
}
