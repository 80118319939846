//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActiveModules {
	TustenaBase = 0,
	MailCenter = 1,
	Storage = 2,
	Sales = 4,
	Ticket = 8,
	Database = 16,
	Knowledgebase = 32,
	Project = 64,
	SalesWarehouse = 128,
	Invoices = 256,
	Campaign = 512,
	InvoicesExtended = 1024,
	Service = 2048,
	ECommerce = 4096,
	MagoPlugin = 8192,
	Free = 16384,
	Soho = 17542,
	Portal = 32768,
	Social = 65536,
	BPM = 131072,
	PreSale = 262144,
	Report = 524288,
	Mobile = 1048576,
	CallManager = 2097152,
	WebMailArchive = 4194304,
	Dynamo = 8388608,
	TeamSystemId = 16777216
}
