//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MailTag {
	Unknown = 0,
	Normal = 1,
	Important = 2,
	Notification = 4,
	Social = 8,
	Promotion = 16,
	Internal = 32,
	Shopping = 64
}
