<template>
  <ul class="breadcrumb text w-100" ref="breadcrumb">
    <li v-if="skip > 0" class="esists-prev-items" @click="skip = 0">
      <a class="text" href="#">...</a>
    </li>
    <li
      v-for="(phase, index) in currentPhases"
      :key="phase.value"
      @click="handlePhaseChange(phase)"
    >
      <a href="#" :class="getBreadcrumbClass(phase, index)" v-indexed="phase.text">
        <slot :phase="phase" :index="index">{{ phase.text }} &nbsp;</slot>
        <span :ref="`selectedTargetPhase_${phase.value}`" class="targetPhaseVisibilityTarget"></span>
      </a>
    </li>
    <slot name="append"></slot>
  </ul>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import BusinessObjectCommandsMixin from 'modules/mixin/views/BusinessObjectCommandsMixin';
import { IOpportunityDTO } from 'dto/IOpportunityDTO';
import { ListsType } from 'rt/Core/ListsType';
import inViewport from 'in-viewport';

interface IBreadcrumbPhase {
  value: number;
  text: string;
  type: string;
}

@Component({
  name: 'BreadcrumbPhases',
})
export default class BreadcrumbPhases extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  phases: Array<IBreadcrumbPhase>;

  @Prop({
    required: true,
    type: Number,
  })
  value: number;

  skip = 0;

  @Watch('phase')
  handlePhaseWatchChange() {
    this.skip = 0;
  }

  get currentPhases(): Array<IBreadcrumbPhase> {
    if (!this.phases) return null;
    const categoryFilter = this.phases;
    if (this.skip) {
      return categoryFilter.slice(this.skip);
    }
    return categoryFilter;
  }

  updated() {
    const refName = `selectedTargetPhase_${this.value}`;
    const phaseBreadcum = this.$refs[refName] as Element[];
    if (phaseBreadcum && phaseBreadcum.length) {
      const { breadcrumb } = this.$refs;
      const isInViewport = inViewport(phaseBreadcum[0], {
        container: breadcrumb,
      });
      if (!isInViewport && this.skip < 30) {
        this.$nextTick(() => {
          this.skip += 1;
        });
      }
    }
  }

  get currentPhasesIds(): number[] {
    if (!this.currentPhases) {
      return null;
    }
    return this.currentPhases.map((p) => p.value);
  }

  async handlePhaseChange(phase: IBreadcrumbPhase) {
    this.$emit('input', phase);
  }

  getBreadcrumbClass(phase: IBreadcrumbPhase, index: number) {
    const phaseIds = this.currentPhasesIds;
    const currentIndex = phaseIds.findIndex((p) => p === this.value);
    if (currentIndex === index) {
      if (phase.type) {
        return phase.type;
      }
      return 'primary';
    }
    if (currentIndex > -1) {
      if (index < currentIndex) {
        return 'warning';
      }
    }
    return null;
  }
}
</script>
<style lang="scss" scoped>
@import '~element-variables';
$--breadcum-vertical-padding: 10px;

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  height: calc(1em + 10px * 2); // 12px internal fond
  border-radius: $--border-radius-base;
  display: inline-block;
  & li {
    display: inline-block;
    white-space: nowrap;
    & > * {
      display: block;
      padding: $--breadcum-vertical-padding 0 $--breadcum-vertical-padding 45px;
      position: relative;
    }
    & span.targetPhaseVisibilityTarget {
      position: absolute;
      display: inline-block;
      width: 4px;
      height: 4px;
      z-index: 999;
      right: -60px;
      // background-color: red;
    }
    & a,
    & b {
      color: white;
      text-decoration: none;
      background: $--color-info;
      &::after {
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
        border-bottom: 50px solid transparent;
        border-left: 30px solid $--color-info;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        left: 100%;
        z-index: 2;
      }
      &::before {
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 30px solid white;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        margin-left: 1px;
        left: 100%;
        z-index: 1;
      }
      &.primary {
        background: $--color-primary;
      }
      &.primary:after {
        border-left-color: $--color-primary;
      }
      &.warning {
        background: $--color-warning;
        color: $--color-text-primary;
      }
      &.warning:after {
        border-left-color: $--color-warning;
      }
      &.success {
        background: $--color-success;
      }
      &.success:after {
        border-left-color: $--color-success;
      }
      &.danger {
        background: $--color-danger;
      }
      &.danger:after {
        border-left-color: $--color-danger;
      }
    }
    & a {
      &:hover {
        background: $--color-primary;
        color: $--color-text-placeholder;
      }
      &:hover:after {
        border-left-color: $--color-primary !important;
      }
    }
    &:first-child a {
      padding-left: 20px;
    }
    &:last-child a {
      cursor: default;
      padding-right: 20px;
      &::after {
        border: 0;
      }
      & span.targetPhaseVisibilityTarget {
        right: 0px;
      }
    }
  }
}
</style>
