<template>
  <el-input
    :value="currentValue"
    @input="handleInput"
    @blur="handleBlur"
    :placeholder="$t('erp.rows.discountFormula')"
    ></el-input>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'DiscountFormula',
})
export default class DiscountFormula extends Vue {
  @Prop({
    type: String,
  })
  value: string;

  @Watch('value')
  handleValueChange(v) {
    this.currentValue = v;
  }

  currentValue: string = null;

  mounted() {
    this.currentValue = this.value;
  }

  handleInput(v) {
    this.currentValue = v;
  }

  handleBlur() {
    if (this.isValidDiscountFormula(this.currentValue)) {
      this.$emit('input', this.currentValue);
    } else {
      this.$emit('input', null);
    }
  }

  isValidDiscountFormula(df) {
    // TODO: implements discount formula validation
    return true;
  }
}
</script>
