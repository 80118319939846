//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ErpRowColumnType {
	String = 0,
	ProductCode = 1,
	Decimal = 2,
	Money = 3,
	Percentage = 4,
	Description = 5,
	TaxCode = 6,
	DiscountFormula = 7,
	Qta = 8,
	Boolean = 9
}
