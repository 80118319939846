import Vue, { PluginObject } from 'vue';
import i18nUtils from 'utils/i18nUtils';

class FlowComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    // i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
    vue.component('t-plumb', () => import(/* webpackChunkName: "flow" */ './Plumb.vue'));
    vue.component('t-plumb-node-editor', () => import(/* webpackChunkName: "flow" */ './PlumbNodeEditor.vue'));
    vue.component('t-plumb-node-summary', () => import(/* webpackChunkName: "flow" */ './PlumbNodeSummary.vue'));
    vue.component('t-flow-duration', () => import(/* webpackChunkName: "flow" */ './components/FlowDuration.vue'));
    vue.component('t-flow-html-description', () => import(/* webpackChunkName: "flow" */ './components/FlowHtmlDescription.vue'));
    vue.component('t-flow-ternary-reference', () => import(/* webpackChunkName: "flow" */ './components/FlowTernaryReference.vue'));
    vue.component('t-flow-search-conditions', () => import(/* webpackChunkName: "flow" */ './components/FlowSearchConditions.vue'));
    vue.component('t-flow-mailing-from', () => import(/* webpackChunkName: "flow" */ './components/FlowMailingFrom.vue'));
    vue.component('t-flow-change-properties', () => import(/* webpackChunkName: "flow" */ './components/FlowChangeProperties.vue'));
    vue.component('t-flow-body-editor', () => import(/* webpackChunkName: "flow" */ './components/FlowBodyEditor.vue'));
    vue.component('t-flow-email-sender-input', () => import(/* webpackChunkName: "flow" */ './components/FlowEmailSenderInput.vue'));
  }
}
export default new FlowComponents();
