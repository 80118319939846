<template>
  <t-icon-label v-if="value.email" class="email m-b-h" icon="el-icon-icon-envelop" @click.native="handleMailClick"
  >{{ value.email }}</t-icon-label>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IEMailNormalizedDTO } from 'dto/IEMailNormalizedDTO';
import { Prop } from 'vue-property-decorator';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';

@Component({
  name: 'EMailLabel',
})
export default class EMailLabel extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: IEMailNormalizedDTO;

  @Prop({
    type: Number,
    required: true,
  })
  id: number;

  @Prop({
    type: Number,
    required: true,
  })
  type: BusinessObjectType;

  handleMailClick(email) {
    this.$root.$emit('activity', {
      filter: [this.$enums.ActivityType.Email],
      schema: 'webmail',
      activity: {
        companyId: this.type === this.$enums.BusinessObjectType.Company ? this.id : 0,
        contactId: this.type === this.$enums.BusinessObjectType.Contact ? this.id : 0,
        leadId: this.type === this.$enums.BusinessObjectType.Lead ? this.id : 0,
        email: {
          to: [{ address: this.value.email }],
        },
      },
    });
  }
}
</script>
<style lang="scss">
@import '~element-variables';
.email {
  color: $--color-info;
  font-size: $--font-size-small;
  cursor: pointer;
}
</style>
