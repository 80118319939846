<template>
  <span v-if="status">
    <el-popover ref="popover-spoolerstatus" placement="left" :width="400">
      <h4
        class="text text-primary"
      >{{ $t('mailing.spooler.sidebar.title') }}{{ $t('mailing.spooler.sidebar.title') }}</h4>
      <div v-if="total" class="m-t">
        <el-progress
          class="percentage"
          :text-inside="true"
          :stroke-width="18"
          :percentage="percentage"
          :width="40"
          :status="progressStatus"
        ></el-progress>
      </div>
      <el-row
        type="flex"
        justify="center"
        class="m-t"
        v-if="total"
        v-loading="spoolerCommandPending"
      >
        <el-col :span="-1">
          <el-button-group>
            <t-tooltip-button
              :content="$t(`mailing.spooler.commands.info`)"
              icon="el-icon-icon-info"
              type="primary"
              @click="
                () =>
                  $router.push({
                    name: $routeNames.mailings,
                  })
              "
            ></t-tooltip-button>
            <t-spooler-spooler-button
              @click="pauseSpool"
              icon="el-icon-icon-pause2"
              action="pause"
              v-if="status.preLoadedMails + status.waitingMails"
            ></t-spooler-spooler-button>
            <t-spooler-spooler-button
              @click="resumeSpool"
              icon="el-icon-icon-play4"
              action="resume"
              v-if="status.pausedMails"
            ></t-spooler-spooler-button>
            <t-spooler-spooler-button
              @click="stopSpool"
              icon="el-icon-icon-stop2"
              action="stop"
              v-if="queued"
            ></t-spooler-spooler-button>
          </el-button-group>
        </el-col>
      </el-row>
      <div class="m-t text-center">
        <el-divider class="nowrap">{{ $t('mailing.spooler.sidebar.profile') }}</el-divider>
        <t-spooler-profile-info mini></t-spooler-profile-info>
      </div>
    </el-popover>
    <div class="spooler-button" v-popover:popover-spoolerstatus>
      <el-progress
        class="percentage"
        type="circle"
        :percentage="percentage"
        :width="40"
        :show-text="false"
        :status="progressStatus"
        v-if="total"
      ></el-progress>
      <t-circle-button type="default" icon="el-icon-icon-bullhorn"></t-circle-button>
    </div>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { ISideBarSpoolerStatusResponse } from 'rt/UIApiControllers/UI/SideBar/ISideBarSpoolerStatusResponse';
import { Prop } from 'vue-property-decorator';
import TSpoolerSpoolerButton from './SpoolerSpoolerButton.vue';
import TSpoolerProfileInfo from './SpoolerProfileInfo.vue';

// tslint:disable-next-line:variable-name
const MailingGetter = namespace('mailing').Getter;
// tslint:disable-next-line:variable-name
const MailingAction = namespace('mailing').Action;

@Component({
  name: 'SpoolerStatus',
  components: {
    TSpoolerSpoolerButton,
    TSpoolerProfileInfo,
  },
})
export default class SpoolerStatus extends Vue {
  @MailingAction watchSpoolerStatus: () => void;

  @MailingAction unwatchSpoolerStatus: () => void;

  @MailingGetter('spoolerStatus') status: ISideBarSpoolerStatusResponse;

  @MailingGetter spoolerCommandPending: boolean;

  @MailingAction pauseSpool: () => void;

  @MailingAction resumeSpool: () => void;

  @MailingAction stopSpool: () => void;

  async mounted() {
    if (this.$modules.isModuleLoaded('mailing')) {
      this.watchSpoolerStatus();
    }
  }

  async beforeDestroy() {
    this.unwatchSpoolerStatus();
  }

  get total(): number {
    if (!this.status) {
      return 0;
    }
    return this.spooled + this.queued;
  }

  get spooled(): number {
    if (!this.status) {
      return 0;
    }
    return this.status.spooledMails + this.status.sentMails;
  }

  get queued(): number {
    if (!this.status) {
      return 0;
    }
    return this.status.pausedMails + this.status.preLoadedMails + this.status.waitingMails;
  }

  get percentage() {
    if (!this.total) {
      return 0;
    }
    return parseInt(((this.spooled / this.total) * 100).toString(), 10);
  }

  get progressStatus(): string {
    if (!this.status) {
      return '';
    }
    if (this.status.hasError) {
      return 'exception';
    }
    if (this.status.hasWarning) {
      return 'warning';
    }
    return 'success';
  }

  handleCommand(cmd: string) {}
}
</script>
<style lang="scss" scoped>
.spooler-button {
  cursor: pointer;
  position: relative;
  & .percentage {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
</style>
