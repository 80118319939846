//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CampaignTypeOfExport {
	Campaign = 0,
	List = 1,
	Operator = 2,
	CampaignCategories = 3,
	Teams = 4
}
