import Vue, { PluginObject } from 'vue';
import i18nUtils from 'utils/i18nUtils';

class ImageComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
    vue.component('t-image-manager', () => import(/* webpackChunkName: "images" */ './ImageManager.vue'));
  }
}
export default new ImageComponents();
