//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IAlarmDTO } from '../../../DTO/IAlarmDTO';

//element.Name=AlarmController result.Name=AlarmController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.AlarmController);
/** Result of WebApiControllerGenerator activity */
export class AlarmController extends DefaultDtoControllerProxy<IAlarmDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Alarms', BusinessObjectType.Alarm);
	}
	public PostPones(postPones: any[], minutes: number = 10, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Alarms/PostPones?'+'&minutes='+minutes, postPones, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAlarmDTO>
	{
		return (this.axios.get('Alarms/GetFull?'+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
