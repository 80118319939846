//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CatalogPriceListRound {
	Exact = 0,
	Mathematical = 1,
	Ceiling = 2,
	Floor = 3,
	MathematicalDivisibleBy10 = 4,
	CeilingDivisibleBy10 = 5,
	FloorDivisibleBy10 = 6
}
