import Vue, { PluginObject } from 'vue';
import i18nUtils from 'utils/i18nUtils';

class FreeFieldComponents implements PluginObject<any> {
  install(vue: typeof Vue, options?: any): void {
    i18nUtils.addRepository((lang: string) => import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`));
    vue.component('t-advanced-search', () => import(/* webpackChunkName: "advancedSearch" */ './AdvancedSearch.vue'));
    vue.component('t-adv-search-empty', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchEmpty.vue'));
    vue.component('t-adv-search-textarea', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchTextarea.vue'));
    vue.component('t-adv-search-text', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchText.vue'));
    vue.component('t-adv-search-checkbox', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchCheckbox.vue'));
    vue.component('t-adv-search-account', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchAccount.vue'));
    vue.component('t-adv-search-company', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchCompany.vue'));
    vue.component('t-adv-search-contact', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchContact.vue'));
    vue.component('t-adv-search-lead', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchLead.vue'));
    vue.component('t-adv-search-opportunity', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchOpportunity.vue'));
    vue.component('t-adv-search-anagraphic', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchAnagraphic.vue'));
    vue.component('t-adv-search-date', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchDate.vue'));
    vue.component('t-adv-search-numeric', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchNumeric.vue'));
    vue.component('t-adv-search-time', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchTime.vue'));
    vue.component('t-adv-search-select', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchSelect.vue'));
    vue.component('t-adv-search-list', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchList.vue'));
    vue.component('t-adv-search-mail-template', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchMailTemplate.vue'));
    vue.component('t-adv-search-work-plan', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchWorkPlan.vue'));
    vue.component('t-adv-search-not-avaliable', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchNotAvaliable.vue'));
    vue.component('t-adv-search-sub-search', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchSubSearch.vue'));
    vue.component('t-adv-search-history-button', () => import(/* webpackChunkName: "advancedSearch" */ './AdvancedSearchHistoryButton.vue'));
    vue.component('t-adv-search-geo', () => import(/* webpackChunkName: "advancedSearch" */ './fields/TAdvSearchGeo.vue'));
  }
}
export default new FreeFieldComponents();
