//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CompanyPermits {
	AllowPublicCategoryInsert = 0,
	AllowPrivateCategoryInsert = 1,
	AllowWatchCompany = 7,
	AllowLogView = 8,
	AllowPrint = 9,
	AllowSendByMail = 10,
	AllowBookMark = 11,
	AllowViewMaillingListLog = 12,
	AllowOptOut = 13,
	AllowCopyAddress = 14,
	AllowLockCompany = 15,
	AllowLinkContact = 16,
	AllowRepeaterExport = 26,
	AllowRepeaterCustomFields = 27,
	ViewLocationsInList = 81,
	AllowMassChange = 88,
	AllowCallLogView = 107,
	AllowViewBaseCustomerCompany = 148,
	AllowOwnerOverrideGroupsFilter = 150,
	EnableGroupsFilter = 154,
	AllowPolyedroAUSync = 164,
	AllowAdvancedSearch = 198
}
