export default (vue) => {
  vue.component('t-erp-rows-input', () => import(/* webpackChunkName:"erpRow" */ './ErpRowsInput.vue'));
  vue.component('t-erp-row-product-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowProductInput.vue'));
  vue.component('t-erp-row-list-price-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowListPriceInput.vue'));
  vue.component('t-erp-row-unit-price-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowUnitPriceInput.vue'));
  vue.component('t-erp-row-total-price-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowTotalPriceInput.vue'));
  vue.component('t-erp-row-qta-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowQtaInput.vue'));
  vue.component('t-erp-row-um-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowUmInput.vue'));
  vue.component('t-erp-row-tax-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowTaxInput.vue'));
  vue.component('t-erp-row-discount-formula-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowDiscountFormulaInput.vue'));
  // vue.component('t-erp-row-not-implemented-input', () => import(/* webpackChunkName: "erpRow" */ './components/ErpRowNotImplementedInput.vue'));
};
