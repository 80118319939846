//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ERPRowType {
	Quote = 0,
	Order = 1,
	Bill = 2,
	DDT = 3,
	Credit = 4,
	CustomErpDocument = 5
}
