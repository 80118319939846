import Vue from 'vue';
import Component from 'vue-class-component';
import { IContactDTO } from 'dto/IContactDTO';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import eventHubManager from '@/modules/mixin/views/EventHub';

import { Getter, Mutation, namespace } from 'vuex-class';
// tslint:disable-next-line:variable-name
const ContactGetter = namespace('contact').Getter;
// tslint:disable-next-line:variable-name
const ContactMutation = namespace('contact').Mutation;
@Component({
  name: 'ContactViewMixin',
})
export default class ContactViewMixin extends Vue {
  @ContactGetter model: IContactDTO;

  get eventHub(): Vue {
    return eventHubManager.getHub('contact');
  }

  get module(): string {
    return 'contact';
  }

  @ContactMutation setModel: (dto: IContactDTO) => void;

  @ContactMutation setModelProperty: (n: string, v: any) => void;
}
