//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum QuoteStage {
	Draft = 1,
	Negotiation = 2,
	Submitted = 3,
	Delayed = 4,
	Confirmed = 5,
	ClosedAccepted = 6,
	ClosedLost = 7,
	ClosedAbandoned = 8,
	OnHold = 9,
	Followup = 10,
	PortalRequest = 11
}
