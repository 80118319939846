<template>
  <t-form-item
    :label="schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <div v-if="isAbuse">
      <el-alert type="warning" :closable="false">
        <div>{{ $t('anagraphic.mailinglists.rights.removed', { date: $moment(data.mailingListRights.removedDate).format('L LT') }) }}</div>
        <div>
          <i>{{ data.mailingListRights.reason }}</i>
        </div>
      </el-alert>
    </div>
    <el-checkbox
      v-else
      :disabled="schema.readOnly"
      :checked="value"
      :value="value"
      @input="simpleEmit"
    />
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import simpleMixin from './mixin';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'MailingListInput',
  mixins: [simpleMixin],
})
export default class MailingListInput extends Vue {
  @Getter('user') user: IUser;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Boolean,
    required: false,
  })
  value: boolean;

  get isAbuse() {
    return this.data?.mailingListRights?.isAbuse;
  }
}
</script>
