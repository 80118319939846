<template>
  <t-form-item
    :label="schema.text"
    :prop="schema.propertyName"
    :required="schema.required"
    :important="schema.important"
  >
    <t-discount-formula :value="value" @input="simpleEmit"></t-discount-formula>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'DiscountFormulaInput',
  mixins: [simpleMixin],
})
export default class DiscountFormulaInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: true,
  })
  value: string;
}
</script>
