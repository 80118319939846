<template>
  <div>
    <el-dialog :visible="voispeedAdvertiseDialog" @update:visible="setVoispeedAdvertiseDialog"
      :close-on-click-modal="false" append-to-body>
      <template #title>
        <b class="text-1_9x text-primary">Integra il servizio telefonico VOIspeed con il tuo CRM</b>
      </template>
      <div v-if="voispeedAdvertiseForm == null">
        <div>Vuoi migliorare i processi lavorativi, snellire le attività quotidiane e offrire un servizio sempre più
          efficace ai tuoi clienti?</div>
        <div>
          Integra
          <b>CRM in Cloud</b> con il centralino Cloud
          <b>TeamSystem VOIspeed</b>. Potrai:
        </div>
        <ul>
          <li class="m-t-q">
            <b>Identificare subito chi ti chiama</b>: Quando ricevi una telefonata si apre all’istante un pop-up con i
            dati del chiamante e puoi scegliere se accettare o rifiutare la chiamata.
          </li>
          <li class="m-t-q">
            <b>Effettuare chiamate direttamente da CRM in Cloud</b>: basta entrare nell’anagrafica del cliente e
            cliccare l’icona del telefono!
          </li>
          <li class="m-t-q">
            <b>Inserire informazioni</b> e dettagli
            <b>importanti sul contatto</b> e
            <b>pianificare nuove attività</b> direttamente all’interno della schermata che si attiva una volta conclusa
            la telefonata.
          </li>
          <li class="m-t-q">
            Gestire le
            <b>telefonate anche fuori ufficio</b> grazie all'App di
            <b>VOIspeed</b>, dal tuo cellulare, ovunque ti trovi, utilizzando le linee telefoniche della tua azienda.
          </li>
        </ul>
        <div class="text-center m-t">
          <el-button @click="() => setVoispeedAdvertiseDialog(false)">
            <h3>No, non mi interessa</h3>
          </el-button>
          <el-button type="primary" @click="() => initAdvertiseForm()">
            <h3>Attiva l'integrazione</h3>
          </el-button>
        </div>
      </div>
      <div v-else>
        <el-row>
          <el-col>
            <el-form @submit.native.prevent :model="voispeedAdvertiseForm" class="form-reset" :rules="rules"
              label-width="140px" ref="adform">
              <el-form-item prop="name" required label="Nome">
                <el-input :value="voispeedAdvertiseForm.name" @input="v => handleFormInput('name', v)" prop="name"
                  placeholder="Nome"></el-input>
              </el-form-item>
              <el-form-item prop="surname" required label="Cognome">
                <el-input :value="voispeedAdvertiseForm.surname" @input="v => handleFormInput('surname', v)"
                  prop="surname" placeholder="Cognome"></el-input>
              </el-form-item>
              <el-form-item prop="companyName" required label="Ragione Sociale">
                <el-input :value="voispeedAdvertiseForm.companyName" @input="v => handleFormInput('companyName', v)"
                  prop="surname" placeholder="Ragione Sociale"></el-input>
              </el-form-item>
              <el-form-item prop="email" required label="Email">
                <el-input :value="voispeedAdvertiseForm.email" @input="v => handleFormInput('email', v)" prop="email"
                  placeholder="Email">
                  <template #suffix><span>@</span></template>
                </el-input>
              </el-form-item>
              <el-form-item prop="phone" label="Telefono">
                <el-input :value="voispeedAdvertiseForm.phone" @input="v => handleFormInput('phone', v)" prop="phone"
                  placeholder="Telefono"></el-input>
              </el-form-item>
              <el-form-item prop="note" label="Note">
                <el-input type="textarea" :rows="4" :value="voispeedAdvertiseForm.note"
                  @input="v => handleFormInput('note', v)" prop="note" placeholder="Note"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <div class="text-center m-t">
          <el-button type="primary" @click="sendVoispeedAdvertise">
            <h3>Invia richiesta di contatto</h3>
          </el-button>
        </div>
      </div>
      <div class="text-right m-t-h">
        <img src="https://www.voispeed.com/wp-content/uploads/2021/04/logo_small.png" />
      </div>
      <el-dialog width="30%" title="Richiesta inviata" :visible.sync="innerVisible" append-to-body
        @close="() => setVoispeedAdvertiseDialog(false)">
        <div>Grazie, la tua richiesta è stata inviata e verrai contattato dal nostro team il prima possibile.</div>
        <div class="text-right m-t-h">
          <img src="https://www.voispeed.com/wp-content/uploads/2021/04/logo_small.png" style="width: 150px" />
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ElementUI from 'element-ui';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

// tslint:disable-next-line:variable-name
const PhoneBarAction = namespace('phone-bar').Action;
// tslint:disable-next-line:variable-name
const PhoneBarGetter = namespace('phone-bar').Getter;
// tslint:disable-next-line:variable-name
const PhoneBarMutation = namespace('phone-bar').Mutation;
@Component({
  name: 'VoispeedAdvertise',
  components: {},
})
export default class VoispeedAdvertise extends Vue {
  @Getter user: IUser;

  @PhoneBarGetter voispeedAdvertiseForm: {
    name: string;
    surname: string;
    companyName: string;
    email: string;
    phone: string;
  };

  @PhoneBarGetter voispeedAdvertiseDialog: boolean;

  @PhoneBarMutation setVoispeedAdvertiseDialog: (visible: boolean) => void;

  @PhoneBarMutation setAdvertiseForm: (payload: { name: string; surname: string; companyName: string; email: string; phone: string }) => void;

  @PhoneBarAction initAdvertiseForm: () => Promise<any>;

  @PhoneBarAction sendAdvertiseForm: () => Promise<any>;

  innerVisible = false;

  rules: any = {};

  mounted() {
    this.rules = {
      name: [
        {
          required: true,
          message: 'Il campo "Nome" è obbligatorio',
          trigger: 'blur',
        },
      ],
      surname: [
        {
          required: true,
          message: 'Il campo "Cognome" è obbligatorio',
          trigger: 'blur',
        },
      ],
      companyName: [
        {
          required: true,
          message: 'Il campo "Ragione Sociale" è obbligatorio',
          trigger: 'blur',
        },
      ],
      email: [
        {
          required: true,
          message: this.$t('login.validation.email'),
          trigger: 'blur',
        },
        {
          type: 'email',
          message: this.$t('login.validation.email'),
          trigger: 'blur',
        },
      ],
    };
  }

  handleFormInput(name: string, value: string) {
    this.setAdvertiseForm({
      ...this.voispeedAdvertiseForm,
      [name]: value,
    });
  }

  sendVoispeedAdvertise() {
    (this.$refs.adform as ElementUI.Form).validate(async (valid) => {
      if (valid) {
        await this.sendAdvertiseForm();
        this.innerVisible = true;
      }
      return false;
    });
  }
}
</script>
<style lang="scss" scoped>
.voispeed-advertise {}
</style>
