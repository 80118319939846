import Vue from 'vue';
import { VueConstructor } from 'vue/types/vue';
import moment from 'moment';
import VueRouter from 'vue-router';

export class ErrorHandler {
  private safeGetResponseHeader(e, h: string): string {
    try {
      return e.request.getResponseHeader(h);
    } catch {
      return null;
    }
  }

  async handleWebApiException(e): Promise<void> {
    if (e.data && (e.data.exceptionMessage || e.data.message)) {
      const message = e.data.exceptionMessage || e.data.message;
      const { title } = e.data;
      const msg = `<div class="pre-wrap">${message}</div>
      <div class="m-t-h text text-info text-0_8x lh-1x">
        <span class="nowrap m-r">DT: ${moment().format('L LT')}</span>
        <span class="nowrap m-r">VR: ${this.safeGetResponseHeader(e, 'server')}</span>
        <span class="nowrap m-r">MN: ${this.safeGetResponseHeader(e, 'machine-name')}</span>
      </div>`;
      await Vue.prototype.$alert(msg, title ?? `Error ${e.status.toString()}`, {
        customClass: 'webapi-error',
        type: 'error',
        roundButton: true,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        dangerouslyUseHTMLString: true,
      });
    }
  }

  async handleWebApiUnauthorized(e): Promise<void> {
    const message = e?.data?.exceptionMessage ?? e?.data?.message;
    if (message) {
      return await Vue.prototype.$alert(message, {
        type: 'warning',
        closeOnClickModal: true,
        closeOnPressEscape: true,
      });
    }
  }

  async handleWebApiExpired(e): Promise<void> {
    const message = e?.data?.exceptionMessage ?? e?.data?.message;
    if (message) {
      return await Vue.prototype.$alert(message, {
        type: 'warning',
        closeOnClickModal: true,
        closeOnPressEscape: true,
      });
    }
  }

  async handleNetworkError(): Promise<void> {
    return await Vue.prototype.$alert(<string>Vue.i18n.t('offline'), {
      type: 'warning',
      closeOnClickModal: true,
      closeOnPressEscape: true,
    });
  }
}

export default () => {
  Vue.use((v) => {
    const error = new ErrorHandler();
    v.prototype.$error = v.error = error;
  });
};
