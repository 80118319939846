//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ReportType {
	Html = 0,
	ExcelHtml = 1,
	PDF = 2,
	RTF = 3,
	ExcelXML = 4,
	XML = 5,
	ADO = 6,
	CSV = 7,
	TAB = 8,
	Directdataset = 9,
	DirectDataTable = 10,
	ExcelXMLPivot = 11,
	ExcelCalc = 12,
	ExcelXlsx = 13
}
