import Vue from 'vue';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);
require('moment/locale/it');
// TODO: cercare di usare import
moment.updateLocale('it', <any>{
  durationLabelsStandard: {
    m: 'minuto',
    mm: 'minuti',
    h: 'ora',
    hh: 'ore',
  },
  durationLabelsShort: {
    m: 'min',
    mm: 'mins',
    h: 'ore',
    hh: 'ore',
  },
});
export default () => {
  Vue.use((v) => {
    v.prototype.$moment = moment;
  });
};
