<template>
  <span>
    <span v-if="isPasswordSet" key="changePassword">
      <t-form-item
        :label="schema.text || schema.id"
        :prop="schema.propertyName"
        :required="schema.required"
        :important="schema.important"
      >
        <t-button type="primary" @click="handleChangePasswordClick">{{ $t('changePassword') }}</t-button>
      </t-form-item>
    </span>
    <span v-else>
      <t-form-item
        :label="schema.text || schema.id"
        :prop="schema.propertyName"
        :required="schema.required"
        :important="schema.important"
      >
        <el-input
          :value="password"
          @input="passwordChange"
          type="password"
          :disabled="schema.readOnly"
        ></el-input>
      </t-form-item>
      <t-form-item
        :label="$t('confirmPassword')"
        :prop="schema.propertyName"
        :required="schema.required"
        :important="schema.important"
      >
        <el-input
          :value="passwordConfirm"
          @input="passwordConfirmChange"
          type="password"
          :disabled="schema.readOnly"
        ></el-input>
      </t-form-item>
    </span>
  </span>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'PasswordInput',
  mixins: [simpleMixin],
})
export default class PasswordInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  password = '';

  passwordConfirm = '';

  isPasswordSet = false;

  mounted() {
    this.isPasswordSet = this.value.length > 0;
    this.password = this.value;
  }

  handleChangePasswordClick() {
    this.password = null;
    this.passwordConfirm = null;
    this.isPasswordSet = false;
    this.$emit('input', this.password);
  }

  passwordConfirmChange(value) {
    this.passwordConfirm = value;
    if (this.password === this.passwordConfirm) {
      this.$emit('input', this.password);
    } else this.$emit('input', '');
  }

  passwordChange(value) {
    this.password = value;
    if (this.password === this.passwordConfirm) {
      this.$emit('input', this.password);
    } else this.$emit('input', '');
  }
}
</script>
