<template>
  <t-form-item :label="schema.text || schema.id" :prop="schema.propertyName" :required="schema.required" key="input"
    :important="schema.important">
    <t-similarity-pop-over :value="value" :id="data.id" :type="formSchema.businessObjectType"
      :mode="$enums.SimilarityCheckType.VatId">
      <el-input :value="value" @input="change" :type="schema.type" :disabled="schema.readOnly" @change="autoCheckVatId">
        <template #append>
          <t-anagraphic-cerved :value="data" :term="value" @input="(v) => $emit('change', v)"></t-anagraphic-cerved>
        </template>
      </el-input>
    </t-similarity-pop-over>
  </t-form-item>
</template>

<script lang='ts'>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { VatIdController } from 'rt/UIApiControllers/UI/Smart/VatIdController';
import FlowTemplatesGridView from '../../../../modules/flowTemplate/views/FlowTemplatesGrid.vue';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';

@Component({
  name: 'VatInput',
  mixins: [simpleMixin],
})
export default class VatInput extends Vue {
  vatIdController = new VatIdController(Vue.axios);

  @Inject() formSchema;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: ICompanyDTO | IContactDTO | ILeadDTO;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  loading = false;

  change(value) {
    this.$emit('input', value);
  }

  async checkVatId() {
    await this.validateVatId('alert');
  }

  async autoCheckVatId() {
    // removed - use cerved
    // await this.validateVatId('notification');
  }

  async validateVatId(type: 'alert' | 'notification') {
    if (this.value) {
      this.loading = true;
      const res = await this.vatIdController.Check(this.value, this.data.state);
      this.loading = false;
      if (res) {
        let body = '';
        if (res.address || res.name) {
          body = `<h3>${res.name}</h3><div>${res.address}</div>`;
        }
        this.notifyVatIdResult(type, body, this.$t('vat.valid') as string, {
          type: 'success',
          dangerouslyUseHTMLString: true,
        });
      } else {
        this.notifyVatIdResult(type, this.$t('vat.invalidText') as string, this.$t('vat.invalid') as string, {
          type: 'warning',
          dangerouslyUseHTMLString: true,
        });
      }
    }
  }

  async notifyVatIdResult(type: 'alert' | 'notification', message: string, title: string, options: any) {
    if (type === 'alert') {
      this.$alert(message, title, options);
    } else {
      this.$notify({
        ...options,
        title,
        message,
      });
    }
  }
}
</script>
