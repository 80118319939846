<template>
  <el-row class="text-field-header">
    <el-col>
      <el-row :gutter="4" type="flex" class="w-100">
        <el-col v-if="allowFilter" :span="-1" class="overflow-clip">
          <el-popover v-model="popoverFilterOpen" ref="popover-filter" placement="top" trigger="click">
            <el-row type="flex" align="middle">
              <el-col :span="-1">
                <el-dropdown @command="changeOperatorFilter" trigger="click">
                  <span :class="operator.spriteClass"></span>
                  <template #dropdown><el-dropdown-menu>
                      <el-dropdown-item :command="operator" v-for="operator in column.operators.slice(1)"
                        :key="operator.value">
                        <t-tooltip-label :content="operator.resource" placement="right">
                          <span :class="operator.spriteClass"></span>
                        </t-tooltip-label>
                      </el-dropdown-item>
                    </el-dropdown-menu></template>
                </el-dropdown>
              </el-col>
              <el-col>
                <slot></slot>
              </el-col>
            </el-row>
          </el-popover>
          <i :class="popoverIconClass" v-popover:popover-filter></i>
        </el-col>
        <el-col class="header-ellipsis">
          <t-tooltip-label :content="column.resource">
            <span>{{ column.resource }}</span>
          </t-tooltip-label>
        </el-col>
        <sorting-column v-bind="$props" @sort-change="v => $emit('sort-change', v)"></sorting-column>
      </el-row>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import SortingColumn from './SortingColumn.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FieldHeader',
  components: {
    SortingColumn,
  },
})
export default class FieldHeader extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  tableColumn: any;

  @Prop({
    type: Object,
    required: true,
  })
  tableStore: any;

  @Prop({
    type: Boolean,
    required: true,
  })
  allowFilter: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  valid: boolean;

  @Prop({
    type: Array,
    required: false,
  })
  filter: any[];

  @Prop({
    required: false,
  })
  value: any;

  @Prop({
    type: Object,
    required: true,
  })
  operator: any;

  @Prop({
    type: Object,
    required: false,
    default: null,
  })
  sorting: any;

  popoverFilterOpen = false;

  changeOperatorFilter(newOperator) {
    this.$emit('operator-change', newOperator);
  }

  get popoverIconClass() {
    if (this.valid) {
      return 'el-icon-icon-filter3 success';
    }
    return 'el-icon-icon-filter3';
  }
}
</script>

<style lang="scss">
@import '~element-variables';

.text-field-header {
  .el-icon-icon-filter3 {
    cursor: pointer;

    &.success {
      color: $--color-success;
    }
  }
}

.header-ellipsis {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
