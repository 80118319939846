//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum TypeOfTemplateTicket {
	TicketOpen = 0,
	TicketOpenFromGrabber = 1,
	Solution = 2,
	CloseNotify = 3,
	CloseNotifyWithActivities = 4,
	PrintTicket = 5,
	PrintTicketWithActivities = 6,
	DefaultEmailResponse = 7
}
