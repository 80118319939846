//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ImportStatus {
	Serializing = 0,
	Running = 1,
	Paused = 2,
	Ended = 3
}
