import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import approx from 'approximate-number';
import { filterXSS } from 'xss';

export default () => {
  Vue.filter(
    'valid',
    (value: Date | string): boolean => {
      if (!value) return false;
      const m = moment(value);
      if (!m.isValid()) return false;
      return m.isAfter('1753-01-02');
    },
  );
  const hellip = (value: string, maxLength = 200, hellip = '...'): string => {
    if (!value) return value;
    if (value.length <= maxLength) return value;
    return `${value.substring(0, maxLength)} ${hellip}`;
  };
  const reverse = (value: string): string => {
    if (!value) return value;
    return _.reverse(value.split('')).join('');
  };
  Vue.filter('hellip', hellip);
  Vue.filter(
    'lhellip',
    (value: string, maxLength = 200, h = '...'): string => reverse(hellip(reverse(value), maxLength, h)),
  );
  Vue.filter('capitalize', (value: string) => _.capitalize(value));
  Vue.filter('camelCase', (value: string) => _.camelCase(value));
  Vue.filter('padStart', (value: string, length = 0, chars = ' ') => _.padStart(value, length, chars));
  Vue.filter('padEnd', (value: string, length = 0, chars = ' ') => _.padEnd(value, length, chars));
  Vue.filter('reverse', (value: any[]) => _.reverse(value));
  Vue.filter('approx', (value: number, options: any) => approx(value, options));
  Vue.filter('xss', (html: string) => filterXSS(html, {
      css: false,
      onTagAttr: (tag: string, name: string, value, isWhiteAttr) => {
        if (name === 'style') {
          return `style="${value}"`;
        }
        return null;
      },
      onTag: (tag, html, options) => {
        if (tag.endsWith('notallowed')) {
          return html;
        }
        return null;
      },
    }));
};
