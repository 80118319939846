//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FreeFieldsParentType {
	ActivitySubjectCode = 0,
	ActivityType = 1,
	CompanyContactType = 2,
	CompanyType = 3,
	CompanyCategories = 4,
	ContactCategories = 5,
	LeadCategory = 6,
	TicketArea = 7,
	TicketType = 8,
	OpportunityCategory = 9,
	LeadIndustry = 10,
	LeadSource = 11,
	CommercialZone = 12,
	TicketStatus = 13,
	ActivitySubType = 14,
	RollingStockMachineType = 15,
	RollingStockProductCategory = 16
}
