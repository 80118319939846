export default {
  back: 'Zurück',
  search: 'Suche',
  choose: 'Auswählen',
  note: 'Notizen',
  lock: {
    close: 'Schließen',
    title: '{Name} wird bereits verwendet',
    locked: '{name} ist in einem anderen Browser geöffnet. Klicken Sie auf „Hier verwenden“, um {name} in diesem Browser zu verwenden.',
    unlock: 'Verwenden Sie hier'
  },
  commands: {
    actions: 'Aktionen',
    save: 'Speichern',
    confirm: 'Bestätigung',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    remove: 'Entfernen',
    copy: 'Kopie',
    close: 'Schließen',
    view: 'Anzeigen',
    create: 'Erstellt',
    edit: 'Bearbeiten',
    yes: 'Ja',
    no: 'Nr',
    ok: 'Okay',
    never: 'Je',
    always: 'Ständig',
    convert: 'Konvertieren',
    reload: 'Aufladen',
    print: 'drucken',
    plus: 'Hinzufügen',
    export: 'Export',
    parameter: {
      value: 'Wert',
      dynamic: 'Dynamischer Parameter'
    },
    rename: 'Umbenennen',
    warning: 'Achtung!',
    next: 'Herein',
    copied: 'In die Zwischenablage kopiert',
    move: 'Umzug',
    deleteConfirm: 'Möchten Sie das Element wirklich löschen?',
    call: 'Wer liebt',
    clone: 'Duplizieren/Klonen',
    click: 'Klicke hier',
    discard: 'Verwerfen Sie die Änderungen',
    undo: 'Abbrechen',
    redo: 'Wiederholen',
    results: {
      savesuccess: 'Retten Sie erfolgreich!'
    },
    copyClipboard: 'In die Zwischenablage kopieren',
    rotateDevice: 'Drehen Sie Ihr Gerät'
  },
  lists: {
    list: 'Liste',
    kanban: 'Kanban'
  },
  password: {
    changed: 'Ihr Passwort wurde erfolgreich geändert',
    wrong: 'Das Kennwort ist ungültig. Möchten Sie noch einmal versuchen?',
    type: 'Geben Sie das neue Passwort',
    expired: 'Passwort abgelaufen'
  },
  token: {
    resign: 'Login',
    refresh: '{Botschaft}. Bitte melden Sie sich erneut an, um den Dienst weiter nutzen zu können.',
    expired: 'Zugriff auf {Server} nicht möglich'
  },
  layout: {
    messages: 'Nachrichten',
    logout: 'Ausfahrt',
    viewProfile: 'Mein Profil',
    mailbox: 'Posteingang',
    help: 'Brauchst du Hilfe?',
    guide: 'Gehen Sie zum Online-Leitfaden'
  },
  group: {
    selected: 'Ausgewählt',
    add: 'Gruppe hinzufügen',
    title: 'Gruppenmanagement',
    unselected: 'Nicht ausgewählt',
    manage: 'Gruppen verwalten'
  },
  alls: 'Alle',
  category: {
    global: 'global',
    personal: 'Personal'
  },
  lookup: {
    account: 'Konto',
    activity: 'Aktivitäten',
    zone: 'Bereich',
    catalogCategory: 'Produktkategorien'
  },
  alarms: {
    notification: {
      title: 'Ablauferinnerungen',
      dateAge: 'Gültig bis',
      note: 'Notizen',
      postpones: 'Verzögerung für {n} Minuten'
    }
  },
  mail: {
    to: 'An:',
    subject: 'Bezeichnung',
    bcc: 'BCC:',
    validation: {
      format: 'Die E-Mail {mail} hat kein gültiges Format',
      domain: 'Die Domain {domain} existiert nicht oder hat keinen gültigen MX',
      mailbox: 'Die Mail {mail} scheint auf dem Zielserver nicht zu existieren'
    },
    from: 'VON:',
    cc: 'CC:'
  },
  offline: 'Warnung, ein Netzwerkproblem wurde erkannt. Überprüfen Sie Ihre Internetverbindung und tun Sie es erneut',
  printing: 'Druck',
  confirmPassword: 'Kennwort bestätigen',
  changePassword: 'Passwort ändern',
  avatar: {
    error: {
      limit: 'Das Bild darf nicht größer als 2MB sein!',
      format: 'Das Bild muss im JPG- oder PNG-Format vorliegen'
    },
    discover: {
      title: 'Die folgenden Logos wurden für die angezeigte Registerkarte gefunden',
      link: 'Wählen Sie das Logo aus, das Sie mit der Karte verknüpfen möchten',
      source: 'Logoquelle: {Quelle}',
      found: 'Ja, gefunden, das ist es',
      nobody: 'Nein, nichts davon',
      searching: 'Ich suche im Netz',
      search: 'Online suchen'
    }
  },
  maps: {
    latitude: 'Breite',
    longitude: 'Längengrad',
    import: {
      search: 'Kartensuche',
      import: 'Importieren Sie diesen Datensatz'
    },
    coords: 'Koordinaten'
  },
  validation: {
    required: {
      once: 'Mindestens eines der Felder ist erforderlich',
      field: 'Das Feld {field} ist obligatorisch',
      fields: 'Es wurden nicht alle Pflichtfelder ausgefüllt',
      title: 'Obligatorischer Wert'
    }
  },
  popupBlocker: {
    detected: 'Popup-Blocker erkannt',
    deactivate: 'Hallo, vertrauen Sie uns, deaktivieren Sie den Popup-Blocker, um die CRM-Funktionen voll nutzen zu können!'
  },
  modules: {
    errors: {
      load: 'Beim Laden des Moduls „{module}“ ist ein Fehler aufgetreten. Stellen Sie sicher, dass keine Werbeblocker das ordnungsgemäße Laden des CRM verhindern, und versuchen Sie, die Seite neu zu laden.',
      reload: 'Seite aktualisieren'
    }
  },
  licence: {
    buy: 'Aktivieren Sie Ihre Lizenz',
    required: 'Funktion in der aktuellen Version nicht verfügbar',
    pro: {
      required: 'Funktionalität vorhanden von der Professional-Version'
    },
    business: {
      required: 'Funktion in der Business-Version vorhanden'
    }
  },
  loading: {
    modules: 'Modul-Downloads',
    profile: 'Ladeprofil',
    connecting: 'Anschließen des Systems',
    satellite: 'Ausrichten der Satelliten',
    ramp: 'Anheben der Startrampe',
    warmup: 'Aufwärmen der Motoren',
    testing: 'neueste Simulationen',
    starting: 'ein'
  },
  messages: {
    nodisplay: 'oops.. immer noch nichts zu sehen',
    nomessages: 'dir hat noch keiner geschrieben'
  },
  unauthorized: {
    read: 'Sie sind nicht berechtigt, auf Artikel in diesem Bereich zuzugreifen'
  },
  integrations: {
    skype: 'In Skype öffnen',
    linkedin: 'In Linkedin öffnen',
    office365: 'In Office365 öffnen',
    clickmeeting: 'In ClickMeeting öffnen',
    maps: 'Karte öffnen',
    lifesizecloud: 'In Lifesize öffnen',
    gotomeeting: 'In GoToMeeting öffnen',
    googlemeet: 'In Google Meet öffnen',
    skymeeting: 'In SkyMeeting öffnen',
    teams: 'In Teams öffnen',
    generic: 'In {hostname} öffnen',
    webex: 'In Webex öffnen',
    zoom: 'In Zoom öffnen',
    calendly: 'In Calendly öffnen'
  },
  form: {
    duration: {
      custom: 'Benutzerdefinierte Dauer'
    }
  }
};