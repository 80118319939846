//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MergePropertyType {
	Object = 0,
	Int = 1,
	Long = 2,
	Decimal = 3,
	String = 4,
	Char = 5,
	Float = 6,
	Double = 7,
	List = 8,
	Array = 9,
	Boolean = 10,
	DateTime = 11,
	NormalizeCity = 12,
	NormalizeAddress = 13,
	NormalizeMail = 14,
	OtherAddress = 15,
	FreeFields = 16,
	NormalizePhone = 17
}
