export default {
  back: 'Précédent',
  search: 'Recherche',
  choose: 'Sélectionner...',
  lists: {
    kanban: 'Kanban',
    list: 'Liste'
  },
  commands: {
    actions: 'actes',
    save: 'Enregistrer',
    confirm: 'Confirmation',
    cancel: 'Annuler',
    delete: 'Efface',
    convert: 'Convertir',
    remove: 'Supprimer',
    copy: 'Copie',
    close: 'Fermer',
    view: 'Affiche',
    create: 'Crée',
    edit: 'Éditer',
    yes: 'Oui',
    no: 'Non',
    ok: 'Bien',
    never: 'Jamais',
    always: 'Tout le temps',
    reload: 'De charge',
    print: 'Impression',
    plus: 'Ajouter',
    export: 'Exportation',
    parameter: {
      value: 'Valeur',
      dynamic: 'Paramètre dynamique'
    },
    rename: 'Changement de nom',
    warning: 'Attention!',
    click: 'Cliquez ici',
    next: 'Entrez',
    copied: 'Copié dans le presse-papier',
    clone: 'Dupliquer/Cloner',
    call: 'Qui aime',
    move: 'Bouge toi',
    deleteConfirm: 'Voulez-vous vraiment supprimer l\'élément ?',
    results: {
      savesuccess: 'Enregistrement exécutée avec succès !'
    },
    discard: 'Abandonner les changements',
    undo: 'Cancel',
    redo: 'Répéter',
    copyClipboard: 'Copier dans le presse-papier',
    rotateDevice: 'Faites pivoter votre appareil'
  },
  note: 'notes',
  password: {
    changed: 'Votre mot de passe a été changé avec succès',
    wrong: 'Le mot de passe est incorrect. Voulez-vous essayer à nouveau ?',
    type: 'Entrez le nouveau mot de passe',
    expired: 'Mot de passe expiré'
  },
  token: {
    resign: 'Connexion',
    expired: 'Impossible d\'accéder au {serveur}',
    refresh: '{message}. Veuillez vous reconnecter pour continuer à utiliser le service.'
  },
  layout: {
    messages: 'messages',
    logout: 'Sortie',
    viewProfile: 'Mon profil',
    mailbox: 'Boîte de réception',
    help: 'As-tu besoin d\'aide?',
    guide: 'Accéder au guide en ligne'
  },
  group: {
    selected: 'Sélectionné',
    add: 'Ajouter groupe',
    title: 'Gestion de groupe',
    unselected: 'Non séléctionné',
    manage: 'Gérer les groupes'
  },
  alls: 'Tous les',
  category: {
    global: 'global',
    personal: 'Personnel'
  },
  lookup: {
    account: 'Compte',
    activity: 'Activités',
    zone: 'Zone',
    catalogCategory: 'Catégories de produits'
  },
  alarms: {
    notification: {
      title: 'Rappels d\'expiration',
      dateAge: 'Date d\'expiration',
      note: 'notes',
      postpones: 'Retard de {n} minutes'
    }
  },
  mail: {
    to: 'À:',
    subject: 'Objet',
    from: 'À PARTIR DE:',
    bcc: 'Cci :',
    validation: {
      format: 'L\'e-mail {mail} n\'est pas dans un format valide',
      domain: 'Le domaine {domain} n\'existe pas ou n\'a pas de MX valide',
      mailbox: 'Le courrier {mail} ne semble pas exister sur le serveur cible'
    },
    cc: 'CC :'
  },
  lock: {
    close: 'Fermer',
    unlock: 'Utiliser ici',
    title: '{nom} déjà utilisé',
    locked: '{name} est ouvert dans un autre navigateur. Cliquez sur "Utiliser ici" pour utiliser {name} dans ce navigateur.'
  },
  confirmPassword: 'Confirmez le mot de passe',
  changePassword: 'Changer le mot de passe',
  offline: 'Attention, un problème réseau a été détecté. Vérifiez votre connexion Internet et recommencez',
  printing: 'Impression',
  avatar: {
    discover: {
      search: 'Rechercher en ligne',
      title: 'Les logos suivants ont été trouvés pour l\'onglet que vous consultez',
      link: 'Sélectionnez le logo que vous souhaitez associer à la carte',
      source: 'Source du logo : {source}',
      found: 'Oui, j\'ai trouvé, c\'est ça',
      nobody: 'Non, aucun de ceux-ci',
      searching: 'je cherche sur le web'
    },
    error: {
      limit: 'L\'image ne doit pas dépasser 2 Mo !',
      format: 'L\'image doit être au format JPG ou PNG'
    }
  },
  maps: {
    coords: 'Coordonnées',
    latitude: 'Latitude',
    longitude: 'Longitude',
    import: {
      search: 'Recherche par carte',
      import: 'Importer cet enregistrement'
    }
  },
  validation: {
    required: {
      fields: 'Tous les champs obligatoires n\'ont pas été remplis',
      title: 'Valeur obligatoire',
      once: 'Au moins un des champs est obligatoire',
      field: 'Le champ {champ} est obligatoire'
    }
  },
  popupBlocker: {
    detected: 'Bloqueur de pop-up détecté',
    deactivate: 'Salut, faites-nous confiance, désactivez le bloqueur de pop-up pour profiter pleinement des fonctionnalités du CRM !'
  },
  modules: {
    errors: {
      load: 'Une erreur s\'est produite lors du chargement du module \'{module}\'. Assurez-vous qu\'aucun bloqueur de publicités n\'empêche le CRM de se charger correctement et essayez de recharger la page.',
      reload: 'Recharge la page'
    }
  },
  licence: {
    buy: 'Activez votre licence',
    pro: {
      required: 'Fonctionnalité présente dès la version Professionnel'
    },
    business: {
      required: 'Fonctionnalité présente dans la version Business'
    },
    required: 'Fonctionnalité non disponible dans la version actuelle'
  },
  loading: {
    satellite: 'alignement des satellites',
    ramp: 'lever la rampe de lancement',
    warmup: 'chauffer les moteurs',
    testing: 'dernières simulations',
    starting: 'allumer',
    modules: 'téléchargements de modules',
    profile: 'profil de chargement',
    connecting: 'connecter le système'
  },
  messages: {
    nodisplay: 'oups.. toujours rien à voir',
    nomessages: 'personne ne t\'a encore écrit'
  },
  unauthorized: {
    read: 'Vous n\'êtes pas autorisé à accéder aux éléments de cette zone'
  },
  form: {
    duration: {
      custom: 'Durée personnalisée'
    }
  },
  integrations: {
    webex: 'Ouvrir dans Webex',
    zoom: 'Ouvrir en Zoom',
    calendly: 'Ouvert à Calendly',
    skype: 'Ouvrir dans Skype',
    linkedin: 'Ouvrir dans Linkedin',
    office365: 'Ouvrir dans Office365',
    clickmeeting: 'Ouvrir dans ClickMeeting',
    maps: 'Ouvrir la carte',
    lifesizecloud: 'Ouvrir dans Lifesize',
    gotomeeting: 'Ouvrir dans GoToMeeting',
    googlemeet: 'Ouvrir dans Google Meet',
    skymeeting: 'Ouvrir dans SkyMeeting',
    teams: 'Ouvrir dans les équipes',
    generic: 'Ouvrir dans {hostname}'
  }
};